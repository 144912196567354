import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { TabComponent } from "@syncfusion/ej2-react-navigations";
import { DialogComponent, DialogUtility } from "@syncfusion/ej2-react-popups";
import DOMPurify from "dompurify";
import * as React from "react";
import { msalInstance } from "../../../..";
import { addToFocusList } from "../../../../helpers/ACutils";
import {
  hideProgress,
  showProgress,
} from "../../../../RAFComponents/helpers/AppHelper";
import {
  getFocusListByRelatedUID,
  removeFromFocusList,
} from "../../../../RAFComponents/helpers/RAFMenuHelper";
import {
  isNotNullAndUndefined,
  isNullOrUndefined,
} from "../../../../RAFComponents/helpers/utils";
import { FormLibraryRow } from "../../../../RAFComponents/models/Common/FormLibraryRow";
import { createInstance } from "../../../../RAFComponents/Utility/FormUtility";
import { showSuccessToast } from "../../../../RAFComponents/Utility/RAFToastComponent";
import CreateActivity from "../../../../RAFModules/ActiveContacts/Activity/CreateActivity";
import RelatedActivityList from "../../../../RAFModules/ActiveContacts/Activity/RelatedActivityList";
import RelatedTaskList from "../../../../RAFModules/ActiveContacts/Dashboards/TaskDashboard/RelatedTaskList";
import RelatedDocumentList from "../../../../RAFModules/ActiveContacts/Document/RelatedDocumentList";
import RelatedDocumentListAttachButtons from "../../../../RAFModules/ActiveContacts/Document/RelatedDocumentListAttachButtons";
import ManageTask from "../../../../RAFModules/ActiveContacts/Task/ManageTask";
import TaskCardView2 from "../../../../RAFModules/ActiveContacts/Task/TaskCardView2";
import TaskDetails from "../../../../RAFModules/ActiveContacts/Task/TaskDetails";
import { completeTaskAndRemoveFromFocusList } from "../../../../RAFModules/ActiveContacts/Task/TaskHelper";
import { ConvertTaskRowToTaskDto } from "../../../../RAFModules/ActiveContacts/Task/TaskMappingRegister";
import { TaskRow } from "../../../../RAFModules/ActiveContacts/Task/TaskRow";
import { UserGroupRow } from "../../../../RAFModules/ActiveContacts/UserGroup/UserGroupRow";
import { MyTeamsContext } from "../../../../RAFModules/Common/Providers/MyTeamsContextProvider";
import { NavigateParamsProps } from "../../../../router";
import { 
  RAFBPStepType,
  RAFExecutionOrder,
  RAFBPStatus,
  RAFBPStepStatus,} from "../../../../constants/Common/RMConstants";
import { EntityRow } from "../../Entity/EntityRow";
import RAFIOCompmentModelRender from "../../FormLibrary/RAFIOCompmentModelRender";
import { RAFFormIOModel } from "../../RAFPage/PageLayoutJM";
import BPStepDecisionDataModel from "../helpers/BPStepDecisionDataModel";
import {
  CompleteTaskAndStep,
  RetrieveBusinessProcessStepById,
  SaveBusinessProcessStepAPI,
} from "../helpers/ProcessHelper";
import RAFFormDataModelRenderer from "../helpers/RAFFormDataModelRenderer";
import RAFQuestionDataModelRender from "../helpers/RAFQuestionModelRender";
import { BusinessProcessRow } from "../Process/BusinessProcessRow";
import { BusinessProcessStepRow } from "../Step/BusinessProcessStepRow";
import { BusinessProcessStepTemplateRow } from "../StepTemplate/BusinessProcessStepTemplateRow";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";

interface IProps {
  businessProcessStepRows?: BusinessProcessStepRow[];
  businessProcessStepRow?: BusinessProcessStepRow;
  businessProcessRow?: BusinessProcessRow;
  onSave?: (values) => void;
  onBPStepSave?: (
    allBPRows?: BusinessProcessStepRow[],
    dependentBPRows?: BusinessProcessStepRow[],
    currentBPStepRow?: BusinessProcessStepRow,
    businessProcessRow?: BusinessProcessRow
  ) => void;
  onBackClick?: () => void;
  index?: number;
  hideRelatedSections?: boolean;
  bpTemplateStepsRow?: BusinessProcessStepTemplateRow[];
  objectData?: any;
  relatedCommentsUpdated: () => void;
  relatedTaskUpdated: () => void;
  mode?: string;
  hideRelatedCommentSections?: boolean;
  hideRelateTaskSections?: boolean;
  hideRelatedDoumentSections?: boolean;
  allForms?: FormLibraryRow[];
  allEntities?: EntityRow[];
  relatedTaskDivKey?: number;
  relatedCommentDivKey?: number;
  relatedDocumnetDivKey?: number;
  onCreateNewActivity?: () => void;
  onUploadDocumentFile?: (UID: string) => void;
  customFooterContent?: React.ReactFragment | React.ReactNode;
}

interface IState {
  relatedTaskList: TaskRow[];
  showCreateTaskContent?: boolean;
  showTaskDetailsContent?: boolean;
  taskUID?: string;
  activitySummaryKey?: number;
  documentSummaryKey?: number;
  takSummaryKey?: number;
  relatedModulesKey?: number;
  mode?: string;
  selectedTabIndex?: number;
}

class ManageStep extends React.Component<NavigateParamsProps & IProps, IState> {
  entityGrid: TaskCardView2;
  constructor(props) {
    super(props);
    this.state = {
      relatedTaskList: [],
      showCreateTaskContent: false,
      showTaskDetailsContent: false,
      activitySummaryKey: Math.random(),
      documentSummaryKey: Math.random(),
      takSummaryKey: Math.random(),
      relatedModulesKey: Math.random(),
      mode: isNotNullAndUndefined(this.props.mode) ? this.props.mode : null,
      selectedTabIndex: 0,
    };
  }

  private hideRelatedCommentSections = isNotNullAndUndefined(
    this.props.hideRelatedCommentSections
  )
    ? this.props.hideRelatedCommentSections
    : false;

  private hideRelatedSections = isNotNullAndUndefined(
    this.props.hideRelatedSections
  )
    ? this.props.hideRelatedSections
    : false;
  //private upComingRafDataList: RAFDataList | null;
  //private upComingGrid: TaskCardView | null;
  private deleteFocusListDialog: any;
  private moduleName = createInstance(BusinessProcessStepRow).getClassName();
  _isMounted = false;
  private colmpleteTaskAlertDialog: any;
  private myTeams: UserGroupRow[] = [];

  componentDidMount = () => {
    this._isMounted = true;
    //this.refresh();

    /*
                    if (this.upComingRafDataList) {
                        let customFilter: RAFCustomFilter = {};
                        customFilter.Condition = "and";
                        customFilter.Rules = [];
                        this.upComingRafDataList.viewName = 'all_tasks';
                        if (isNotNullAndUndefined(businessProcessStepRow.UID)) {
                            let relatedToFilter: RAFCustomFilter = {};
                            let relatedToFilterVal: string[] = [];
                            relatedToFilterVal.push(businessProcessStepRow.UID);
                            relatedToFilter.Operator = RAFCustomOperator.Equal;
                            relatedToFilter.Value = relatedToFilterVal;
                            relatedToFilter.Field = propertyOf<TaskRow>("SecondaryRelatedToUID");
                            customFilter.Rules.push(relatedToFilter);
                        }
            
                        let relatedToFilter1: RAFCustomFilter = {};
                        //let relatedToFilterVal1: string[] = [];
                        //relatedToFilterVal1.push(businessProcessStepRow.UID);
                        relatedToFilter1.Operator = RAFCustomOperator.IsNull;
                        //relatedToFilter1.Value = relatedToFilterVal;
                        relatedToFilter1.Field = propertyOf<TaskRow>("BPStepUID");
                        customFilter.Rules.push(relatedToFilter1);
            
                        this.upComingRafDataList.additionalFilter = customFilter;
                        if (this.upComingGrid) {
                            this.upComingGrid.setSelectColumnsRefreshData(['TaskDate', 'Title', 'Assignee', 'TaskStatus', 'CompletedDate', 'ModifiedDate'], true);
                        }
                    }
                    */
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  rowClick = (taskRow: TaskRow) => {
    this.taskDetailsDialogOpen(taskRow.UID);
    //if (taskRow.TaskStatus === RAFActionStatus.Completed) {
    //    showWarningToast('This task has been completed')
    //} else {
    //    this.taskDetailsDialogOpen(taskRow.UID);
    //}
  };

  createTaskDialogOpen = () => {
    document.body.classList.add("overflow-hidden");
    if (this._isMounted) {
      this.setState({ showCreateTaskContent: true });
    }
  };

  createTaskDialogClose = () => {
    if (this._isMounted) {
      this.setState({ showCreateTaskContent: false });
    }
  };

  createTaskContent = () => {
    let { businessProcessStepRow, businessProcessRow } = this.props;
    if (
      isNotNullAndUndefined(this.state.showCreateTaskContent) &&
      this.state.showCreateTaskContent === true
    ) {
      return (
        <ManageTask
          onSave={() => this.refreshOnCreateNewTask()}
          onClose={this.createTaskDialogClose.bind(this)}
          secondaryRelatedToType={businessProcessStepRow.StepType}
          secondaryRelatedToUID={businessProcessStepRow.UID}
          secondaryRelatedTo={businessProcessStepRow.Title}
          relatedToType={businessProcessRow.RelatedToType}
          relatedToUID={businessProcessRow.RelatedToUID}
          relatedTo={businessProcessRow.RelatedTo}
          isActive
          isCreateTask
        />
      );
    } else {
      return <div></div>;
    }
  };

  refreshOnCreateNewTask = () => {
    let { businessProcessStepRow } = this.props;
    if (this._isMounted) {
      this.setState({ showCreateTaskContent: false }, () => {
        this.refreshTask();
        this.saveBPStep(businessProcessStepRow);
        if (this.props.relatedTaskUpdated) {
          this.props.relatedTaskUpdated();
        }
      });
    }
  };

  taskDetailsDialogOpen = (id) => {
    if (this._isMounted) {
      this.setState({ showTaskDetailsContent: true, taskUID: id }, () => {
        document.body.classList.add("overflow-hidden");
      });
    }
  };

  taskDetailsDialogClose = () => {
    if (this._isMounted) {
      this.setState({ showTaskDetailsContent: false });
    }
  };

  taskDetailsContent = () => {
    if (
      isNotNullAndUndefined(this.state.showTaskDetailsContent) &&
      this.state.showTaskDetailsContent === true
    ) {
      return (
        <div>
          <TaskDetails
            onUpdate={() => {
              this.refreshOnUpdateTask();
            }}
            taskUID={this.state.taskUID}
            isActive
            onClose={this.taskDetailsDialogClose.bind(this)}
            complete={(taskRow, taskStatus) =>
              this.CompleteTaskByStatus(taskRow, taskStatus)
            }
          />
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  refreshOnUpdateTask = () => {
    if (this._isMounted) {
      this.setState({ showTaskDetailsContent: false }, () => {
        if (this.props.relatedTaskUpdated) {
          this.props.relatedTaskUpdated();
        }
        this.refreshTask();
      });
    }
  };

  refreshTask = () => {
    let { businessProcessStepRow } = this.props;
    if (this._isMounted) {
      this.setState({ takSummaryKey: Math.random() });
    }
    /*
                    if (this.upComingRafDataList) {
                        let customFilter: RAFCustomFilter = {};
                        customFilter.Condition = "and";
                        customFilter.Rules = [];
                        this.upComingRafDataList.viewName = 'all_tasks';
            
                        let relatedToFilter: RAFCustomFilter = {};
                        let relatedToFilterVal: string[] = [];
            
                        if (isNotNullAndUndefined(businessProcessStepRow.UID)) {
                            relatedToFilterVal.push(businessProcessStepRow.UID);
                            relatedToFilter.Operator = RAFCustomOperator.Equal;
                            relatedToFilter.Value = relatedToFilterVal;
                            relatedToFilter.Field = propertyOf<TaskRow>("SecondaryRelatedToUID");
                            customFilter.Rules.push(relatedToFilter);
                        }
            
                        let relatedToFilter1: RAFCustomFilter = {};
                        relatedToFilter1.Operator = RAFCustomOperator.IsNull;
                        relatedToFilter1.Field = propertyOf<TaskRow>("BPStepUID");
                        customFilter.Rules.push(relatedToFilter1);
            
                        this.upComingRafDataList.additionalFilter = customFilter;
                        if (this.upComingGrid) {
                            this.upComingGrid.setSelectColumnsRefreshData(['TaskDate', 'Title', 'Assignee', 'TaskStatus', 'CompletedDate', 'ModifiedDate'], true);
                        }
                    }
                    */
  };

  CompleteTask = (selectedTaskRow: TaskRow, taskStatus: string) => {
    let isMyTeamExist = isNotNullAndUndefined(selectedTaskRow.AssignTeamUID)
      ? this.myTeams.find((x) => x.UID === selectedTaskRow.AssignTeamUID)
      : null;
    if (
      (isNotNullAndUndefined(selectedTaskRow.AssigneeUID) &&
        selectedTaskRow.AssigneeUID === msalInstance.currentUserId) ||
      (isNotNullAndUndefined(selectedTaskRow.AssignTeamUID) &&
        isNotNullAndUndefined(isMyTeamExist))
    ) {
      this.CompleteTaskByStatus(selectedTaskRow, taskStatus);
    } else {
      this.ColmpleteTaskAlert(selectedTaskRow, taskStatus);
    }
  };

  ColmpleteTaskAlert(taskRow: TaskRow, taskStatus: string) {
    this.colmpleteTaskAlertDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content:
        "Are you sure you want to complete this task assigned to another user?",
      okButton: {
        text: "Yes",
        click: this.CompleteTaskByStatus.bind(this, taskRow, taskStatus),
      },
      showCloseIcon: true,
      title: " Complete Task",
      position: { X: "center", Y: "center" },
      cssClass: "alert-dialog",
    });
  }

  CompleteTaskByStatus = (selectedTaskRow: TaskRow, taskStatus: string) => {
    this.colmpleteTaskAlertDialog && this.colmpleteTaskAlertDialog.hide();
    let taskRow = ConvertTaskRowToTaskDto(selectedTaskRow);
    let progressDiv = showProgress("body", true);
    let businessProcessStepRow: BusinessProcessStepRow =
      this.props.businessProcessStepRow;
    let businessProcessStepRows: BusinessProcessStepRow[] =
      this.props.businessProcessStepRows;
    let businessProcessRow: BusinessProcessRow = this.props.businessProcessRow;
    let bpTemplateStepsRow: BusinessProcessStepTemplateRow[] =
      this.props.bpTemplateStepsRow;
    let objectData = this.props.objectData;
    if (
      isNotNullAndUndefined(taskRow.BPStepUID) &&
      isNotNullAndUndefined(businessProcessStepRow) &&
      businessProcessStepRow.UID === taskRow.BPStepUID
    ) {
      CompleteTaskAndStep(
        businessProcessStepRow,
        businessProcessStepRows,
        businessProcessRow,
        bpTemplateStepsRow,
        objectData,
        taskStatus
      ).then((result) => {
        hideProgress(progressDiv);
        this.taskDetailsDialogClose();
        this.refreshTask();
        if (this.props.onBPStepSave) {
          this.props.onBPStepSave(
            result.allBPRows,
            result.dependentBPRows,
            businessProcessStepRow,
            businessProcessRow
          );
        }
      });
    } else {
      taskRow.TaskStatus = taskStatus;

      completeTaskAndRemoveFromFocusList(taskRow)
        .then((response) => {
          hideProgress(progressDiv);
          //if (isNotNullAndUndefined(response)) {
          this.taskDetailsDialogClose();
          this.refreshTask();
          if (this.props.relatedTaskUpdated) {
            this.props.relatedTaskUpdated();
          }
          // }
        })
        .catch((error) => error);
    }
  };

  onFocusClicked = (relatedTo: string, relatedUID: string) => {
    getFocusListByRelatedUID(relatedUID).then((isFocused) => {
      if (isFocused) {
        this.RemoveFromFocusList(relatedUID);
      } else {
        this.AddToFocusList(relatedTo, relatedUID);
      }
    });
  };

  AddToFocusList = (relatedTo: string, relatedUID: string) => {
    if (isNotNullAndUndefined(relatedTo) && isNotNullAndUndefined(relatedUID)) {
      addToFocusList(relatedTo, this.moduleName, relatedUID).then(
        (response) => {
          if (isNotNullAndUndefined(response)) {
            showSuccessToast("Added to my focus list");
            this.refreshTask();
          }
        }
      );
    }
  };

  RemoveFromFocusList = (relatedUID: string) => {
    this.deleteFocusListDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Already added in focus list! Are you sure want to remove?",
      okButton: {
        text: "Yes",
        click: this.onRemoveFocusList.bind(this, relatedUID),
      },
      showCloseIcon: true,
      title: "Remove Item",
      position: { X: "center", Y: "center" },
      cssClass: "alert-dialog e-small",
    });
  };

  onRemoveFocusList = (relatedUID: string) => {
    removeFromFocusList(relatedUID).then((response) => {
      if (isNotNullAndUndefined(response)) {
        showSuccessToast("Removed from my focus list");
        this.deleteFocusListDialog.hide();
        this.refreshTask();
      }
    });
  };

  saveBPStep = (bpStepRow: BusinessProcessStepRow) => {
    this.setState({ documentSummaryKey: Math.random() }, () => {
      RetrieveBusinessProcessStepById(bpStepRow.UID).then((btStepResponse) => {
        if (isNullOrUndefined(btStepResponse)) {
          SaveBusinessProcessStepAPI(bpStepRow).then(() => { });
        }
      });
    });
  };

  tabSelected = (selectedTabIndex) => {
    this.setState({ selectedTabIndex });
  };
  onCreateNewActivity = () => {
    if (isNotNullAndUndefined(this.props.onCreateNewActivity))
      this.props.onCreateNewActivity();
  };

  private renderTabContent = () => {
    let businessProcessStepRow: BusinessProcessStepRow =
      this.props.businessProcessStepRow;
    let selectedTabIndex = this.state.selectedTabIndex;
    let businessProcessRow: BusinessProcessRow = this.props.businessProcessRow;
    if (selectedTabIndex === 1) {
      return (
        <div className="p-3 border-top">
          <div className="row g-0 gy-2 gy-md-3">
            <div className="col-12">
              <CreateActivity
                onUpdate={() => {
                  if (
                    isNotNullAndUndefined(businessProcessRow) &&
                    businessProcessRow.RelatedToType !==
                    RAFEntityName.BusinessProcess
                  ) {
                    this.setState({ activitySummaryKey: Math.random() }, () => {
                      if (this.props.relatedCommentsUpdated) {
                        this.props.relatedCommentsUpdated();
                      }
                    });
                  } else {
                    this.setState({ activitySummaryKey: Math.random() });
                  }
                }}
                secondaryRelatedToType={RAFEntityName.BusinessProcessStep}
                secondaryRelatedToUID={businessProcessStepRow.UID}
                secondaryRelatedTo={businessProcessStepRow.Title}
                relatedToUID={
                  businessProcessRow && businessProcessRow.RelatedToUID
                }
                relatedTo={businessProcessRow && businessProcessRow.RelatedTo}
                entityName={
                  businessProcessRow && businessProcessRow.RelatedToType
                }
                addBtnText={"Add"}
              />
            </div>
            <div className="col-12">
              <RelatedActivityList
                key={this.state.activitySummaryKey}
                relatedCommentsUpdated={() => {
                  if (this.props.relatedCommentsUpdated) {
                    this.props.relatedCommentsUpdated();
                  }
                }}
                secondaryRelatedToUID={businessProcessStepRow.UID}
                // relatedToUID={
                //     businessProcessRow && businessProcessRow.UID
                // }
                entityName={
                  businessProcessRow && businessProcessRow.RelatedToType
                }
                // showFirstComment={true}
                showFirstComment={false}
                showCollapsePanel={false}
                uiMode="table"
                {...this.props}
              />
            </div>
          </div>
        </div>
      );
    } else if (selectedTabIndex === 2) {
      return (
        <div className="relatedCardCol">
          {/* <div className="bg-grey p-2">
            <div className="row g-2">
              <div className="col-auto">
                <ButtonComponent type="button" cssClass="primary-custom-button" content="Attach photo"></ButtonComponent>
              </div>
              <div className="col-auto">
                <ButtonComponent type="button" cssClass="primary-custom-button" content="Attach file"></ButtonComponent>
              </div>
            </div>
          </div> */}
          <div className="row flex-column">
            <div className="col">
              <div className="border-top border-bottom">
                <RelatedDocumentList
                  key={this.state.documentSummaryKey}
                  moduleName={this.moduleName}
                  showLookupGrid={false}
                  documentCreated={() => {
                    this.saveBPStep(businessProcessStepRow);
                  }}
                  collapsePanelArrowPosistion="title"
                  secondaryRelatedToUID={businessProcessStepRow.UID}
                  secondaryRelatedTo={businessProcessStepRow.Title}
                  secondaryRelatedToType={RAFEntityName.BusinessProcessStep}
                  relatedToUID={
                    businessProcessRow && businessProcessRow.RelatedToUID
                  }
                  relatedTo={businessProcessRow && businessProcessRow.RelatedTo}
                  relatedEntity={
                    businessProcessRow && businessProcessRow.RelatedToType
                  }
                  // showAddBtn
                  {...this.props}
                />
              </div>
            </div>
            {this.state.selectedTabIndex === 2 && (
              <div className="col-auto">
                <RelatedDocumentListAttachButtons
                  key={this.state.documentSummaryKey}
                  moduleName={this.moduleName}
                  documentCreated={() => {
                    this.saveBPStep(businessProcessStepRow);
                  }}
                  secondaryRelatedToUID={businessProcessStepRow.UID}
                  secondaryRelatedTo={businessProcessStepRow.Title}
                  secondaryRelatedToType={RAFEntityName.BusinessProcessStep}
                  relatedToUID={
                    businessProcessRow && businessProcessRow.RelatedToUID
                  }
                  relatedTo={businessProcessRow && businessProcessRow.RelatedTo}
                  relatedEntity={
                    businessProcessRow && businessProcessRow.RelatedToType
                  }
                  showAddBtn
                  {...this.props}
                ></RelatedDocumentListAttachButtons>
              </div>
            )}
          </div>
        </div>
      );
    } else if (selectedTabIndex === 3) {
      let {
        businessProcessStepRow,
        businessProcessRow,
        businessProcessStepRows,
        bpTemplateStepsRow,
        objectData,
        onBPStepSave,
      } = this.props;
      return (
        <div className="relatedCardCol border-top">
          <RelatedTaskList
            businessProcessStepRow={businessProcessStepRow}
            businessProcessRow={businessProcessRow}
            relatedTaskDivKey={this.state.takSummaryKey}
            businessProcessStepRows={businessProcessStepRows}
            bpTemplateStepsRow={bpTemplateStepsRow}
            objectData={objectData}
            onBPStepSave={onBPStepSave}
            key={this.state.takSummaryKey}
            showEmptyState={false}
          />
        </div>
      );
    }
  };

  render() {
    let hideRelateTaskSections = isNotNullAndUndefined(
      this.props.hideRelateTaskSections
    )
      ? this.props.hideRelateTaskSections
      : false;
    let hideRelatedDoumentSections = isNotNullAndUndefined(
      this.props.hideRelatedDoumentSections
    )
      ? this.props.hideRelatedDoumentSections
      : false;

    let businessProcessStepRow: BusinessProcessStepRow =
      this.props.businessProcessStepRow;
    let businessProcessStepRows: BusinessProcessStepRow[] =
      this.props.businessProcessStepRows;
    let businessProcessRow: BusinessProcessRow = this.props.businessProcessRow;
    let stepCssClass;
    let stepIconClass;
    let stepOrder;
    let stepStatus = businessProcessStepRow.StepStatus;
    let toDoIndex = 0;
    // let stepTitle: string = businessProcessStepRow.Title;

    let processsExecutionOrder: string =
      businessProcessRow && businessProcessRow.ExecutionOrder;
    let parentStepUID: string = businessProcessStepRow.ParentBpStepTemplateUID;
    stepOrder = businessProcessStepRow.StepOrder;
    let parentStep =
      businessProcessStepRows &&
      businessProcessStepRows.find(
        (x) => x.BpStepTemplateUID === parentStepUID
      );
    if (stepStatus === RAFBPStepStatus.Completed) {
      stepOrder = null;
      stepCssClass = "bp-step-avatar completed-step-avatar";
      stepIconClass = "fa fa-check";
    } else if (stepStatus === RAFBPStepStatus.Approved) {
      stepOrder = null;
      stepCssClass = "bp-step-avatar completed-step-avatar";
      stepIconClass = "far fa-thumbs-up";
    } else if (stepStatus === RAFBPStepStatus.Rejected) {
      stepOrder = null;
      stepCssClass = "bp-step-avatar rejected-step-avatar";
      stepIconClass = "far fa-thumbs-down";
    } else if (stepStatus === RAFBPStepStatus.Skipped) {
      stepOrder = null;
      stepCssClass = "bp-step-avatar skipped-step-avatar";
      stepIconClass = "fa fa-ban";
    } else if (stepStatus === RAFBPStepStatus.ToDo) {
      if (toDoIndex === 0) {
        stepOrder = null;
        stepCssClass = "bp-step-avatar todo-step-avatar";
        stepIconClass = "fa fa-ellipsis-h";
        toDoIndex++;
      } else {
        if (
          isNotNullAndUndefined(processsExecutionOrder) &&
          processsExecutionOrder === RAFExecutionOrder.AnyOrder
        ) {
          stepOrder = null;
          stepCssClass = "bp-step-avatar";
          stepIconClass = "fa fa-random";
        } else {
          stepCssClass = "bp-step-avatar";
        }
      }
    } else if (isNotNullAndUndefined(parentStepUID)) {
      if (
        isNotNullAndUndefined(parentStep) &&
        parentStep.ExecutionOrder === RAFExecutionOrder.AnyOrder
      ) {
        stepOrder = null;
        stepCssClass = "bp-step-avatar";
        stepIconClass = "fa fa-random";
      } else {
        stepCssClass = "bp-step-avatar";
      }
    } else {
      if (
        isNotNullAndUndefined(processsExecutionOrder) &&
        processsExecutionOrder === RAFExecutionOrder.AnyOrder
      ) {
        //   stepOrder = null;
        stepCssClass = "bp-step-avatar";
        stepIconClass = "fa fa-random";
      } else {
        stepCssClass = "bp-step-avatar";
      }
    }

    let addTaskBtn =
      businessProcessStepRow.StepStatus === RAFBPStepStatus.ToDo ||
        businessProcessStepRow.StepStatus === RAFBPStepStatus.Assigned ||
        businessProcessStepRow.StepStatus ===
        RAFBPStepStatus.AwaitingforApproval ? (
        <div>
          <ButtonComponent
            type="button"
            cssClass="e-small d-none primary-custom-button d-sm-inline-block"
            iconCss="fas fa-plus"
            onClick={() => this.createTaskDialogOpen()}
          >
            Add
          </ButtonComponent>
          <ButtonComponent
            type="button"
            cssClass="e-small primary-custom-button d-sm-none"
            iconCss="fas fa-plus"
            onClick={() => this.createTaskDialogOpen()}
          ></ButtonComponent>
        </div>
      ) : (
        <div></div>
      );
    const loggedinUserId = msalInstance.currentUserId;
    let bpStepTemplateRows = this.props.bpTemplateStepsRow;
    let currentBPStepTemplateRow =
      bpStepTemplateRows &&
      bpStepTemplateRows.find(
        (x) => x.UID === businessProcessStepRow.BpStepTemplateUID
      );
    let currentBPStepConnector =
      currentBPStepTemplateRow && currentBPStepTemplateRow.StepConnectorJson;

    return (
      <div>
        <MyTeamsContext.Consumer>
          {({ teams }) => {
            this.myTeams = teams;
            let isMyTeam = [];
            if (
              isNotNullAndUndefined(isMyTeam) &&
              isNotNullAndUndefined(businessProcessStepRow.AssignTeamUID)
            ) {
              isMyTeam =
                teams &&
                teams.filter(
                  (x) => x.UID === businessProcessStepRow.AssignTeamUID
                );
            }

            let assignText = isNotNullAndUndefined(
              businessProcessStepRow.AssignTeam
            )
              ? businessProcessStepRow.AssignTeam
              : businessProcessStepRow.Assignee;
            let isApprovalText = businessProcessStepRow.IsApproval
              ? " can approve this step"
              : " can complete this step";
            let isMembersText = isNotNullAndUndefined(
              businessProcessStepRow.AssignTeam
            )
              ? "' members "
              : "' ";
            let warningText =
              "Only '" + assignText + isMembersText + isApprovalText;

            let allowSubmit = true;
            if (
              isNotNullAndUndefined(businessProcessRow) &&
              isNotNullAndUndefined(businessProcessStepRow)
            ) {
              allowSubmit =
                businessProcessRow.ProcessStatus === RAFBPStatus.Completed ||
                  businessProcessStepRow.StepStatus === RAFBPStepStatus.Completed
                  ? false
                  : true;
            }
            let selectedFormLibrary: FormLibraryRow;

            if (
              isNotNullAndUndefined(businessProcessRow) &&
              isNotNullAndUndefined(businessProcessStepRow.FormUID)
            ) {
              selectedFormLibrary = isNotNullAndUndefined(this.props.allForms)
                ? this.props.allForms.find(
                  (x) => x.UID === businessProcessStepRow.FormUID
                )
                : null;
            }
            return (
              <div>
                {/* <div className="title-value pb-3 pt-2 d-flex align-items-center">
                            <div className="me-2">
                                {businessProcessStepRow.StepType === RAFBPStepType.Group ?
                                    <div className="treeview-avatar p-2 show-badge position-relative bg-white">
                                        <RAFIconImage iconCssClass={stepIconClass} value={stepOrder}
                                            moduleavatar={stepCssClass} tooltipText={stepTitle}
                                            iconSize="25" fontSize="12" showFullText></RAFIconImage>
                                        <span className="treeview-folder-avatar-badge">
                                            <i className="fa fa-folder"></i>
                                        </span>
                                    </div>
                                    :
                                    <div className="treeview-avatar p-2 position-relative bg-white">
                                        <RAFIconImage value={stepOrder} tooltipText={stepTitle} iconCssClass={stepIconClass} moduleavatar={stepCssClass} iconSize="30" fontSize="12" showFullText></RAFIconImage>
                                    </div>
                                }
                            </div>
                            <div>
                                <span className="">{businessProcessStepRow.Title}</span>
                                {isNotNullAndUndefined(businessProcessStepRow.Description) ? <div className="secondary-value p-1 ps-0"><span>{businessProcessStepRow.Description}</span></div> : null}
                            </div>
                        </div>*/}

                {((isNotNullAndUndefined(businessProcessStepRow.AssigneeUID) &&
                  businessProcessStepRow.AssigneeUID !== loggedinUserId) ||
                  (isNotNullAndUndefined(
                    businessProcessStepRow.AssignTeamUID
                  ) &&
                    isNotNullAndUndefined(isMyTeam) &&
                    isMyTeam.length === 0)) &&
                  (businessProcessStepRow.StepStatus === RAFBPStepStatus.ToDo ||
                    businessProcessStepRow.StepStatus ===
                    RAFBPStepStatus.Assigned ||
                    businessProcessStepRow.StepStatus ===
                    RAFBPStepStatus.AwaitingforApproval) && (
                    <div className="mb-2 hidden">
                      <div
                        className="badge-status-item text-nowrap"
                        style={{
                          fontSize: "12px",
                          padding: "10px 10px",
                          borderLeft: "3px solid #0078D7",
                        }}
                      >
                        {warningText}
                      </div>
                    </div>
                  )}
                <div className="">
                  <div className="row g-0 gy-3 manageStep-tab-row">
                    {/* <div className="col-12 hidden">
                      <span className="text-wrap ecllipseFirstLine title-value">
                        {businessProcessStepRow.Title}
                      </span>
                      {/* {(businessProcessStepRow.StepStatus === RAFBPStepStatus.Completed || businessProcessStepRow.StepStatus === RAFBPStepStatus.Approved || businessProcessStepRow.StepStatus === RAFBPStepStatus.Skipped || businessProcessStepRow.StepStatus === RAFBPStepStatus.Rejected) &&
                                        <div className="">
                                            <div className="row g-2">
                                                 <div className="col-auto">
                                                    {businessProcessStepRow.StepStatus === RAFBPStepStatus.Completed && <span className="e-badge bp-step-avatar completed-step-avatar" style={{ padding: '5px 10px', cursor: 'pointer' }}>{businessProcessStepRow && businessProcessStepRow.StepStatus}</span>}
                                                    {businessProcessStepRow.StepStatus === RAFBPStepStatus.Approved && <span className="e-badge bp-step-avatar completed-step-avatar" style={{ padding: '5px 10px', cursor: 'pointer' }}>{businessProcessStepRow && businessProcessStepRow.StepStatus}</span>}
                                                    {businessProcessStepRow.StepStatus === RAFBPStepStatus.Rejected && <span className="e-badge bp-step-avatar rejected-step-avatar" style={{ padding: '5px 10px', cursor: 'pointer' }}>{businessProcessStepRow && businessProcessStepRow.StepStatus}</span>}
                                                    {businessProcessStepRow.StepStatus === RAFBPStepStatus.Skipped && <span className="e-badge bp-step-avatar skipped-step-avatar" style={{ padding: '5px 10px', cursor: 'pointer' }}>{businessProcessStepRow && businessProcessStepRow.StepStatus}</span>}
                                                </div>
                                                <div className="col-auto">
                                                    <div className="recordInfomsg">
                                                        <RAFRecordInfo createdBy={businessProcessStepRow.CreatedBy} createdDate={businessProcessStepRow.CreatedDate} modifiedDate={businessProcessStepRow.ModifiedDate} modifiedBy={businessProcessStepRow.ModifiedBy} lastActivityDate={businessProcessStepRow.LastActivityDate} content=""></RAFRecordInfo>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* {(businessProcessStepRow.StepStatus === RAFBPStepStatus.Completed || businessProcessStepRow.StepStatus === RAFBPStepStatus.Approved || businessProcessStepRow.StepStatus === RAFBPStepStatus.Skipped || businessProcessStepRow.StepStatus === RAFBPStepStatus.Rejected) &&
                                                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                                                        <div className="me-2">
                                                                                                            {businessProcessStepRow.StepStatus === RAFBPStepStatus.Completed && <span className="e-badge bp-step-avatar completed-step-avatar" style={{ padding: '5px 10px', cursor: 'pointer' }}>{businessProcessStepRow && businessProcessStepRow.StepStatus}</span>}
                                                                                                            {businessProcessStepRow.StepStatus === RAFBPStepStatus.Approved && <span className="e-badge bp-step-avatar completed-step-avatar" style={{ padding: '5px 10px', cursor: 'pointer' }}>{businessProcessStepRow && businessProcessStepRow.StepStatus}</span>}
                                                                                                            {businessProcessStepRow.StepStatus === RAFBPStepStatus.Rejected && <span className="e-badge bp-step-avatar rejected-step-avatar" style={{ padding: '5px 10px', cursor: 'pointer' }}>{businessProcessStepRow && businessProcessStepRow.StepStatus}</span>}
                                                                                                            {businessProcessStepRow.StepStatus === RAFBPStepStatus.Skipped && <span className="e-badge bp-step-avatar skipped-step-avatar" style={{ padding: '5px 10px', cursor: 'pointer' }}>{businessProcessStepRow && businessProcessStepRow.StepStatus}</span>}
                                                                                                        </div>
                                                                                                        <div className="recordInfomsg me-2 align-items-center d-flex">
                                                                                                            <RAFRecordInfo createdBy={businessProcessStepRow.CreatedBy} createdDate={businessProcessStepRow.CreatedDate} modifiedDate={businessProcessStepRow.ModifiedDate} modifiedBy={businessProcessStepRow.ModifiedBy} lastActivityDate={businessProcessStepRow.LastActivityDate} content=""></RAFRecordInfo>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                } */}
                    {/* </div>
                                    } */}
                    {/*}  </div> */}
                    {isNotNullAndUndefined(
                      businessProcessStepRow.Description
                    ) ? (
                      <div className="col-12">
                        <div className="secondary-title-value secondary-title-value-light">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                businessProcessStepRow.Description
                              ),
                            }}
                          ></div>
                        </div>
                      </div>
                    ) : null}
                    {businessProcessStepRow &&
                      businessProcessStepRow.StepType !== RAFBPStepType.Task ? (
                      <RAFFormDataModelRenderer
                        formDataModelFieldName={"FormDataModel"}
                        formDataFieldName={"FormData"}
                        validateFields
                        allEntities={this.props.allEntities}
                        allForms={this.props.allForms}
                        isReadonly={
                          businessProcessStepRow.StepStatus ===
                            RAFBPStepStatus.Completed ||
                            businessProcessStepRow.StepStatus ===
                            RAFBPStepStatus.Approved ||
                            businessProcessStepRow.StepStatus ===
                            RAFBPStepStatus.Rejected ||
                            businessProcessStepRow.StepStatus ===
                            RAFBPStepStatus.Skipped ||
                            businessProcessStepRow.StepStatus ===
                            RAFBPStepStatus.Reviewed
                            ? true
                            : false
                        }
                      />
                    ) : null}
                    {businessProcessStepRow &&
                      businessProcessStepRow.StepType ===
                      RAFBPStepType.Decision && (
                        <BPStepDecisionDataModel
                          formDataModelFieldName="FormDataModel"
                          formDataFieldName={"FormData"}
                          validateFields={false}
                          stepConnector={currentBPStepConnector}
                          mode="Details"
                        />
                      )}
                    {businessProcessStepRow &&
                      businessProcessStepRow.StepType ===
                      RAFBPStepType.Question && (
                        <RAFQuestionDataModelRender
                          formDataModelFieldName="FormDataModel"
                          formDataFieldName={"FormData"}
                          validateFields={false}
                          allowSubmit={allowSubmit}
                        />
                      )}
                    {businessProcessStepRow &&
                      (businessProcessStepRow.StepType === RAFBPStepType.ToDo ||
                        businessProcessStepRow.StepType ===
                        RAFBPStepType.Task) && (
                        <RAFIOCompmentModelRender
                          formDataModelFieldName="FormDataModel"
                          formDataFieldName={"FormData"}
                          {...(isNotNullAndUndefined(selectedFormLibrary)
                            ? {
                              componentModel:
                                selectedFormLibrary.DataModel as RAFFormIOModel,
                            }
                            : {})}
                          // validateFields={true}
                          validateFields
                          allowSubmit={allowSubmit}
                        />
                      )}
                  </div>
                  {this.hideRelatedCommentSections === false &&
                    hideRelatedDoumentSections === false &&
                    hideRelateTaskSections === false &&
                    businessProcessStepRow &&
                    businessProcessStepRow.StepType !== RAFBPStepType.Group && (
                      <div className="manageStep-tab-col">
                        <TabComponent
                          cssClass={
                            "e-fill custom-tab-footer-border m-0 remover-footer-border scrollspy-tab manageStep-tab"
                          }
                          loadOn="Demand"
                          heightAdjustMode="Auto"
                          selectedItem={this.state.selectedTabIndex}
                          selected={(e) => this.tabSelected(e.selectedIndex)}
                        >
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="e-tab-header w-100">
                              <div></div>
                              <div className="align-items-center">
                                <span className="secondary-header-bold-text remove-color">
                                  Comments
                                </span>
                              </div>
                              <div className="align-items-center">
                                <span className="secondary-header-bold-text remove-color">
                                  Attachments
                                </span>
                              </div>
                              {/* <div>
                              <i className="fa fa-tasks me-2"></i>
                              <span>Tasks</span>
                            </div> */}
                            </div>
                            <div className="me-3">{this.props.customFooterContent}</div>
                          </div>
                          <div className="e-content"></div>
                        </TabComponent>

                        {this.renderTabContent()}
                        {/*{businessProcessStepRow && (businessProcessStepRow.StepType === RAFBPStepType.Item ||*/}
                        {/*    businessProcessStepRow.StepType === RAFBPStepType.ToDo) &&*/}

                        {/*{(businessProcessStepRow.StepType === RAFBPStepType.Item ||*/}
                        {/*    businessProcessStepRow.StepType === RAFBPStepType.ToDo) &&*/}

                        {/*{(businessProcessStepRow.StepType === RAFBPStepType.Item ||*/}
                        {/*    businessProcessStepRow.StepType === RAFBPStepType.ToDo) &&*/}
                      </div>
                    )}
                </div>
                {this.state.showCreateTaskContent && (
                  <DialogComponent
                    header="Create Task"
                    showCloseIcon
                    visible={this.state.showCreateTaskContent}
                    cssClass="rightDialog createEditForm full-height"
                    content={this.createTaskContent.bind(this)}
                    isModal
                    target="body"
                    closeOnEscape={false}
                    close={() => this.createTaskDialogClose()}
                  ></DialogComponent>
                )}
                {this.state.showTaskDetailsContent && (
                  <DialogComponent
                    visible={this.state.showTaskDetailsContent}
                    cssClass="rightDialog createEditForm full-height"
                    id="taskUpdateDialog"
                    content={this.taskDetailsContent.bind(this)}
                    isModal
                    target="body"
                    closeOnEscape={false}
                    close={() => this.taskDetailsDialogClose()}
                  ></DialogComponent>
                )}
              </div>
            );
          }}
        </MyTeamsContext.Consumer>
      </div>
    );
  }
}

export default ManageStep;
