import * as React from "react";
import { PropsWithChildren, useContext } from "react";
import { FormRenderProps } from "react-final-form";
import { isNotNullAndUndefined } from "../../../../RAFComponents/helpers/utils";
import { RAFFormContext } from "../../../../RAFComponents/Inputs/RFFUtils";
import { FormLibraryRow } from "../../../../RAFComponents/models/Common/FormLibraryRow";
import { EntityRow } from "../../Entity/EntityRow";
import {
  RAFComponentModel,
  RAFFormDataModel
} from "../../RAFPage/PageLayoutJM";
import RAFComponentModelRenderer from "./RAFComponentModelRenderer";

interface IProps {
  formDataFieldName?: string;
  formDataModelFieldName?: string;
  validateFields?: boolean;
  mode?: "Edit" | "Details";
  allEntities?: EntityRow[];
  allForms?: FormLibraryRow[];
  isReadonly?: boolean;
}

function RAFFormDataModelRenderer({ ...props }: PropsWithChildren<IProps>) {
  const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);
  //const layout: RAFComponentModel[] = props.rafPageBuilderLayout && props.rafPageBuilderLayout.layout;

  let formDataModel: RAFFormDataModel = null;
  if (
    isNotNullAndUndefined(rafFormContextValue) &&
    isNotNullAndUndefined(rafFormContextValue.values)
  ) {
    formDataModel = rafFormContextValue.values[props.formDataModelFieldName];
  }

  const components: RAFComponentModel[] =
    isNotNullAndUndefined(formDataModel) &&
      isNotNullAndUndefined(formDataModel.components) &&
      formDataModel.components.length > 0
      ? formDataModel.components
      : null;

  if (components && components.length > 0) {
    components.map((item, index) => {
      return (
        <div className="col-12" key={index}>
          {/*<BPFormDataModel
                        formDataModelFieldName="FormDataModel"
                        formDataFieldName={"FormData"}
                        validateFields={false}
                        mode="Details"
                    />*/}
          <div className="formDataModel px-3">
            <RAFComponentModelRenderer
              key={index}
              data={item}
              formDataFieldName={props.formDataFieldName}
              isReadonly={props.isReadonly}
              validateFields={props.validateFields}
              allEntities={props.allEntities}
              allForms={props.allForms}
            />
          </div>
        </div>
      );
    })
  } else {
    return null;
  }
}

export default RAFFormDataModelRenderer;
