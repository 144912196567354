import { propertyOf } from "../../../../RAFComponents/helpers/utils";
import { RAFEntityBase } from "../../../../RAFComponents/models/Common/RAFEntityBase";
import { RAFRelatedToRow } from "../../../../RAFComponents/models/Common/RAFRelatedToRow";
//import {
//    Connector,
//  } from "@syncfusion/ej2-react-diagrams";
import { ConnectorRow } from "./ConnectorRow";

export class IBusinessProcessStepTemplateDto {
    UID?: string;
    Title?: string;
    StepType?: string;
    IsApproval?: boolean;
    IsRequired?: boolean;
    StepOrder?: number;
    DisplayOrder?: string;
    StepStatus?: string;
    Assignee?: string;
    AssigneeUID?: string;
    AssigneeType?: string;
    FormData?: any;
    FormDataModel?: any;
    BpTemplate?: string;
    BpTemplateUID?: string;
    StepDeadlineUnits?: string;
    StepDeadlineInterval?: number;
    StepDeadlineCalculation?: string;
    Description?: string;
    ParentStep?: string;
    ParentStepUID?: string;
    DependentStep?: string;
    DependentStepUID?: string;
    StepCount?: number;
    IsConditional?: boolean;
    IsDependent?: boolean;
    ExecutionOrder?: string;
    FormType?: string;
    FormName?: string;
    FormUID?: string;
    PageLayout?: string;
    PageLayoutUID?: string;
    AssignTeam?: string;
    AssignTeamUID?: string;
    StepConnectorJson?: ConnectorRow[];
    TagsListJson?: string[];
    Portal?: string;
    PortalUID?: string;
    Partner?: string;
    PartnerUID?: string;
    StepSettings?: any;
    RelatedEntities?: RAFRelatedToRow[];
    //Entity?: string;
    Attachment?:string;
    AttachmentUID?:string;
    EnableStopGate?: boolean;
    SecondaryRelatedTo?:string;
    SecondaryRelatedToUID?:string;
    SecondaryRelatedToType?:string;
    LastActivityDate?: Date;
    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
}



export class IBusinessProcessStepTemplateRow {
    UID?: string;
    Title?: string;
    StepType?: string;
    IsApproval?: boolean;
    IsRequired?: boolean;
    StepOrder?: number;
    DisplayOrder?: string;
    StepStatus?: string;
    Assignee?: string;
    AssigneeUID?: string;
    AssigneeType?: string;
    FormData?: any;
    FormDataModel?: any;
    BpTemplate?: string;
    BpTemplateUID?: string;
    StepDeadlineUnits?: string;
    StepDeadlineInterval?: number;
    StepDeadlineCalculation?: string;
    Description?: string;
    ParentStep?: string;
    ParentStepUID?: string;
    DependentStep?: string;
    DependentStepUID?: string;
    StepCount?: number;
    IsConditional?: boolean;
    IsDependent?: boolean;
    ExecutionOrder?: string;
    FormType?: string;
    FormName?: string;
    FormUID?: string;
    PageLayout?: string;
    PageLayoutUID?: string;
    AssignTeam?: string;
    AssignTeamUID?: string;
    StepConnectorJson?: ConnectorRow[];
    TagsListJson?: string[];
    Portal?: string;
    PortalUID?: string;
    Partner?: string;
    PartnerUID?: string;
    StepSettings?: any;
    RelatedEntities?: RAFRelatedToRow[];
    Attachment?:string;
    AttachmentUID?:string;
    //Entity?: string;
    EnableStopGate?: boolean;
    SecondaryRelatedTo?:string;
    SecondaryRelatedToUID?:string;
    SecondaryRelatedToType?:string;
    LastActivityDate?: Date;
    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
}

export class BusinessProcessStepTemplateRow extends IBusinessProcessStepTemplateRow implements RAFEntityBase {

    AdditionalInfo?: boolean;
    RememberSelection?: boolean;
    //ProcessStarter?: string;
    IsChildStep?: boolean;
    SelectedLevel?: string;
    CopySettingsFromUID?: string;
    DecisionType?: string;
    Email?: string;
    RelatedModule?: string;
    CollectData?: boolean;
    ManageFlowRule?: boolean;
    TaskStepType?: string;
    FormInputType?: string;
    EnableAutoComplete?:boolean;

    getClassName?() {//use entityname
        return 'business_process_step_template';
    }

    getIdField?(): string {
        return propertyOf<BusinessProcessStepTemplateRow>("UID");
    }
    getListUrl?(): string {//use route name(entity displayname)
        return "BusinessProcessStepTemplate/List";
    }
    getSaveUrl?(): string {//use route name(entity displayname)
        return "BusinessProcessStepTemplate/Save";
    }

}
