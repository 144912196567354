import React, { PropsWithChildren, useContext } from "react";
import { NavigateParamsProps, withRouter } from "../../../../router";
import { RAFProcessContext } from "./RAFProcessContextProvider";
import {
  IsNullOrWhiteSpace,
  isNotNullAndUndefined,
  isNullOrUndefined,
} from "../../../../RAFComponents/helpers/utils";
import {RAFExecutionOrder,RAFBPStepStatus,} from "../../../../constants/Common/RMConstants"
import { RAFBPTemplateContext } from "../Template/RAFBPTemplateContextProvider";

interface IProps {
  hideProgressBar?: boolean;
  hideProgressValue?: boolean;
  hideStepCount?: boolean;
  ulClassName?: string;
  totalSteps?: number;
  completedSteps?; number;
}

function RAFIndexPageButtons({
  navigate,
  ...props
}: PropsWithChildren<IProps & NavigateParamsProps>) {
  const { businessProcess, businessProcessSteps } =
    useContext(RAFProcessContext);
  const { bpTemplateSteps } = useContext(RAFBPTemplateContext);

  let totalSteps = isNotNullAndUndefined(props.totalSteps) ? props.totalSteps : 0;
  if (isNullOrUndefined(props.totalSteps)) {
    if (
      isNotNullAndUndefined(businessProcess) &&
      businessProcess.ExecutionOrder === RAFExecutionOrder.Conditional
    ) {
      totalSteps = isNotNullAndUndefined(businessProcessSteps)
        ? businessProcessSteps.filter((x) =>
          IsNullOrWhiteSpace(x.ParentBpStepTemplateUID)
        ).length
        : 0;
    } else {
      if (isNotNullAndUndefined(bpTemplateSteps) && bpTemplateSteps.length > 0) {
        totalSteps = bpTemplateSteps.filter((x) =>
          IsNullOrWhiteSpace(x.ParentStepUID)
        ).length;
      } else {
        totalSteps = businessProcess && businessProcess.StepCount;
      }
    }
  }
  let completedSteps = isNotNullAndUndefined(props.completedSteps) ? props.completedSteps :
    isNotNullAndUndefined(businessProcessSteps) &&
      businessProcessSteps.length > 0
      ? businessProcessSteps.filter(
        (x) =>
          (x.StepStatus === RAFBPStepStatus.Completed ||
            x.StepStatus === RAFBPStepStatus.Skipped ||
            x.StepStatus === RAFBPStepStatus.Approved ||
            x.StepStatus === RAFBPStepStatus.ResponseReceived ||
            x.StepStatus === RAFBPStepStatus.Reviewed ||
            x.StepStatus === RAFBPStepStatus.Rejected) &&
          IsNullOrWhiteSpace(x.ParentBpStepTemplateUID)
      ).length
      : 0;

  let bpStepRow = [];
  for (let i = 0; i < totalSteps; i++) {
    if (i < completedSteps) {
      bpStepRow.push(<li key={i} className="bp-step-li completed"></li>);
    } else {
      bpStepRow.push(<li key={i} className="bp-step-li"></li>);
    }
  }

  let progressValue: any = (completedSteps / totalSteps) * 100;
  // let progressValue: any = 0
  // let progressValue: any = 24
  // let progressValue: any = 49
  // let progressValue: any = 74
  // let progressValue: any = 99

  let progressValueTextColor;

  if (progressValue < 25) {
    progressValueTextColor = "text__Red";
  } else if (progressValue < 50) {
    progressValueTextColor = "text__Red-1";
  } else if (progressValue < 75) {
    progressValueTextColor = "text-warning-1";
  } else if (progressValue <= 100) {
    progressValueTextColor = "text__Green-2";
  } else {
    progressValueTextColor = "text__Red";
  }

  progressValue = progressValue.toFixed();
  let ulClassName = props.ulClassName;
  return (
    totalSteps > 0 && (
      <div className="d-flex align-items-center">
        {props.hideProgressBar === true ? "" :
          <ul
            className={`${isNotNullAndUndefined(ulClassName) && ulClassName
              } bp-step-ul`}
          >
            {bpStepRow}
          </ul>
        }
        {props.hideProgressValue === true ? "" :
          <span
            className={
              props.hideStepCount === true
                ? `header-text-without-color ps-1 ${progressValueTextColor}`
                : `header-text-without-color pe-3 ps-1 ${progressValueTextColor}`
            }
          >
            {progressValue === "0" ? "0%" : `${progressValue}%`}
          </span>
        }
        {props.hideStepCount === true ? null : (
          <>
            <span className="bp-template-content">{completedSteps}</span>
            <span className="bp-template-content px-1">/</span>
            <span className="bp-template-content">{totalSteps}</span>
            <span className="bp-template-content d-none d-md-inline-block ps-1">
              {" step(s)"}
            </span>
          </>
        )}
      </div>
    )
  );
}

export default React.memo(withRouter(RAFIndexPageButtons));
