import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import {
  AnimationSettingsModel,
  DialogComponent,
  TooltipComponent,
} from "@syncfusion/ej2-react-popups";
import {
  ItemModel,
  MenuEventArgs,
  SplitButtonComponent,
} from "@syncfusion/ej2-react-splitbuttons";
import moment from "moment";
//import { MenuEventArgs, SplitButtonComponent, ItemModel } from "@syncfusion/ej2-react-splitbuttons";
import * as React from "react";
import { Fragment } from "react";
import { FormRenderProps } from "react-final-form";
import { msalInstance } from "../../../..";
import { ContentType, RAFLayout } from "../../../../constants/Common/Constants";
import {
  hideProgress,
  showProgress,
} from "../../../../RAFComponents/helpers/AppHelper";
import {
  convertUTCDateToLocalTimezone,
  getSaveRequest,
  isNotNullAndUndefined,
  isNullOrUndefined,
  IsNullOrWhiteSpace,
} from "../../../../RAFComponents/helpers/utils";
import RAFChoiceOption from "../../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFForm, {
  Condition,
  WhenFieldChanges,
} from "../../../../RAFComponents/Inputs/RAFForm";
import RAFLookupCC from "../../../../RAFComponents/Inputs/RAFLookupCC";
import RAFRadioButtonList from "../../../../RAFComponents/Inputs/RAFRadioButtonList";
import RAFTextArea from "../../../../RAFComponents/Inputs/RAFTextArea";
import {
  getFormValue,
  setFormValue,
} from "../../../../RAFComponents/Inputs/RFFUtils";
import { FormLibraryRow } from "../../../../RAFComponents/models/Common/FormLibraryRow";
import { RAFRelatedToRow } from "../../../../RAFComponents/models/Common/RAFRelatedToRow";
import RAFIconImage from "../../../../RAFComponents/Navigation/RAFIconImage";
import RAFRecordInfo from "../../../../RAFComponents/Navigation/RAFRecordInfo";
import * as repositoryActions from "../../../../RAFComponents/store/actions/repositoryActions";
import { showWarningToast } from "../../../../RAFComponents/Utility/RAFToastComponent";
import { ActivityRow } from "../../../../RAFModules/ActiveContacts/Activity/ActivityRow";
import CreateActivity from "../../../../RAFModules/ActiveContacts/Activity/CreateActivity";
import { ContentLibraryRow } from "../../../../RAFModules/ActiveContacts/Document/Library/ContentLibraryRow";
import RAFManageUploadDocumentFile from "../../../../RAFModules/ActiveContacts/Document/ManageDocument/RAFManageUploadDocumentFile";
import ManageTask from "../../../../RAFModules/ActiveContacts/Task/ManageTask";
import { UserRow } from "../../../../RAFModules/ActiveContacts/User/UserRow";
import { UserGroupRow } from "../../../../RAFModules/ActiveContacts/UserGroup/UserGroupRow";
import RAFCreate from "../../../../RAFModules/Common/Create";
import { MyTeamsContext } from "../../../../RAFModules/Common/Providers/MyTeamsContextProvider";
import { NavigateParamsProps, withRouter } from "../../../../router";

import {
  RAFBPStatus,
  RAFBPStepStatus,
  RAFBPStepType,
  RAFBPTemplateSettings,
  RAFExecutionOrder,
  RAFProcessAssigneeType,
} from "../../../../constants/Common/RMConstants";
import { EntityRow } from "../../Entity/EntityRow";
import {
  CompleteBusinessProcessStep,
  RetrieveBusinessProcessStepById,
  SaveBusinessProcessStepAPI,
  SaveProcessStepAndUpdateBP,
} from "../helpers/ProcessHelper";
import { BusinessProcessRow } from "../Process/BusinessProcessRow";
import { RAFProcessContext } from "../Process/RAFProcessContextProvider";
import { BusinessProcessStepRow } from "../Step/BusinessProcessStepRow";
import { BusinessProcessStepTemplateRow } from "../StepTemplate/BusinessProcessStepTemplateRow";
import { BusinessProcessTemplateRow } from "../Template/BusinessProcessTemplateRow";
import ManageStep from "./ManageStep";

import {
  RAFBPStepStatusDisplayText,
  RAFBPStepTypeIcon,
} from "../../../../constants/BPType/RAFBPConstants";
import { RAFEntityName } from "../../../../constants/Common/EntityConstants";
import { RAFActivityAction } from "../../../../RAFModules/ActiveContacts/Activity/ActivityHelper";

interface IProps {
  businessProcessStepRows?: BusinessProcessStepRow[];
  businessProcessStepRow?: BusinessProcessStepRow;
  businessProcessRow?: BusinessProcessRow;
  bpTemplateStepsRow?: BusinessProcessStepTemplateRow[];
  bpTemplateRow?: BusinessProcessTemplateRow;
  objectData?: any;
  onSave?: (values) => void;
  //onBPUpdate?: () => void;
  onBPStepSave?: (
    allBPRows?: BusinessProcessStepRow[],
    dependentBPRows?: BusinessProcessStepRow[],
    currentBPStepRow?: BusinessProcessStepRow,
    businessProcessRow?: BusinessProcessRow,
    bpStatusUpdated?: boolean
  ) => void;
  onBPStepUpdated?: (
    businessProcess?: BusinessProcessRow,
    processStep?: BusinessProcessStepRow,
    processSteps?: BusinessProcessStepRow[]
  ) => void;
  onDeleteClicked?: (values) => void;
  onShowNextClicked?: (UID) => void;
  onShowPreviousClicked?: (UID) => void;
  onBackClick?: () => void;
  onCloseBusinessProcessDialog?: () => void;
  showPrevious?: boolean;
  showNext?: boolean;
  hideRelatedSections?: boolean;
  index?: number;
  mode?: string;
  relatedSectionUpdated?: (isTask?: boolean, isActivity?: boolean) => void;
  hideRelatedCommentSections?: boolean;
  hideRelateTaskSections?: boolean;
  hideRelatedDoumentSections?: boolean;
  allForms?: FormLibraryRow[];
  allEntities?: EntityRow[];
  relatedCommentsUpdated?: () => void;
  relatedTaskUpdated?: () => void;
}

interface IState {
  isActive?: boolean;
  isRelatedCommentsUpdated?: boolean;
  isRelatedTaskUpdated?: boolean;
  showApproverDialogContent?: boolean;
  showCreateTaskContent?: boolean;
  showCreateCommentContent?: boolean;
  showUploadContent?: boolean;
  selectedDocumentRow: ContentLibraryRow;
  relatedTaskDivKey: number;
  relatedCommentDivKey: number;
  relatedDocumnetDivKey: number;
  showCompleteStepAlertDialogContent: boolean;
  newStepStatus: string;
  showAssigneeDialogContent?: boolean;
  showCreateRelatedContent?: boolean;
  triggerInputFile?: boolean;
  relatedModuleToCreate?: RAFRelatedToRow;
}

class RAFBusinessProcessStepDetails extends React.Component<
  NavigateParamsProps & IProps,
  IState
> {
  animationSettings: AnimationSettingsModel;

  constructor(props) {
    super(props);

    this.animationSettings = { effect: "Fade" };

    this.state = {
      isActive: true,
      isRelatedCommentsUpdated: false,
      isRelatedTaskUpdated: false,
      showApproverDialogContent: false,
      showCreateTaskContent: false,
      showCreateCommentContent: false,
      showUploadContent: false,
      selectedDocumentRow: null,
      relatedTaskDivKey: Math.random(),
      relatedCommentDivKey: Math.random(),
      relatedDocumnetDivKey: Math.random(),
      showCompleteStepAlertDialogContent: false,
      newStepStatus: null,
      showAssigneeDialogContent: false,
      showCreateRelatedContent: false,
      triggerInputFile: false,
      relatedModuleToCreate: null,
    };
  }

  private rafForm: FormRenderProps | null;
  _isMounted = false;

  private isMyTeam: UserGroupRow[] = [];
  public completeStepForm: FormRenderProps | null;

  componentDidMount = () => {
    this._isMounted = true;
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  onSubmitBusinessProcessStep = (values) => {
    let businessProcessStep: BusinessProcessStepRow =
      values as BusinessProcessStepRow;
    let {
      businessProcessStepRows,
      businessProcessRow,
      bpTemplateStepsRow,
      bpTemplateRow,
    } = this.props;

    if (isNotNullAndUndefined(this.rafForm) && this.rafForm.valid) {
      CompleteBusinessProcessStep(
        businessProcessStep.StepStatus,
        businessProcessStep,
        businessProcessStepRows,
        bpTemplateStepsRow,
        businessProcessRow,
        bpTemplateRow
      ).then((result) => {
        if (this._isMounted) {
          this.setState({ isActive: false }, () => {
            if (this.props.onBPStepUpdated) {
              this.props.onBPStepUpdated(
                result.businessProcess,
                result.processStep,
                result.processSteps
              );
            }
          });
        }
      });
    }
  };

  //Complete step start
  updateStepStatusAndSubmit = (
    businessProcessStep: BusinessProcessStepRow,
    newStepStatus
  ) => {
    if (isNotNullAndUndefined(this.rafForm)) {
      setFormValue(this.rafForm, "StepStatus", newStepStatus);
      setFormValue(this.rafForm, "FormData", businessProcessStep.FormData);
      setFormValue(this.rafForm, "CompletedBy", msalInstance.currentUserName);
      setFormValue(this.rafForm, "CompletedByUID", msalInstance.currentUserId);
      setFormValue(this.rafForm, "CompletedDate", new Date());
      this.rafForm.form.submit();
      this.completeStepAlertDialogClose();
    }
  };

  resetBusinessProcessStep = (stepStatus) => {
    if (stepStatus === RAFBPStepStatus.Cleared) {
      let businessProcessStep: BusinessProcessStepRow = getFormValue(
        this.rafForm
      );
      let {
        businessProcessRow,
        businessProcessStepRows,
        bpTemplateStepsRow,
        bpTemplateRow,
      } = this.props;
      businessProcessStep.StepStatus = RAFBPStepStatus.ToDo;
      businessProcessStep.FormData = null;
      businessProcessStep.CompletedBy = null;
      businessProcessStep.CompletedByUID = null;
      businessProcessStep.CompletedDate = null;
      let progressDiv = showProgress("body", true);
      SaveProcessStepAndUpdateBP(
        businessProcessStep,
        businessProcessStepRows,
        businessProcessRow,
        bpTemplateStepsRow,
        bpTemplateRow
      ).then((result) => {
        if (this._isMounted) {
          this.setState({ isActive: false }, () => {
            hideProgress(progressDiv);
            if (this.props.onBPStepUpdated) {
              this.props.onBPStepUpdated(
                result.businessProcess,
                result.businessProcessStep,
                result.allBPRows
              );
            }
          });
        }
      });
    }
  };

  updateBusinessProcessStepStatus = (stepStatus) => {
    let businessProcessStepRow: BusinessProcessStepRow = getFormValue(
      this.rafForm
    );
    const loggedinUserId = msalInstance.currentUserId;
    if (
      businessProcessStepRow.StepStatus ===
        RAFBPStepStatus.AwaitingforApproval ||
      businessProcessStepRow.StepStatus === RAFBPStepStatus.AwaitingforReview
    ) {
      this.showCompleteStepAlertDialog(stepStatus);
    } else if (
      ((isNotNullAndUndefined(businessProcessStepRow.AssigneeUID) &&
        businessProcessStepRow.AssigneeUID !== loggedinUserId) ||
        (isNotNullAndUndefined(businessProcessStepRow.AssignTeamUID) &&
          isNotNullAndUndefined(this.isMyTeam) &&
          this.isMyTeam.length === 0)) &&
      (businessProcessStepRow.StepStatus === RAFBPStepStatus.ToDo ||
        businessProcessStepRow.StepStatus === RAFBPStepStatus.Assigned)
    ) {
      this.showCompleteStepAlertDialog(stepStatus);
    } else {
      this.updateStepStatusAndSubmit(businessProcessStepRow, stepStatus);
    }
  };

  showCompleteStepAlertDialog = (newStepStatus) => {
    if (isNotNullAndUndefined(newStepStatus)) {
      if (this._isMounted) {
        this.setState({
          showCompleteStepAlertDialogContent: true,
          newStepStatus,
        });
      }
    }
  };

  completeStepAlertContent = () => {
    if (this.state.showCompleteStepAlertDialogContent === true) {
      let bpStepRow: BusinessProcessStepRow = getFormValue(this.rafForm);
      let assignedTo = isNotNullAndUndefined(bpStepRow.Assignee)
        ? bpStepRow.Assignee
        : bpStepRow.AssignTeam;
      let bpStepType = bpStepRow.StepType;
      let showAlertMessage = true;
      let actionValue = "No";
      const loggedinUserId = msalInstance.currentUserId;
      if (
        (isNotNullAndUndefined(bpStepRow.AssigneeUID) &&
          bpStepRow.AssigneeUID === loggedinUserId) ||
        (isNotNullAndUndefined(bpStepRow.AssignTeamUID) &&
          isNotNullAndUndefined(this.isMyTeam) &&
          this.isMyTeam.length > 0)
      ) {
        showAlertMessage = false;
        actionValue = "Yes";
      }

      let completeFormInitialValue = {
        Action: actionValue,
        Message: null,
        Mentions: [],
      };
      let actionDisplayText = isNotNullAndUndefined(
        RAFBPStepStatusDisplayText[this.state.newStepStatus]
      )
        ? RAFBPStepStatusDisplayText[this.state.newStepStatus]
        : this.state.newStepStatus;

      let alertMessage;
      if (this.state.newStepStatus === RAFBPStepStatus.Approved) {
        alertMessage = (
          <label>
            Approval task is assigned to {assignedTo}. Do you want to approve on
            his behalf?
          </label>
        );
      } else if (this.state.newStepStatus === RAFBPStepStatus.Reviewed) {
        alertMessage = (
          <label>
            Review task is assigned to {assignedTo}. Do you want to complete the
            review on his behalf?
          </label>
        );
      } else if (this.state.newStepStatus === RAFBPStepStatus.Rejected) {
        alertMessage = (
          <label>
            {bpStepType === RAFBPStepType.Approval ? "Approval" : "Review"} task
            is assigned to {assignedTo}. Do you want to reject{" "}
            {bpStepType === RAFBPStepType.Review ? "the review " : ""}on his
            behalf?
          </label>
        );
      } else if (this.state.newStepStatus === RAFBPStepStatus.RequestforInfo) {
        alertMessage = (
          <label>
            {bpStepType === RAFBPStepType.Approval ? "Approval" : "Review"} task
            is assigned to {assignedTo}. Do you want to request more info on his
            behalf?
          </label>
        );
      } else {
        alertMessage = (
          <label>
            This step is assigned to {assignedTo}. Do you want to{" "}
            {actionDisplayText} on his behalf?
          </label>
        );
      }

      return (
        <div className="px-2">
          <RAFForm
            initialValues={completeFormInitialValue}
            formRef={(g) => (this.completeStepForm = g)}
            submitOnEnterKey={false}
            layout={RAFLayout.TwoColumnLayout}
            onSubmit={this.onSubmitCompleteStep}
          >
            <div className="e-dlg-body-content">
              <div className="row">
                {showAlertMessage === true && (
                  <div className="col-auto">{alertMessage}</div>
                )}
                {showAlertMessage === true && (
                  <div className="col-auto mt-2">
                    <RAFRadioButtonList
                      field="Action"
                      label="Complete ?"
                      showLabel={false}
                      labelClassName="col-auto"
                      inputFieldClassName="col-auto radio-btn-m-0"
                      formGroupClassName="mb-0"
                      uitype="customButton"
                    >
                      <RAFChoiceOption
                        label="Yes"
                        value={"Yes"}
                      ></RAFChoiceOption>
                      <RAFChoiceOption
                        label="No"
                        value={"No"}
                      ></RAFChoiceOption>
                    </RAFRadioButtonList>
                  </div>
                )}
                <WhenFieldChanges field={"Action"} set={"Message"} to={null} />
                <WhenFieldChanges field={"Action"} set={"Mentions"} to={null} />
                <Condition when="Action" is="Yes">
                  <div className="w-100" id="actionTextAreaDiv">
                    <RAFTextArea<ActivityRow>
                      field="Message"
                      label="Add Reason"
                      required
                      placeholder="Add notes, comments, or @ to mention a person"
                      rows={5}
                      onInputs={(e) => {}}
                      onChanged={(e) => {}}
                      useMentions
                      mentionsField="Mentions"
                    ></RAFTextArea>
                  </div>
                </Condition>
              </div>
            </div>
            <div className="e-dlg-footerContent ">
              {/* <ButtonComponent className="me-2" cssClass='e-flat e-info'>OK</ButtonComponent> */}
              <ButtonComponent
                type="button"
                onClick={() =>
                  this.completeStepForm && this.completeStepForm.form.submit()
                }
                cssClass="e-flat e-info me-2"
              >
                OK
              </ButtonComponent>
              <ButtonComponent
                type="button"
                cssClass="e-flat"
                onClick={() => this.completeStepAlertDialogClose()}
              >
                Cancel
              </ButtonComponent>
            </div>
          </RAFForm>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  onSubmitCompleteStep = (value) => {
    let bpStepRow: BusinessProcessStepRow = getFormValue(this.rafForm);
    if (
      isNotNullAndUndefined(value) &&
      value.Action === "Yes" &&
      isNotNullAndUndefined(bpStepRow)
    ) {
      let progressDiv = showProgress("#completeStepAlertDialogContent");

      let activityRow: ActivityRow = new ActivityRow();
      activityRow.UserUID = msalInstance.currentUserId;
      activityRow.UserName = msalInstance.currentUserName;

      activityRow.RelatedToType = bpStepRow.RelatedToType;
      activityRow.Action = "added";
      activityRow.IsSystem = false;
      activityRow.RelatedToUID = isNotNullAndUndefined(bpStepRow.RelatedToUID)
        ? bpStepRow.RelatedToUID
        : "";
      activityRow.RelatedTo = isNotNullAndUndefined(bpStepRow.RelatedTo)
        ? bpStepRow.RelatedTo
        : "";

      activityRow.SecondaryRelatedTo = bpStepRow.Title;
      activityRow.SecondaryRelatedToType = RAFEntityName.BusinessProcessStep;
      activityRow.SecondaryRelatedToUID = bpStepRow.UID;

      activityRow.Mentions = value.Mentions;
      activityRow.Message = value.Message;
      activityRow.Action = RAFActivityAction.Added;

      repositoryActions
        .postDataAndGetResponse(
          "Activity/Save",
          getSaveRequest(activityRow, null),
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.EntityId)
          ) {
            hideProgress(progressDiv);
            this.updateStepStatusAndSubmit(bpStepRow, this.state.newStepStatus);
          } else {
            hideProgress(progressDiv);
            showWarningToast("Sorry something went wrong !");
          }
        })
        .catch((error) => error);
    } else {
      this.completeStepAlertDialogClose();
    }
  };

  completeStepAlertDialogClose = () => {
    if (this._isMounted) {
      this.setState({
        showCompleteStepAlertDialogContent: false,
        newStepStatus: null,
      });
    }
  };
  //Complete step end

  onSetApproverOrReviewerClicked = () => {
    let values = this.rafForm && this.rafForm.values;
    let businessProcessStepRow: BusinessProcessStepRow =
      values as BusinessProcessStepRow;
    let {
      businessProcessStepRows,
      businessProcessRow,
      bpTemplateStepsRow,
      bpTemplateRow,
    } = this.props;
    let processStatus = businessProcessRow.ProcessStatus;
    if (
      isNotNullAndUndefined(businessProcessStepRow.AssigneeUID) ||
      isNotNullAndUndefined(businessProcessStepRow.AssignTeamUID)
    ) {
      businessProcessStepRow.StepStatus = RAFBPStepStatus.AwaitingforApproval;
      if (businessProcessStepRow.StepType === RAFBPStepType.Review) {
        businessProcessStepRow.StepStatus = RAFBPStepStatus.AwaitingforReview;
      }
      this.SaveAndUpdateBusinessProcessStep(
        businessProcessStepRow,
        businessProcessStepRows,
        businessProcessRow,
        bpTemplateStepsRow,
        bpTemplateRow,
        false
      );
    } else {
      this.showApproverDialogOpen();
    }
  };

  SaveAndUpdateBusinessProcessStep = (
    businessProcessStepRow?: BusinessProcessStepRow,
    businessProcessStepRows?: BusinessProcessStepRow[],
    businessProcessRow?: BusinessProcessRow,
    bpTemplateStepsRow?: BusinessProcessStepTemplateRow[],
    bpTemplateRow?: BusinessProcessTemplateRow,
    hideApproverDialog?: boolean,
    hideAssigneeDialog?: boolean
  ) => {
    let progressDiv = showProgress("body", true);
    SaveProcessStepAndUpdateBP(
      businessProcessStepRow,
      businessProcessStepRows,
      businessProcessRow,
      bpTemplateStepsRow,
      bpTemplateRow
    ).then((result) => {
      hideProgress(progressDiv);
      if (this._isMounted) {
        this.setState({ isActive: true }, () => {
          if (this.props.onBPStepUpdated) {
            this.props.onBPStepUpdated(
              result.businessProcess,
              result.businessProcessStep,
              result.allBPRows
            );
          }
        });
      }
      if (hideApproverDialog) {
        this.showApproverDialogClose();
      }
      if (hideAssigneeDialog) {
        this.showAssigneeDialogClose();
      }
    });
  };

  showApproverDialogOpen(): void {
    if (this._isMounted) {
      this.setState({ showApproverDialogContent: true }, () => {
        document.body.classList.add("overflow-hidden");
      });
    }
  }

  showApproverDialogClose(): void {
    if (this._isMounted) {
      this.setState({ showApproverDialogContent: false }, () => {
        document.body.classList.remove("overflow-hidden");
      });
    }
  }

  onSubmitBPStepApprover = (values) => {
    let businessProcessStepRow: BusinessProcessStepRow =
      values as BusinessProcessStepRow;
    let {
      businessProcessStepRows,
      businessProcessRow,
      bpTemplateStepsRow,
      objectData,
      bpTemplateRow,
    } = this.props;
    let processStatus = businessProcessRow.ProcessStatus;

    businessProcessStepRow.StepStatus = RAFBPStepStatus.AwaitingforApproval;
    if (businessProcessStepRow.StepType === RAFBPStepType.Review) {
      businessProcessStepRow.StepStatus = RAFBPStepStatus.AwaitingforReview;
    }
    this.SaveAndUpdateBusinessProcessStep(
      businessProcessStepRow,
      businessProcessStepRows,
      businessProcessRow,
      bpTemplateStepsRow,
      bpTemplateRow,
      true
    );
  };

  showApproverContent() {
    if (
      this.state.showApproverDialogContent &&
      this.state.showApproverDialogContent === true
    ) {
      let { businessProcessStepRow } = this.props;
      return (
        <div className="startBusinessProcessForm" id="setApproverReviewForm">
          <RAFForm
            type={BusinessProcessStepRow}
            initialValues={businessProcessStepRow}
            layout={RAFLayout.TwoColumnLayout}
            onSubmit={this.onSubmitBPStepApprover}
          >
            <div className="e-dlg-body-content">
              <div>
                <RAFLookupCC<BusinessProcessStepRow>
                  field="Assignee"
                  label={
                    businessProcessStepRow.StepType === RAFBPStepType.Review
                      ? "Reviewer"
                      : businessProcessStepRow.StepType ===
                        RAFBPStepType.Approval
                      ? "Approver"
                      : "Assignee"
                  }
                  placeholder={
                    businessProcessStepRow.StepType === RAFBPStepType.Review
                      ? "Select Reviewer"
                      : businessProcessStepRow.StepType ===
                        RAFBPStepType.Approval
                      ? "Select Approver"
                      : "Assignee"
                  }
                  type={UserRow}
                  url="User/Lookup"
                  moduleName={RAFEntityName.User}
                  closeToolTip={false}
                  showClearButton={false}
                  showFullList={false}
                  required
                />
              </div>
            </div>
            <div className="e-dlg-footerContent ">
              <ButtonComponent
                type="submit"
                className="me-2"
                cssClass="e-flat e-small e-info"
              >
                Save
              </ButtonComponent>
              <ButtonComponent
                type="button"
                cssClass="e-flat e-small"
                onClick={() => this.showApproverDialogClose()}
              >
                Cancel
              </ButtonComponent>
            </div>
          </RAFForm>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  showAssigneeDialogOpen(): void {
    if (this._isMounted) {
      this.setState({ showAssigneeDialogContent: true }, () => {
        document.body.classList.add("overflow-hidden");
      });
    }
  }

  showAssigneeDialogClose(): void {
    if (this._isMounted) {
      this.setState({ showAssigneeDialogContent: false }, () => {
        document.body.classList.remove("overflow-hidden");
      });
    }
  }

  onSubmitBPStepAssignee = (values) => {
    let businessProcessStepRow: BusinessProcessStepRow =
      values as BusinessProcessStepRow;
    let {
      businessProcessStepRows,
      businessProcessRow,
      bpTemplateStepsRow,
      bpTemplateRow,
    } = this.props;

    businessProcessStepRow.StepStatus = RAFBPStepStatus.Assigned;

    this.SaveAndUpdateBusinessProcessStep(
      businessProcessStepRow,
      businessProcessStepRows,
      businessProcessRow,
      bpTemplateStepsRow,
      bpTemplateRow,
      false,
      true
    );
  };

  onAssigneeChanged = (value) => {
    if (value === RAFProcessAssigneeType.User) {
      setFormValue(this.rafForm, "AssignTeam", null);
      setFormValue(this.rafForm, "AssignTeamUID", null);
    } else if (value === RAFProcessAssigneeType.Team) {
      setFormValue(this.rafForm, "Assignee", null);
      setFormValue(this.rafForm, "AssigneeUID", null);
    }
  };

  showAssigneeContent() {
    if (
      this.state.showAssigneeDialogContent &&
      this.state.showAssigneeDialogContent === true
    ) {
      let { businessProcessStepRow } = this.props;
      businessProcessStepRow.AssigneeType = RAFProcessAssigneeType.User;
      return (
        <div className="startBusinessProcessForm" id="setAssigneeForm">
          <RAFForm
            type={BusinessProcessStepRow}
            initialValues={businessProcessStepRow}
            layout={RAFLayout.TwoColumnLayout}
            onSubmit={this.onSubmitBPStepAssignee}
          >
            <div className="e-dlg-body-content">
              <div>
                <RAFRadioButtonList<BusinessProcessStepRow>
                  field="AssigneeType"
                  showLabel={false}
                  uitype="button"
                  label="Process Starter"
                  labelClassName="col-12 mb-2"
                  inputFieldClassName="col-12"
                  onChanged={(value) => this.onAssigneeChanged(value)}
                >
                  <RAFChoiceOption
                    label={RAFProcessAssigneeType.User}
                    value={RAFProcessAssigneeType.User}
                  />
                  <RAFChoiceOption
                    label={RAFProcessAssigneeType.Team}
                    value={RAFProcessAssigneeType.Team}
                  />
                </RAFRadioButtonList>
                <Condition when="AssigneeType" is={RAFProcessAssigneeType.User}>
                  <RAFLookupCC<BusinessProcessStepRow>
                    field="Assignee"
                    label={"Assignee"}
                    placeholder={"Select User"}
                    type={UserRow}
                    url="User/Lookup"
                    moduleName={RAFEntityName.User}
                    showLabel={false}
                    closeToolTip={false}
                    showClearButton={false}
                    showFullList={false}
                    required
                  />
                </Condition>
                <Condition when="AssigneeType" is={RAFProcessAssigneeType.Team}>
                  <RAFLookupCC<BusinessProcessStepRow>
                    field="AssignTeam"
                    label={RAFProcessAssigneeType.Team}
                    placeholder="Select Team"
                    type={UserGroupRow}
                    url="Team/Lookup"
                    showLabel={false}
                    closeToolTip={false}
                    showClearButton={false}
                    showFullList={false}
                    required
                    validate
                  />
                </Condition>
              </div>
            </div>
            <div className="e-dlg-footerContent ">
              <ButtonComponent
                type="submit"
                className="me-2"
                cssClass="e-flat e-small e-info"
              >
                Save
              </ButtonComponent>
              <ButtonComponent
                type="button"
                cssClass="e-flat e-small"
                onClick={() => this.showAssigneeDialogClose()}
              >
                Cancel
              </ButtonComponent>
            </div>
          </RAFForm>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  onClickNext(UID) {
    if (this.props.onShowNextClicked) {
      this.props.onShowNextClicked(UID);
    }
  }

  onClickPrevious(UID) {
    if (this.props.onShowPreviousClicked) {
      this.props.onShowPreviousClicked(UID);
    }
  }

  /*private groupedBtnitems: ItemModel[] = [
                  {
                      text: 'Approve',
                      //iconCss: 'e-btn-icons e-paste',
                      id: 'Approve'
                  },
                  {
                      text: 'Reject',
                      //iconCss: 'e-btn-icons e-paste-special',
                      id: 'Reject'
                  },
                  {
                      text: 'Request for Info',
                      //iconCss: 'e-btn-icons e-paste-formula',
                      id: 'RequestforInfo'
                  }];
          
              onGroupedBtnClicked = (args: MenuEventArgs) => {
                  if (isNotNullAndUndefined(args) && isNotNullAndUndefined(args.item)) {
                      switch (args.item.id) {
                          case 'Approve':
                              this.updateBusinessProcessStepStatus(RAFBPStepStatus.Approved);
                              break;
                          case 'Reject':
                              this.updateBusinessProcessStepStatus(RAFBPStepStatus.Rejected);
                              break;
                          case 'RequestforInfo':
                              this.updateBusinessProcessStepStatus(RAFBPStepStatus.RequestforInfo);
                              break;
                          default:
                              break;
                      }
                  }
              }*/

  onCloseBusinessProcessDialog = () => {
    if (
      this.state.isRelatedCommentsUpdated === true ||
      this.state.isRelatedTaskUpdated === true
    ) {
      if (this._isMounted) {
        this.setState({ isActive: false }, () => {
          if (this.props.relatedSectionUpdated) {
            this.props.relatedSectionUpdated(
              this.state.isRelatedTaskUpdated,
              this.state.isRelatedCommentsUpdated
            );
          }
        });
      }
    } else {
      if (this._isMounted) {
        this.setState({ isActive: false }, () => {
          if (this.props.onCloseBusinessProcessDialog)
            this.props.onCloseBusinessProcessDialog();
        });
      }
    }
  };

  onBPStepSaved = (
    allBPRows?: BusinessProcessStepRow[],
    dependentBPRows?: BusinessProcessStepRow[],
    currentBPStepRow?: BusinessProcessStepRow,
    businessProcessRow?: BusinessProcessRow
  ) => {
    if (this._isMounted) {
      this.setState({ isActive: false }, () => {
        if (this.props.onBPStepSave) {
          this.props.onBPStepSave(
            allBPRows,
            dependentBPRows,
            currentBPStepRow,
            businessProcessRow
          );
        }
      });
    }
  };

  onMoreMenuClicked(args: MenuEventArgs) {
    if (args.item.id === "Approve") {
      this.updateBusinessProcessStepStatus(RAFBPStepStatus.Approved);
    }
    if (args.item.id === "Review") {
      this.updateBusinessProcessStepStatus(RAFBPStepStatus.Reviewed);
    }
    if (args.item.id === "Reject") {
      this.updateBusinessProcessStepStatus(RAFBPStepStatus.Rejected);
    }
    if (args.item.id === "RequestforInfo") {
      this.updateBusinessProcessStepStatus(RAFBPStepStatus.RequestforInfo);
    }
    if (args.item.id === "Skip") {
      this.updateBusinessProcessStepStatus(RAFBPStepStatus.Skipped);
    }
  }

  uploadDocument = () => {
    if (this._isMounted) {
      this.setState({ showUploadContent: true });
    }
  };

  uploadAndLinkDocumentContent() {
    if (this.state.showUploadContent === true) {
      let { businessProcessStepRow, businessProcessRow } = this.props;
      return (
        <RAFManageUploadDocumentFile
          onSave={(UID) => this.onUploadDocumentFile(UID)}
          onClose={() => this.uploadDialogClose()}
          selectedFolder={null}
          selectedDocumentRow={null}
          secondaryRelatedToType={RAFEntityName.BusinessProcessStep}
          secondaryRelatedToUID={businessProcessStepRow.UID}
          secondaryRelatedTo={businessProcessStepRow.Title}
          relatedToUID={businessProcessRow && businessProcessRow.RelatedToUID}
          relatedTo={businessProcessRow && businessProcessRow.RelatedTo}
          relatedEntity={businessProcessRow && businessProcessRow.RelatedToType}
          mode={"create"}
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  }

  onUploadDocumentFile = (UID) => {
    if (this._isMounted) {
      this.setState(
        { showUploadContent: false, triggerInputFile: false },
        () => {
          this.documentCreated();
        }
      );
    }
  };

  documentCreated = () => {
    let { businessProcessStepRow } = this.props;
    document.body.classList.remove("overflow-hidden");
    if (this._isMounted) {
      this.setState(
        { showUploadContent: false, relatedDocumnetDivKey: Math.random() },
        () => {
          this.saveBPStep(businessProcessStepRow);
        }
      );
    }
  };

  private uploadDialogClose(): void {
    if (this._isMounted) {
      this.setState({ showUploadContent: false });
    }
  }

  createTaskDialogOpen = () => {
    document.body.classList.add("overflow-hidden");
    if (this._isMounted) {
      this.setState({ showCreateTaskContent: true });
    }
  };

  createTaskContent = () => {
    let { businessProcessStepRow, businessProcessRow } = this.props;
    if (
      isNotNullAndUndefined(this.state.showCreateTaskContent) &&
      this.state.showCreateTaskContent === true
    ) {
      return (
        <ManageTask
          onSave={() => this.refreshOnCreateNewTask()}
          onClose={this.createTaskDialogClose.bind(this)}
          secondaryRelatedToType={businessProcessStepRow.StepType}
          secondaryRelatedToUID={businessProcessStepRow.UID}
          secondaryRelatedTo={businessProcessStepRow.Title}
          relatedToType={businessProcessRow.RelatedToType}
          relatedToUID={businessProcessRow.RelatedToUID}
          relatedTo={businessProcessRow.RelatedTo}
          isActive
          isCreateTask
        />
      );
    } else {
      return <div></div>;
    }
  };

  createTaskDialogClose = () => {
    document.body.classList.remove("overflow-hidden");
    if (this._isMounted) {
      this.setState({ showCreateTaskContent: false });
    }
  };

  refreshOnCreateNewTask = () => {
    let { businessProcessStepRow } = this.props;
    document.body.classList.remove("overflow-hidden");
    if (this._isMounted) {
      this.setState(
        { showCreateTaskContent: false, relatedTaskDivKey: Math.random() },
        () => {
          this.saveBPStep(businessProcessStepRow);
          if (this.state.isRelatedTaskUpdated !== true) {
            if (this._isMounted) {
              this.setState({ isRelatedTaskUpdated: true });
            }
          }
          if (this.props.mode === "Details") {
            if (this.props.relatedCommentsUpdated) {
              this.props.relatedCommentsUpdated();
            }
          }
        }
      );
    }
  };

  saveBPStep = (bpStepRow: BusinessProcessStepRow) => {
    RetrieveBusinessProcessStepById(bpStepRow.UID).then((btStepResponse) => {
      if (isNullOrUndefined(btStepResponse)) {
        SaveBusinessProcessStepAPI(bpStepRow).then((bpStepRow) => {});
      }
    });
  };

  showCreateCommentDialog = () => {
    document.body.classList.add("overflow-hidden");
    if (this._isMounted) {
      this.setState({ showCreateCommentContent: true });
    }
  };

  createCommentContent() {
    if (
      this.state.showCreateCommentContent &&
      this.state.showCreateCommentContent === true
    ) {
      let { businessProcessStepRow, businessProcessRow } = this.props;
      return (
        <div className="px-2 py-2">
          <CreateActivity
            onUpdate={() => this.onCreateNewActivity()}
            secondaryRelatedToType={RAFEntityName.BusinessProcessStep}
            secondaryRelatedToUID={businessProcessStepRow.UID}
            secondaryRelatedTo={businessProcessStepRow.Title}
            relatedToUID={businessProcessRow && businessProcessRow.RelatedToUID}
            relatedTo={businessProcessRow && businessProcessRow.RelatedTo}
            entityName={businessProcessRow && businessProcessRow.RelatedToType}
            addBtnText={"Add"}
          />
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  onCreateNewActivity = () => {
    let { businessProcessStepRow } = this.props;
    document.body.classList.remove("overflow-hidden");
    this.setState(
      { showCreateCommentContent: false, relatedCommentDivKey: Math.random() },
      () => {
        this.saveBPStep(businessProcessStepRow);
        if (this.state.isRelatedCommentsUpdated !== true) {
          if (this._isMounted) {
            this.setState({ isRelatedCommentsUpdated: true });
          }
        }
        if (this.props.mode === "Details") {
          if (this.props.relatedCommentsUpdated) {
            this.props.relatedCommentsUpdated();
          }
        }
      }
    );
  };

  createCommentDialogClose = () => {
    document.body.classList.remove("overflow-hidden");
    if (this._isMounted) {
      this.setState({ showCreateCommentContent: false });
    }
  };

  onClickAddBp(args: MenuEventArgs) {
    if (args.item.id === "AddComments") {
      this.showCreateCommentDialog();
    } else if (args.item.id === "AddDocument") {
      this.uploadDocument();
    } else if (args.item.id === "AddTask") {
      this.createTaskDialogOpen();
    } else if (args.item.id === "AddPhoto") {
      this.triggerInputFile();
    }
  }

  triggerInputFile = () => {
    if (this._isMounted) {
      if (this.state.triggerInputFile === true) {
        this.setState({ triggerInputFile: false }, () => {
          this.setState({ triggerInputFile: true });
        });
      } else {
        this.setState({ triggerInputFile: true });
      }
    }
  };

  createRelatedDialogOpen = (relatedModuleToCreate) => {
    document.body.classList.add("overflow-hidden");
    if (this._isMounted) {
      this.setState({ showCreateRelatedContent: true, relatedModuleToCreate });
    }
  };

  createRelatedContent = () => {
    let { businessProcessStepRow } = this.props;
    let { relatedModuleToCreate, showCreateRelatedContent } = this.state;
    if (
      isNotNullAndUndefined(showCreateRelatedContent) &&
      showCreateRelatedContent === true
    ) {
      let initialValues = {
        BPStep: businessProcessStepRow.Title,
        BPStepUID: businessProcessStepRow.UID,
      };
      return (
        <div>
          <RAFCreate
            initialValues={initialValues}
            isActive
            moduleName={relatedModuleToCreate.Type}
            onSave={(entityId, objectName) =>
              this.refreshOnRelatedCreate(entityId, objectName)
            }
            onClose={() => this.createRelatedDialogClose()}
          />
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  createRelatedDialogClose = () => {
    document.body.classList.remove("overflow-hidden");
    if (this._isMounted) {
      this.setState({
        showCreateRelatedContent: false,
        relatedModuleToCreate: null,
      });
    }
  };

  refreshOnRelatedCreate = (entityId?: string, objectName?: string) => {
    this.setState({
      showCreateRelatedContent: false,
      relatedModuleToCreate: null,
    });
  };

  render() {
    let {
      businessProcessRow,
      businessProcessStepRow,
      businessProcessStepRows,
      showNext,
      showPrevious,
      index,
      mode,
      hideRelatedCommentSections,
      hideRelateTaskSections,
      hideRelatedDoumentSections,
      bpTemplateStepsRow,
    } = this.props;
    const loggedinUserId = msalInstance.currentUserId;
    let stepCssClass;
    let stepIconClass;
    let stepOrder = businessProcessStepRow.StepOrder;
    let displayOrder = businessProcessStepRow.DisplayOrder;
    let stepStatus = businessProcessStepRow.StepStatus;
    let toDoIndex = 0;
    let stepTitle: string = businessProcessStepRow.Title;

    let processsExecutionOrder: string =
      businessProcessRow && businessProcessRow.ExecutionOrder;
    let parentStepUID: string = businessProcessStepRow.ParentBpStepTemplateUID;
    let parentStep =
      businessProcessStepRows &&
      businessProcessStepRows.find(
        (x) => x.BpStepTemplateUID === parentStepUID
      );

    let childBPStepTemplates =
      bpTemplateStepsRow &&
      bpTemplateStepsRow.filter(
        (x) => x.ParentStepUID === businessProcessStepRow.BpStepTemplateUID
      );
    let childBPStepTemplatesCount = 0;
    if (
      isNotNullAndUndefined(childBPStepTemplates) &&
      childBPStepTemplates.length > 0
    ) {
      childBPStepTemplatesCount = childBPStepTemplates.length;
    }
    let showGroupCompleteBtn = false;
    let stepType = businessProcessStepRow.StepType;
    let stepTypeInitial =
      stepType && stepType.toString().charAt(0).toUpperCase();
    let stepAssigneeUID = businessProcessStepRow.AssigneeUID;
    let stepAssignee: string = businessProcessStepRow.Assignee;
    let stepCompletedBy: string = businessProcessStepRow.CompletedBy;
    let stepUID: string = businessProcessStepRow.UID;
    let isApproval = businessProcessStepRow.IsApproval;
    let stepTeamAssignee = businessProcessStepRow.AssignTeam;
    let totalSteps = 0;
    let completedSteps = 0;

    totalSteps = isNotNullAndUndefined(businessProcessStepRows)
      ? businessProcessStepRows.filter((x) => x.ParentStepUID === stepUID)
          .length
      : 0;
    completedSteps =
      isNotNullAndUndefined(businessProcessStepRows) &&
      businessProcessStepRows.length > 0
        ? businessProcessStepRows.filter(
            (x) =>
              x.ParentStepUID === stepUID &&
              (x.StepStatus === RAFBPStepStatus.Completed ||
                x.StepStatus === RAFBPStepStatus.Skipped ||
                x.StepStatus === RAFBPStepStatus.Approved ||
                x.StepStatus === RAFBPStepStatus.Reviewed ||
                x.StepStatus === RAFBPStepStatus.Rejected)
          ).length
        : 0;
    let modifiedBy: string = businessProcessStepRow.ModifiedBy;
    let completedBy = isNotNullAndUndefined(stepCompletedBy)
      ? stepCompletedBy
      : isNotNullAndUndefined(stepAssignee)
      ? stepAssignee
      : modifiedBy;

    let localDate = convertUTCDateToLocalTimezone(
      businessProcessStepRow.DueDate
    );
    let stepDueDateTooltip = isNotNullAndUndefined(localDate)
      ? moment(localDate).format("DD/MM/YYYY")
      : null;
    let stepDueDate: string = isNotNullAndUndefined(localDate)
      ? "Due " + moment(localDate).format("MMM DD")
      : null;
    let isOverDue: boolean = moment(localDate).isBefore(new Date(), "day");

    let stepCompletedlocalDate = convertUTCDateToLocalTimezone(
      businessProcessStepRow.CompletedDate
    );
    let stepCompletedTooltip = isNotNullAndUndefined(stepCompletedlocalDate)
      ? moment(stepCompletedlocalDate).format("DD/MM/YYYY hh:mm A")
      : null;
    //let stepCompletedDateFormate: string = isNotNullAndUndefined(stepCompletedlocalDate) ? completedBy + " on "+  moment(stepCompletedlocalDate).format('MMM DD') : null;
    let stepCompletedDateFormat: string = isNotNullAndUndefined(
      stepCompletedlocalDate
    )
      ? moment(stepCompletedlocalDate).subtract(20, "s").fromNow() +
        (stepStatus === RAFBPStepStatus.Completed ||
        stepStatus === RAFBPStepStatus.Skipped ||
        stepStatus === RAFBPStepStatus.Approved ||
        stepStatus === RAFBPStepStatus.Reviewed ||
        stepStatus === RAFBPStepStatus.Rejected
          ? " by "
          : "") +
        (isNotNullAndUndefined(completedBy) &&
        isNotNullAndUndefined(stepAssignee) &&
        completedBy !== stepAssignee
          ? completedBy + " on behalf of " + stepAssignee
          : completedBy)
      : null;

    let completedChildBPSteps =
      businessProcessStepRows &&
      businessProcessStepRows.filter(
        (x) =>
          x.ParentBpStepTemplateUID ===
            businessProcessStepRow.BpStepTemplateUID &&
          (x.StepStatus === RAFBPStepStatus.Completed ||
            x.StepStatus === RAFBPStepStatus.Skipped ||
            x.StepStatus === RAFBPStepStatus.Approved ||
            x.StepStatus === RAFBPStepStatus.Reviewed ||
            x.StepStatus === RAFBPStepStatus.Rejected ||
            x.StepStatus === RAFBPStepStatus.RequestforInfo)
      );
    if (
      isNotNullAndUndefined(completedChildBPSteps) &&
      completedChildBPSteps.length > 0
    ) {
      showGroupCompleteBtn =
        completedChildBPSteps.length === childBPStepTemplatesCount
          ? true
          : false;
    }

    if (stepStatus === RAFBPStepStatus.Completed) {
      //stepOrder = null;
      stepCssClass = "bp-step-avatar completed-step-avatar";
      stepIconClass = "fa fa-check";
    } else if (stepStatus === RAFBPStepStatus.Approved) {
      //stepOrder = null;
      stepCssClass = "bp-step-avatar completed-step-avatar";
      stepIconClass = "far fa-thumbs-up";
    } else if (stepStatus === RAFBPStepStatus.Reviewed) {
      //stepOrder = null;
      stepCssClass = "bp-step-avatar completed-step-avatar";
      stepIconClass = "far fa-thumbs-up";
    } else if (stepStatus === RAFBPStepStatus.Rejected) {
      //stepOrder = null;
      stepCssClass = "bp-step-avatar rejected-step-avatar";
      stepIconClass = "far fa-thumbs-down";
    } else if (stepStatus === RAFBPStepStatus.Skipped) {
      //stepOrder = null;
      stepCssClass = "bp-step-avatar skipped-step-avatar";
      stepIconClass = "fa fa-ban";
    } else if (stepStatus === RAFBPStepStatus.ToDo) {
      stepCssClass = "bp-step-avatar inProgress-step-avatar";
      stepIconClass =
        stepStatus === RAFBPStepStatus.ToDo
          ? "fa fa-random"
          : "fas fa-user-check";
    } else if (stepStatus === RAFBPStepStatus.AwaitingforApproval) {
      //stepOrder = null;
      stepCssClass = "bp-step-avatar awaiting-step-avatar";
      stepIconClass = "fa fa-spinner";
    } else if (stepStatus === RAFBPStepStatus.AwaitingforReview) {
      //stepOrder = null;
      stepCssClass = "bp-step-avatar awaiting-step-avatar";
      stepIconClass = "fa fa-spinner";
    } else if (stepStatus === RAFBPStepStatus.Assigned) {
      //stepOrder = null;
      stepCssClass = "bp-step-avatar inProgress-step-avatar";
      stepIconClass = "fas fa-user";
    } else if (stepStatus === RAFBPStepStatus.RequestApproval) {
      //stepOrder = null;
      stepCssClass = "bp-step-avatar inProgress-step-avatar";
      stepIconClass = "fas fa-user-check";
    } else if (stepStatus === RAFBPStepStatus.RequestReview) {
      //stepOrder = null;
      stepCssClass = "bp-step-avatar inProgress-step-avatar";
      stepIconClass = "fas fa-user-check";
    } else if (stepStatus === RAFBPStepStatus.Queued) {
      /*else if (isNotNullAndUndefined(parentStepUID)) {
                                          if (isNotNullAndUndefined(parentStep) && parentStep.ExecutionOrder === RAFExecutionOrder.AnyOrder) {
                                              stepOrder = null;
                                              stepCssClass = "bp-step-avatar";
                                              stepIconClass = "fa fa-random";
                                          }
                                          else {
                                              stepCssClass = "bp-step-avatar";
                                          }
                                      }*/
      stepCssClass = "bp-step-avatar queued-step-avatar";
    } else {
      if (
        isNotNullAndUndefined(processsExecutionOrder) &&
        processsExecutionOrder === RAFExecutionOrder.AnyOrder
      ) {
        //stepOrder = null;
        stepCssClass = "bp-step-avatar";
        stepIconClass = "fa fa-random";
      } else {
        stepCssClass = "bp-step-avatar";
      }
    }

    let approvalGroupedBtnitems: ItemModel[] = [
      {
        text: "Approve",
        id: "Approve",
      },
      {
        text: "Reject",
        id: "Reject",
      },
      {
        text: "Request more Info",
        id: "RequestforInfo",
      },
    ];

    let reviewGroupedBtnitems: ItemModel[] = [
      {
        text: "Complete Review",
        id: "Review",
      },
      {
        text: "Reject",
        id: "Reject",
      },
      {
        text: "Request more Info",
        id: "RequestforInfo",
      },
    ];

    let isActive = this.state.isActive;

    let stepTypeIcon;
    let stepTypeName;

    switch (stepType) {
      case RAFBPStepType.ToDo:
        stepTypeIcon = RAFBPStepTypeIcon.ToDo;
        stepTypeName = RAFBPStepType.ToDo;
        break;
      case RAFBPStepType.Note:
        stepTypeIcon = RAFBPStepTypeIcon.Note;
        stepTypeName = RAFBPStepType.Note;
        break;
      case RAFBPStepType.Task:
        stepTypeIcon = RAFBPStepTypeIcon.Task;
        stepTypeName = RAFBPStepType.Task;
        break;
      case RAFBPStepType.Question:
        stepTypeIcon = RAFBPStepTypeIcon.Question;
        stepTypeName = RAFBPStepType.Question;
        break;
      case RAFBPStepType.Approval:
        stepTypeIcon = RAFBPStepTypeIcon.Approval;
        stepTypeName = RAFBPStepType.Approval;
        break;
      case RAFBPStepType.Review:
        stepTypeIcon = RAFBPStepTypeIcon.Review;
        stepTypeName = RAFBPStepType.Review;
        break;
      case RAFBPStepType.Group:
        stepTypeIcon = RAFBPStepTypeIcon.Group;
        stepTypeName = RAFBPStepType.Group;
        break;
      default:
        stepTypeIcon = "far fa-file";
        break;
    }

    let actionDisplayText = isNotNullAndUndefined(
      RAFBPStepStatusDisplayText[this.state.newStepStatus]
    )
      ? RAFBPStepStatusDisplayText[this.state.newStepStatus]
      : this.state.newStepStatus;

    let completeStepAlertTitle;
    if (isNotNullAndUndefined(actionDisplayText)) {
      switch (this.state.newStepStatus) {
        case RAFBPStepStatus.Reviewed:
          completeStepAlertTitle = "Review Request";
          break;
        case RAFBPStepStatus.Approved:
          completeStepAlertTitle = "Approval Request";
          break;
        case RAFBPStepStatus.Rejected:
          completeStepAlertTitle = "Reject";
          break;
        case RAFBPStepStatus.RequestforInfo:
          completeStepAlertTitle = "Request more Info";
          break;
        default:
          completeStepAlertTitle = actionDisplayText + " Step";
          break;
      }
    }

    if (isActive) {
      return (
        <div className="h-100">
          <RAFProcessContext.Consumer>
            {({ businessProcess }) => {
              if (isNotNullAndUndefined(businessProcess)) {
                let bpTheme = RAFBPTemplateSettings.find(
                  (x) => x.Id === businessProcess.ThemeTemplate
                );

                let showCompleteBtn =
                  isNotNullAndUndefined(bpTheme) &&
                  isNotNullAndUndefined(bpTheme)
                    ? bpTheme.ShowComplete
                    : true;

                return (
                  <div className="h-100">
                    <MyTeamsContext.Consumer>
                      {({ teams }) => {
                        let isMyTeam = [];
                        if (
                          isNotNullAndUndefined(isMyTeam) &&
                          isNotNullAndUndefined(
                            businessProcessStepRow.AssignTeamUID
                          )
                        )
                          isMyTeam =
                            teams &&
                            teams.filter(
                              (x) =>
                                x.UID === businessProcessStepRow.AssignTeamUID
                            );
                        this.isMyTeam = isMyTeam;
                        return (
                          <Fragment>
                            <RAFForm
                              type={BusinessProcessStepRow}
                              initialValues={businessProcessStepRow}
                              formRef={(g) => (this.rafForm = g)}
                              onSubmit={this.onSubmitBusinessProcessStep}
                              {...(businessProcessStepRow
                                ? { primaryKey: businessProcessStepRow.UID }
                                : {})}
                            >
                              {mode === "Dialog" && (
                                <div className="e-dlg-content-outer">
                                  <div
                                    className="e-dlg-headerContent justify-content-between"
                                    id="taskUpdateDialog_dialog-header"
                                  >
                                    <div className="d-flex align-items-center">
                                      <div className="me-2">
                                        {businessProcessStepRow.StepType ===
                                          RAFBPStepType.Group ||
                                        businessProcessStepRow.StepType ===
                                          RAFBPStepType.Stage ? (
                                          <div className="treeview-avatar p-2 show-badge position-relative bg-white">
                                            <RAFIconImage
                                              iconCssClass={stepIconClass}
                                              value={displayOrder}
                                              moduleavatar={stepCssClass}
                                              tooltipText={stepTitle}
                                              iconSize="30"
                                              fontSize="12"
                                              showFullText
                                            ></RAFIconImage>
                                            <span className="treeview-folder-avatar-badge">
                                              <i className="fa fa-folder"></i>
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="treeview-avatar p-2 position-relative bg-white">
                                            <RAFIconImage
                                              value={displayOrder}
                                              tooltipText={stepTitle}
                                              iconCssClass={stepIconClass}
                                              moduleavatar={stepCssClass}
                                              iconSize="30"
                                              fontSize="12"
                                              showFullText
                                            ></RAFIconImage>
                                          </div>
                                        )}
                                      </div>
                                      <TooltipComponent
                                        content={businessProcessStepRow.Title}
                                      >
                                        <div
                                          className="e-dlg-header w-auto"
                                          id="_title"
                                        >
                                          {" "}
                                          {businessProcessStepRow.Title}
                                        </div>
                                      </TooltipComponent>
                                    </div>
                                    <ButtonComponent
                                      type="button"
                                      cssClass="primary-custom-button"
                                      iconCss="fas fa-xmark"
                                      onClick={() =>
                                        this.onCloseBusinessProcessDialog()
                                      }
                                    ></ButtonComponent>
                                    {/* <button type="button" className="btn-close ms-2" onClick={() => this.onCloseBusinessProcessDialog()}></button>*/}
                                  </div>
                                  <div className="e-dlg-body-content bp-body-content mt-0 h-100 overflow-auto pt-3">
                                    <ManageStep
                                      businessProcessStepRow={
                                        businessProcessStepRow
                                      }
                                      businessProcessRow={businessProcessRow}
                                      businessProcessStepRows={
                                        businessProcessStepRows
                                      }
                                      onBPStepSave={(
                                        allBPRows,
                                        dependentBPRows,
                                        currentBPStepRow,
                                        businessProcessRow
                                      ) =>
                                        this.onBPStepSaved(
                                          allBPRows,
                                          dependentBPRows,
                                          currentBPStepRow,
                                          businessProcessRow
                                        )
                                      }
                                      bpTemplateStepsRow={
                                        this.props.bpTemplateStepsRow
                                      }
                                      /*onShowNextClicked={(UID) => this.props.onShowNextSPClicked(UID)}
                                                                                                                                                                                                                                                                      onShowPreviousClicked={(UID) => this.props.onShowPreviousSPClicked(UID)}*/
                                      //showNext={showNext} showPrevious={showPrevious}
                                      index={index}
                                      //hideRelatedSections={hideRelatedSections}
                                      hideRelatedCommentSections={
                                        hideRelatedCommentSections
                                      }
                                      hideRelateTaskSections={
                                        hideRelateTaskSections
                                      }
                                      hideRelatedDoumentSections={
                                        hideRelatedDoumentSections
                                      }
                                      onBackClick={() =>
                                        this.props.navigate(-1)
                                      }
                                      relatedCommentsUpdated={() => {
                                        if (
                                          this.state.isRelatedCommentsUpdated !=
                                          true
                                        ) {
                                          if (this._isMounted) {
                                            this.setState({
                                              isRelatedCommentsUpdated: true,
                                            });
                                          }
                                        }
                                      }}
                                      relatedTaskUpdated={() => {
                                        if (
                                          this.state.isRelatedTaskUpdated !=
                                          true
                                        ) {
                                          if (this._isMounted) {
                                            this.setState({
                                              isRelatedTaskUpdated: true,
                                            });
                                          }
                                        }
                                      }}
                                      allEntities={this.props.allEntities}
                                      allForms={this.props.allForms}
                                      relatedTaskDivKey={
                                        this.state.relatedTaskDivKey
                                      }
                                      relatedDocumnetDivKey={
                                        this.state.relatedDocumnetDivKey
                                      }
                                      relatedCommentDivKey={
                                        this.state.relatedCommentDivKey
                                      }
                                    />
                                  </div>
                                  {showCompleteBtn && (
                                    <div
                                      className="e-dlg-footerContent "
                                      style={{ display: "inline" }}
                                    >
                                      {businessProcess.ProcessStatus !==
                                        RAFBPStatus.Completed && (
                                        <div className="d-flex justify-content-center">
                                          {isNotNullAndUndefined(
                                            businessProcessStepRow.IsApproval
                                          ) &&
                                            businessProcessStepRow.IsApproval ===
                                              false &&
                                            businessProcessStepRow.StepStatus ===
                                              RAFBPStepStatus.ToDo &&
                                            (businessProcessStepRow.StepType ===
                                              RAFBPStepType.Item ||
                                              businessProcessStepRow.StepType ===
                                                RAFBPStepType.ToDo ||
                                              businessProcessStepRow.StepType ===
                                                RAFBPStepType.Heading ||
                                              businessProcessStepRow.StepType ===
                                                RAFBPStepType.Question ||
                                              businessProcessStepRow.StepType ===
                                                RAFBPStepType.Note) && (
                                              <div className="d-none d-sm-flex align-items-center">
                                                {businessProcessStepRow.IsRequired !==
                                                  true && (
                                                  <ButtonComponent
                                                    type="button"
                                                    className="e-small me-2"
                                                    onClick={() =>
                                                      this.updateBusinessProcessStepStatus(
                                                        RAFBPStepStatus.Skipped
                                                      )
                                                    }
                                                  >
                                                    Skip
                                                  </ButtonComponent>
                                                )}
                                                <ButtonComponent
                                                  type="button"
                                                  className="e-primary e-small mx-2"
                                                  onClick={() =>
                                                    this.updateBusinessProcessStepStatus(
                                                      RAFBPStepStatus.Completed
                                                    )
                                                  }
                                                >
                                                  Complete
                                                </ButtonComponent>
                                              </div>
                                            )}
                                          {isNotNullAndUndefined(
                                            businessProcessStepRow.IsApproval
                                          ) &&
                                            businessProcessStepRow.IsApproval ===
                                              false &&
                                            businessProcessStepRow.StepStatus ===
                                              RAFBPStepStatus.Assigned &&
                                            businessProcessStepRow.StepType ===
                                              RAFBPStepType.Task && (
                                              <div className="d-none d-sm-flex align-items-center">
                                                {businessProcessStepRow.IsRequired !==
                                                  true && (
                                                  <ButtonComponent
                                                    type="button"
                                                    className="e-small me-2"
                                                    onClick={() =>
                                                      this.updateBusinessProcessStepStatus(
                                                        RAFBPStepStatus.Skipped
                                                      )
                                                    }
                                                  >
                                                    Skip
                                                  </ButtonComponent>
                                                )}
                                                <ButtonComponent
                                                  type="button"
                                                  className="e-primary e-small mx-2"
                                                  onClick={() =>
                                                    this.updateBusinessProcessStepStatus(
                                                      RAFBPStepStatus.Completed
                                                    )
                                                  }
                                                >
                                                  Complete
                                                </ButtonComponent>
                                              </div>
                                            )}
                                          {isNotNullAndUndefined(
                                            businessProcessStepRow.IsApproval
                                          ) &&
                                            businessProcessStepRow.IsApproval ===
                                              true &&
                                            businessProcessStepRow.StepStatus ===
                                              RAFBPStepStatus.RequestApproval && (
                                              <div
                                                id="bpBtnOuter"
                                                className="d-flex align-items-center"
                                              >
                                                <ButtonComponent
                                                  type="button"
                                                  className="e-primary e-small"
                                                  onClick={() =>
                                                    this.onSetApproverOrReviewerClicked()
                                                  }
                                                >
                                                  {" "}
                                                  Request Approval
                                                </ButtonComponent>
                                              </div>
                                            )}
                                          {businessProcessStepRow.StepType ===
                                            RAFBPStepType.Task &&
                                            (isNullOrUndefined(
                                              businessProcessStepRow.AssignTeamUID
                                            ) ||
                                              isNullOrUndefined(
                                                businessProcessStepRow.AssigneeUID
                                              )) &&
                                            businessProcessStepRow.StepStatus ===
                                              RAFBPStepStatus.ToDo && (
                                              <div className="col-auto">
                                                <ButtonComponent
                                                  type="button"
                                                  className="e-primary e-small"
                                                  onClick={() =>
                                                    this.showAssigneeDialogOpen()
                                                  }
                                                >
                                                  {" "}
                                                  Set Assignee
                                                </ButtonComponent>
                                              </div>
                                            )}
                                          {isNotNullAndUndefined(
                                            businessProcessStepRow.IsApproval
                                          ) &&
                                            businessProcessStepRow.IsApproval ===
                                              true &&
                                            businessProcessStepRow.StepStatus ===
                                              RAFBPStepStatus.RequestReview && (
                                              <div
                                                id="bpBtnOuter"
                                                className="d-flex align-items-center"
                                              >
                                                <ButtonComponent
                                                  type="button"
                                                  className="e-primary e-small"
                                                  onClick={() =>
                                                    this.onSetApproverOrReviewerClicked()
                                                  }
                                                >
                                                  {" "}
                                                  Request Review
                                                </ButtonComponent>
                                              </div>
                                            )}

                                          {isNotNullAndUndefined(
                                            businessProcessStepRow.IsApproval
                                          ) &&
                                            businessProcessStepRow.IsApproval ===
                                              false &&
                                            businessProcessStepRow.StepStatus ===
                                              RAFBPStepStatus.ToDo &&
                                            (businessProcessStepRow.StepType ===
                                              RAFBPStepType.Item ||
                                              businessProcessStepRow.StepType ===
                                                RAFBPStepType.ToDo ||
                                              businessProcessStepRow.StepType ===
                                                RAFBPStepType.Heading ||
                                              businessProcessStepRow.StepType ===
                                                RAFBPStepType.Question ||
                                              businessProcessStepRow.StepType ===
                                                RAFBPStepType.Note) && (
                                              <div className="d-flex d-sm-none align-items-center">
                                                <SplitButtonComponent
                                                  items={[
                                                    {
                                                      id: "Skip",
                                                      text: "Skip",
                                                      disabled:
                                                        businessProcessStepRow.IsRequired ===
                                                        true,
                                                    },
                                                  ]}
                                                  cssClass="e-small e-info"
                                                  click={() =>
                                                    this.updateBusinessProcessStepStatus(
                                                      RAFBPStepStatus.Completed
                                                    )
                                                  }
                                                  select={(args) =>
                                                    this.onMoreMenuClicked(args)
                                                  }
                                                >
                                                  Complete
                                                </SplitButtonComponent>
                                              </div>
                                            )}

                                          {isNotNullAndUndefined(
                                            businessProcessStepRow.IsApproval
                                          ) &&
                                            businessProcessStepRow.IsApproval ===
                                              true &&
                                            (businessProcessStepRow.StepStatus ===
                                              RAFBPStepStatus.ToDo ||
                                              businessProcessStepRow.StepStatus ===
                                                RAFBPStepStatus.AwaitingforApproval) &&
                                            businessProcessStepRow.StepType ===
                                              RAFBPStepType.Approval && (
                                              <div className=" align-items-center">
                                                <ButtonComponent
                                                  type="button"
                                                  className="d-none d-sm-inline-block e-success e-small me-2"
                                                  onClick={() =>
                                                    this.updateBusinessProcessStepStatus(
                                                      RAFBPStepStatus.Approved
                                                    )
                                                  }
                                                >
                                                  {" "}
                                                  Approve
                                                </ButtonComponent>
                                                <ButtonComponent
                                                  type="button"
                                                  className="d-none d-sm-inline-block e-danger e-small mx-2"
                                                  onClick={() =>
                                                    this.updateBusinessProcessStepStatus(
                                                      RAFBPStepStatus.Rejected
                                                    )
                                                  }
                                                >
                                                  {" "}
                                                  Reject
                                                </ButtonComponent>
                                                <ButtonComponent
                                                  type="button"
                                                  className="d-none d-sm-inline-block e-info e-small ms-2"
                                                  onClick={() =>
                                                    this.updateBusinessProcessStepStatus(
                                                      RAFBPStepStatus.RequestforInfo
                                                    )
                                                  }
                                                >
                                                  Request more Info
                                                </ButtonComponent>

                                                <SplitButtonComponent
                                                  items={
                                                    approvalGroupedBtnitems
                                                  }
                                                  cssClass="d-sm-none e-small e-info"
                                                  click={() =>
                                                    this.updateBusinessProcessStepStatus(
                                                      RAFBPStepStatus.Approved
                                                    )
                                                  }
                                                  select={(args) =>
                                                    this.onMoreMenuClicked(args)
                                                  }
                                                >
                                                  Approve
                                                </SplitButtonComponent>

                                                {/*<ButtonComponent type="button" className="d-sm-none d-lg-none e-success e-small me-2" iconCss="far fa-thumbs-up" onClick={() => this.updateBusinessProcessStepStatus(RAFBPStepStatus.Approved)}></ButtonComponent>
                                    <ButtonComponent type="button" className="d-sm-none e-danger e-small mx-2" iconCss="far fa-thumbs-down" onClick={() => this.updateBusinessProcessStepStatus(RAFBPStepStatus.Rejected)}></ButtonComponent>
                                    <ButtonComponent type="button" className="d-sm-none e-info e-small ms-2" iconCss="fas fa-info" onClick={() => this.updateBusinessProcessStepStatus(RAFBPStepStatus.RequestforInfo)}></ButtonComponent>*/}
                                              </div>
                                            )}

                                          {isNotNullAndUndefined(
                                            businessProcessStepRow.IsApproval
                                          ) &&
                                            businessProcessStepRow.IsApproval ===
                                              true &&
                                            (businessProcessStepRow.StepStatus ===
                                              RAFBPStepStatus.ToDo ||
                                              businessProcessStepRow.StepStatus ===
                                                RAFBPStepStatus.AwaitingforReview) &&
                                            businessProcessStepRow.StepType ===
                                              RAFBPStepType.Review && (
                                              <div className=" align-items-center">
                                                <ButtonComponent
                                                  type="button"
                                                  className="d-none d-sm-inline-block e-success e-small me-2"
                                                  onClick={() =>
                                                    this.updateBusinessProcessStepStatus(
                                                      RAFBPStepStatus.Reviewed
                                                    )
                                                  }
                                                >
                                                  {" "}
                                                  Complete Review
                                                </ButtonComponent>
                                                <ButtonComponent
                                                  type="button"
                                                  className="d-none d-sm-inline-block e-danger e-small mx-2"
                                                  onClick={() =>
                                                    this.updateBusinessProcessStepStatus(
                                                      RAFBPStepStatus.Rejected
                                                    )
                                                  }
                                                >
                                                  {" "}
                                                  Reject
                                                </ButtonComponent>
                                                <ButtonComponent
                                                  type="button"
                                                  className="d-none d-sm-inline-block e-info e-small ms-2"
                                                  onClick={() =>
                                                    this.updateBusinessProcessStepStatus(
                                                      RAFBPStepStatus.RequestforInfo
                                                    )
                                                  }
                                                >
                                                  Request more Info
                                                </ButtonComponent>

                                                <SplitButtonComponent
                                                  items={reviewGroupedBtnitems}
                                                  cssClass="d-sm-none e-small e-info"
                                                  click={() =>
                                                    this.updateBusinessProcessStepStatus(
                                                      RAFBPStepStatus.Reviewed
                                                    )
                                                  }
                                                  select={(args) =>
                                                    this.onMoreMenuClicked(args)
                                                  }
                                                >
                                                  Complete Review
                                                </SplitButtonComponent>

                                                {/*<ButtonComponent type="button" className="d-sm-none d-lg-none e-success e-small me-2" iconCss="far fa-thumbs-up" onClick={() => this.updateBusinessProcessStepStatus(RAFBPStepStatus.Approved)}></ButtonComponent>
                                    <ButtonComponent type="button" className="d-sm-none e-danger e-small mx-2" iconCss="far fa-thumbs-down" onClick={() => this.updateBusinessProcessStepStatus(RAFBPStepStatus.Rejected)}></ButtonComponent>
                                    <ButtonComponent type="button" className="d-sm-none e-info e-small ms-2" iconCss="fas fa-info" onClick={() => this.updateBusinessProcessStepStatus(RAFBPStepStatus.RequestforInfo)}></ButtonComponent>*/}
                                              </div>
                                            )}
                                          {(businessProcessStepRow.StepStatus ===
                                            RAFBPStepStatus.Completed ||
                                            businessProcessStepRow.StepStatus ===
                                              RAFBPStepStatus.Skipped) &&
                                            (businessProcessStepRow.StepType ===
                                              RAFBPStepType.Question ||
                                              businessProcessStepRow.StepType ===
                                                RAFBPStepType.ToDo) && (
                                              <div className="col-auto d-flex">
                                                <div className="w-100">
                                                  <div className="row g-2 flex-nowrap">
                                                    <div className="col-auto">
                                                      <ButtonComponent
                                                        type="button"
                                                        className="e-small primary-custom-button unset-custom-button-md"
                                                        onClick={() =>
                                                          this.resetBusinessProcessStep(
                                                            RAFBPStepStatus.Cleared
                                                          )
                                                        }
                                                      >
                                                        Undo
                                                      </ButtonComponent>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                        </div>
                                      )}
                                      {(businessProcessStepRow.StepStatus ===
                                        RAFBPStepStatus.Completed ||
                                        businessProcessStepRow.StepStatus ===
                                          RAFBPStepStatus.Approved ||
                                        businessProcessStepRow.StepStatus ===
                                          RAFBPStepStatus.Reviewed ||
                                        businessProcessStepRow.StepStatus ===
                                          RAFBPStepStatus.Skipped ||
                                        businessProcessStepRow.StepStatus ===
                                          RAFBPStepStatus.Rejected) && (
                                        <div className="recordInfomsg me-2 align-items-center d-flex">
                                          <RAFRecordInfo
                                            createdBy={
                                              businessProcessStepRow.CreatedBy
                                            }
                                            createdDate={
                                              businessProcessStepRow.CreatedDate
                                            }
                                            modifiedDate={
                                              businessProcessStepRow.ModifiedDate
                                            }
                                            modifiedBy={
                                              businessProcessStepRow.ModifiedBy
                                            }
                                            lastActivityDate={
                                              businessProcessStepRow.LastActivityDate
                                            }
                                            spanContentClassName="d-none d-sm-flex d-md-none d-lg-flex"
                                            content=""
                                          ></RAFRecordInfo>
                                        </div>
                                      )}
                                      {/*{isNotNullAndUndefined(businessProcessStepRow.AssigneeUID) && businessProcessStepRow.AssigneeUID !== loggedinUserId && (businessProcessStepRow.StepStatus === RAFBPStepStatus.ToDo || businessProcessStepRow.StepStatus === RAFBPStepStatus.Assigned || businessProcessStepRow.StepStatus === RAFBPStepStatus.AwaitingforApproval) && <div className="ms-2 badge-status-item text-nowrap" style={{ fontSize: '12px', padding: '2px 10px' }}>
                                {"Only '" + businessProcessStepRow.Assignee} {businessProcessStepRow.IsApproval ? "' can approve this step" : "' can complete this step"}
                            </div>}*/}
                                    </div>
                                  )}
                                </div>
                              )}
                              {mode === "Details" && (
                                <div className="h-100 d-flex flex-column">
                                  <div className="business-process-content">
                                    <div className="outerDiv">
                                      <div className="bp-header border-bottom bp-header-height-auto px-3 py-2">
                                        <div className="treeview w-100">
                                          <div className="w-100">
                                            <div className="row align-items-center justify-content-between g-3">
                                              <div className="col">
                                                <div className="d-flex align-items-center justify-content-between">
                                                  <div className="d-flex align-items-center">
                                                    <div className="treeview-avatar">
                                                      <RAFIconImage
                                                        // value={displayOrder.substring(0, 3)}
                                                        value={displayOrder}
                                                        moduleavatar={
                                                          stepCssClass +
                                                          " no-hover-avatar"
                                                        }
                                                        iconSize="30"
                                                        fontSize="12"
                                                        showFullText
                                                      ></RAFIconImage>
                                                    </div>
                                                    <div className="bp-treeview-item-content">
                                                      <div className="d-flex">
                                                        <span className="ms-2 text-wrap ecllipseFirstLine title-value">
                                                          {stepTitle}
                                                        </span>
                                                        {(stepType ===
                                                          RAFBPStepType.Group ||
                                                          stepType ===
                                                            RAFBPStepType.Stage) &&
                                                          totalSteps > 0 && (
                                                            <div
                                                              className="ms-2 d-flex flex-wrap align-items-center"
                                                              style={{
                                                                marginLeft:
                                                                  "10px",
                                                              }}
                                                            >
                                                              <span className="bp-template-content">
                                                                {"(" +
                                                                  completedSteps}
                                                              </span>
                                                              <span
                                                                className="bp-template-content"
                                                                style={{
                                                                  padding:
                                                                    "0 1px",
                                                                }}
                                                              >
                                                                /
                                                              </span>
                                                              {/*<span className="bp-template-content">{totalSteps + " step(s)"}</span>*/}
                                                              <span className="bp-template-content">
                                                                {totalSteps +
                                                                  ")"}
                                                              </span>
                                                            </div>
                                                          )}
                                                      </div>

                                                      {(stepType ===
                                                        RAFBPStepType.Item ||
                                                        stepType ===
                                                          RAFBPStepType.ToDo ||
                                                        stepType ===
                                                          RAFBPStepType.Group ||
                                                        stepType ===
                                                          RAFBPStepType.Stage ||
                                                        stepType ===
                                                          RAFBPStepType.Decision ||
                                                        stepType ===
                                                          RAFBPStepType.Approval ||
                                                        stepType ===
                                                          RAFBPStepType.Task ||
                                                        businessProcessStepRow.StepType ===
                                                          RAFBPStepType.Question ||
                                                        businessProcessStepRow.StepType ===
                                                          RAFBPStepType.Note ||
                                                        businessProcessStepRow.StepType ===
                                                          RAFBPStepType.Review) && (
                                                        <div className="ms-2 d-flex flex-wrap align-items-center">
                                                          <div>
                                                            {stepStatus ===
                                                              RAFBPStepStatus.Completed && (
                                                              <span className="bp-item-label green-text fw-bold">
                                                                {
                                                                  RAFBPStepStatus.Completed
                                                                }
                                                              </span>
                                                            )}
                                                            {stepStatus ===
                                                              RAFBPStepStatus.ToDo && (
                                                              <span className="bp-item-label blue-text fw-bold">
                                                                <i
                                                                  className={`${stepTypeIcon} stepTypeMenuIcon_${stepTypeName} me-1`}
                                                                ></i>
                                                                {stepTypeName}
                                                              </span>
                                                            )}
                                                            {stepStatus ===
                                                              RAFBPStepStatus.Queued && (
                                                              <span className="bp-item-label grey-text fw-bold">
                                                                {
                                                                  RAFBPStepStatus.Queued
                                                                }
                                                              </span>
                                                            )}
                                                            {stepStatus ===
                                                              RAFBPStepStatus.Skipped && (
                                                              <span className="bp-item-label blue-grey-text fw-bold">
                                                                {
                                                                  RAFBPStepStatus.Skipped
                                                                }
                                                              </span>
                                                            )}
                                                            {stepStatus ===
                                                              RAFBPStepStatus.Approved && (
                                                              <span className="bp-item-label green-text fw-bold">
                                                                {
                                                                  RAFBPStepStatus.Approved
                                                                }
                                                              </span>
                                                            )}
                                                            {stepStatus ===
                                                              RAFBPStepStatus.Rejected && (
                                                              <span className="bp-item-label red-text fw-bold">
                                                                {
                                                                  RAFBPStepStatus.Rejected
                                                                }
                                                              </span>
                                                            )}
                                                            {stepStatus ===
                                                              RAFBPStepStatus.Reviewed && (
                                                              <span className="bp-item-label green-text fw-bold">
                                                                {
                                                                  RAFBPStepStatus.Reviewed
                                                                }
                                                              </span>
                                                            )}
                                                            {/* {stepStatus === RAFBPStepStatus.Assigned &&
                                                <span className="bp-item-label blue-text fw-bold">{RAFBPStepStatus.Assigned}</span>
                                            } */}
                                                            {stepStatus ===
                                                              RAFBPStepStatus.AwaitingforApproval && (
                                                              <span className="bp-item-label blue-text fw-bold">
                                                                {
                                                                  RAFBPStepStatus.AwaitingforApproval
                                                                }
                                                              </span>
                                                            )}
                                                            {stepStatus ===
                                                              RAFBPStepStatus.AwaitingforReview && (
                                                              <span className="bp-item-label blue-text fw-bold">
                                                                {
                                                                  RAFBPStepStatus.AwaitingforReview
                                                                }
                                                              </span>
                                                            )}
                                                            {stepStatus ===
                                                              RAFBPStepStatus.RequestApproval && (
                                                              <span className="bp-item-label blue-text fw-bold">
                                                                {
                                                                  RAFBPStepStatus.RequestApproval
                                                                }
                                                              </span>
                                                            )}
                                                            {stepStatus ===
                                                              RAFBPStepStatus.RequestReview && (
                                                              <span className="bp-item-label blue-text fw-bold">
                                                                {
                                                                  RAFBPStepStatus.RequestReview
                                                                }
                                                              </span>
                                                            )}
                                                          </div>
                                                          <div>
                                                            {isNotNullAndUndefined(
                                                              stepAssignee
                                                            ) &&
                                                              isApproval &&
                                                              stepStatus ===
                                                                RAFBPStepStatus.AwaitingforApproval && (
                                                                <span className="bp-template-endtime pe-1">
                                                                  {"Assigned to " +
                                                                    stepAssignee +
                                                                    " "}
                                                                </span>
                                                              )}
                                                            {isNotNullAndUndefined(
                                                              stepAssignee
                                                            ) &&
                                                              isApproval &&
                                                              stepStatus ===
                                                                RAFBPStepStatus.AwaitingforReview && (
                                                                <span className="bp-template-endtime pe-1">
                                                                  {"Assigned to " +
                                                                    stepAssignee +
                                                                    " "}
                                                                </span>
                                                              )}
                                                            {isNotNullAndUndefined(
                                                              stepAssignee
                                                            ) &&
                                                              isApproval ===
                                                                false &&
                                                              stepStatus ===
                                                                RAFBPStepStatus.Assigned && (
                                                                <span className="bp-template-endtime pe-1">
                                                                  {"Assigned to " +
                                                                    stepAssignee +
                                                                    " "}
                                                                </span>
                                                              )}
                                                            {IsNullOrWhiteSpace(
                                                              stepAssignee
                                                            ) &&
                                                              isNotNullAndUndefined(
                                                                stepTeamAssignee
                                                              ) &&
                                                              isApproval ===
                                                                false &&
                                                              stepStatus ===
                                                                RAFBPStepStatus.Assigned && (
                                                                <span className="bp-template-endtime pe-1">
                                                                  {"Assigned to " +
                                                                    stepTeamAssignee +
                                                                    " "}
                                                                </span>
                                                              )}
                                                          </div>
                                                          {stepDueDate &&
                                                            stepStatus !==
                                                              RAFBPStepStatus.Completed &&
                                                            stepStatus !==
                                                              RAFBPStepStatus.Rejected &&
                                                            stepStatus !==
                                                              RAFBPStepStatus.Skipped &&
                                                            stepStatus !==
                                                              RAFBPStepStatus.Approved &&
                                                            stepStatus !==
                                                              RAFBPStepStatus.Reviewed && (
                                                              <div className="">
                                                                <TooltipComponent
                                                                  content={
                                                                    stepDueDateTooltip
                                                                  }
                                                                  position="BottomCenter"
                                                                >
                                                                  <span
                                                                    className={
                                                                      isOverDue &&
                                                                      (stepStatus ===
                                                                        RAFBPStepStatus.Assigned ||
                                                                        stepStatus ===
                                                                          RAFBPStepStatus.AwaitingforApproval ||
                                                                        stepStatus ===
                                                                          RAFBPStepStatus.AwaitingforReview ||
                                                                        stepStatus ===
                                                                          RAFBPStepStatus.ToDo ||
                                                                        stepStatus ===
                                                                          RAFBPStepStatus.Queued)
                                                                        ? "bp-template-endtime red-text"
                                                                        : "bp-template-endtime"
                                                                    }
                                                                  >
                                                                    {
                                                                      stepDueDate
                                                                    }
                                                                  </span>
                                                                </TooltipComponent>
                                                              </div>
                                                            )}
                                                          {/* {(stepStatus === RAFBPStepStatus.Completed || stepStatus === RAFBPStepStatus.Approved || stepStatus === RAFBPStepStatus.Rejected) && stepCompleted && <div className="">
                                            <TooltipComponent content={stepCompletedTooltip} position="BottomCenter">
                                                <span className={"bp-template-endtime"}>{stepCompleted}</span>
                                            </TooltipComponent>
                                        </div>
                                        } */}
                                                          {(stepStatus ===
                                                            RAFBPStepStatus.Completed ||
                                                            stepStatus ===
                                                              RAFBPStepStatus.Approved ||
                                                            stepStatus ===
                                                              RAFBPStepStatus.Reviewed ||
                                                            stepStatus ===
                                                              RAFBPStepStatus.Rejected) &&
                                                            stepCompletedDateFormat && (
                                                              <div className="">
                                                                <TooltipComponent
                                                                  content={
                                                                    stepCompletedTooltip
                                                                  }
                                                                  position="BottomCenter"
                                                                >
                                                                  <span
                                                                    className={
                                                                      "bp-template-endtime"
                                                                    }
                                                                  >
                                                                    {
                                                                      stepCompletedDateFormat
                                                                    }
                                                                  </span>
                                                                </TooltipComponent>
                                                              </div>
                                                            )}
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {/* <div
                                            className={
                                              "bp-badge-item full-height bp-badge-item-" +
                                              stepType
                                            }
                                          >
                                            <TooltipComponent
                                              content={stepTypeName}
                                              position={"BottomLeft"}
                                            >
                                              <div
                                                className={
                                                  "bp-badge-item full-height bp-badge-item-" +
                                                  stepType
                                                }
                                              >
                                                <i className={stepTypeIcon}></i>
                                              </div>
                                              {/* <span>{stepType}</span> */}
                                          {/*</TooltipComponent>
                                          </div> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div
                                    className="bp-header d-flex align-items-center py-2 hidden"
                                    style={{
                                      padding: "0px 16px",
                                      height: "unset",
                                      minHeight: "unset",
                                    }}
                                  >
                                    <div className="w-100">
                                      <div className="row align-items-center justify-content-between g-2">
                                        <div className="col-auto d-none d-md-block">
                                          <div className="w-100">
                                            <div className="row g-4">
                                              <div className="col-auto">
                                                <ButtonComponent
                                                  type="button"
                                                  className="link-button"
                                                  content="Add Comment"
                                                  onClick={() => {
                                                    this.showCreateCommentDialog();
                                                  }}
                                                ></ButtonComponent>
                                              </div>
                                              <div className="col-auto">
                                                <ButtonComponent
                                                  type="button"
                                                  className="link-button"
                                                  content="Add Document"
                                                  onClick={() => {
                                                    this.uploadDocument();
                                                  }}
                                                ></ButtonComponent>
                                              </div>
                                              <div className="col-auto">
                                                <ButtonComponent
                                                  type="button"
                                                  className="link-button"
                                                  content="Add Task"
                                                  onClick={() => {
                                                    this.createTaskDialogOpen();
                                                  }}
                                                ></ButtonComponent>
                                              </div>
                                              <div className="col-auto">
                                                <RAFCaptureImage
                                                  onSave={(UID) =>
                                                    this.onUploadDocumentFile(
                                                      UID
                                                    )
                                                  }
                                                  secondaryRelatedToType={
                                                    RAFEntityName.BusinessProcessStep
                                                  }
                                                  secondaryRelatedToUID={
                                                    businessProcessStepRow.UID
                                                  }
                                                  secondaryRelatedTo={
                                                    businessProcessStepRow.Title
                                                  }
                                                  relatedToUID={
                                                    businessProcessRow &&
                                                    businessProcessRow.RelatedToUID
                                                  }
                                                  relatedTo={
                                                    businessProcessRow &&
                                                    businessProcessRow.RelatedTo
                                                  }
                                                  relatedEntity={
                                                    businessProcessRow &&
                                                    businessProcessRow.RelatedToType
                                                  }
                                                  triggerInputFile={
                                                    this.state.triggerInputFile
                                                  }
                                                />
                                              </div>
                                              {isNotNullAndUndefined(
                                                businessProcessStepRow.RelatedEntities
                                              ) &&
                                                businessProcessStepRow
                                                  .RelatedEntities.length > 0
                                                ? businessProcessStepRow.RelatedEntities.map(
                                                  (relatedEntity) => {
                                                    return (
                                                      <div
                                                        key={
                                                          relatedEntity.UID
                                                        }
                                                        className="col-auto"
                                                      >
                                                        <ButtonComponent
                                                          type="button"
                                                          className="link-button"
                                                          content={
                                                            "Add " +
                                                            relatedEntity.Value
                                                          }
                                                          onClick={() => {
                                                            this.createRelatedDialogOpen(
                                                              relatedEntity
                                                            );
                                                          }}
                                                        ></ButtonComponent>
                                                      </div>
                                                    );
                                                  }
                                                )
                                                : null}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-auto d-md-none">
                                          <DropDownButtonComponent
                                            cssClass="e-info"
                                            items={[
                                              {
                                                text: "Add Comment",
                                                id: "AddComments",
                                              },
                                              {
                                                text: "Add Document",
                                                id: "AddDocument",
                                              },
                                              {
                                                text: "Add Task",
                                                id: "AddTask",
                                              },
                                              {
                                                text: "Add Photo",
                                                id: "AddPhoto",
                                              },
                                            ]}
                                            select={this.onClickAddBp.bind(
                                              this
                                            )}
                                            content={"Add"}
                                          ></DropDownButtonComponent>
                                        </div>

                                        <div className="col-auto">
                                          <div className="w-100">
                                            <div className="row g-2">
                                              <div className="col-auto">
                                                <ButtonComponent
                                                  type="button"
                                                  className="e-small px-1"
                                                  iconCss="fa fa-arrow-alt-circle-left"
                                                  onClick={() =>
                                                    this.onClickPrevious(
                                                      businessProcessStepRow.UID
                                                    )
                                                  }
                                                  disabled={
                                                    !this.props.showPrevious
                                                  }
                                                >
                                                  Prev
                                                </ButtonComponent>
                                              </div>
                                              <div className="col-auto d-none">
                                                <ButtonComponent
                                                  type="button"
                                                  className="e-small"
                                                  iconCss="fa fa-arrow-alt-circle-left"
                                                  onClick={() =>
                                                    this.onClickPrevious(
                                                      businessProcessStepRow.UID
                                                    )
                                                  }
                                                  disabled={
                                                    !this.props.showPrevious
                                                  }
                                                ></ButtonComponent>
                                              </div>
                                              <div className="col-auto">
                                                <ButtonComponent
                                                  type="button"
                                                  className="e-small px-1"
                                                  iconPosition="Right"
                                                  iconCss="fa fa-arrow-alt-circle-right"
                                                  onClick={() =>
                                                    this.onClickNext(
                                                      businessProcessStepRow.UID
                                                    )
                                                  }
                                                  disabled={
                                                    !this.props.showNext
                                                  }
                                                >
                                                  Next
                                                </ButtonComponent>
                                              </div>
                                              <div className="col-auto d-none">
                                                <ButtonComponent
                                                  type="button"
                                                  className="e-small"
                                                  iconPosition="Right"
                                                  iconCss="fa fa-arrow-alt-circle-right"
                                                  onClick={() =>
                                                    this.onClickNext(
                                                      businessProcessStepRow.UID
                                                    )
                                                  }
                                                  disabled={
                                                    !this.props.showNext
                                                  }
                                                ></ButtonComponent>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                                  <div className="e-dlg-body-content bp-body-content customScrollBar p-0 bg-white">
                                    <ManageStep
                                      businessProcessStepRow={
                                        businessProcessStepRow
                                      }
                                      businessProcessRow={businessProcessRow}
                                      businessProcessStepRows={
                                        businessProcessStepRows
                                      }
                                      onBPStepSave={(
                                        allBPRows,
                                        dependentBPRows,
                                        currentBPStepRow,
                                        businessProcessRow
                                      ) =>
                                        this.onBPStepSaved(
                                          allBPRows,
                                          dependentBPRows,
                                          currentBPStepRow,
                                          businessProcessRow
                                        )
                                      }
                                      bpTemplateStepsRow={
                                        this.props.bpTemplateStepsRow
                                      }
                                      /*onShowNextClicked={(UID) => this.props.onShowNextSPClicked(UID)}
                                                                                                                                                                                                                                                                      onShowPreviousClicked={(UID) => this.props.onShowPreviousSPClicked(UID)}*/
                                      //showNext={showNext} showPrevious={showPrevious}
                                      index={index}
                                      //hideRelatedSections={hideRelatedSections}
                                      hideRelatedCommentSections={
                                        hideRelatedCommentSections
                                      }
                                      hideRelateTaskSections={
                                        hideRelateTaskSections
                                      }
                                      hideRelatedDoumentSections={
                                        hideRelatedDoumentSections
                                      }
                                      onBackClick={() =>
                                        this.props.navigate(-1)
                                      }
                                      relatedCommentsUpdated={() => {
                                        if (this.props.mode === "Details") {
                                          if (
                                            this.props.relatedCommentsUpdated
                                          ) {
                                            this.props.relatedCommentsUpdated();
                                          }
                                        } else {
                                          if (
                                            this.state
                                              .isRelatedCommentsUpdated !== true
                                          ) {
                                            if (this._isMounted) {
                                              this.setState({
                                                isRelatedCommentsUpdated: true,
                                              });
                                            }
                                          }
                                        }
                                      }}
                                      relatedTaskUpdated={() => {
                                        if (this.props.mode === "Details") {
                                          if (this.props.relatedTaskUpdated) {
                                            this.props.relatedTaskUpdated();
                                          }
                                        } else {
                                          if (
                                            this.state.isRelatedTaskUpdated !=
                                            true
                                          ) {
                                            if (this._isMounted) {
                                              this.setState({
                                                isRelatedTaskUpdated: true,
                                              });
                                            }
                                          }
                                        }
                                      }}
                                      allEntities={this.props.allEntities}
                                      allForms={this.props.allForms}
                                      relatedTaskDivKey={
                                        this.state.relatedTaskDivKey
                                      }
                                      relatedDocumnetDivKey={
                                        this.state.relatedDocumnetDivKey
                                      }
                                      relatedCommentDivKey={
                                        this.state.relatedCommentDivKey
                                      }
                                    />
                                  </div>
                                  <div>
                                    {showCompleteBtn && (
                                      <div className="w-100 p-2 border-top bg-white">
                                        {businessProcess.ProcessStatus !==
                                          RAFBPStatus.Completed && (
                                          <div className="row g-2 justify-content-end">
                                            {(businessProcessStepRow.StepStatus ===
                                              RAFBPStepStatus.ToDo ||
                                              businessProcessStepRow.StepStatus ===
                                                RAFBPStepStatus.Assigned) &&
                                              (businessProcessStepRow.StepType ===
                                                RAFBPStepType.Item ||
                                                businessProcessStepRow.StepType ===
                                                  RAFBPStepType.ToDo ||
                                                businessProcessStepRow.StepType ===
                                                  RAFBPStepType.Question ||
                                                businessProcessStepRow.StepType ===
                                                  RAFBPStepType.Note ||
                                                businessProcessStepRow.StepType ===
                                                  RAFBPStepType.FormComponent ||
                                                businessProcessStepRow.StepType ===
                                                  RAFBPStepType.Approval ||
                                                businessProcessStepRow.StepType ===
                                                  RAFBPStepType.Review ||
                                                businessProcessStepRow.StepType ===
                                                  RAFBPStepType.Task) && (
                                                <div className="col-auto d-flex">
                                                  <div className="w-100">
                                                    <div className="row g-2">
                                                      {businessProcessStepRow.IsRequired !==
                                                        true && (
                                                        <div className="col-auto">
                                                          <ButtonComponent
                                                            type="button"
                                                            className="e-small unset-custom-button-md primary-custom-button"
                                                            onClick={() =>
                                                              this.updateBusinessProcessStepStatus(
                                                                RAFBPStepStatus.Skipped
                                                              )
                                                            }
                                                          >
                                                            Skip
                                                          </ButtonComponent>
                                                        </div>
                                                      )}
                                                      <div className="col-auto">
                                                        <ButtonComponent
                                                          type="button"
                                                          className="e-small unset-custom-button-md e-success"
                                                          onClick={() =>
                                                            this.updateBusinessProcessStepStatus(
                                                              RAFBPStepStatus.Completed
                                                            )
                                                          }
                                                        >
                                                          Complete
                                                        </ButtonComponent>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            {businessProcessStepRow.StepStatus ===
                                              RAFBPStepStatus.Assigned &&
                                              businessProcessStepRow.StepType ===
                                                RAFBPStepType.Task &&
                                              (isNotNullAndUndefined(
                                                businessProcessStepRow.AssigneeUID
                                              ) ||
                                                isNotNullAndUndefined(
                                                  businessProcessStepRow.AssignTeamUID
                                                )) && (
                                                <div className="col-auto d-flex">
                                                  <div className="w-100">
                                                    <div className="row g-2">
                                                      {businessProcessStepRow.IsRequired !==
                                                        true && (
                                                        <div className="col-auto">
                                                          <ButtonComponent
                                                            type="button"
                                                            className="e-small unset-custom-button-md primary-custom-button"
                                                            onClick={() =>
                                                              this.updateBusinessProcessStepStatus(
                                                                RAFBPStepStatus.Skipped
                                                              )
                                                            }
                                                          >
                                                            Skip
                                                          </ButtonComponent>
                                                        </div>
                                                      )}
                                                      <div className="col-auto">
                                                        <ButtonComponent
                                                          type="button"
                                                          className="e-small unset-custom-button-md e-success"
                                                          onClick={() =>
                                                            this.updateBusinessProcessStepStatus(
                                                              RAFBPStepStatus.Completed
                                                            )
                                                          }
                                                        >
                                                          Complete
                                                        </ButtonComponent>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            {businessProcessStepRow.StepStatus ===
                                              RAFBPStepStatus.ToDo &&
                                              (businessProcessStepRow.StepType ===
                                                RAFBPStepType.Item ||
                                                businessProcessStepRow.StepType ===
                                                  RAFBPStepType.ToDo ||
                                                businessProcessStepRow.StepType ===
                                                  RAFBPStepType.Question ||
                                                businessProcessStepRow.StepType ===
                                                  RAFBPStepType.Note) && (
                                                <div className="col-auto d-none">
                                                  <div className="w-100">
                                                    <div className="row g-2">
                                                      <div className="col-auto">
                                                        <SplitButtonComponent
                                                          items={[
                                                            {
                                                              id: "Skip",
                                                              text: "Skip",
                                                              disabled:
                                                                businessProcessStepRow.IsRequired ===
                                                                true,
                                                            },
                                                          ]}
                                                          cssClass="e-small e-success"
                                                          click={() =>
                                                            this.updateBusinessProcessStepStatus(
                                                              RAFBPStepStatus.Completed
                                                            )
                                                          }
                                                          select={(args) =>
                                                            this.onMoreMenuClicked(
                                                              args
                                                            )
                                                          }
                                                        >
                                                          Complete
                                                        </SplitButtonComponent>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            {businessProcessStepRow.StepStatus ===
                                              RAFBPStepStatus.Assigned &&
                                              businessProcessStepRow.StepType ===
                                                RAFBPStepType.Task &&
                                              (isNotNullAndUndefined(
                                                businessProcessStepRow.AssigneeUID
                                              ) ||
                                                isNotNullAndUndefined(
                                                  businessProcessStepRow.AssignTeamUID
                                                )) && (
                                                <div className="col-auto d-none">
                                                  <div className="w-100">
                                                    <div className="row g-2">
                                                      <div className="col-auto">
                                                        <SplitButtonComponent
                                                          items={[
                                                            {
                                                              id: "Skip",
                                                              text: "Skip",
                                                              disabled:
                                                                businessProcessStepRow.IsRequired ===
                                                                true,
                                                            },
                                                          ]}
                                                          cssClass="e-small e-success"
                                                          click={() =>
                                                            this.updateBusinessProcessStepStatus(
                                                              RAFBPStepStatus.Completed
                                                            )
                                                          }
                                                          select={(args) =>
                                                            this.onMoreMenuClicked(
                                                              args
                                                            )
                                                          }
                                                        >
                                                          Complete
                                                        </SplitButtonComponent>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            {isNotNullAndUndefined(
                                              businessProcessStepRow.IsApproval
                                            ) &&
                                              businessProcessStepRow.IsApproval ===
                                                true &&
                                              businessProcessStepRow.StepStatus ===
                                                RAFBPStepStatus.RequestApproval && (
                                                <div className="col-auto d-flex">
                                                  <div className="w-100">
                                                    <div className="row g-2">
                                                      <div className="col-auto">
                                                        <ButtonComponent
                                                          type="button"
                                                          className="e-primary e-small unset-custom-button-md"
                                                          onClick={() =>
                                                            this.onSetApproverOrReviewerClicked()
                                                          }
                                                        >
                                                          {" "}
                                                          Request Approval
                                                        </ButtonComponent>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            {businessProcessStepRow.StepType ===
                                              RAFBPStepType.Task &&
                                              (isNullOrUndefined(
                                                businessProcessStepRow.AssignTeamUID
                                              ) ||
                                                isNullOrUndefined(
                                                  businessProcessStepRow.AssigneeUID
                                                )) &&
                                              businessProcessStepRow.StepStatus ===
                                                RAFBPStepStatus.ToDo && (
                                                <div className="col-auto">
                                                  <ButtonComponent
                                                    type="button"
                                                    className="e-primary e-small unset-custom-button-md"
                                                    onClick={() =>
                                                      this.showAssigneeDialogOpen()
                                                    }
                                                  >
                                                    {" "}
                                                    Set Assignee
                                                  </ButtonComponent>
                                                </div>
                                              )}
                                            {isNotNullAndUndefined(
                                              businessProcessStepRow.IsApproval
                                            ) &&
                                              businessProcessStepRow.IsApproval ===
                                                true &&
                                              businessProcessStepRow.StepStatus ===
                                                RAFBPStepStatus.RequestReview && (
                                                <div className="col-auto d-flex">
                                                  <div className="w-100">
                                                    <div className="row g-2">
                                                      <div className="col-auto">
                                                        <ButtonComponent
                                                          type="button"
                                                          className="e-primary e-small unset-custom-button-md"
                                                          onClick={() =>
                                                            this.onSetApproverOrReviewerClicked()
                                                          }
                                                        >
                                                          {" "}
                                                          Request Review
                                                        </ButtonComponent>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            {isNotNullAndUndefined(
                                              businessProcessStepRow.IsApproval
                                            ) &&
                                              businessProcessStepRow.IsApproval ===
                                                true &&
                                              (businessProcessStepRow.StepStatus ===
                                                RAFBPStepStatus.ToDo ||
                                                businessProcessStepRow.StepStatus ===
                                                  RAFBPStepStatus.AwaitingforApproval) &&
                                              (businessProcessStepRow.StepType ===
                                                RAFBPStepType.Approval ||
                                                businessProcessStepRow.StepType ===
                                                  RAFBPStepType.Review) && (
                                                <div className="col-auto align-items-center">
                                                  <div className="w-100">
                                                    <div className="row g-2">
                                                      <div className="col-auto d-none">
                                                        <ButtonComponent
                                                          type="button"
                                                          className="e-success e-small"
                                                          onClick={() =>
                                                            this.updateBusinessProcessStepStatus(
                                                              RAFBPStepStatus.Approved
                                                            )
                                                          }
                                                        >
                                                          {" "}
                                                          Approve
                                                        </ButtonComponent>
                                                      </div>
                                                      <div className="col-auto d-none">
                                                        <ButtonComponent
                                                          type="button"
                                                          className="e-danger e-small"
                                                          onClick={() =>
                                                            this.updateBusinessProcessStepStatus(
                                                              RAFBPStepStatus.Rejected
                                                            )
                                                          }
                                                        >
                                                          {" "}
                                                          Reject
                                                        </ButtonComponent>
                                                      </div>
                                                      <div className="col-auto d-none">
                                                        <ButtonComponent
                                                          type="button"
                                                          className="e-info e-small"
                                                          onClick={() =>
                                                            this.updateBusinessProcessStepStatus(
                                                              RAFBPStepStatus.RequestforInfo
                                                            )
                                                          }
                                                        >
                                                          Request for Info
                                                        </ButtonComponent>
                                                      </div>
                                                      <div className="col-auto">
                                                        <SplitButtonComponent
                                                          items={
                                                            approvalGroupedBtnitems
                                                          }
                                                          cssClass="e-small unset-custom-button-md e-info"
                                                          click={() =>
                                                            this.updateBusinessProcessStepStatus(
                                                              RAFBPStepStatus.Approved
                                                            )
                                                          }
                                                          select={(args) =>
                                                            this.onMoreMenuClicked(
                                                              args
                                                            )
                                                          }
                                                        >
                                                          Approve
                                                        </SplitButtonComponent>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            {isNotNullAndUndefined(
                                              businessProcessStepRow.IsApproval
                                            ) &&
                                              businessProcessStepRow.IsApproval ===
                                                true &&
                                              (businessProcessStepRow.StepStatus ===
                                                RAFBPStepStatus.ToDo ||
                                                businessProcessStepRow.StepStatus ===
                                                  RAFBPStepStatus.AwaitingforReview) &&
                                              businessProcessStepRow.StepType ===
                                                RAFBPStepType.Review && (
                                                <div className="col-auto align-items-center">
                                                  <div className="w-100">
                                                    <div className="row g-2">
                                                      <div className="col-auto d-none">
                                                        <ButtonComponent
                                                          type="button"
                                                          className="e-success e-small"
                                                          onClick={() =>
                                                            this.updateBusinessProcessStepStatus(
                                                              RAFBPStepStatus.Reviewed
                                                            )
                                                          }
                                                        >
                                                          {" "}
                                                          Complete Review
                                                        </ButtonComponent>
                                                      </div>
                                                      <div className="col-auto d-none">
                                                        <ButtonComponent
                                                          type="button"
                                                          className="e-danger e-small"
                                                          onClick={() =>
                                                            this.updateBusinessProcessStepStatus(
                                                              RAFBPStepStatus.Rejected
                                                            )
                                                          }
                                                        >
                                                          {" "}
                                                          Reject
                                                        </ButtonComponent>
                                                      </div>
                                                      <div className="col-auto d-none">
                                                        <ButtonComponent
                                                          type="button"
                                                          className="e-info e-small"
                                                          onClick={() =>
                                                            this.updateBusinessProcessStepStatus(
                                                              RAFBPStepStatus.RequestforInfo
                                                            )
                                                          }
                                                        >
                                                          Request for Info
                                                        </ButtonComponent>
                                                      </div>
                                                      <div className="col-auto">
                                                        <SplitButtonComponent
                                                          items={
                                                            reviewGroupedBtnitems
                                                          }
                                                          cssClass="e-small unset-custom-button-md e-info"
                                                          click={() =>
                                                            this.updateBusinessProcessStepStatus(
                                                              RAFBPStepStatus.Reviewed
                                                            )
                                                          }
                                                          select={(args) =>
                                                            this.onMoreMenuClicked(
                                                              args
                                                            )
                                                          }
                                                        >
                                                          Complete Review
                                                        </SplitButtonComponent>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            {businessProcessStepRow.StepStatus ===
                                              RAFBPStepStatus.ToDo &&
                                              businessProcessStepRow.StepType ===
                                                RAFBPStepType.Decision && (
                                                <div className="col-auto d-flex">
                                                  <div className="w-100">
                                                    <div className="row g-2">
                                                      <div className="col-auto hidden">
                                                        <ButtonComponent
                                                          type="button"
                                                          className="e-small primary-custom-button unset-custom-button-md"
                                                          onClick={() =>
                                                            this.updateBusinessProcessStepStatus(
                                                              RAFBPStepStatus.Skipped
                                                            )
                                                          }
                                                        >
                                                          Skip
                                                        </ButtonComponent>
                                                      </div>
                                                      <div className="col-auto">
                                                        <ButtonComponent
                                                          type="button"
                                                          className="e-success unset-custom-button-md e-small"
                                                          onClick={() =>
                                                            this.updateBusinessProcessStepStatus(
                                                              RAFBPStepStatus.Completed
                                                            )
                                                          }
                                                        >
                                                          Complete
                                                        </ButtonComponent>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            {businessProcessStepRow.StepStatus ===
                                              RAFBPStepStatus.Assigned &&
                                              (businessProcessStepRow.StepType ===
                                                RAFBPStepType.Group ||
                                                businessProcessStepRow.StepType ===
                                                  RAFBPStepType.Stage) &&
                                              showGroupCompleteBtn && (
                                                <div className="col-auto d-flex">
                                                  <div className="w-100">
                                                    <div className="row g-2">
                                                      <div className="col-auto hidden">
                                                        <ButtonComponent
                                                          type="button"
                                                          className="e-small primary-custom-button unset-custom-button-md"
                                                          onClick={() =>
                                                            this.updateBusinessProcessStepStatus(
                                                              RAFBPStepStatus.Skipped
                                                            )
                                                          }
                                                        >
                                                          Skip
                                                        </ButtonComponent>
                                                      </div>
                                                      <div className="col-auto">
                                                        <ButtonComponent
                                                          type="button"
                                                          className="e-success unset-custom-button-md e-small"
                                                          onClick={() =>
                                                            this.updateBusinessProcessStepStatus(
                                                              RAFBPStepStatus.Completed
                                                            )
                                                          }
                                                        >
                                                          Complete
                                                        </ButtonComponent>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                            {(businessProcessStepRow.StepStatus ===
                                              RAFBPStepStatus.Completed ||
                                              businessProcessStepRow.StepStatus ===
                                                RAFBPStepStatus.Skipped) &&
                                              (businessProcessStepRow.StepType ===
                                                RAFBPStepType.Question ||
                                                businessProcessStepRow.StepType ===
                                                  RAFBPStepType.ToDo) && (
                                                <div className="col-auto d-flex">
                                                  <div className="w-100">
                                                    <div className="row g-2 flex-nowrap">
                                                      <div className="col-auto">
                                                        <ButtonComponent
                                                          type="button"
                                                          className="e-small primary-custom-button unset-custom-button-md"
                                                          onClick={() =>
                                                            this.resetBusinessProcessStep(
                                                              RAFBPStepStatus.Cleared
                                                            )
                                                          }
                                                        >
                                                          Undo
                                                        </ButtonComponent>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </RAFForm>
                            <Fragment>
                              {this.state.showApproverDialogContent && (
                                <DialogComponent
                                  header={
                                    isNotNullAndUndefined(
                                      businessProcessStepRow
                                    ) &&
                                    businessProcessStepRow.StepType ===
                                      RAFBPStepType.Approval
                                      ? "Set Approver"
                                      : "Set Reviewer"
                                  }
                                  showCloseIcon
                                  visible={this.state.showApproverDialogContent}
                                  cssClass="centerDialog-sm createEditForm form-center-dialog"
                                  content={this.showApproverContent.bind(this)}
                                  isModal
                                  target="body"
                                  closeOnEscape={false}
                                  close={this.showApproverDialogClose.bind(
                                    this
                                  )}
                                ></DialogComponent>
                              )}
                              {this.state.showCreateTaskContent && (
                                <DialogComponent
                                  //header="Create Task"
                                  //showCloseIcon
                                  visible={this.state.showCreateTaskContent}
                                  cssClass="rightDialog createEditForm full-height"
                                  content={this.createTaskContent.bind(this)}
                                  isModal
                                  target="body"
                                  closeOnEscape={false}
                                  close={() => this.createTaskDialogClose()}
                                ></DialogComponent>
                              )}
                              {/* {this.state.showUploadContent && (
                                                                <DialogComponent
                                                                    id="uploadDialog"
                                                                    header="Upload File"
                                                                    buttons={this.uploadDialogButtons}
                                                                    cssClass="centerDialog-sm createEditForm full-height form-center-dialog"
                                                                    showCloseIcon
                                                                    visible={this.state.showUploadContent}
                                                                    content={this.uploadAndLinkDocumentContent.bind(
                                                                        this
                                                                    )}
                                                                    isModal
                                                                    target="body"
                                                                    closeOnEscape={false}
                                                                    close={this.uploadDialogClose.bind(this)}
                                                                    animationSettings={this.animationSettings}
                                                                ></DialogComponent>
                                                            )} */}
                              {this.state.showUploadContent === true && (
                                <DialogComponent
                                  header="Upload File"
                                  showCloseIcon
                                  visible={this.state.showUploadContent}
                                  cssClass="centerDialog-sm createEditForm form-center-dialog dlg-new-style fixed-header"
                                  content={this.uploadAndLinkDocumentContent.bind(
                                    this
                                  )}
                                  isModal
                                  target="body"
                                  closeOnEscape={false}
                                  close={this.uploadDialogClose.bind(this)}
                                  animationSettings={this.animationSettings}
                                  id="manageUploadDocumentDialog"
                                ></DialogComponent>
                              )}
                              {this.state.showCreateCommentContent && (
                                <DialogComponent
                                  header="Add Comment"
                                  showCloseIcon
                                  visible={this.state.showCreateCommentContent}
                                  cssClass="centerDialog-sm createEditForm form-center-dialog"
                                  content={this.createCommentContent.bind(this)}
                                  isModal
                                  target="body"
                                  closeOnEscape={false}
                                  close={this.createCommentDialogClose.bind(
                                    this
                                  )}
                                  animationSettings={this.animationSettings}
                                ></DialogComponent>
                              )}
                              {this.state
                                .showCompleteStepAlertDialogContent && (
                                <DialogComponent
                                  header={completeStepAlertTitle}
                                  showCloseIcon={false}
                                  visible={
                                    this.state
                                      .showCompleteStepAlertDialogContent
                                  }
                                  cssClass="centerDialog-sm createEditForm form-center-dialog"
                                  id="completeStepAlertDialogContent"
                                  content={this.completeStepAlertContent.bind(
                                    this
                                  )}
                                  isModal
                                  target="body"
                                  closeOnEscape={false}
                                  close={this.completeStepAlertDialogClose.bind(
                                    this
                                  )}
                                  animationSettings={this.animationSettings}
                                ></DialogComponent>
                              )}
                              {this.state.showAssigneeDialogContent && (
                                <DialogComponent
                                  header={"Set Assignee"}
                                  showCloseIcon
                                  visible={this.state.showAssigneeDialogContent}
                                  cssClass="centerDialog-sm createEditForm form-center-dialog"
                                  content={this.showAssigneeContent.bind(this)}
                                  isModal
                                  target="body"
                                  closeOnEscape={false}
                                  close={this.showAssigneeDialogClose.bind(
                                    this
                                  )}
                                ></DialogComponent>
                              )}
                              {this.state.showCreateRelatedContent && (
                                <DialogComponent
                                  header={
                                    "Create " +
                                    this.state.relatedModuleToCreate.Value
                                  }
                                  showCloseIcon
                                  visible={this.state.showCreateRelatedContent}
                                  cssClass="rightDialog createEditForm full-height form-center-dialog"
                                  content={this.createRelatedContent.bind(this)}
                                  isModal
                                  target="body"
                                  closeOnEscape={false}
                                  close={this.createRelatedDialogClose.bind(
                                    this
                                  )}
                                ></DialogComponent>
                              )}
                            </Fragment>
                          </Fragment>
                        );
                      }}
                    </MyTeamsContext.Consumer>
                  </div>
                );
              } else {
                return <div></div>;
              }
            }}
          </RAFProcessContext.Consumer>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default withRouter(RAFBusinessProcessStepDetails);
