import React, { PropsWithChildren } from 'react';
import { ConvertToPascal, isNotNullAndUndefined } from "../../../../RAFComponents/helpers/utils";
import { getElement } from "../../../../RAFModules/Common/RAFFieldHelper";
import { RAFComponentInputModel } from "../../RAFPage/PageLayoutJM";
//import RowRender from "./RowRender";

interface IProps {
    formDataFieldName?: string;
    validateFields?: boolean;
    data?: RAFComponentInputModel;
    isPropertyName?: boolean;
    showLabel?: boolean;
    isReadonly?: boolean;
}

function RAFInputModelRenderer(
    { ...props }: PropsWithChildren<IProps>,
) {

    const objComponent: RAFComponentInputModel = isNotNullAndUndefined(props.data) ? props.data : null;
    if (isNotNullAndUndefined(objComponent)) {
        let objFieldName = isNotNullAndUndefined(props.isPropertyName) && props.isPropertyName === true ? ConvertToPascal(objComponent.Name) : objComponent.Name;
        return (
            <div className="col-12">
                {getElement(null,
                    objComponent.UID,
                    objComponent.DataType,
                    isNotNullAndUndefined(props.formDataFieldName) ? props.formDataFieldName + "." + objFieldName : objFieldName,
                    objComponent.DisplayName,
                    null,
                    isNotNullAndUndefined(props.validateFields) ? props.validateFields : false,
                    isNotNullAndUndefined(objComponent.AttributeSettings) ? objComponent.AttributeSettings.IsRequired : false,
                    isNotNullAndUndefined(props.showLabel) ? props.showLabel : true,
                    isNotNullAndUndefined(props.isReadonly) ? props.isReadonly : isNotNullAndUndefined(objComponent.AttributeSettings) ? objComponent.AttributeSettings.IsReadonly : false,
                    isNotNullAndUndefined(objComponent.AttributeSettings) ? objComponent.AttributeSettings.UIType : null,
                    objComponent.Multiselect,
                    objComponent.ValueJson,
                    objComponent.RelatedEntities,
                    isNotNullAndUndefined(objComponent.AttributeSettings) ? objComponent.AttributeSettings.DefaultValue : null,
                    null,
                    "Edit",
                    objComponent.DisplayName,
                    objComponent.DisplayName,
                )}
            </div>
        );
    }
    else {
        return null;
    }
}


export default RAFInputModelRenderer;