import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import React, { Fragment, PropsWithChildren, ReactNode } from "react";
import { Field } from "react-final-form";
import RAFDetailsField from "../../../../RAFComponents/Inputs/RAFDetailsField";
import RAFFieldLabel from "../../../../RAFComponents/Inputs/RAFFieldLabel";
import "../../../../RAFComponents/Navigation/NavigationStyle.scss";
import { isNotNullAndUndefined } from "../../../../RAFComponents/helpers/utils";
import { RAFButtonConstant } from "../../../../constants/Common/Constants";
import { NavigateParamsProps } from "../../../../router";

interface IProps {
    value?: string;
    label?: string,
    fieldName?: string,
    emptyString?: string,
    labelClassName?: string,
    inputVisibility?: 'row' | 'column';
    position?: any,
    disabled?: boolean,
    showLabel?: boolean,
    customContent?: React.ReactFragment | ReactNode;
    customButton?: React.ReactFragment | ReactNode;
    customValue?: React.ReactFragment | ReactNode;
    tooltipContent?: React.ReactFragment | ReactNode;
    onClickSaveField?: () => void;
    onClickClearField?: (fieldName) => void;
    inputClassName?: string;
    rowClassName?: string;
    UiMode: 'floatingCustomDiv' | 'floatingCustomPopup';
}

interface IState {

}

//It's having the floatingCustomDiv and floatingCustomPopup classname.Show the div when click on edit and input value and edit btn will display click on ok btn.

function RAFBPDetailInlineEdit1({
    showLabel = true,
    ...props
}: PropsWithChildren<IProps & NavigateParamsProps>) {

    // class RAFBPDetailInlineEdit1 extends Component<IDialogProps & RAFFormComponentProps & NavigateParamsProps & IProps, IState> {

    const onClickSaveField = () => {
        if (props.onClickSaveField) {
            props.onClickSaveField();
        }
    };
    const openFloatingPopupContent = (field) => {
        let floatingPopupContent = document.getElementById(`floatingCustomPopup${field}`);
        let customInputDiv = document.getElementById(`customInputDiv_${fieldName.toString()}`);
        if (isNotNullAndUndefined(floatingPopupContent)) {
            floatingPopupContent.classList.add("active");
        }
        if (isNotNullAndUndefined(customInputDiv)) {
            customInputDiv.classList.add("remove");
        }
    };
    const closeFloatingPopupContent = (field) => {
        let floatingPopupContent = document.getElementById(`floatingCustomPopup${field}`);
        let customInputDiv = document.getElementById(`customInputDiv_${fieldName.toString()}`);
        if (isNotNullAndUndefined(floatingPopupContent)) {
            floatingPopupContent.classList.remove("active");
        }
        if (isNotNullAndUndefined(customInputDiv)) {
            customInputDiv.classList.remove("remove");
        }
        if (props.onClickSaveField) {
            onClickSaveField();
        }
    };

    let floatingCustomPopupColClass = "col-12";
    switch (props.UiMode) {
        case ('floatingCustomDiv'):
            floatingCustomPopupColClass = props.inputClassName ? `${props.inputClassName}` : "col-12";
            break;
    }

    let { fieldName, label, labelClassName, inputVisibility, tooltipContent, customValue, customContent } = props;
    labelClassName = `form-label-container-height ${isNotNullAndUndefined(labelClassName) ? labelClassName : "col-12"}`;
    return (
        <div className={props.rowClassName ? props.rowClassName : "row"}>
            {showLabel === true && (
                <RAFFieldLabel field={fieldName} label={label} labelClassName={labelClassName} />
            )}
            <div className={props.inputClassName ? `${props.inputClassName} customInputDiv` : "col-12 customInputDiv"} id={`customInputDiv_${fieldName.toString()}`}>
                <div className="row g-2 align-items-center">
                    <div className="col-auto">
                        {isNotNullAndUndefined(customValue) ?
                            customValue
                            :
                            <Field name={fieldName} allowNull parse={value => (value === "" ? null : value)} >
                                {({ input }) => {
                                    return (<div>
                                        <RAFDetailsField
                                            field={props.fieldName}
                                            {...props.emptyString ? { emptyString: props.emptyString } : {}}
                                        />

                                    </div>);
                                }}
                            </Field>
                        }
                    </div>
                    {props.disabled !== true && (
                        <Fragment>
                            <div className="col-auto">
                                <i className="fat fa-pipe"></i>
                            </div>
                            <div className="col-auto">
                                <ButtonComponent type="button"
                                    className="link-button" content={RAFButtonConstant.Edit.DisplayName}
                                    iconCss={RAFButtonConstant.Edit.IconCss}
                                    id={`btn_open_floatingCustomPopup_${RAFButtonConstant.Edit.Id}_${fieldName}`}
                                    onClick={() => openFloatingPopupContent(fieldName)}
                                ></ButtonComponent>
                            </div>
                        </Fragment>
                    )}
                </div>
            </div>
            <div className={floatingCustomPopupColClass}>
                <div className={`${props.UiMode} mb-1 showArrowtip`} id={`floatingCustomPopup${fieldName.toString()}`}>
                    {tooltipContent}
                    <div className="p-2 px-3 border-top d-flex justify-content-end">
                        {isNotNullAndUndefined(props.customButton) ?
                            props.customButton :
                            <div className="row gx-2">
                                {isNotNullAndUndefined(props.onClickClearField) ?
                                    <div className="col-auto">
                                        <ButtonComponent type="button"
                                            id={`btn_close_floatingCustomPopup_${RAFButtonConstant.Clear.Id}_${fieldName}`}
                                            content={RAFButtonConstant.Clear.DisplayName}
                                            iconCss={RAFButtonConstant.Close.IconCss} onClick={() => props.onClickClearField(fieldName)}></ButtonComponent>
                                    </div>
                                    :
                                    ""
                                }
                                <div className="col-auto">
                                    <ButtonComponent type="button"
                                        id={`btn_close_floatingCustomPopup_${RAFButtonConstant.Ok.Id}_${fieldName}`}
                                        isPrimary
                                        content={RAFButtonConstant.Ok.DisplayName} iconCss={RAFButtonConstant.Ok.IconCss} onClick={() => closeFloatingPopupContent(fieldName)}></ButtonComponent>
                                </div>
                            </div>
                        }
                        {props.UiMode !== 'floatingCustomDiv' &&
                            <div className="e-arrow-tip e-tip-top e-tip-left">
                                <div className="e-arrow-tip-outer e-tip-top"></div>
                                <div className="e-arrow-tip-inner e-tip-top"></div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RAFBPDetailInlineEdit1;
