import { atomFamily } from "recoil";
import { initUserAndTeam } from "../../../helpers/ACutils";
import { ContentType } from "../../../constants/Common/Constants";
import { RAFSort } from "../../../RAFComponents/helpers/types";
import {
  isNotEmptyArray,
  isNotNullAndUndefined,
  IsNullOrWhiteSpace,
  propertyOf,
} from "../../../RAFComponents/helpers/utils";
import { FormLibraryRow } from "../../../RAFComponents/models/Common/FormLibraryRow";
import { ListServiceRequest } from "../../../RAFComponents/models/Common/ListRequest";
import { LookUpRow } from "../../../RAFComponents/models/CompositeTypes/LookUpRow";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { BusinessProcessTemplateRow } from "../BusinessProcess/Template/BusinessProcessTemplateRow";
import { BusinessProcessTypeRow } from "./BusinessProcessTypeRow";
import { LookUpGroupRow } from "../../../RAFComponents/models/CompositeTypes/LookUpGroupRow";
import { RAFEntityName } from "../../../constants/Common/EntityConstants";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";

export const BPTypeDefaultNodeTitle = "All Categories";
export const BPTemplateTypeDefaultNodeTitle = "All Processes";

export const atomSelectedBPType = atomFamily<any, any>({
  key: "selectedBPTypeState",
  default: null,
});

export const RetrieveBusinessProcessType = (categoryUID) => {
  return new Promise<BusinessProcessTypeRow>((resolve, reject) => {
    if (isNotNullAndUndefined(categoryUID)) {
      let url = "Category/Retrieve";
      return repositoryActions
        .postDataAndGetResponse(
          url,
          { EntityId: categoryUID },
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.Entity)
          ) {
            resolve(response.data.Entity);
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    } else {
      resolve(null);
    }
  });
};

export const getCategoryListByCategoryName = (
  categoryName: string,
  entityName: string
) => {
  return new Promise<BusinessProcessTypeRow[]>((resolve, reject) => {
    if (isNotNullAndUndefined(categoryName)) {
      let listServiceRequest = new ListServiceRequest();
      listServiceRequest.Skip = 0;
      listServiceRequest.Take = 0;

      let relatedFilter: RAFCustomFilter = {};
      relatedFilter.Condition = "and";
      relatedFilter.Rules = [];

      let filter: RAFCustomFilter = {};
      let filterVal: string[] = [];
      filterVal.push(categoryName);
      filter.Operator = RAFCustomOperator.Equal;
      filter.Value = filterVal;
      filter.Field = propertyOf<BusinessProcessTypeRow>("Title");
      relatedFilter.Rules.push(filter);

      if (isNotNullAndUndefined(entityName)) {
        let filter1: RAFCustomFilter = {};
        let filterVal1: string[] = [];
        filterVal1.push(entityName);
        filter1.Operator = RAFCustomOperator.Equal;
        filter1.Value = filterVal1;
        filter1.Field = propertyOf<BusinessProcessTypeRow>("CategoryType");
        relatedFilter.Rules.push(filter1);
      }

      listServiceRequest.CustomFilter = relatedFilter;

      return repositoryActions
        .postDataAndGetResponse(
          "Category/List",
          listServiceRequest,
          null,
          ContentType.applicationJson,
          false
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data)
          ) {
            resolve(response.data.Entities);
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    } else {
      resolve(null);
    }
  });
};

export const RetrieveCategoryByCategoryName = (
  categoryName: string,
  entityName: string
) => {
  return new Promise<BusinessProcessTypeRow>(async (resolve, reject) => {
    if (
      isNotNullAndUndefined(categoryName) &&
      isNotNullAndUndefined(entityName)
    ) {
      const allCategory = await getCategoryListByCategoryName(
        categoryName,
        entityName
      );
      if (isNotNullAndUndefined(allCategory) && allCategory.length > 0) {
        const currentCategory = allCategory[0];
        if (
          isNotNullAndUndefined(currentCategory) &&
          isNotNullAndUndefined(currentCategory.UID)
        ) {
          if (isNotNullAndUndefined(currentCategory.InitiatorDefault)) {
            getBPTypeInitiatorType(currentCategory.InitiatorDefault).then(
              (userTeam) => {
                if (isNotNullAndUndefined(userTeam)) {
                  currentCategory.InitiatorType = userTeam.Catagory;
                  currentCategory.InitiatorDefaultUID = userTeam.UID;
                  currentCategory.InitiatorDefault = userTeam.Value;
                }
                resolve(currentCategory);
              }
            );
          } else {
            resolve(currentCategory);
          }
        } else {
          resolve(null);
        }
      } else {
        resolve(null);
      }
    } else {
      resolve(null);
    }
  });
};

export const RetrieveBusinessProcessTypeDto = (categoryUID) => {
  return new Promise<BusinessProcessTypeRow>((resolve, reject) => {
    if (isNotNullAndUndefined(categoryUID)) {
      RetrieveBusinessProcessType(categoryUID)
        .then((bpTypeRow) => {
          if (
            isNotNullAndUndefined(bpTypeRow) &&
            isNotNullAndUndefined(bpTypeRow.UID)
          ) {
            if (isNotNullAndUndefined(bpTypeRow.InitiatorDefault)) {
              getBPTypeInitiatorType(bpTypeRow.InitiatorDefault).then(
                (userTeam) => {
                  if (isNotNullAndUndefined(userTeam)) {
                    bpTypeRow.InitiatorType = userTeam.Catagory;
                    bpTypeRow.InitiatorDefaultUID = userTeam.UID;
                    bpTypeRow.InitiatorDefault = userTeam.Value;
                  }
                  resolve(bpTypeRow);
                }
              );
            } else {
              resolve(bpTypeRow);
            }
          } else {
            resolve(null);
          }
        })
        .catch((error) => reject(error));
    } else {
      resolve(null);
    }
  });
};

export function getBPTypeInitiatorType(userTeamUID: string) {
  return new Promise<LookUpGroupRow>((resolve, reject) => {
    if (isNotNullAndUndefined(userTeamUID)) {
      initUserAndTeam().then((allUserTeam) => {
        if (isNotNullAndUndefined(allUserTeam)) {
          let item = allUserTeam.find((x) => x.UID === userTeamUID);
          if (isNotNullAndUndefined(item) && isNotNullAndUndefined(item.UID)) {
            resolve(item);
          } else {
            resolve(null);
          }
        } else {
          resolve(null);
        }
      });
    } else {
      resolve(null);
    }
  });
}

export function getAllBusinessProcessType(entityName?: string) {
  return new Promise<BusinessProcessTypeRow[]>((resolve, reject) => {
    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.Skip = 0;
    listServiceRequest.Take = 0;

    if (!IsNullOrWhiteSpace(entityName)) {
      let customFilter: RAFCustomFilter = {};
      customFilter.Condition = "and";
      customFilter.Rules = [];

      let filter: RAFCustomFilter = {};
      let filterVal: string[] = [];
      filterVal.push(entityName);
      filter.Operator = RAFCustomOperator.Equal;
      filter.Value = filterVal;
      filter.Field = propertyOf<BusinessProcessTypeRow>("CategoryType");
      customFilter.Rules.push(filter);
      listServiceRequest.CustomFilter = customFilter;
    }

    let sort: RAFSort = {
      field: propertyOf<BusinessProcessTypeRow>("Title"),
      order: "ascending",
    };
    let sortQuery: string[] = [];
    if (isNotNullAndUndefined(sort) && isNotNullAndUndefined(sort.field)) {
      sortQuery.push(
        sort.order === "descending" ? `${sort.field} desc` : sort.field
      );
    }

    listServiceRequest.Sort = sortQuery;

    repositoryActions
      .postDataAndGetResponse(
        "Category/List",
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.Entities)
        ) {
          resolve(response.data.Entities);
        } else {
          resolve(null);
        }
      })
      .catch((error) => error);
  });
}

export function getAllCategroyLookup(entityName?: string) {
  return new Promise<LookUpRow[]>((resolve, reject) => {
    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.Skip = 0;
    listServiceRequest.Take = 0;

    listServiceRequest.EntityName = entityName;

    repositoryActions
      .postDataAndGetResponse(
        "Category/LookUpDropDown",
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.Entities)
        ) {
          resolve(response.data.Entities);
        } else {
          resolve(null);
        }
      })
      .catch((error) => error);
  });
}

export const convertToDropdownChoiceOptions = (choiceListPrompts: string) => {
  let defaultChoiceOptions = "Yes|No|NA, True|False";
  if (isNotNullAndUndefined(choiceListPrompts)) {
    let choiceListOptions = choiceListPrompts.trim();
    let options = choiceListOptions.split(",");
    let dropdownSelectOptions = getDropDownChoiceOptions(options);
    if (
      isNotNullAndUndefined(dropdownSelectOptions) &&
      dropdownSelectOptions.length > 0
    ) {
      return dropdownSelectOptions;
    } else {
      let choiceListOptions = defaultChoiceOptions.trim();
      let options = choiceListOptions.split(",");
      let dropdownSelectOptions = getDropDownChoiceOptions(options);
      return dropdownSelectOptions;
    }
  } else {
    let choiceListOptions = defaultChoiceOptions.trim();
    let options = choiceListOptions.split(",");
    let dropdownSelectOptions = getDropDownChoiceOptions(options);
    return dropdownSelectOptions;
  }
};

function getDropDownChoiceOptions(options) {
  let dropdownSelectOptions: {
    type: string;
    value: { Title: string; Value: string; Score: number }[];
  }[] = [];
  if (isNotNullAndUndefined(options) && options.length > 0) {
    options.forEach((item) => {
      if (isNotNullAndUndefined(item)) {
        let itemArray = item.split("|");
        let value = [];
        itemArray.forEach((valueItem, index) => {
          if (isNotNullAndUndefined(valueItem)) {
            value.push({
              Title: valueItem,
              Value: valueItem,
              Score: index === 0 ? 1 : 0,
            });
          }
        });
        dropdownSelectOptions.push({ type: item, value: value });
      }
    });
  }
  return dropdownSelectOptions;
}

export const checkIsUniqueBPType = (
  itemUID: string,
  title: string,
  entityName: string,
  bpTypeItems?: BusinessProcessTypeRow[]
) => {
  return new Promise<boolean>(async (resolve, reject) => {
    if (!IsNullOrWhiteSpace(title)) {
      if (isNotEmptyArray(bpTypeItems)) {
        let items;
        if (!IsNullOrWhiteSpace(entityName)) {
          items = bpTypeItems.filter(
            (x) =>
              isNotNullAndUndefined(x.Title) &&
              x.Title.toLowerCase() === title.toLowerCase() &&
              x.CategoryType === entityName
          );
        } else {
          items = bpTypeItems.filter(
            (x) =>
              isNotNullAndUndefined(x.Title) &&
              x.Title.toLowerCase() === title.toLowerCase()
          );
        }
        if (isNotEmptyArray(items)) {
          if (IsNullOrWhiteSpace(itemUID)) {
            resolve(false);
          } else {
            let currentItem = items.find((x) => x.UID === itemUID);
            if (
              isNotNullAndUndefined(currentItem) &&
              isNotNullAndUndefined(currentItem.UID)
            ) {
              resolve(true);
            } else {
              resolve(false);
            }
          }
        } else {
          resolve(true);
        }
      } else {
        let allBPTypes = await getAllBusinessProcessType(entityName);
        if (isNotEmptyArray(allBPTypes)) {
          let items = allBPTypes.filter(
            (x) =>
              isNotNullAndUndefined(x.Title) &&
              x.Title.toLowerCase() === title.toLowerCase()
          );
          if (isNotEmptyArray(items)) {
            if (IsNullOrWhiteSpace(itemUID)) {
              resolve(false);
            } else {
              let currentItem = items.find((x) => x.UID === itemUID);
              if (
                isNotNullAndUndefined(currentItem) &&
                isNotNullAndUndefined(currentItem.UID)
              ) {
                resolve(true);
              } else {
                resolve(false);
              }
            }
          } else {
            resolve(true);
          }
        } else {
          resolve(true);
        }
      }
    } else {
      resolve(false);
    }
  });
};

export const getAllBPTemplateByBPType = (categoryUID: string) => {
  return new Promise<BusinessProcessTemplateRow[]>(async (resolve, reject) => {
    const listRequest: ListServiceRequest = new ListServiceRequest();
    //let relatedFilter: RAFCustomFilter = {};
    //relatedFilter.Condition = "and";
    //relatedFilter.Rules = [];

    listRequest.Skip = 0;
    listRequest.Take = 0;

    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];

    let filterCategoryUID: RAFCustomFilter = {};
    filterCategoryUID.Operator = RAFCustomOperator.Equal;
    filterCategoryUID.Field =
      propertyOf<BusinessProcessTemplateRow>("CategoryUID");

    let filterValCategoryUID: string[] = [];
    filterValCategoryUID.push(categoryUID);
    filterCategoryUID.Value = filterValCategoryUID;
    customFilter.Rules.push(filterCategoryUID);

    listRequest.CustomFilter = customFilter;
    repositoryActions
      .postDataAndGetResponse(
        "BusinessProcessTemplate/ListAll",
        listRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.Entities)
        ) {
          resolve(response.data.Entities);
        } else {
          resolve(null);
        }
      })
      .catch((error) => error);
  });
};

export const getAllFormLibraryItemsByBPType = (categoryUID: string) => {
  return new Promise<FormLibraryRow[]>(async (resolve, reject) => {
    const listRequest: ListServiceRequest = new ListServiceRequest();
    //let relatedFilter: RAFCustomFilter = {};
    //relatedFilter.Condition = "and";
    //relatedFilter.Rules = [];

    listRequest.Skip = 0;
    listRequest.Take = 0;

    let customFilter: RAFCustomFilter = {};
    customFilter.Condition = "and";
    customFilter.Rules = [];

    let filterCategoryUID: RAFCustomFilter = {};
    filterCategoryUID.Operator = RAFCustomOperator.Equal;
    filterCategoryUID.Field = propertyOf<FormLibraryRow>("CategoryUID");

    let filterValCategoryUID: string[] = [];
    filterValCategoryUID.push(categoryUID);
    filterCategoryUID.Value = filterValCategoryUID;
    customFilter.Rules.push(filterCategoryUID);

    listRequest.CustomFilter = customFilter;
    repositoryActions
      .postDataAndGetResponse(
        "FormLibrary/List",
        listRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.Entities)
        ) {
          resolve(response.data.Entities);
        } else {
          resolve(null);
        }
      })
      .catch((error) => error);
  });
};

export const isChildItemExistBPType = (bpTypeRow: BusinessProcessTypeRow) => {
  return new Promise<boolean>(async (resolve, reject) => {
    if (
      isNotNullAndUndefined(bpTypeRow) &&
      !IsNullOrWhiteSpace(bpTypeRow.UID)
    ) {
      if (
        bpTypeRow.CategoryType ===
        getEntityDisplayNameByModuleName(RAFEntityName.BusinessProcessTemplate)
      ) {
        let allBPTemplates = await getAllBPTemplateByBPType(bpTypeRow.UID);
        if (
          isNotNullAndUndefined(allBPTemplates) &&
          allBPTemplates.length > 0
        ) {
          resolve(true);
        } else {
          resolve(false);
        }
      } else if (
        bpTypeRow.CategoryType ===
        getEntityDisplayNameByModuleName(RAFEntityName.FormLibrary)
      ) {
        let allFormLibrary = await getAllFormLibraryItemsByBPType(
          bpTypeRow.UID
        );
        if (
          isNotNullAndUndefined(allFormLibrary) &&
          allFormLibrary.length > 0
        ) {
          resolve(true);
        } else {
          resolve(false);
        }
      } else {
        resolve(false);
      }
    } else {
      resolve(true);
    }
  });
};

export function getEntityDisplayNameByModuleName(entityName?: string) {
  let retVal = entityName;
  switch (entityName) {
    case RAFEntityName.FormLibrary:
      retVal = "Form Library";
      break;
    case RAFEntityName.ProcessLibrary:
      retVal = "Process Library";
      break;
    case RAFEntityName.BusinessProcessTemplate:
      retVal = "Business Process Template";
      break;
    case "assessment":
      retVal = "Assessment";
      break;
    case "care_shift_log":
      retVal = "Shift Log";
      break;
    case "cqi_register":
      retVal = "CQI Register";
      break;
    case "recurring_form":
      retVal = "Recurring Form";
      break;
    case CareEsioEntity.Employee.EntityName:
      retVal = CareEsioEntity.Employee.DisplayName;
      break;
    case CareEsioEntity.care_recipient.EntityName:
      retVal = CareEsioEntity.care_recipient.DisplayName;
      break;
    default:
      break;
  }
  return retVal;
}
