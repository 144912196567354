import { MapperConfiguration, MappingPair } from "@dynamic-mapper/mapper";
import { isNotNullAndUndefined } from "../../../../RAFComponents/helpers/utils";
import { RepeatProcesssItems } from "../../../../RAFModules/ActiveContacts/Task/RAFRepeatProcesssDropdown";
import {
  BusinessProcessTemplateRow,
  IBusinessProcessTemplateDto,
} from "./BusinessProcessTemplateRow";

export const ConvertBPTemplateRowToBPTemplateDto = (
  BusinessProcessTemplateRow: BusinessProcessTemplateRow
): IBusinessProcessTemplateDto => {
  const BPTemplateRowToIBPTemplateDto = new MappingPair<
    BusinessProcessTemplateRow,
    IBusinessProcessTemplateDto
  >();
  const BPTemplateRowToBPTemplateDtoConfiguration = new MapperConfiguration(
    (cfg) => {
      cfg
        .createAutoMap(BPTemplateRowToIBPTemplateDto, {})
        .forSourceMember("AdditionalInfo", (opt) => opt.ignore())
        .forSourceMember("DueDate", (opt) => opt.ignore())
        .forSourceMember("RepeatProcesss", (opt) => opt.ignore())
        .forSourceMember("IsWorkflowForm", (opt) => opt.ignore())
        .forSourceMember("InitiatorType", (opt) => opt.ignore())
        .forSourceMember("RelateExisting", (opt) => opt.ignore());
    }
  );

  const mapper = BPTemplateRowToBPTemplateDtoConfiguration.createMapper();
  return mapper.map(BPTemplateRowToIBPTemplateDto, BusinessProcessTemplateRow);
};

export const ConvertBPTemplateDtoToBusinessProcessTemplateRow = (
  BusinessProcessTemplateDto: IBusinessProcessTemplateDto
): BusinessProcessTemplateRow => {
  const IBPTemplateDtoToBPTemplateRow = new MappingPair<
    IBusinessProcessTemplateDto,
    BusinessProcessTemplateRow
  >();
  const IBPTemplateDtoToBPTemplateRowConfiguration = new MapperConfiguration(
    (cfg) => {
      cfg.createAutoMap(IBPTemplateDtoToBPTemplateRow, {
        AdditionalInfo: (opt) =>
          opt.mapFrom((src) =>
            isNotNullAndUndefined(src) && isNotNullAndUndefined(src.FormUID)
              ? true
              : false
          ),
        DueDate: (opt) => opt.mapFrom(() => null),
        IsWorkflowForm: (opt) => opt.mapFrom(() => null),
        InitiatorType: (opt) => opt.mapFrom(() => null),
        RepeatProcesss: (opt) =>
          opt.mapFrom((src) =>
            isNotNullAndUndefined(src.CronExpression)
              ? RepeatProcesssItems.Custom
              : RepeatProcesssItems.Never
          ),
        RelateExisting: (opt) =>
          opt.mapFrom((src) =>
            isNotNullAndUndefined(src) && isNotNullAndUndefined(src.EntityUID)
              ? true
              : false
          ),
      });
    }
  );
  const mapper = IBPTemplateDtoToBPTemplateRowConfiguration.createMapper();
  return mapper.map(IBPTemplateDtoToBPTemplateRow, BusinessProcessTemplateDto);
};
