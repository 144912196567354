import { propertyOf } from "../../../../RAFComponents/helpers/utils";
import { RAFEntityBase } from "../../../../RAFComponents/models/Common/RAFEntityBase";
import { RAFRelatedToRow } from "../../../../RAFComponents/models/Common/RAFRelatedToRow";
import { ConnectorRow } from "../StepTemplate/ConnectorRow";

export class IBusinessProcessStepDto {
    UID?: string;
    Title?: string;
    StepType?: string;
    StepOrder?: number;
    DisplayOrder?: string;
    StepStatus?: string;
    Assignee?: string;
    AssigneeUID?: string;
    AssigneeType?: string;
    BpStepTemplate?: string;
    BpStepTemplateUID?: string;
    Description?: string;
    FormData?: any;
    FormDataModel?: any;
    DueDate?: Date;
    BusinessProcess?: string;
    BusinessProcessUID?: string;
    RelatedTo?: string;
    RelatedToUID?: string;
    RelatedToType?: string;
    IsApproval?: boolean;
    IsRequired?: boolean;
    StepCount?: number;
    CommentCount?: number;
    IsConditional?: boolean;
    IsDependent?: boolean;
    ExecutionOrder?: string;
    FormName?: string;
    FormType?: string;
    FormUID?: string;
    ParentBpStepTemplate?: string;
    ParentBpStepTemplateUID?: string;
    DependentBpStepTemplate?: string;
    DependentBpStepTemplateUID?: string;
    ParentStep?: string;
    ParentStepUID?: string;
    StepConnectorJson?: ConnectorRow[];
    CompletedDate?: Date;
    AssignTeam?: string;
    AssignTeamUID?: string;
    CompletedByUID?: string;
    CompletedBy?: string;
    TagsListJson?: string[];
    IsActive?: boolean;
    Portal?: string;
    PortalUID?: string;
    Partner?: string;
    PartnerUID?: string;
    PageLayout?: string;
    PageLayoutUID?: string;
    StepSettings?: any;
    RelatedEntities?: RAFRelatedToRow[];
    Attachment?:string;
    AttachmentUID?:string;
    Comment?: string;
    EnableStopGate?: boolean;
    SecondaryRelatedTo?:string;
    SecondaryRelatedToUID?:string;
    SecondaryRelatedToType?:string;
    //Entity?: string;
    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
    LastActivityDate?: Date;
    //PageLayout?: RAFPageLayoutRow;
}

export class IBusinessProcessStepRow {
    UID?: string;
    Title?: string;
    StepType?: string;
    StepOrder?: number;
    DisplayOrder?: string;
    StepStatus?: string;
    Assignee?: string;
    AssigneeUID?: string;
    AssigneeType?: string;
    BpStepTemplate?: string;
    BpStepTemplateUID?: string;
    Description?: string;
    FormData?: any;
    FormDataModel?: any;
    DueDate?: Date;
    BusinessProcess?: string;
    BusinessProcessUID?: string;
    RelatedTo?: string;
    RelatedToUID?: string;
    RelatedToType?: string;
    IsApproval?: boolean;
    IsRequired?: boolean;
    StepCount?: number;
    CommentCount?: number;
    IsConditional?: boolean;
    IsDependent?: boolean;
    ExecutionOrder?: string;
    FormName?: string;
    FormType?: string;
    FormUID?: string;
    ParentBpStepTemplate?: string;
    ParentBpStepTemplateUID?: string;
    DependentBpStepTemplate?: string;
    DependentBpStepTemplateUID?: string;
    ParentStep?: string;
    ParentStepUID?: string;
    StepConnectorJson?: ConnectorRow[];
    CompletedDate?: Date;
    AssignTeam?: string;
    AssignTeamUID?: string;
    CompletedByUID?: string;
    CompletedBy?: string;
    TagsListJson?: string[];
    IsActive?: boolean;
    Portal?: string;
    PortalUID?: string;
    Partner?: string;
    PartnerUID?: string;
    PageLayout?: string;
    PageLayoutUID?: string;
    StepSettings?: any;
    RelatedEntities?: RAFRelatedToRow[];
    Attachment?:string;
    AttachmentUID?:string;
    Comment?: string;
    EnableStopGate?: boolean;
    SecondaryRelatedTo?:string;
    SecondaryRelatedToUID?:string;
    SecondaryRelatedToType?:string;
    //Entity?: string;
    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwnerUID?: string;
    RecordOwner?: string;
    LastActivityDate?: Date;
    //PageLayout?: RAFPageLayoutRow;
}

export class BusinessProcessStepRow extends IBusinessProcessStepRow implements RAFEntityBase {

    ProcessStarter?: string;
    IsChildStep?: boolean;
    SelectedLevel?: string;
    CopySettingsFromUID?: string;
    DecisionType?: string;

    getClassName?() {
        return 'business_process_step';
    }
    getIdField?(): string {
        return propertyOf<BusinessProcessStepRow>("Title");
    }
    getListUrl?(): string {
        return "BusinessProcessStep/List";
    }
    getSaveUrl?(): string {
        return "BusinessProcessStep/Save";
    }
}

