import { propertyOf } from "../../../../RAFComponents/helpers/utils";
import { RAFEntityBase } from "../../../../RAFComponents/models/Common/RAFEntityBase";

export class IBusinessProcessTemplateDto {
    UID?: string;
    ProcessName?: string;
    StartMode?: string;
    IsRecurring?: boolean;
    ExecutionOrder?: string;
    Frequency?: string;
    ProcessStatus?: string;
    FormData?: any;
    FormDataModel?: any;
    InitialFormData?: any;
    Initiator?: string;
    InitiatorUID?: string;
    ProcessDeadlineUnits?: string;
    ProcessDeadlineInterval?: number;
    ProcessDeadlineCalculation?: string;
    CronExpression?: string;
    Description?: string;
    StepCount?: number;
    //Entity?: string;
    TagsListJson?: string[];
    FormType?: string;
    FormName?: string;
    FormUID?: string;
    //InitialFormData?: string;
    IconName?: string;
    PageLayout?: string;
    PageLayoutUID?: string;
    //Category?: string;
    TemplateType?: string;
    RRuleExpression?: string;
    IsOfflineProcess?: boolean;
    IsRevision?: boolean;
    VersionUID?: string;
    VersionNumber?: number;
    IsActive?: boolean;
    //BusinessProcessType?: string;
    //BusinessProcessTypeUID?: string;
    Category?: string;
    CategoryUID?: string;
    NextRunDate?: Date;
    LastRunDate?: Date;
    CurrentStatus?: string;
    ThemeTemplate?: string;
    TitleExpression?: string;
    Portal?: string;
    PortalUID?: string;
    CreatedDate?: Date;
    CreatedBy?: string;
    CreatedByUID?: string;
    ModifiedDate?: Date;
    ModifiedBy?: string;
    ModifiedByUID?: string;
    RecordOwner?: string;
    RecordOwnerUID?: string;
    LastActivityDate?: Date;
    Entity?: string;
    EntityUID?: string;
}

export class IBusinessProcessTemplateRow {
    UID?: string;
    ProcessName?: string;
    StartMode?: string;
    IsRecurring?: boolean;
    ExecutionOrder?: string;
    Frequency?: string;
    ProcessStatus?: string;
    FormData?: any;
    FormDataModel?: any;
    InitialFormData?: any;
    Initiator?: string;
    InitiatorUID?: string;
    ProcessDeadlineUnits?: string;
    ProcessDeadlineInterval?: number;
    ProcessDeadlineCalculation?: string;
    CronExpression?: string;
    Description?: string;
    StepCount?: number;
    //Entity?: string;
    TagsListJson?: string[];
    FormType?: string;
    FormName?: string;
    FormUID?: string;
    //InitialFormData?: string;
    IconName?: string;
    PageLayout?: string;
    PageLayoutUID?: string;
    //Category?: string;
    TemplateType?: string;
    RRuleExpression?: string;
    IsOfflineProcess?: boolean;
    IsRevision?: boolean;
    VersionUID?: string;
    VersionNumber?: number;
    IsActive?: boolean;
    //BusinessProcessType?: string;
    //BusinessProcessTypeUID?: string;
    Category?: string;
    CategoryUID?: string;
    NextRunDate?: Date;
    LastRunDate?: Date;
    CurrentStatus?: string;
    ThemeTemplate?: string;
    TitleExpression?: string;
    Portal?: string;
    PortalUID?: string;
    CreatedDate?: Date;
    CreatedBy?: string;
    CreatedByUID?: string;
    ModifiedDate?: Date;
    ModifiedBy?: string;
    ModifiedByUID?: string;
    RecordOwner?: string;
    RecordOwnerUID?: string;
    LastActivityDate?: Date;
    Entity?: string;
    EntityUID?: string;
}


export class BusinessProcessTemplateRow extends IBusinessProcessTemplateRow implements RAFEntityBase {
    AdditionalInfo?: boolean;
    DueDate?: Date;
    RepeatProcesss?: string;
    InitiatorType?: string;
    IsWorkflowForm?: boolean;
    RelateExisting?:boolean;

    getClassName?() {//use entityname
        return 'business_process_template';
    }

    getIdField?(): string {
        return propertyOf<BusinessProcessTemplateRow>("UID");
    }
    getListUrl?(): string {//use route name(entity displayname)
        return "BusinessProcessTemplate/List";
    }
    getSaveUrl?(): string {//use route name(entity displayname)
        return "BusinessProcessTemplate/Save";
    }
    getLookupUrl?(): string {//use route name(entity displayname)
        return "BusinessProcessTemplate/Lookup";
    }

    getRelatedListUrl?(): string {//use route name(entity displayname)
        return "BusinessProcessTemplate/RelatedList";
    }
}
