import React, { PropsWithChildren } from 'react';
import { isNotNullAndUndefined, IsNullOrWhiteSpace } from '../../../../RAFComponents/helpers/utils';
import { RetrieveFormLibraryById } from '../../../helpers/RMutils';
import { RAFFormDataModel ,RAFComponentInputModel,RAFComponentModel} from '../../RAFPage/PageLayoutJM';
import { isRAFComponentInputModel } from '../../RAFPage/RAFPageHelper';
import RAFInputModelRenderer from './RAFInputModelRenderer';


interface IProps {
    formUID?: string;
    showLabel?:boolean;
    isReadonly?:boolean;
    validateFields?:boolean;
}

export default function RAFFormTemplateRenderer({ formUID,
    showLabel,
    isReadonly, 
    validateFields,
    //children, 
    //...props 
    }: PropsWithChildren<IProps>) {
    const [inputComponents, setInputComponents] = React.useState(null);

    React.useEffect(() => {
        if (IsNullOrWhiteSpace(formUID)) {
            return;
        }
        RetrieveFormLibraryById(formUID).then(formLibrary => {
            let componentDataModel: RAFFormDataModel = formLibrary.DataModel as RAFFormDataModel;
                    let components: RAFComponentModel[] = componentDataModel.components;
                    let inputComponents: RAFComponentInputModel[] = [];
                    components.forEach(item => {
                        if (isRAFComponentInputModel(item.component)) {
                            inputComponents.push(item.component);
                        }
                    });
                    setInputComponents(inputComponents);
        })
        
    }, [formUID]);

    if (isNotNullAndUndefined(inputComponents)) {
        return (
            <div>
                {
                    inputComponents.map((objItem,index) => {
                        return (<div key={index}>
                            <div className="col-12 mb-3">
                                <RAFInputModelRenderer key={index} data={objItem} formDataFieldName={"FormData"} validateFields={validateFields} showLabel={showLabel} isReadonly={isReadonly} />
                            </div>
                        </div>)
                    })
                }

            </div>
        );
    }
    else {
        return null;
    }

}
