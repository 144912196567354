import React, { PropsWithChildren } from 'react';
import { hideProgress, showProgress } from '../../../../RAFComponents/helpers/AppHelper';
import { isNotNullAndUndefined, isNullOrUndefined, wrapChildrenWith } from '../../../../RAFComponents/helpers/utils';
import { initializeRAFDB } from '../../../../WorkesioRepository';
import { RetrieveBusinessProcessTypeDto } from '../../Category/BusinessProcessTypeHelper';
import { BusinessProcessTypeRow } from '../../Category/BusinessProcessTypeRow';
import { GetBusinessProcessTemplateSteps, RetrieveBusinessProcessTemplate } from '../helpers/ProcessHelper';
import { BusinessProcessStepTemplateRow } from '../StepTemplate/BusinessProcessStepTemplateRow';
import { BusinessProcessTemplateRow } from './BusinessProcessTemplateRow';

interface IState {
  businessProcessTemplate?: BusinessProcessTemplateRow;
  bpTemplateSteps?: BusinessProcessStepTemplateRow[];
  bpTypeRow: BusinessProcessTypeRow;
  noContent: boolean;
}

interface IProps {
  bpTemplateUID?: string;
}

export const RAFBPTemplateContext = React.createContext<{ noContent?: boolean, businessProcessTemplate?: BusinessProcessTemplateRow, bpTemplateSteps?: BusinessProcessStepTemplateRow[], bpTypeRow?: BusinessProcessTypeRow, updateProcessTemplate?: (businessProcessTemplate?: BusinessProcessTemplateRow) => void, updateProcessTemplateAndSteps?: (bpTemplateSteps?: BusinessProcessStepTemplateRow[]) => void, updateProcessTemplateSteps?: (bpTemplateSteps?: BusinessProcessStepTemplateRow[]) => void; }>({ businessProcessTemplate: null, bpTemplateSteps: [], updateProcessTemplate: null, updateProcessTemplateAndSteps: null, updateProcessTemplateSteps: null });
class RAFBPTemplateContextProvider extends React.Component<PropsWithChildren<IProps>, IState> {

  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      businessProcessTemplate: null,
      bpTemplateSteps: [],
      bpTypeRow: null,
      noContent: false,
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount = async () => {
    this._isMounted = true;
    await initializeRAFDB();
    RetrieveBusinessProcessTemplate(this.props.bpTemplateUID).then(
      (businessProcessTemplate) => {
        if (isNotNullAndUndefined(businessProcessTemplate)) {
          GetBusinessProcessTemplateSteps(businessProcessTemplate).then(
            (bpTemplateSteps) => {
              RetrieveBusinessProcessTypeDto(
                businessProcessTemplate.CategoryUID
              ).then((bpTypeRow) => {
                if (this._isMounted) {
                  this.setState({
                    businessProcessTemplate,
                    bpTemplateSteps,
                    bpTypeRow,
                    noContent: false,
                  });
                }
              });
            }
          );
        } else {
          if (this._isMounted) {
            this.setState({ businessProcessTemplate, noContent: true });
          }
        }
      }
    );
  };

  updateProcessTemplateAndSteps = (
    bpTemplateSteps?: BusinessProcessStepTemplateRow[]
  ) => {
    if (isNotNullAndUndefined(bpTemplateSteps)) {
      RetrieveBusinessProcessTemplate(this.props.bpTemplateUID).then(
        (businessProcessTemplate) => {
          if (isNotNullAndUndefined(businessProcessTemplate)) {
            if (this._isMounted) {
              this.setState({
                businessProcessTemplate,
                bpTemplateSteps,
                noContent: false,
              });
            }
          } else {
            if (this._isMounted) {
              this.setState({ businessProcessTemplate, noContent: true });
            }
          }
        }
      );
    } else {
      RetrieveBusinessProcessTemplate(this.props.bpTemplateUID).then(
        (businessProcessTemplate) => {
          if (isNotNullAndUndefined(businessProcessTemplate)) {
            GetBusinessProcessTemplateSteps(businessProcessTemplate).then(
              (bpTemplateSteps) => {
                if (this._isMounted) {
                  this.setState({
                    businessProcessTemplate,
                    bpTemplateSteps,
                    noContent: false,
                  });
                }
              }
            );
          } else {
            if (this._isMounted) {
              this.setState({ businessProcessTemplate, noContent: true });
            }
          }
        }
      );
    }
  };

  updateProcessTemplate = () => {
    let progressDiv = showProgress("body", true);
    RetrieveBusinessProcessTemplate(this.props.bpTemplateUID).then(
      (businessProcessTemplate) => {
        hideProgress(progressDiv);
        this.setState({
          businessProcessTemplate,
          noContent: isNullOrUndefined(businessProcessTemplate) ? true : false,
        });
      }
    );
  };

  updateProcessTemplateSteps = (
    bpTemplateSteps?: BusinessProcessStepTemplateRow[]
  ) => {
    this.setState({ bpTemplateSteps });
  };

  render() {
    return (
      <RAFBPTemplateContext.Provider
        value={{
          noContent: this.state.noContent,
          businessProcessTemplate: this.state.businessProcessTemplate,
          bpTemplateSteps: this.state.bpTemplateSteps,
          bpTypeRow: this.state.bpTypeRow,
          updateProcessTemplate: this.updateProcessTemplate,
          updateProcessTemplateSteps: this.updateProcessTemplateSteps,
          updateProcessTemplateAndSteps: this.updateProcessTemplateAndSteps,
        }}
      >
        {wrapChildrenWith(this.props.children, {})}
      </RAFBPTemplateContext.Provider>
    );
  }
}
export default RAFBPTemplateContextProvider;
