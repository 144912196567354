import * as React from "react";
import { PropsWithChildren, useContext } from "react";
import { FormRenderProps } from "react-final-form";
import { isNotNullAndUndefined } from "../../../../RAFComponents/helpers/utils";
import RAFChoiceOption from "../../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFRadioButtonList from "../../../../RAFComponents/Inputs/RAFRadioButtonList";
import { RAFFormContext } from "../../../../RAFComponents/Inputs/RFFUtils";
import { BPStepDecisionModel } from "../../../../constants/BPType/RAFBPConstants";
import { ConnectorRow } from "../StepTemplate/ConnectorRow";

interface IProps {
    formDataFieldName?: string;
    formDataModelFieldName?: string;
    validateFields?: boolean;
    mode?: "Edit" | "Details";
    stepConnector?: ConnectorRow[];
}

function BPStepDecisionDataModel({ ...props }: PropsWithChildren<IProps>) {
    const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);
    let formDataModel: BPStepDecisionModel = null;

    if (
        isNotNullAndUndefined(rafFormContextValue) &&
        isNotNullAndUndefined(rafFormContextValue.values)
    ) {
        formDataModel = rafFormContextValue.values[props.formDataModelFieldName];
    }
    let stepConnector = props.stepConnector;

    return (
        stepConnector && stepConnector.length > 0 &&
        <div className="col-12">
            {/*<RAFRadioButtonList
                field="FormData.Prompt"
                showLabel={false}
                label="Decision"
                labelClassName="col-12 mb-2"
                inputFieldClassName="col-12"
            >
                {
                    formDataModel && formDataModel.Decision && formDataModel.Decision.length > 0 && formDataModel.Decision.map(item => {
                        return <RAFChoiceOption key={item.Conditions.Value.toString()}
                            label={item.Conditions.Value.toString()}
                            value={item.Conditions.Value}
                        />
                    })
                }
                
            </RAFRadioButtonList>*/}

            <RAFRadioButtonList
                field="FormData.Prompt"
                showLabel={false}
                label="Decision"
                labelClassName="col-12 mb-2"
                inputFieldClassName="col-12"
                formGroupClassName="mb-0 w-auto"
                uitype="button"
                groupBtnClassName="min-width cssradio-mb-0 label-my-0"
            >
                {
                    stepConnector && stepConnector.length > 0 && stepConnector.map(item => {
                        return <RAFChoiceOption key={item.TargetID}
                            label={isNotNullAndUndefined(item.Annotations) && isNotNullAndUndefined(item.Annotations[0]) && item.Annotations[0].Content}
                            value={isNotNullAndUndefined(item.Annotations) && isNotNullAndUndefined(item.Annotations[0]) && item.Annotations[0].Content}
                        />

                    })
                }

            </RAFRadioButtonList>
        </div>
    );
}

export default BPStepDecisionDataModel;
