import { propertyOf } from "../../../../RAFComponents/helpers/utils";
import { RAFEntityBase } from "../../../../RAFComponents/models/Common/RAFEntityBase";
import { ILookupRow } from "../../../../RAFComponents/models/CompositeTypes/ILookupRow";

export class IBusinessProcessDto {
    UID?: string;
    ProcessName?: string;
    StartMode?: string;
    ExecutionOrder?: string;
    //StepCompleteOrder?: string;
    //Frequency?: string;
    ProcessStatus?: string;
    FormData?: any;
    FormDataModel?: any;
    ProcessStarterUID?: string;
    ProcessStarter?: string;
    //InitiatorUID?: string;
    //Initiator?: string;
    StartDate?: Date;
    EndDate?: Date;
    DueDate?: Date;
    StepCount?: number;
    CompletedCount?: number;
    BpTemplate?: string;
    BpTemplateUID?: string;
    CommentCount?: number;
    RelatedTo?: string;
    RelatedToUID?: string;
    RelatedToType?: string;
    SecondaryRelatedTo?: string;
    SecondaryRelatedToUID?: string;
    SecondaryRelatedToType?: string;
    Description?: string;
    TagsListJson?: string[];
    FormType?: string;
    FormName?: string;
    FormUID?: string;
    IconName?: string;
    VersionUID?: string;
    VersionNumber?: number;
    //BusinessProcessType?: string;
    //BusinessProcessTypeUID?: string;
    Category?: string;
    CategoryUID?: string;
    ThemeTemplate?: string;
    Portal?: string;
    PortalUID?: string;
    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwner?: string;
    RecordOwnerUID?: string;
    LastActivityDate?: Date;
    //RRuleExpression?: string;
    //InitialSettings?: BusinessProcessIntitialSettingsRow;
    //Steps?: BusinessProcessStepRow[];
    Entity?: string;
    EntityUID?: string;
}

export class IBusinessProcessRow {
    UID?: string;
    ProcessName?: string;
    StartMode?: string;
    ExecutionOrder?: string;
    //StepCompleteOrder?: string;
    //Frequency?: string;
    ProcessStatus?: string;
    FormData?: any;
    FormDataModel?: any;
    ProcessStarterUID?: string;
    ProcessStarter?: string;
    //InitiatorUID?: string;
    //Initiator?: string;
    StartDate?: Date;
    EndDate?: Date;
    DueDate?: Date;
    StepCount?: number;
    CompletedCount?: number;
    BpTemplate?: string;
    BpTemplateUID?: string;
    CommentCount?: number;
    RelatedTo?: string;
    RelatedToUID?: string;
    RelatedToType?: string;
    SecondaryRelatedTo?: string;
    SecondaryRelatedToUID?: string;
    SecondaryRelatedToType?: string;
    Description?: string;
    TagsListJson?: string[];
    FormType?: string;
    FormName?: string;
    FormUID?: string;
    IconName?: string;
    VersionUID?: string;
    VersionNumber?: number;
    //BusinessProcessType?: string;
    //BusinessProcessTypeUID?: string;
    Category?: string;
    CategoryUID?: string;
    ThemeTemplate?: string;
    Portal?: string;
    PortalUID?: string;
    CreatedDate?: Date;
    CreatedByUID?: string;
    CreatedBy?: string;
    ModifiedDate?: Date;
    ModifiedByUID?: string;
    ModifiedBy?: string;
    RecordOwner?: string;
    RecordOwnerUID?: string;
    LastActivityDate?: Date;
    //RRuleExpression?: string;
    //InitialSettings?: BusinessProcessIntitialSettingsRow;
    //Steps?: BusinessProcessStepRow[];
    Entity?: string;
    EntityUID?: string;
}

export class BusinessProcessRow extends IBusinessProcessRow implements RAFEntityBase, ILookupRow {

    getClassName?() {
        return 'business_process';
    }
    getIdField?(): string {
        return propertyOf<BusinessProcessRow>("ProcessName");
    }
    getListUrl?(): string {
        return "BusinessProcess/List";
    }
    getSaveUrl?(): string {
        return "BusinessProcess/Save";
    }
    getLookupUrl(): string {//use route name(entity displayname)
        return "BusinessProcess/Lookup";
    }
    isOptionCreatable?(): boolean {
        return false;
    }
}

export class BusinessProcessIntitialSettingsRow {
    Title?: string;
    StepCanCompleted?: string;
    Initiators?: string;
    Deadline?: string;
    StartMode?: string;
    Frequency?: string;
    InitialForm?: string;
}

