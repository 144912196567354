// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';
import { getAuth, signInWithCustomToken } from 'firebase/auth';

import { getFirebaseAuthToken } from '../helpers/ACutils';


export const firebaseConfig = {
    databaseURL: "https://activecontacts-9afd4.firebaseio.com",
    apiKey: "AIzaSyAzul4UGp_pSKzD5-HepWEqswh9NHougRI",
    authDomain: "activecontacts-9afd4.firebaseapp.com",
    projectId: "activecontacts-9afd4",
    storageBucket: "activecontacts-9afd4.appspot.com",
    messagingSenderId: "308846639242",
    appId: "1:308846639242:web:9c544dc1dc55e522dbd573",
    measurementId: "G-FN5C5PQWZN"
};


export const initializeRAFFB = () => {
    return new Promise<boolean>(async (resolve, reject) => {
        // const allEntities = await getAllEntitiesFB();
        // const allFields = await getAllFieldsFB();
        // const allForms = await getAllFormsFB();
        // const allPageLayouts = await getAllPageLayoutsFB();
        resolve(true);
    });
};



export const signInFBWithCustomTokenAsync = async () => {
    const token = await getFirebaseAuthToken();
    const auth = getAuth();
    await signInWithCustomToken(auth, token);
};