import { PropsWithChildren } from "react";
import RAFCheckBoxList from "../../RAFComponents/Inputs/RAFCheckBoxList";
import {
  RAFDefaultFieldProps,
  RAFFieldProps,
} from "../../RAFComponents/Inputs/RFFUtils";
import { isNotNullAndUndefined } from "../../RAFComponents/helpers/utils";

//const RAFTextBox = ({ field, label, required }) => {
interface IProps {
  uitype?: "default" | "vertical";
}

function RMCheckBoxList<T>({
  field,
  label,
  width,
  onChanged,
  labelClassName,
  inputFieldClassName,
  children,
  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  showClearButton = RAFDefaultFieldProps.showClearButton,
  validate = RAFDefaultFieldProps.validate,
  ...props
}: PropsWithChildren<RAFFieldProps<T> & IProps>) {
  return (
    <div
      className={isNotNullAndUndefined(props.cssClass) ? props.cssClass : null}
    >
      <div
        className="row align-items-center radio-button"
        id={"rafdiv" + field.toString()}
      >
        {showLabel && showLabel === true && (
          <div
            className={
              isNotNullAndUndefined(labelClassName)
                ? labelClassName + " d-flex"
                : "mb-md-3 col-md-3 d-flex justify-content-md-end"
            }
          >
            <label className={required ? "form-label required" : "form-label"}>
              <>
                {label || field}{" "}
              </>
            </label>
          </div>
        )}
        <div
          className={
            isNotNullAndUndefined(inputFieldClassName)
              ? inputFieldClassName + ""
              : isNotNullAndUndefined(showLabel)
                ? "col-md-7"
                : "col-md-12"
          }
        >
          <div className="w-100">
            <RAFCheckBoxList<T>
              field={field}
              label={label}
              required={required}
              showLabel={false}
              children={children}
              width={width && width !== null ? width : "100%"}
              disabled={disabled}
              onChanged={onChanged}
              {...props}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RMCheckBoxList;
