import { RuleProperties, TopLevelCondition, NestedCondition } from "json-rules-engine";
import { /*propertyOf,*/ getSaveRequest, isNotNullAndUndefined } from "../../../RAFComponents/helpers/utils";
import { RAFCustomFilter, RAFCustomOperator } from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { RAFOperator } from "../../../RAFComponents/models/Common/QueryAttributeJM";
import { BusinessRuleRow } from "./BusinessRuleRow";
import { ListServiceRequest } from "../../../RAFComponents/models/Common/ListRequest";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { ContentType } from "../../../constants/Common/Constants";

export function ConvertBusinessRuleJMToRuleProperties(businessRuleJMList: BusinessRuleRow[]): RuleProperties[] {
    let retVal: RuleProperties[] = [];
    //console.log('ConvertBusinessRuleJMToRuleProperties', businessRuleJMList);
    if (isNotNullAndUndefined(businessRuleJMList) && businessRuleJMList.length > 0) {
        for (var i = 0; i < businessRuleJMList.length; i++) {
            const item: BusinessRuleRow = businessRuleJMList[i];
            //console.log('ConvertBusinessRuleJMToRuleProperties item', item);
            //if (item.EvaluateAs === "clientrule") {
            const rule: RuleProperties = {
                name: item.RuleName, conditions: null, event: null
            };
            if (isNotNullAndUndefined(item.Actions)) {
                rule.event = { type: "Action", params: item.Actions };
            }
            if (isNotNullAndUndefined(item.Conditions)) {
                rule.conditions = getTopLevelConditionByCustomFilter(item.Conditions);
            }
            retVal.push(rule);
            //}
        }

    }
    return retVal;
}

function getTopLevelConditionByCustomFilter(rule: RAFCustomFilter): TopLevelCondition {
    let outVal: TopLevelCondition = null;
    if (isNotNullAndUndefined(rule)) {
        //console.log('getTopLevelConditionByCustomFilter rule', rule);
        if (rule.Condition === RAFOperator.OrCondition) {
            outVal = { any: [GetNestedConditionsByRule(rule)] };
        }
        else {
            outVal = { all: [GetNestedConditionsByRule(rule)] };
        }

    }
    return outVal;
}

function GetNestedConditionsByRule(rule: RAFCustomFilter): NestedCondition {
    let retVal: NestedCondition = null;
    if (isNotNullAndUndefined(rule)) {
        if (rule.Rules && isNotNullAndUndefined(rule.Rules)) {
            const ruleModels: RAFCustomFilter[] = rule.Rules;
            if (ruleModels.length > 0) {
                let obj1: NestedCondition[] = [];
                for (var i = 0; i < ruleModels.length; i++) {
                    obj1.push(GetNestedConditionsByRule(ruleModels[i]));
                }
                //console.log('obj1', obj1);
                if (isNotNullAndUndefined(obj1) && obj1.length > 0) {
                    if (rule.Condition === RAFOperator.OrCondition) {
                        retVal = ({ any: obj1 });
                    }
                    else {
                        retVal = ({ all: obj1 });
                    }
                }
            }
        }
        else if (isNotNullAndUndefined(rule.Value) && isNotNullAndUndefined(rule.Value[0])) {
            let objField: NestedCondition = { fact: rule.Field, operator: getRuleEngineOperatorByRAFOperator(rule.Operator), value: rule.Value[0] };
            retVal = (objField);
        }
    }
    //console.log('retVal', retVal);
    return retVal;
}

/*function getTopLevelConditionByCustomFilter1(rule: RAFCustomFilter): TopLevelCondition {
    let outVal: TopLevelCondition = null;
    if (isNotNullAndUndefined(rule)) {
        //console.log('getTopLevelConditionByCustomFilter rule', rule);
        if (rule.Condition === RAFOperator.OrCondition) {
            outVal = { any: GetNestedConditionsByRule1(rule) }
        }
        else {
            outVal = { all: GetNestedConditionsByRule1(rule) }
        }

    }
    return outVal;
}*/

/*function GetNestedConditionsByRule1(rule: RAFCustomFilter): NestedCondition[] {
    let retVal: NestedCondition[] = [];
    if (isNotNullAndUndefined(rule)) {
        //console.log('GetNestedConditionsByRule rule', rule, rule.Rules, rule.Value);
        if (rule.Rules && isNotNullAndUndefined(rule.Rules)) {
            const ruleModels: RAFCustomFilter[] = rule.Rules;
            if (ruleModels.length > 0) {
                let obj1: NestedCondition[] = [];
                for (var i = 0; i < ruleModels.length; i++) {
                    obj1.push(...GetNestedConditionsByRule1(ruleModels[i]))
                }
                //console.log('obj1', obj1);
                if (isNotNullAndUndefined(obj1) && obj1.length > 0) {
                    if (rule.Condition === RAFOperator.OrCondition) {
                        retVal.push({ any: obj1 });
                    }
                    else {
                        retVal.push({ all: obj1 });
                    }
                }
            }
        }
        else if (isNotNullAndUndefined(rule.Value) && isNotNullAndUndefined(rule.Value[0])) {
            let objField: NestedCondition = { fact: rule.Field, operator: getRuleEngineOperatorByRAFOperator(rule.Operator), value: rule.Value[0] };
            retVal.push(objField);
        }
    }
    //console.log('retVal', retVal);
    return retVal;
}
*/
function getRuleEngineOperatorByRAFOperator(rafOperator: RAFCustomOperator): string {
    let retVal = "";
    switch (rafOperator) {
        case RAFCustomOperator.BeginsWith: retVal = "startsWith"; break;
        case RAFCustomOperator.EndsWith: retVal = "endsWith"; break;
        case RAFCustomOperator.Contains: retVal = "contains"; break;
        case RAFCustomOperator.NotContains: retVal = "doesNotContain"; break;
        case RAFCustomOperator.Equal: retVal = "equal"; break;
        case RAFCustomOperator.NotEqual: retVal = "notEqual"; break;
        case RAFCustomOperator.GreaterThan: retVal = "greaterThan"; break;
        case RAFCustomOperator.GreaterThanOrEqual: retVal = "greaterThanInclusive"; break;
        case RAFCustomOperator.LesserThan: retVal = "lessThan"; break;
        case RAFCustomOperator.LesserThanOrEqual: retVal = "lessThanInclusive"; break;
        case RAFCustomOperator.Between: retVal = "between"; break;
        case RAFCustomOperator.NotBetween: retVal = "notBetween"; break;
        default:
    }
    return retVal;
}

export function getBusinessRulesByRelatedObjectId(relatedObjectId?: string, relatedToType?: string) {

    let relatedFilter: RAFCustomFilter = {};
    relatedFilter.Condition = "and";
    relatedFilter.Rules = [];

    if (isNotNullAndUndefined(relatedObjectId)) {
        let filter: RAFCustomFilter = {};
        let filterVal: string[] = [];
        filterVal.push(relatedObjectId);
        filter.Operator = RAFCustomOperator.Equal;
        filter.Value = filterVal;
        filter.Field = "RelatedToUID";
        relatedFilter.Rules.push(filter);
    }

    if (isNotNullAndUndefined(relatedToType)) {
        let filter: RAFCustomFilter = {};
        let filterVal: string[] = [];
        filterVal.push(relatedToType);
        filter.Operator = RAFCustomOperator.Equal;
        filter.Value = filterVal;
        filter.Field = "RelatedToType";
        relatedFilter.Rules.push(filter);
    }



    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.CustomFilter = relatedFilter;
    listServiceRequest.Skip = 0;
    listServiceRequest.Take = 0;

    return new Promise<BusinessRuleRow[]>((resolve) => {
        repositoryActions.postDataAndGetResponse('BusinessRule/List', listServiceRequest, null, ContentType.applicationJson)
            .then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                    let businessRuleList: BusinessRuleRow[] = response.data.Entities;
                    if (isNotNullAndUndefined(businessRuleList) && businessRuleList.length > 0) {
                        resolve(businessRuleList);
                    }
                    else {
                        resolve(null);
                    }

                }
            }).catch((error) => error);
    });


}

export function getAllBusinessRules() {
    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.Skip = 0;
    listServiceRequest.Take = 0;

    return new Promise<BusinessRuleRow[]>((resolve) => {
        repositoryActions.postDataAndGetResponse('BusinessRule/List', listServiceRequest, null, ContentType.applicationJson)
            .then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                    let businessRuleList: BusinessRuleRow[] = response.data.Entities;
                    if (isNotNullAndUndefined(businessRuleList) && businessRuleList.length > 0) {
                        resolve(businessRuleList);
                    }
                    else {
                        resolve(null);
                    }

                }
            }).catch((error) => error);
    });
}

export function RetrieveBusinessRuleById(id?: string) {
    return new Promise<BusinessRuleRow>((resolve) => {
        repositoryActions.postDataAndGetResponse('BusinessRule/Retrieve', { EntityId: id }, null, ContentType.applicationJson)
            .then((response) => {
                if (isNotNullAndUndefined(response) && isNotNullAndUndefined(response.data)) {
                    let businessRule: BusinessRuleRow = response.data.Entity;
                    resolve(businessRule);

                }
            }).catch((error) => error);
    });
}
