import React, { PropsWithChildren } from "react";
import {
  hideProgress,
  showProgress,
} from "../../../../RAFComponents/helpers/AppHelper";
import {
  isNotNullAndUndefined,
  //isNullOrUndefined,
  wrapChildrenWith,
} from "../../../../RAFComponents/helpers/utils";
import { initializeRAFDB } from "../../../../WorkesioRepository";
import {
  RAFBPStepType,
  RAFBPStatus,
  RAFBPStepStatus,
  RAFPageType,
} from "../../../../constants/Common/RMConstants";
import {
  //GetNextStepByPrompt,
  //GetNextStep,
  findNextStep,
  GetbusinessProcessSteps,
  GetBusinessProcessTemplateSteps,
  MergeBPStepAndTemplateStep,
  RetrieveBusinessProcessById,
  RetrieveBusinessProcessTemplate,
  RetrieveRelatedFormData,
} from "../helpers/ProcessHelper";
import { BusinessProcessStepRow } from "../Step/BusinessProcessStepRow";
import { BusinessProcessStepTemplateRow } from "../StepTemplate/BusinessProcessStepTemplateRow";
import { BusinessProcessTemplateRow } from "../Template/BusinessProcessTemplateRow";
import { RAFBPTemplateContext } from "../Template/RAFBPTemplateContextProvider";
import { BusinessProcessRow } from "../Process/BusinessProcessRow";
import {
  RetrieveFormLibraryById,
  getEntityByName,
} from "../../../helpers/RMutils";
import { PageLayoutJM } from "../../RAFPage/PageLayoutJM";
import { EntityRow } from "../../Entity/EntityRow";
import { FormLibraryRow } from "../../../../RAFComponents/models/Common/FormLibraryRow";

interface IState {
  businessProcess?: BusinessProcessRow;
  businessProcessSteps?: BusinessProcessStepRow[];
  mergedBPSteps?: BusinessProcessStepRow[];
  currentStep?: BusinessProcessStepRow;
  businessProcessTemplate?: BusinessProcessTemplateRow;
  bpTemplateSteps?: BusinessProcessStepTemplateRow[];
  noContent: boolean;
  objEntity?: EntityRow;
  formLibrary?: FormLibraryRow;
  formContextValue?: any;
}

interface IProps {
  processUID?: string;
}

export const RAFProcessContext = React.createContext<{
  noContent?: boolean;
  businessProcess?: BusinessProcessRow;
  businessProcessSteps?: BusinessProcessStepRow[];
  mergedBPSteps?: BusinessProcessStepRow[];
  updateStep?: (step?: BusinessProcessStepRow) => void;
  updateProcessSteps?: (steps?: BusinessProcessStepRow[]) => void;
  updateProcess: (
    businessProcess?: BusinessProcessStepRow,
    step?: BusinessProcessStepRow,
    steps?: BusinessProcessStepRow[]
  ) => void;
  setCurrentStep?: (step?: BusinessProcessStepRow) => void;
  currentStep?: BusinessProcessStepRow;
  updateProcessAndSteps?: (
    businessProcessSteps?: BusinessProcessStepRow[]
  ) => void;
  objEntity?: EntityRow;
  formLibrary?: FormLibraryRow;
  businessProcessTemplate?: BusinessProcessTemplateRow;
  bpTemplateSteps?: BusinessProcessStepTemplateRow[];
  formContextValue?: any;
}>({
  noContent: false,
  businessProcess: null,
  businessProcessSteps: [],
  mergedBPSteps: [],
  updateStep: null,
  updateProcess: null,
  updateProcessSteps: null,
  setCurrentStep: null,
  currentStep: null,
  updateProcessAndSteps: null,
  objEntity: null,
  formLibrary: null,
  businessProcessTemplate: null,
  bpTemplateSteps: null,
  formContextValue: null,
});

class RAFProcessContextProvider extends React.Component<
  PropsWithChildren<IProps>,
  IState
> {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      businessProcess: null,
      businessProcessSteps: [],
      mergedBPSteps: [],
      currentStep: null,
      businessProcessTemplate: null,
      bpTemplateSteps: [],
      noContent: false,
      objEntity: null,
      formLibrary: null,
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount = async () => {
    this._isMounted = true;
    await initializeRAFDB();
    let progressDiv = showProgress("body", true);
    RetrieveBusinessProcessById(this.props.processUID).then(
      (businessProcess) => {
        if (isNotNullAndUndefined(businessProcess)) {
          RetrieveBusinessProcessTemplate(businessProcess.BpTemplateUID).then(
            (businessProcessTemplate) => {
              GetbusinessProcessSteps(
                this.props.processUID,
                "all_bp_steps",
                businessProcessTemplate
              ).then((businessProcessSteps) => {
                GetBusinessProcessTemplateSteps(businessProcessTemplate).then(
                  (bpTemplateSteps) => {
                    if (
                      isNotNullAndUndefined(businessProcessTemplate.FormUID)
                    ) {
                      RetrieveFormLibraryById(
                        businessProcessTemplate.FormUID
                      ).then((formLibrary) => {
                        if (isNotNullAndUndefined(formLibrary)) {
                          getEntityByName(formLibrary.Entity).then(
                            (objEntity) => {
                              RetrieveRelatedFormData(
                                businessProcess.UID,
                                objEntity.UID
                              ).then((formContextValue) => {
                                if (this._isMounted) {
                                  hideProgress(progressDiv);
                                  const mergedBPSteps: BusinessProcessStepRow[] =
                                    MergeBPStepAndTemplateStep(
                                      businessProcess,
                                      businessProcessSteps,
                                      businessProcessTemplate,
                                      bpTemplateSteps
                                    );

                                  this.setState({
                                    businessProcess,
                                    businessProcessSteps,
                                    businessProcessTemplate,
                                    bpTemplateSteps,
                                    mergedBPSteps,
                                    currentStep: mergedBPSteps[0],
                                    noContent: false,
                                    formLibrary,
                                    objEntity,
                                    formContextValue,
                                  });
                                }
                              });
                            }
                          );
                        } else {
                          if (this._isMounted) {
                            hideProgress(progressDiv);
                            const mergedBPSteps: BusinessProcessStepRow[] =
                              MergeBPStepAndTemplateStep(
                                businessProcess,
                                businessProcessSteps,
                                businessProcessTemplate,
                                bpTemplateSteps
                              );

                            this.setState({
                              businessProcess,
                              businessProcessSteps,
                              businessProcessTemplate,
                              bpTemplateSteps,
                              mergedBPSteps,
                              currentStep: mergedBPSteps[0],
                              noContent: false,
                            });
                          }
                        }
                      });
                    }
                  }
                );
              });
            }
          );
        } else {
          hideProgress(progressDiv);
          if (this._isMounted) {
            this.setState({ businessProcess, noContent: true });
          }
        }
      }
    );
  };

  updateProcessAndSteps = (businessProcessSteps?: BusinessProcessStepRow[]) => {
    let { businessProcessTemplate } = this.state;
    if (isNotNullAndUndefined(businessProcessSteps)) {
      RetrieveBusinessProcessById(this.props.processUID).then(
        (businessProcess) => {
          if (isNotNullAndUndefined(businessProcess)) {
            if (this._isMounted) {
              this.setState({
                businessProcess,
                businessProcessSteps,
                noContent: false,
              });
            }
          } else {
            if (this._isMounted) {
              this.setState({ businessProcess, noContent: true });
            }
          }
        }
      );
    } else {
      RetrieveBusinessProcessById(this.props.processUID).then(
        (businessProcess) => {
          if (isNotNullAndUndefined(businessProcess)) {
            GetbusinessProcessSteps(
              this.props.processUID,
              "all_bp_steps",
              businessProcessTemplate
            ).then((businessProcessSteps) => {
              if (this._isMounted) {
                this.setState({
                  businessProcess,
                  businessProcessSteps,
                  noContent: false,
                });
              }
            });
          } else {
            if (this._isMounted) {
              this.setState({ businessProcess, noContent: true });
            }
          }
        }
      );
    }
  };

  updateStep = (step?: BusinessProcessStepRow) => {
    const { businessProcess, businessProcessTemplate, bpTemplateSteps } =
      this.state;
    GetbusinessProcessSteps(this.props.processUID, "all_bp_steps").then(
      (businessProcessSteps) => {
        let stepIndex;
        let currentBPStepRow: BusinessProcessStepRow =
          isNotNullAndUndefined(businessProcessSteps) &&
          businessProcessSteps.find((x) => x.UID === step.UID);
        isNotNullAndUndefined(businessProcessSteps) &&
          businessProcessSteps.map((item, Index) => {
            if (item.UID === step.UID) {
              stepIndex = Index;
            }
          });
        let i =
          isNotNullAndUndefined(currentBPStepRow) &&
          (currentBPStepRow.StepStatus ===
            RAFBPStepStatus.AwaitingforApproval ||
            currentBPStepRow.StepStatus === RAFBPStepStatus.AwaitingforReview)
            ? stepIndex
            : ++stepIndex;
        if (
          isNotNullAndUndefined(businessProcessSteps) &&
          isNotNullAndUndefined(stepIndex) &&
          businessProcessSteps.length > i
        ) {
          let currentStep;

          do {
            currentStep = businessProcessSteps[i];
            i++;
          } while (
            currentStep.StepType === RAFBPStepType.Group &&
            businessProcessSteps.length > i
          );

          const mergedBPSteps: BusinessProcessStepRow[] =
            MergeBPStepAndTemplateStep(
              businessProcess,
              businessProcessSteps,
              businessProcessTemplate,
              bpTemplateSteps
            );

          this.setState({ mergedBPSteps, currentStep, businessProcessSteps });
        }
      }
    );
  };

  updateProcess = (
    businessProcess?: BusinessProcessRow,
    currentStep?: BusinessProcessStepRow,
    businessProcessSteps?: BusinessProcessStepRow[]
  ) => {
    const { businessProcessTemplate, bpTemplateSteps } = this.state;
    if (isNotNullAndUndefined(businessProcess)) {
      if (businessProcess.ProcessStatus === RAFBPStatus.Completed) {
        const mergedBPSteps: BusinessProcessStepRow[] =
          MergeBPStepAndTemplateStep(
            businessProcess,
            businessProcessSteps,
            businessProcessTemplate,
            bpTemplateSteps
          );
        this.setState({
          mergedBPSteps,
          currentStep: mergedBPSteps[0],
          businessProcess,
          businessProcessSteps,
        });
      } else {
        const mergedBPSteps: BusinessProcessStepRow[] =
          MergeBPStepAndTemplateStep(
            businessProcess,
            businessProcessSteps,
            businessProcessTemplate,
            bpTemplateSteps
          );
        let nextBPStep;
        if (
          currentStep.StepStatus === RAFBPStepStatus.AwaitingforApproval ||
          currentStep.StepStatus === RAFBPStepStatus.AwaitingforReview
        ) {
          nextBPStep = currentStep;
        } else {
          nextBPStep = findNextStep(
            currentStep,
            mergedBPSteps,
            businessProcessSteps
          );
        }

        this.setState({
          mergedBPSteps,
          currentStep: isNotNullAndUndefined(nextBPStep)
            ? nextBPStep
            : mergedBPSteps[0],
          businessProcess,
          businessProcessSteps,
        });
      }
    } else {
      if (this._isMounted) {
        this.setState({ businessProcess, noContent: true });
      }
    }
  };

  updateProcess1 = (
    businessProcessRow?: BusinessProcessRow,
    currentStep?: BusinessProcessStepRow
  ) => {
    const { businessProcessTemplate, bpTemplateSteps } = this.state;
    let progressDiv = showProgress("body", true);
    RetrieveBusinessProcessById(this.props.processUID).then(
      (businessProcess) => {
        if (isNotNullAndUndefined(businessProcess)) {
          GetbusinessProcessSteps(this.props.processUID, "all_bp_steps").then(
            (businessProcessSteps) => {
              hideProgress(progressDiv);
              if (businessProcess.ProcessStatus === RAFBPStatus.Completed) {
                const mergedBPSteps: BusinessProcessStepRow[] =
                  MergeBPStepAndTemplateStep(
                    businessProcess,
                    businessProcessSteps,
                    businessProcessTemplate,
                    bpTemplateSteps
                  );
                this.setState({
                  mergedBPSteps,
                  currentStep: mergedBPSteps[0],
                  businessProcess,
                  businessProcessSteps,
                });
              } else {
                const mergedBPSteps: BusinessProcessStepRow[] =
                  MergeBPStepAndTemplateStep(
                    businessProcess,
                    businessProcessSteps,
                    businessProcessTemplate,
                    bpTemplateSteps
                  );
                let nextBPStep;
                if (
                  currentStep.StepStatus ===
                    RAFBPStepStatus.AwaitingforApproval ||
                  currentStep.StepStatus === RAFBPStepStatus.AwaitingforReview
                ) {
                  nextBPStep = currentStep;
                } else {
                  nextBPStep = findNextStep(
                    currentStep,
                    mergedBPSteps,
                    businessProcessSteps
                  );
                }

                this.setState({
                  mergedBPSteps,
                  currentStep: isNotNullAndUndefined(nextBPStep)
                    ? nextBPStep
                    : mergedBPSteps[0],
                  businessProcess,
                  businessProcessSteps,
                });
              }
            }
          );
        } else {
          hideProgress(progressDiv);
          if (this._isMounted) {
            this.setState({ businessProcess, noContent: true });
          }
        }
      }
    );
  };

  setCurrentStep = (step?: BusinessProcessStepRow) => {
    this.setState({ currentStep: step });
  };

  updateProcessTemplate = (
    businessProcessTemplate?: BusinessProcessTemplateRow
  ) => {
    this.setState({ businessProcessTemplate });
  };

  updateProcessSteps = (businessProcessSteps?: BusinessProcessStepRow[]) => {
    this.setState({ businessProcessSteps });
  };

  render() {
    return (
      <RAFProcessContext.Provider
        value={{
          noContent: this.state.noContent,
          businessProcess: this.state.businessProcess,
          businessProcessSteps: this.state.businessProcessSteps,
          updateStep: this.updateStep,
          updateProcess: this.updateProcess,
          updateProcessSteps: this.updateProcessSteps,
          setCurrentStep: this.setCurrentStep,
          currentStep: this.state.currentStep,
          mergedBPSteps: this.state.mergedBPSteps,
          updateProcessAndSteps: this.updateProcessAndSteps,
          objEntity: this.state.objEntity,
          formLibrary: this.state.formLibrary,
          businessProcessTemplate: this.state.businessProcessTemplate,
          bpTemplateSteps: this.state.bpTemplateSteps,
          formContextValue: this.state.formContextValue,
        }}
      >
        <RAFBPTemplateContext.Provider
          value={{
            noContent: this.state.noContent,
            businessProcessTemplate: this.state.businessProcessTemplate,
            bpTemplateSteps: this.state.bpTemplateSteps,
          }}
        >
          {wrapChildrenWith(this.props.children, {})}
        </RAFBPTemplateContext.Provider>
      </RAFProcessContext.Provider>
    );
  }
}
export default RAFProcessContextProvider;
