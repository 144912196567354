import { MapperConfiguration, MappingPair } from "@dynamic-mapper/mapper";
import { getBPStepProcessStarter } from "../helpers/ProcessHelper";
import {
  BusinessProcessStepRow,
  IBusinessProcessStepDto,
} from "./BusinessProcessStepRow";

export const ConvertBPStepRowToBPStepDto = (
  BusinessProcessRow: BusinessProcessStepRow
): IBusinessProcessStepDto => {
  const BPStepRowToIBPStepDto = new MappingPair<
    BusinessProcessStepRow,
    IBusinessProcessStepDto
  >();
  const BPStepRowToBPStepDtoConfiguration = new MapperConfiguration((cfg) => {
    cfg
      .createAutoMap(BPStepRowToIBPStepDto, {})
      .forSourceMember("ProcessStarter", (opt) => opt.ignore())
      .forSourceMember("IsChildStep", (opt) => opt.ignore())
      .forSourceMember("SelectedLevel", (opt) => opt.ignore())
      .forSourceMember("CopySettingsFromUID", (opt) => opt.ignore())
      .forSourceMember("DecisionType", (opt) => opt.ignore());
  });

  const mapper = BPStepRowToBPStepDtoConfiguration.createMapper();
  return mapper.map(BPStepRowToIBPStepDto, BusinessProcessRow);
};

export const ConvertBPStepDtoToBPStepRow = (
  BusinessProcessDto: IBusinessProcessStepDto
): BusinessProcessStepRow => {
  const IBPStepDtoToBPStepRow = new MappingPair<
    IBusinessProcessStepDto,
    BusinessProcessStepRow
  >();
  const IBPStepDtoToBPStepRowConfiguration = new MapperConfiguration((cfg) => {
    cfg.createAutoMap(IBPStepDtoToBPStepRow, {
      ProcessStarter: (opt) =>
        opt.mapFrom((src) => getBPStepProcessStarter(src)),
      IsChildStep: (opt) => false,
      SelectedLevel: (opt) => opt.mapFrom((src) => null),
      CopySettingsFromUID: (opt) => opt.mapFrom((src) => null),
      DecisionType: (opt) => opt.mapFrom((src) => null),
    });
  });

  const mapper = IBPStepDtoToBPStepRowConfiguration.createMapper();
  return mapper.map(IBPStepDtoToBPStepRow, BusinessProcessDto);
};
