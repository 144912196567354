import { DialogUtility } from "@syncfusion/ej2-popups";
import * as React from "react";
import { PropsWithChildren, useContext } from "react";
import { FormRenderProps } from "react-final-form";
import { isNotNullAndUndefined } from "../../../../RAFComponents/helpers/utils";
import RAFChoiceOption from "../../../../RAFComponents/Inputs/RAFChoiceOption";
import RAFStartRatingInput from "../../../../RAFComponents/Inputs/RAFStartRatingInput";
import RAFTextArea from "../../../../RAFComponents/Inputs/RAFTextArea";
import RAFTextBox from "../../../../RAFComponents/Inputs/RAFTextBox";
import { RAFFormContext } from "../../../../RAFComponents/Inputs/RFFUtils";
import { RAFUIType } from "../../../../RAFComponents/models/Common/RAFDataType";
import RMCheckBoxList from "../../../RMForms/RMCheckBoxList";
import RMRadioButtonList from "../../../RMForms/RMRadioButtonList";
import { RAFQuestionModel } from "../../RAFPage/PageLayoutJM";

interface IProps {
  formDataFieldName?: string;
  formDataModelFieldName?: string;
  validateFields?: boolean;
  allowSubmit?: boolean;
  autoSave?: boolean;
}

function RAFQuestionDataModelRender({ ...props }: PropsWithChildren<IProps>) {
  const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);
  let formDataModel: RAFQuestionModel = null;

  if (
    isNotNullAndUndefined(rafFormContextValue) &&
    isNotNullAndUndefined(rafFormContextValue.values)
  ) {
    formDataModel = rafFormContextValue.values[props.formDataModelFieldName];
  }

  let promptDialog;

  const onValueChanged = (field, value, promptUser) => {
    if (
      isNotNullAndUndefined(rafFormContextValue) &&
      isNotNullAndUndefined(rafFormContextValue.form) &&
      isNotNullAndUndefined(rafFormContextValue.form.mutators)
    ) {
      setTimeout(() => {
        rafFormContextValue.form.mutators.setValue(field, value);
        if (
          isNotNullAndUndefined(props.allowSubmit) &&
          props.allowSubmit === true
        ) {
          if (promptUser === true) {
            promptDialog = DialogUtility.confirm({
              animationSettings: { effect: "Fade" },
              cancelButton: { text: "No" },
              closeOnEscape: false,
              content: "Are you sure want to complete step with given value?",
              okButton: { text: "Yes", click: submitFormData },
              title: "Complete Step",
              position: { X: "center", Y: "center" },
              cssClass: "alert-dialog",
            });
          } else {
            submitFormData();
          }
        }
      }, 500);
    }
  };

  const submitFormData = () => {
    if (isNotNullAndUndefined(promptDialog)) {
      promptDialog.hide();
    }
    rafFormContextValue.form.submit();
  };

  const renderformComponent = (formDataModel?: RAFQuestionModel) => {
    let outVal = <div></div>;

    if (isNotNullAndUndefined(formDataModel)) {
      let valueJson = formDataModel.QuestionOptions;
      let questionSettings = isNotNullAndUndefined(
        formDataModel.QuestionSettings
      )
        ? formDataModel.QuestionSettings
        : null;

      if (
        isNotNullAndUndefined(questionSettings) &&
        isNotNullAndUndefined(questionSettings.DisplayType) &&
        formDataModel.QuestionSettings.DisplayType === RAFUIType.RadioButton
      ) {
        outVal = (
          <RMRadioButtonList
            field={props.formDataFieldName + "." + formDataModel.Name}
            label="Answer"
            showLabel={false}
            uitype={"customButton"}
            labelClassName="col-12 mb-2 px-1 py-1"
            inputFieldClassName="bp-Input col-12"
            formGroupClassName="mb-0"
            radioBtnClassName="custom-radio-btn-m-0 col"
            {...(isNotNullAndUndefined(props.autoSave) &&
              props.autoSave === true
              ? {
                onChanged: (value) =>
                  onValueChanged(
                    props.formDataFieldName + "." + formDataModel.Name,
                    value,
                    false
                  ),
              }
              : {})}
          >
            {valueJson &&
              valueJson.map((opt, index) => {
                return (
                  <RAFChoiceOption
                    key={index}
                    label={opt.Title}
                    value={opt.Value}
                  />
                );
              })}
          </RMRadioButtonList>
        );
      } else if (
        isNotNullAndUndefined(questionSettings) &&
        isNotNullAndUndefined(questionSettings.DisplayType) &&
        questionSettings.DisplayType === RAFUIType.SimpleDropdown
      ) {
        outVal = (
          <RMCheckBoxList
            field={props.formDataFieldName + "." + formDataModel.Name}
            label="Answer"
            showLabel={false}
            labelClassName="col-12 mb-2 px-1 py-1"
            inputFieldClassName="bp-Input col-12"
            formGroupClassName={"mb-0"}
            uitype="vertical"
            {...(isNotNullAndUndefined(props.autoSave) &&
              props.autoSave === true
              ? {
                onChanged: (value) =>
                  onValueChanged(
                    props.formDataFieldName + "." + formDataModel.Name,
                    value,
                    false
                  ),
              }
              : {})}
          >
            {valueJson &&
              valueJson.map((opt, index) => {
                return (
                  <RAFChoiceOption
                    key={index}
                    label={opt.Title}
                    value={opt.Value}
                  />
                );
              })}
          </RMCheckBoxList>
        );
      } else if (
        isNotNullAndUndefined(questionSettings) &&
        isNotNullAndUndefined(questionSettings.DisplayType) &&
        questionSettings.DisplayType === RAFUIType.Multiline
      ) {
        outVal = (
          <RAFTextArea
            field={props.formDataFieldName + "." + formDataModel.Name}
            showLabel={false}
            formGroupClassName={"mb-0"}
            // onChanged={(value) => {
            //   onValueChanged(
            //     props.formDataFieldName + "." + formDataModel.Name,
            //     value
            //   );
            // }}
            {...(isNotNullAndUndefined(props.autoSave) &&
              props.autoSave === true
              ? {
                onInputBlur: (value) =>
                  onValueChanged(
                    props.formDataFieldName + "." + formDataModel.Name,
                    value,
                    true
                  ),
              }
              : {})}
          />
        );
      } else if (
        isNotNullAndUndefined(questionSettings) &&
        isNotNullAndUndefined(questionSettings.DisplayType) &&
        questionSettings.DisplayType === RAFUIType.Singleline
      ) {
        outVal = (
          <RAFTextBox
            field={props.formDataFieldName + "." + formDataModel.Name}
            showLabel={false}
            formGroupClassName={"mb-0"}
            // onChanged={(value) => {
            //   onValueChanged(
            //     props.formDataFieldName + "." + formDataModel.Name,
            //     value
            //   );
            // }}
            {...(isNotNullAndUndefined(props.autoSave) &&
              props.autoSave === true
              ? {
                onChanged: (value) =>
                  onValueChanged(
                    props.formDataFieldName + "." + formDataModel.Name,
                    value,
                    true
                  ),
              }
              : {})}
          />
        );
      } else if (
        isNotNullAndUndefined(questionSettings) &&
        isNotNullAndUndefined(questionSettings.DisplayType) &&
        questionSettings.DisplayType === RAFUIType.StarRating
      ) {
        outVal = (
          <RAFStartRatingInput
            field={props.formDataFieldName + "." + formDataModel.Name}
            showLabel={false}
            formGroupClassName={"mb-0"}
            length={formDataModel.QuestionSettings.StarCount}
            allowSubmit={props.allowSubmit}
            autoSave={props.autoSave}
          />
        );
      }
    }
    return outVal;
  };

  if (isNotNullAndUndefined(formDataModel)) {
    return (
      <div className="col-12">
        {renderformComponent(formDataModel)}
      </div>
    );
  } else {
    return;
  }
}
export default RAFQuestionDataModelRender;
