import { MapperConfiguration, MappingPair } from "@dynamic-mapper/mapper";
import { isNotNullAndUndefined } from "../../../../RAFComponents/helpers/utils";
import { getBPStepTemplateRelatedModules } from "../helpers/ProcessHelper";
import {
  BusinessProcessStepTemplateRow,
  IBusinessProcessStepTemplateDto,
} from "./BusinessProcessStepTemplateRow";
import { RAFBPStepType } from "../../../../constants/Common/RMConstants";
import { RAFBPStepTypeDisplayName } from "../../../../constants/BPType/RAFBPConstants";

export const ConvertBPStepTemplateRowToBPStepTemplateDto = (
  BusinessProcessTemplateRow: BusinessProcessStepTemplateRow
): IBusinessProcessStepTemplateDto => {
  const BPStepTemplateRowToIBPStepTemplateDto = new MappingPair<
    BusinessProcessStepTemplateRow,
    IBusinessProcessStepTemplateDto
  >();
  const BPStepTemplateRowToBPStepTemplateDtoConfiguration =
    new MapperConfiguration((cfg) => {
      cfg
        .createAutoMap(BPStepTemplateRowToIBPStepTemplateDto, {})
        .forSourceMember("AdditionalInfo", (opt) => opt.ignore())
        //.forSourceMember("ProcessStarter", (opt) => opt.ignore())
        .forSourceMember("RememberSelection", (opt) => opt.ignore())
        .forSourceMember("IsChildStep", (opt) => opt.ignore())
        .forSourceMember("SelectedLevel", (opt) => opt.ignore())
        .forSourceMember("CopySettingsFromUID", (opt) => opt.ignore())
        .forSourceMember("DecisionType", (opt) => opt.ignore())
        .forSourceMember("Email", (opt) => opt.ignore())
        .forSourceMember("RelatedModule", (opt) => opt.ignore())
        .forSourceMember("ManageFlowRule", (opt) => opt.ignore())
        .forSourceMember("CollectData", (opt) => opt.ignore())
        .forSourceMember("TaskStepType", (opt) => opt.ignore())
        .forSourceMember("FormInputType", (opt) => opt.ignore())
        .forSourceMember("EnableAutoComplete", (opt) => opt.ignore())
        .forSourceMember("ManageFlowRule", (opt) => opt.ignore());
    });

  const mapper =
    BPStepTemplateRowToBPStepTemplateDtoConfiguration.createMapper();
  return mapper.map(
    BPStepTemplateRowToIBPStepTemplateDto,
    BusinessProcessTemplateRow
  );
};

export const ConvertBPStepTemplateDtoToBPStepTemplateRow = (
  BusinessProcessTemplateDto: IBusinessProcessStepTemplateDto
): BusinessProcessStepTemplateRow => {
  const IBPStepTemplateDtoToBPStepTemplateRow = new MappingPair<
    IBusinessProcessStepTemplateDto,
    BusinessProcessStepTemplateRow
  >();
  const IBPStepTemplateDtoToBPStepTemplateRowConfiguration =
    new MapperConfiguration((cfg) => {
      cfg.createAutoMap(IBPStepTemplateDtoToBPStepTemplateRow, {
        AdditionalInfo: (opt) =>
          opt.mapFrom((src) =>
            isNotNullAndUndefined(src) && isNotNullAndUndefined(src.FormUID)
              ? true
              : false
          ),
        RememberSelection: (opt) =>
          opt.mapFrom((src) =>
            isNotNullAndUndefined(src) && isNotNullAndUndefined(src.FormUID)
              ? true
              : false
          ),
        // ProcessStarter: opt => opt.mapFrom(src => (isNotNullAndUndefined(src) &&
        //     isNotNullAndUndefined(src.AssignTeamUID) && IsNullOrWhiteSpace(src.AssigneeUID) ? 'Team' :
        //     isNotNullAndUndefined(src.Assignee) && src.Assignee === RAFProcessConstant.ProcessStarter ? RAFProcessConstant.ProcessStarter :
        //         isNotNullAndUndefined(src.AssigneeUID) && src.IsApproval ? 'Approver' :
        //             isNotNullAndUndefined(src.AssigneeUID) ? 'User' :
        //                 'None')),
        // ProcessStarter: (opt) =>
        //   opt.mapFrom((src) => getBPStepTemplateProcessStarter(src)),
        IsChildStep: (opt) => false,
        SelectedLevel: (opt) => opt.mapFrom((src) => null),
        DecisionType: (opt) => opt.mapFrom((src) => null),
        CopySettingsFromUID: (opt) => opt.mapFrom((src) => null),
        Email: (opt) => opt.mapFrom((src) => null),
        RelatedModule: (opt) =>
          opt.mapFrom((src) => getBPStepTemplateRelatedModules(src)),
        CollectData: (opt) =>
          opt.mapFrom((src) =>
            isNotNullAndUndefined(src) && isNotNullAndUndefined(src.FormUID)
              ? true
              : false
          ),
        ManageFlowRule: (opt) =>
          opt.mapFrom((src) =>
            isNotNullAndUndefined(src) &&
            isNotNullAndUndefined(src.StepType) &&
            src.StepType === RAFBPStepType.Decision
              ? true
              : false
          ),
        TaskStepType: (opt) =>
          opt.mapFrom((src) =>
            isNotNullAndUndefined(src) &&
            isNotNullAndUndefined(src.StepType) &&
            src.StepType === RAFBPStepType.Approval &&
            src.IsApproval === true
              ? RAFBPStepType.Approval
              : RAFBPStepType.Task
          ),
        FormInputType: (opt) =>
          opt.mapFrom((src) =>
            isNotNullAndUndefined(src) &&
            isNotNullAndUndefined(src.StepType) &&
            src.StepType === RAFBPStepType.Decision &&
            isNotNullAndUndefined(src.FormUID)
              ? RAFBPStepTypeDisplayName.Form
              : RAFBPStepTypeDisplayName.Decision
          ),
        EnableAutoComplete: (opt) =>
          opt.mapFrom((src) =>
            isNotNullAndUndefined(src) &&
            isNotNullAndUndefined(src.StepType) &&
            src.StepType === RAFBPStepType.AutoComplete
              ? true
              : false
          ),
      });
    });

  const mapper =
    IBPStepTemplateDtoToBPStepTemplateRowConfiguration.createMapper();
  return mapper.map(
    IBPStepTemplateDtoToBPStepTemplateRow,
    BusinessProcessTemplateDto
  );
};
