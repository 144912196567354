import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
//import RowRender from "./RowRender";
import DOMPurify from "dompurify";
import * as React from "react";
import { PropsWithChildren } from "react";
import { FormRenderProps } from "react-final-form";
import {
    ContentType,
    } from "../../../../constants/Common/Constants";
import {
    getSaveRequest,
    isNotNullAndUndefined
} from "../../../../RAFComponents/helpers/utils";
import RAFForm from "../../../../RAFComponents/Inputs/RAFForm";
import RAFImageInput from "../../../../RAFComponents/Inputs/RAFImageInput";
import RAFSignaturePad from "../../../../RAFComponents/Inputs/RAFSignaturePad";
import RAFVideoInput from "../../../../RAFComponents/Inputs/RAFVideoInput";
import { FormLibraryRow } from "../../../../RAFComponents/models/Common/FormLibraryRow";
import { RAFDataType } from "../../../../RAFComponents/models/Common/RAFDataType";
import RAFAttributeRelatedListProvider from "../../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import * as repositoryActions from "../../../../RAFComponents/store/actions/repositoryActions";
import RAFEntityProvider from "../../../../RAFModules/Common/Providers/RAFEntityProvider";
import RAFPageLayoutProvider from "../../../../RAFModules/Common/Providers/RAFPageLayoutProvider";
import RAFPageLayout from "../../../../RAFModules/Common/RAFPageLayout";
import { EntityRow } from "../../Entity/EntityRow";
import {
    RAFComponentFormModel, RAFComponentHeadingModel, RAFComponentImageModel, RAFComponentInputModel, RAFComponentModel, RAFComponentRichTextModel,
    RAFComponentSignatureModel, RAFComponentVideoModel
} from "../../RAFPage/PageLayoutJM";
import {
    isRAFComponentFormModel,
    isRAFComponentHeadingModel, isRAFComponentImageModel,
    isRAFComponentRichTextModel,
    isRAFComponentSignatureModel, isRAFComponentVideoModel
} from "../../RAFPage/RAFPageHelper";
import RAFComponentFormModelRenderer from "./RAFComponentFormModelRenderer";
import RAFFormTemplateRenderer from "./RAFFormTemplateRenderer";
import { getURLPrefixByModuleName } from "../../../../helpers/Common/URLPrefixByModuleName";


interface IProps {
    formDataFieldName?: string;
    validateFields?: boolean;
    data?: RAFComponentModel;
    allEntities?: EntityRow[];
    allForms?: FormLibraryRow[];
    showLabel?: boolean;
    isReadonly?: boolean;
}

function RAFComponentModelRenderer({ ...props }: PropsWithChildren<IProps>) {
    const objComponent: RAFComponentModel = isNotNullAndUndefined(props.data)
        ? props.data
        : null;

    /* link form code starts here */
    const openLinkForm = (index) => {
        let retVal = document.querySelectorAll('div[id^="linkFormDiv_"]');

        let parentDiv = document.querySelector(
            "#processDetailsDialog_dialog-content"
        );
        parentDiv.classList.add("overflow-hidden");
        let overlayDiv = document.createElement("div");
        overlayDiv.className = "e-dlg-overlay settingsOverlay";

        overlayDiv.id = "linkFormOverlayDiv_" + index;

        parentDiv.appendChild(overlayDiv);

        let linkFormDiv = document.querySelectorAll('div[id^="linkFormDiv_"]');
        if (isNotNullAndUndefined(retVal)) {
            retVal.forEach((x) => {
                x.classList.remove("active");
                if (isNotNullAndUndefined(index) && x.id === "linkFormDiv_" + index) {
                    x.classList.add("active");
                }
            });
        }
        if (isNotNullAndUndefined(linkFormDiv)) {
            linkFormDiv.forEach((x) => {
                x.classList.remove("active");
                if (isNotNullAndUndefined(index) && x.id === "linkFormDiv_" + index) {
                    x.classList.add("active");
                }
            });
        }
    };

    const closeLinkForm = (index) => {
        let retVal = document.querySelectorAll('div[id^="linkFormDiv_"]');
        let parentDiv = document.querySelector(
            "#processDetailsDialog_dialog-content"
        );
        parentDiv.classList.remove("overflow-hidden");

        let overlayDiv = document.querySelector("#linkFormOverlayDiv_" + index);
        if (isNotNullAndUndefined(overlayDiv)) {
            overlayDiv.remove();
        }
        let linkFormDiv = document.querySelectorAll('div[id^="linkFormDiv_"]');
        if (isNotNullAndUndefined(retVal)) {
            retVal.forEach((x) => {
                x.classList.remove("active");
                if (isNotNullAndUndefined(index) && x.id === "linkFormDiv_" + index) {
                    x.classList.remove("active");
                }
            });
        }
        if (isNotNullAndUndefined(linkFormDiv)) {
            linkFormDiv.forEach((x) => {
                x.classList.remove("active");
                if (isNotNullAndUndefined(index) && x.id === "linkFormDiv_" + index) {
                    x.classList.remove("active");
                }
            });
        }
    };

    const onSubmitLinkForm = (value) => {
        let objData = value;
        let moduleName;
        if (isRAFComponentFormModel(objComponent.component)) {
            let component = objComponent.component;
            moduleName = getURLPrefixByModuleName(component.EntityName);
        }
        let url = `${moduleName}/Save`;
        let id =
            isNotNullAndUndefined(objData) && isNotNullAndUndefined(objData["UID"])
                ? objData["UID"]
                : null;
        repositoryActions
            .postDataAndGetResponse(
                url,
                getSaveRequest(objData, id),
                { ...this.props },
                ContentType.applicationJson
            )
            .then((response) => {
                if (response !== null && response !== undefined) {
                    if (
                        response.data.EntityId !== null &&
                        response.data.EntityId !== undefined &&
                        response.data.EntityId !== ""
                    ) {
                        console.log(response);
                    }
                }
            })
            .catch((error) => error);
    };
    // const objectContext = React.useContext(RAFObjectContext);
    // const rafObject = objectContext["rafObject"];
    /* link form code starts here */

    const formComponentRender = (
        componentModel?:
            | RAFComponentHeadingModel
            | RAFComponentVideoModel
            | RAFComponentImageModel
            | RAFComponentRichTextModel
            | RAFComponentSignatureModel
            | RAFComponentFormModel
            | RAFComponentInputModel
    ) => {
        let outVal;
        let moduleName;
        if (isRAFComponentFormModel(componentModel)) {
            if (componentModel.DataType === RAFDataType.Component) {
                outVal = (
                    <RAFComponentFormModelRenderer
                        formDataFieldName={props.formDataFieldName}
                        data={componentModel}
                        showLabel={props.showLabel}
                    ></RAFComponentFormModelRenderer>
                );
            }
            if (componentModel.DataType === RAFDataType.FormTemplate) {
                outVal = (
                    <RAFFormTemplateRenderer
                        formUID={componentModel.UID}
                        validateFields
                        isReadonly={props.isReadonly}
                        showLabel
                    />
                );
            }
            if (componentModel.DataType === RAFDataType.Module) {
                //let entityRow: EntityRow = props.allEntities && props.allEntities.find(x => x.UID === componentModel.EntityUID);
                //moduleName = isNotNullAndUndefined(entityRow) ? entityRow.EntityName : null;
                moduleName = componentModel.EntityName;
                outVal = (
                    isNotNullAndUndefined(moduleName) && (
                        <RAFEntityProvider moduleName={moduleName}>
                            <RAFAttributeRelatedListProvider moduleName={moduleName}>
                                <RAFPageLayoutProvider
                                    pagelayoutUID={componentModel.PageLayoutId}
                                    prefix={"FormData." + componentModel.EntityName}
                                >
                                    <RAFPageLayout />
                                </RAFPageLayoutProvider>
                            </RAFAttributeRelatedListProvider>
                        </RAFEntityProvider>
                    )
                );
            }

            if (componentModel.DataType === RAFDataType.Form) {
                let formLibraryRow: FormLibraryRow =
                    props.allForms &&
                    props.allForms.find((x) => x.Entity === componentModel.EntityName);
                moduleName = isNotNullAndUndefined(formLibraryRow)
                    ? formLibraryRow.Entity
                    : null;
                outVal = (
                    isNotNullAndUndefined(moduleName) && (
                        <RAFEntityProvider moduleName={moduleName}>
                            <RAFAttributeRelatedListProvider moduleName={moduleName}>
                                <RAFPageLayoutProvider
                                    pagelayoutUID={componentModel.PageLayoutId}
                                    prefix={"FormData." + formLibraryRow.UID}
                                >
                                    <RAFPageLayout />
                                </RAFPageLayoutProvider>
                            </RAFAttributeRelatedListProvider>
                        </RAFEntityProvider>
                    )
                );
            }
            if (componentModel.DataType === RAFDataType.EmbedForm) {
                if (componentModel.FormType === "Entity") {
                    moduleName = componentModel.EntityName;
                    outVal = (
                        isNotNullAndUndefined(moduleName) && (
                            <RAFEntityProvider moduleName={moduleName}>
                                <RAFAttributeRelatedListProvider moduleName={moduleName}>
                                    <RAFPageLayoutProvider
                                        pagelayoutUID={componentModel.PageLayoutId}
                                        prefix={"FormData." + componentModel.EntityName}
                                    >
                                        <RAFPageLayout />
                                    </RAFPageLayoutProvider>
                                </RAFAttributeRelatedListProvider>
                            </RAFEntityProvider>
                        )
                    );
                } else if (componentModel.FormType === "Form") {
                    let formLibraryRow: FormLibraryRow =
                        props.allForms &&
                        props.allForms.find((x) => x.Entity === componentModel.EntityName);
                    moduleName = isNotNullAndUndefined(formLibraryRow)
                        ? formLibraryRow.Entity
                        : null;
                    outVal = (
                        isNotNullAndUndefined(moduleName) && (
                            <RAFEntityProvider moduleName={moduleName}>
                                <RAFAttributeRelatedListProvider moduleName={moduleName}>
                                    <RAFPageLayoutProvider
                                        pagelayoutUID={componentModel.PageLayoutId}
                                        prefix={"FormData." + formLibraryRow.UID}
                                    >
                                        <RAFPageLayout />
                                    </RAFPageLayoutProvider>
                                </RAFAttributeRelatedListProvider>
                            </RAFEntityProvider>
                        )
                    );
                }
            }

            if (componentModel.DataType === RAFDataType.LinkForm) {
                if (componentModel.FormType === "Entity") {
                    let rafObjForm: FormRenderProps | null;
                    let initialValues = {};
                    // initialValues[componentModel.RelatedAttribute] =
                    //   rafObject[componentModel.RelatedAttribute];
                    // initialValues[componentModel.RelatedAttribute + "UID"] =
                    //   rafObject["UID"];
                    moduleName = componentModel.EntityName;
                    outVal = (
                        <div>
                            <ButtonComponent
                                type="button"
                                cssClass="e-flat e-primary primary-btn me-2"
                                onClick={() => openLinkForm(componentModel.Name)}
                            >
                                Show Form
                            </ButtonComponent>
                            <div
                                id={"linkFormDiv_" + componentModel.Name}
                                className="details-right-section"
                            >
                                <RAFForm
                                    {...(initialValues ? { initialValues: initialValues } : {})}
                                    formRef={(f) => (rafObjForm = f)}
                                    onSubmit={onSubmitLinkForm}
                                // {...(componentModel.IsUpdate === true
                                //     ? { primaryKey: businessProcessStepTemplateRow.UID }
                                //     : {})}
                                >
                                    <div className="e-dlg-header-content">
                                        <div className="e-dlg-header">Link Form</div>
                                        <ButtonComponent
                                            type="button"
                                            cssClass="e-flat e-primary primary-btn me-2"
                                            onClick={() => rafObjForm && rafObjForm.form.submit()}
                                        >
                                            Save
                                        </ButtonComponent>
                                        <ButtonComponent
                                            cssClass="primary-custom-button"
                                            iconCss="fas fa-xmark"
                                            onClick={() => closeLinkForm(componentModel.Name)}
                                            type="button"
                                        ></ButtonComponent>
                                    </div>
                                    <div className="e-dlg-content ">
                                        <div className="col-12 px-4 py-3">
                                            {isNotNullAndUndefined(moduleName) && (
                                                <RAFEntityProvider moduleName={moduleName}>
                                                    <RAFAttributeRelatedListProvider
                                                        moduleName={moduleName}
                                                    >
                                                        <RAFPageLayoutProvider
                                                            pagelayoutUID={componentModel.PageLayoutId}
                                                        //prefix={"FormData." + componentModel.EntityName}
                                                        >
                                                            <RAFPageLayout />
                                                        </RAFPageLayoutProvider>
                                                    </RAFAttributeRelatedListProvider>
                                                </RAFEntityProvider>
                                            )}
                                        </div>
                                    </div>
                                    <div className="e-dlg-footerContent">
                                        <ButtonComponent
                                            type="submit"
                                            cssClass="e-flat e-primary primary-btn me-2"
                                            onClick={() => rafObjForm && rafObjForm.form.submit()}
                                        >
                                            Save
                                        </ButtonComponent>
                                    </div>
                                </RAFForm>
                            </div>
                        </div>
                    );
                } else if (componentModel.FormType === "Form") {
                    let formLibraryRow: FormLibraryRow =
                        props.allForms &&
                        props.allForms.find((x) => x.Entity === componentModel.EntityName);
                    moduleName = isNotNullAndUndefined(formLibraryRow)
                        ? formLibraryRow.Entity
                        : null;
                    outVal = (
                        isNotNullAndUndefined(moduleName) && (
                            <RAFEntityProvider moduleName={moduleName}>
                                <RAFAttributeRelatedListProvider moduleName={moduleName}>
                                    <RAFPageLayoutProvider
                                        pagelayoutUID={componentModel.PageLayoutId}
                                        prefix={"FormData." + formLibraryRow.UID}
                                    >
                                        <RAFPageLayout />
                                    </RAFPageLayoutProvider>
                                </RAFAttributeRelatedListProvider>
                            </RAFEntityProvider>
                        )
                    );
                }
            }
        }
        if (isRAFComponentHeadingModel(componentModel)) {
            outVal = (
                <div>
                    <h4>{componentModel.Data}</h4>
                </div>
            );
        }
        if (isRAFComponentVideoModel(componentModel)) {
            outVal = (
                <div>
                    <RAFVideoInput
                        showLabel={false}
                        field={componentModel.Name}
                        url={componentModel.URL}
                    />
                </div>
            );
        }
        if (isRAFComponentImageModel(componentModel)) {
            outVal = (
                <div>
                    <RAFImageInput
                        showLabel={false}
                        field={componentModel.Name}
                        url={componentModel.URL}
                    />
                </div>
            );
        }
        /*if (isRAFComponentImageModel(componentModel)) {
                outVal = <div>
                    <RAFImageInput
                        showLabel={false}
                        field={componentModel.Name}
                        url={componentModel.URL} />
                </div>
            }*/
        if (isRAFComponentRichTextModel(componentModel)) {
            outVal = (
                <div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html:
                                componentModel.DefaultValue !== null
                                    ? DOMPurify.sanitize(componentModel.DefaultValue)
                                    : undefined,
                        }}
                    ></div>
                </div>
            );
        }
        if (isRAFComponentSignatureModel(componentModel)) {
            if (componentModel.DataType === RAFDataType.Signature) {
                outVal = (
                    <RAFSignaturePad
                        showLabel={
                            false
                        }
                        field={isNotNullAndUndefined(props.formDataFieldName) ? props.formDataFieldName + '.' + componentModel.Name : componentModel.Name}
                        disabled={props.isReadonly}
                    />
                );
            }
        }
        return outVal
    };
    if (isNotNullAndUndefined(objComponent)) {
        return <div className="col-12">{formComponentRender(objComponent.component)}</div>
    } else {
        return null
    }
}
export default RAFComponentModelRenderer;
