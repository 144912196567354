import * as React from "react";
import { PropsWithChildren } from "react";
import { isNotNullAndUndefined } from "../../../../RAFComponents/helpers/utils";
import { RAFComponentFormModel } from "../../RAFPage/PageLayoutJM";
import RAFInputModelRenderer from "./RAFInputModelRenderer";
//import RowRender from "./RowRender";

interface IProps {
    formDataFieldName?: string;
    validateFields?: boolean;
    data?: RAFComponentFormModel;
    showLabel?: boolean;
}

function RAFComponentFormModelRenderer(
    { ...props }: PropsWithChildren<IProps>,
) {

    const objComponent: RAFComponentFormModel = isNotNullAndUndefined(props.data) ? props.data : null;

    if (isNotNullAndUndefined(objComponent)) {
        return (
            <div>
                {
                    objComponent.Components.map((objItem,index) => {
                        return (<div key={index}>
                            <div className="col-12 mb-3">
                                <RAFInputModelRenderer key={index} data={objItem} formDataFieldName={props.formDataFieldName} validateFields={props.validateFields} showLabel={props.showLabel} />
                            </div>
                        </div>)
                    })
                }

            </div>
        );
    }
    else {
        return null;
    }
}


export default RAFComponentFormModelRenderer;