import { RuleProperties } from "json-rules-engine";
import moment from "moment";
import * as R from "ramda";
import { RRule } from "rrule";
import { msalInstance } from "../../../..";
import {
  RAFBPAction,
  RAFBPStepTemplateStatus,
  RAFProcessFormPropertyName,
} from "../../../../constants/BPType/RAFBPConstants";
import {
  ContentType,
  RAFActionStatus,
  RAFBusinessAction,
} from "../../../../constants/Common/Constants";
import {
  RAFEntityName,
  RAFModuleName,
} from "../../../../constants/Common/EntityConstants";
import {
  RAFBPStatus,
  RAFBPStepStatus,
  RAFBPStepType,
  RAFDeadlineCalculation,
  RAFDeadlineUnit,
  RAFExecutionOrder,
  RAFFormFieldName,
  RAFProcessAssigneeType,
} from "../../../../constants/Common/RMConstants";
import { getURLPrefixByModuleName } from "../../../../helpers/Common/URLPrefixByModuleName";
import {
  hideProgress,
  RetrieveRecord,
  showProgress,
} from "../../../../RAFComponents/helpers/AppHelper";
import {
  deepMerge,
  flattenArray,
  getSaveRequest,
  Guid,
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
  IsNullOrWhiteSpace,
  propertyOf,
  setZeroHours,
  sortItem,
} from "../../../../RAFComponents/helpers/utils";
import {
  FormLibraryRow,
  RAFFormType,
} from "../../../../RAFComponents/models/Common/FormLibraryRow";
import { ListServiceRequest } from "../../../../RAFComponents/models/Common/ListRequest";
import { SaveRequest } from "../../../../RAFComponents/models/Common/ListResponse";
import { RAFDataType } from "../../../../RAFComponents/models/Common/RAFDataType";
import {
  RAFCustomFilter,
  RAFCustomOperator,
} from "../../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import * as repositoryActions from "../../../../RAFComponents/store/actions/repositoryActions";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../../RAFComponents/Utility/RAFToastComponent";
import { runRulesEngine1 } from "../../../../RAFFormBuilder/JsonRulesEngine";
import { RAFActivityAction } from "../../../../RAFModules/ActiveContacts/Activity/ActivityHelper";
import { ActivityRow } from "../../../../RAFModules/ActiveContacts/Activity/ActivityRow";
import { completeTaskAndRemoveFromFocusList } from "../../../../RAFModules/ActiveContacts/Task/TaskHelper";
import { TaskRow } from "../../../../RAFModules/ActiveContacts/Task/TaskRow";
import {
  getAllTeams,
  getMyTeams,
} from "../../../../RAFModules/ActiveContacts/UserGroup/UserGroupServiceHelper";
import { BpStepTemplateRepository } from "../../../../WorkesioRepository/BusinessProcess/BpStepTemplateRepository";
import { BpTemplateRepository } from "../../../../WorkesioRepository/BusinessProcess/BpTemplateRepository";
import { BusinessProcessRepository } from "../../../../WorkesioRepository/BusinessProcess/BusinessProcessRepository";
import { BusinessProcessStepRepository } from "../../../../WorkesioRepository/BusinessProcess/BusinessProcessStepRepository";
import * as RAFDatabase from "../../../../WorkesioRepository/index";
import {
  flattenFormComponents,
  RetrieveFormLibraryById,
} from "../../../helpers/RMutils";
import { BusinessAction } from "../../BusinessRules/BusinessAction";
import { ConvertBusinessRuleJMToRuleProperties } from "../../BusinessRules/BusinessRuleHelper";
import { BusinessRuleRow } from "../../BusinessRules/BusinessRuleRow";
import { EntityRow } from "../../Entity/EntityRow";
import { FormDataRow } from "../../FormLibrary/FormDataRow";
import { RAFDataModelRow, RAFFormDataModel } from "../../RAFPage/PageLayoutJM";
import { ConvertBusinessProcessDtoToBusinessProcessRow } from "../Process/BusinessProcessMappingRegister";
import {
  BusinessProcessRow,
  IBusinessProcessDto,
} from "../Process/BusinessProcessRow";
import {
  ConvertBPStepDtoToBPStepRow,
  ConvertBPStepRowToBPStepDto,
} from "../Step/BPStepMappingRegister";
import {
  BusinessProcessStepRow,
  IBusinessProcessStepDto,
} from "../Step/BusinessProcessStepRow";
import {
  ConvertBPStepTemplateDtoToBPStepTemplateRow,
  ConvertBPStepTemplateRowToBPStepTemplateDto,
} from "../StepTemplate/BPStepTemplateMappingRegister";
import {
  BusinessProcessStepTemplateRow,
  IBusinessProcessStepTemplateDto,
} from "../StepTemplate/BusinessProcessStepTemplateRow";
import { ConnectorRow } from "../StepTemplate/ConnectorRow";
import { ConvertBPTemplateDtoToBusinessProcessTemplateRow } from "../Template/BPTemplateMappingRegister";
import {
  BusinessProcessTemplateRow,
  IBusinessProcessTemplateDto,
} from "../Template/BusinessProcessTemplateRow";
import { QueryAttributeJM } from "../../../../RAFComponents/models/Common/QueryAttributeJM";

export class BPStartRequest {
  bptemplateId?: string;
  bpFormData?: any;
  bpDuedate?: Date;
  bpRelatedTo?: string;
  bpRelatedToType?: string;
  bpRelatedToUID?: string;
  objectData?: any;
}

//export function StartProcess(bptemplateId?: string, bpFormData?: any, bpDuedate?: Date, bpRelatedTo?: string, bpRelatedToType?: string, bpRelatedToUID?: string) {
/*export function StartProcessAndSaveSteps(bpStartRequest?: BPStartRequest) {
    const bptemplateId: string = bpStartRequest.bptemplateId;
    const bpFormData: any = bpStartRequest.bpFormData;
    const bpDuedate: Date = bpStartRequest.bpDuedate;
    const bpRelatedTo: string = bpStartRequest.bpRelatedTo;
    const bpRelatedToType: string = bpStartRequest.bpRelatedToType;
    const bpRelatedToUID: string = bpStartRequest.bpRelatedToUID;
    const objectData: any = bpStartRequest.objectData;
    return new Promise<{ bpTemplateRow: BusinessProcessTemplateRow, bpStepTemplateRows: BusinessProcessStepTemplateRow[], bpRow: BusinessProcessRow, bpStepRows: BusinessProcessStepRow[] }>((resolve, reject) => {
        RetrieveBusinessProcessTemplate(bptemplateId).then(bpTemplateResponse => {
            GetBusinessProcessTemplateSteps(bpTemplateResponse).then(bpTemplateStepResponse => {
                CreateBusinessProcess(bpTemplateResponse, bpFormData, bpDuedate, bpRelatedTo, bpRelatedToType, bpRelatedToUID).then(bpResponse => {
                    let bpStepRows: BusinessProcessStepRow[] = [];
                    bpTemplateStepResponse && bpTemplateStepResponse.sort(function (x, y) {
                        return (x.CreatedDate === y.CreatedDate) ? 0 : x.CreatedDate ? -1 : 1;
                    });
                    bpTemplateStepResponse && bpTemplateStepResponse.forEach(bpTemplateStep => {
                        let bpStepRow = GenerateBusinessProcessStep(bpResponse, bpTemplateResponse, bpTemplateStep);
                        bpStepRows.push(bpStepRow);
                    })
                    if (isNotNullAndUndefined(bpTemplateResponse.ExecutionOrder)) {
                        if (bpTemplateResponse.ExecutionOrder === RAFExecutionOrder.Sequential && isNotNullAndUndefined(bpStepRows)) {
                            bpStepRows[0].StepStatus = RAFBPStepStatus.ToDo;
                        }
                    }

                    SaveBusinessProcessStepsAPI(bpStepRows).then(bpStepsResponse => {
                        resolve({ bpTemplateRow: bpTemplateResponse, bpStepTemplateRows: bpTemplateStepResponse, bpRow: bpResponse, bpStepRows: bpStepsResponse });
                    });
                })
            })
        })
    });
}*/

export function checkStartProcessPermission(
  bpTemplateRow: BusinessProcessTemplateRow
) {
  return new Promise<boolean>((resolve, reject) => {
    if (isNotNullAndUndefined(bpTemplateRow)) {
      if (isNotNullAndUndefined(bpTemplateRow.InitiatorUID)) {
        if (isNotNullAndUndefined(bpTemplateRow.InitiatorType)) {
          if (bpTemplateRow.InitiatorType === "All Users") {
            resolve(true);
          } else if (bpTemplateRow.InitiatorType === "User") {
            if (msalInstance.currentUserId === bpTemplateRow.InitiatorUID)
              resolve(true);
            else resolve(false);
          } else if (bpTemplateRow.InitiatorType === "Team") {
            getMyTeams().then((myTeams) => {
              let isMyTeam = isNotNullAndUndefined(myTeams)
                ? myTeams.find((x) => x.UID === bpTemplateRow.InitiatorUID)
                : null;
              if (
                isNotNullAndUndefined(isMyTeam) &&
                isNotNullAndUndefined(isMyTeam.UID)
              )
                resolve(true);
              else resolve(false);
            });
          }
        } else {
          if (isNotNullAndUndefined(bpTemplateRow.InitiatorUID)) {
            getMyTeams().then((myTeams) => {
              const loggedinUserId = msalInstance.currentUserId;
              let isMyTeam = isNotNullAndUndefined(myTeams)
                ? myTeams.find((x) => x.UID === bpTemplateRow.InitiatorUID)
                : null;
              let allowCreate =
                isNotNullAndUndefined(isMyTeam) &&
                isNotNullAndUndefined(isMyTeam.UID)
                  ? true
                  : loggedinUserId === bpTemplateRow.InitiatorUID
                  ? true
                  : false;

              resolve(allowCreate);
            });
          } else {
            resolve(true);
          }
        }
      } else {
        resolve(true);
      }
    } else {
      resolve(false);
    }
  });
}

export function StartProcessAndSaveProcessSteps(
  bpStartRequest?: BPStartRequest,
  bpTemplateRow?: BusinessProcessTemplateRow,
  completedProcessRow?: BusinessProcessRow
) {
  const bptemplateId: string = bpTemplateRow.UID;
  const bpFormData = bpTemplateRow.FormData;
  const bpDuedate: Date = bpTemplateRow.DueDate;
  const bpRelatedTo: string = bpStartRequest && bpStartRequest.bpRelatedTo;
  const bpRelatedToType: string =
    bpStartRequest && bpStartRequest.bpRelatedToType;
  const bpRelatedToUID: string =
    bpStartRequest && bpStartRequest.bpRelatedToUID;
  const objectData: any = bpStartRequest && bpStartRequest.objectData;

  return new Promise<{
    bpTemplateRow: BusinessProcessTemplateRow;
    bpStepTemplateRows: BusinessProcessStepTemplateRow[];
    bpRow: BusinessProcessRow;
    bpStepRows: BusinessProcessStepRow[];
  }>((resolve, reject) => {
    // resolve(null);
    // return;
    // console.log("bpTemplateRow", bpTemplateRow);
    //RetrieveBusinessProcessTemplate(bptemplateId).then(bpTemplateResponse => {
    checkStartProcessPermission(bpTemplateRow).then((allowCreate) => {
      if (allowCreate === true) {
        GetBusinessProcessTemplateSteps(bpTemplateRow).then(
          (bpTemplateStepResponse) => {
            //Get Business Process Template Steps
            CreateBusinessProcess(
              bpTemplateRow,
              bpFormData,
              bpDuedate,
              bpRelatedTo,
              bpRelatedToType,
              bpRelatedToUID,
              completedProcessRow
            ).then((businessProcess) => {
              if (
                isNotNullAndUndefined(businessProcess) &&
                isNotNullAndUndefined(businessProcess.UID)
              ) {
                //Returns any running process or creates new process
                let bpStepRows: BusinessProcessStepRow[] = [];
                /*bpTemplateStepResponse && bpTemplateStepResponse.sort(function (x, y) {
                                                                                                                                  return (x.CreatedDate === y.CreatedDate) ? 0 : x.CreatedDate ? -1 : 1;
                                                                                                                              });*/
                //Generate Business Process Steps

                let reOrderedBPSteps = GetOrderedBPStepTemplateArray(
                  bpTemplateStepResponse
                );
                let bpTemplateSteps =
                  GetBPStepTemplateArrayOrdered(reOrderedBPSteps);
                bpTemplateSteps &&
                  bpTemplateSteps.forEach((bpTemplateStep) => {
                    if (
                      bpTemplateStep.StepStatus ===
                        RAFBPStepTemplateStatus.Active ||
                      bpTemplateStep.StepStatus ===
                        RAFBPStepTemplateStatus.Hidden
                    ) {
                      let bpStepRow = GenerateBusinessProcessStep(
                        businessProcess,
                        bpTemplateRow,
                        bpTemplateStep,
                        null
                      );
                      bpStepRows.push(bpStepRow);
                    }
                  });

                //Update parent step
                let updatedParentBpSteps = UpdateParentStep(bpStepRows);

                //Updte stepconnectorJson go to step uid
                let updatedBpStep =
                  UpdateStepConnectorJSON(updatedParentBpSteps);
                let bpStepRowsToSave: BusinessProcessStepRow[] = [];
                updatedBpStep.forEach((bpStepRow, index) => {
                  if (
                    businessProcess.ExecutionOrder ===
                      RAFExecutionOrder.StepByStep ||
                    businessProcess.ExecutionOrder ===
                      RAFExecutionOrder.Conditional
                  ) {
                    if (index === 0) {
                      if (bpStepRow.IsApproval) {
                        //bpStepRow.StepStatus = RAFBPStepStatus.RequestApproval;
                        bpStepRow.StepStatus = RAFBPStepStatus.InitiateRequest;
                        if (bpStepRow.StepType === RAFBPStepType.Review) {
                          bpStepRow.StepStatus = RAFBPStepStatus.RequestReview;
                        }
                      } else {
                        if (
                          isNotNullAndUndefined(bpStepRow.AssigneeUID) ||
                          isNotNullAndUndefined(bpStepRow.AssignTeamUID)
                        ) {
                          bpStepRow.StepStatus = RAFBPStepStatus.Assigned;
                        } else {
                          bpStepRow.StepStatus = RAFBPStepStatus.ToDo;
                        }
                      }
                    } else {
                      bpStepRow.StepStatus = RAFBPStepStatus.Queued;
                    }
                  } else {
                    if (bpStepRow.IsApproval) {
                      // bpStepRow.StepStatus = RAFBPStepStatus.RequestApproval;
                      bpStepRow.StepStatus = RAFBPStepStatus.InitiateRequest;
                      if (bpStepRow.StepType === RAFBPStepType.Review) {
                        bpStepRow.StepStatus = RAFBPStepStatus.RequestReview;
                      }
                    } else {
                      if (
                        isNotNullAndUndefined(bpStepRow.AssigneeUID) ||
                        isNotNullAndUndefined(bpStepRow.AssignTeamUID)
                      ) {
                        bpStepRow.StepStatus = RAFBPStepStatus.Assigned;
                      } else {
                        bpStepRow.StepStatus = RAFBPStepStatus.ToDo;
                      }
                    }
                  }
                  bpStepRowsToSave.push(bpStepRow);
                });

                // let i = 0;
                // let firstBPStep: BusinessProcessStepRow;
                // do {
                //   firstBPStep = isNotNullAndUndefined(bpStepRows[i])
                //     ? bpStepRows[i]
                //     : null;
                //   i++;
                // } while (
                //   firstBPStep !== null &&
                //   (firstBPStep.StepType === RAFBPStepType.Group ||
                //     firstBPStep.StepType === RAFBPStepType.Stage)
                // );

                // //Update Business Process Step status
                // if (isNotNullAndUndefined(bpTemplateRow.ExecutionOrder)) {
                //   if (
                //     bpTemplateRow.ExecutionOrder ===
                //     RAFExecutionOrder.StepByStep ||
                //     bpTemplateRow.ExecutionOrder === RAFExecutionOrder.Conditional
                //   ) {
                //     if (isNotNullAndUndefined(bpStepRows)) {
                //       if (firstBPStep.IsApproval) {
                //         firstBPStep.StepStatus = RAFBPStepStatus.RequestApproval;
                //         if (firstBPStep.StepType === RAFBPStepType.Review) {
                //           firstBPStep.StepStatus = RAFBPStepStatus.RequestReview;
                //         }
                //       } else {
                //         firstBPStep.StepStatus = RAFBPStepStatus.ToDo;
                //       }
                //     }
                //   } else {
                //     if (isNotNullAndUndefined(bpStepRows))
                //       bpStepRows.forEach((stepRow) => {
                //         if (stepRow.IsApproval) {
                //           stepRow.StepStatus = RAFBPStepStatus.RequestApproval;
                //           if (stepRow.StepType === RAFBPStepType.Review) {
                //             stepRow.StepStatus = RAFBPStepStatus.RequestReview;
                //           }
                //         } else {
                //           if (
                //             isNotNullAndUndefined(stepRow.AssigneeUID) ||
                //             isNotNullAndUndefined(stepRow.AssignTeamUID)
                //           )
                //             stepRow.StepStatus = RAFBPStepStatus.Assigned;
                //           else stepRow.StepStatus = RAFBPStepStatus.ToDo;
                //         }
                //         //}
                //       });
                //   }
                // }

                // //Validate Business Process Steps and Save Step in API
                // //if sequential & assignee not null save only first step , if parallel & assignee not null save all steps
                // let bpStepRowsToSave: BusinessProcessStepRow[] = [];
                // if (
                //   bpTemplateRow.ExecutionOrder === RAFExecutionOrder.StepByStep ||
                //   bpTemplateRow.ExecutionOrder === RAFExecutionOrder.Conditional
                // ) {

                //   if (firstBPStep.IsApproval) {
                //   } else {
                //     if (
                //       isNotNullAndUndefined(firstBPStep.AssigneeUID) ||
                //       isNotNullAndUndefined(firstBPStep.AssignTeamUID)
                //     ) {
                //       bpStepRowsToSave.push(firstBPStep);
                //     }
                //   }
                // } else {
                //   bpStepRows.forEach((bpStepRow) => {
                //     if (bpStepRow.IsApproval) {
                //     } else {
                //       if (
                //         isNotNullAndUndefined(bpStepRow.AssigneeUID) ||
                //         isNotNullAndUndefined(bpStepRow.AssignTeamUID)
                //       ) {
                //         bpStepRowsToSave.push(bpStepRow);
                //       }
                //     }
                //   });
                // }

                //Save Business Process Step(s)
                if (
                  isNotNullAndUndefined(bpStepRowsToSave) &&
                  bpStepRowsToSave.length > 0
                ) {
                  if (bpTemplateRow.IsOfflineProcess) {
                    SaveBusinessProcessStepsFB(bpStepRowsToSave).then(
                      (bpStepsResponse) => {
                        resolve({
                          bpTemplateRow: bpTemplateRow,
                          bpStepTemplateRows: bpTemplateStepResponse,
                          bpRow: businessProcess,
                          bpStepRows: bpStepsResponse,
                        });
                      }
                    );
                  } else {
                    CreateBusinessProcessStepsAPI(bpStepRowsToSave).then(
                      (bpStepsResponse) => {
                        resolve({
                          bpTemplateRow: bpTemplateRow,
                          bpStepTemplateRows: bpTemplateStepResponse,
                          bpRow: businessProcess,
                          bpStepRows: bpStepsResponse,
                        });
                      }
                    );
                  }
                } else {
                  resolve({
                    bpTemplateRow: bpTemplateRow,
                    bpStepTemplateRows: bpTemplateStepResponse,
                    bpRow: businessProcess,
                    bpStepRows: bpStepRows,
                  });
                }
              } else {
                showWarningToast("Sorry something went wrong !");
                resolve(null);
              }
            });
          }
        );
      } else {
        showWarningToast("You do not have permission to start this process.");
        resolve(null);
      }
    });
    //})
  });
}

//startup form start process method starts
export function StartProcessAndProcessSteps(
  bpStartRequest?: BPStartRequest,
  bpTemplateRow?: BusinessProcessTemplateRow,
  formData?: any,
  formLibrary?: FormLibraryRow,
  objEntity?: EntityRow,
  completedProcessRow?: BusinessProcessRow,
  formQueryAttributes?: QueryAttributeJM[]
) {
  const bptemplateId: string = bpTemplateRow.UID;
  const bpFormData = isNotNullAndUndefined(formData)
    ? formData
    : bpTemplateRow.FormData;
  const bpDuedate: Date = bpTemplateRow.DueDate;
  const bpRelatedTo: string = bpStartRequest && bpStartRequest.bpRelatedTo;
  const bpRelatedToType: string =
    bpStartRequest && bpStartRequest.bpRelatedToType;
  const bpRelatedToUID: string =
    bpStartRequest && bpStartRequest.bpRelatedToUID;
  const objectData: any = bpStartRequest && bpStartRequest.objectData;

  return new Promise<{
    bpTemplateRow: BusinessProcessTemplateRow;
    bpStepTemplateRows: BusinessProcessStepTemplateRow[];
    bpRow: BusinessProcessRow;
    bpStepRows: BusinessProcessStepRow[];
  }>((resolve, reject) => {
    const utcNewDate = moment.utc().format() as any;
    checkStartProcessPermission(bpTemplateRow).then((allowCreate) => {
      if (allowCreate === true) {
        GetBusinessProcessTemplateSteps(bpTemplateRow).then(
          (bpTemplateStepResponse) => {
            //Get Business Process Template Steps
            CreateProcessonStartProcess(
              bpTemplateRow,
              formData,
              bpDuedate,
              bpRelatedTo,
              bpRelatedToType,
              bpRelatedToUID,
              completedProcessRow,
              formLibrary,
              objEntity,
              formQueryAttributes
            ).then((businessProcess) => {
              if (
                isNotNullAndUndefined(businessProcess) &&
                isNotNullAndUndefined(businessProcess.UID)
              ) {
                //Returns any running process or creates new process
                let bpStepRows: BusinessProcessStepRow[] = [];
                /*bpTemplateStepResponse && bpTemplateStepResponse.sort(function (x, y) {
                                                                                                                                  return (x.CreatedDate === y.CreatedDate) ? 0 : x.CreatedDate ? -1 : 1;
                                                                                                                              });*/
                //Generate Business Process Steps
                let reOrderedBPSteps = GetOrderedBPStepTemplateArray(
                  bpTemplateStepResponse
                );
                let bpTemplateSteps =
                  GetBPStepTemplateArrayOrdered(reOrderedBPSteps);
                bpTemplateSteps &&
                  bpTemplateSteps.forEach((bpTemplateStep) => {
                    if (
                      bpTemplateStep.StepStatus ===
                        RAFBPStepTemplateStatus.Active ||
                      bpTemplateStep.StepStatus ===
                        RAFBPStepTemplateStatus.Hidden
                    ) {
                      let bpStepRow = GenerateBusinessProcessStep(
                        businessProcess,
                        bpTemplateRow,
                        bpTemplateStep,
                        null
                      );
                      bpStepRows.push(bpStepRow);
                    }
                  });

                //Update parent step
                let updatedParentBpSteps = UpdateParentStep(bpStepRows);

                //Updte stepconnectorJson go to step uid
                let updatedBpStep =
                  UpdateStepConnectorJSON(updatedParentBpSteps);
                let bpStepRowsToSave: BusinessProcessStepRow[] = [];
                updatedBpStep.forEach((bpStepRow, index) => {
                  if (
                    businessProcess.ExecutionOrder ===
                      RAFExecutionOrder.StepByStep ||
                    businessProcess.ExecutionOrder ===
                      RAFExecutionOrder.Conditional
                  ) {
                    if (index === 0) {
                      if (bpStepRow.StepType === RAFBPStepType.Start) {
                        bpStepRow.FormData = isNotNullAndUndefined(formData)
                          ? formData
                          : null;
                        bpStepRow.StepStatus = RAFBPStepStatus.Completed;
                        bpStepRow.CompletedBy = msalInstance.currentUserName;
                        bpStepRow.CompletedByUID = msalInstance.currentUserId;
                        bpStepRow.CompletedDate = utcNewDate;
                      } else {
                        if (bpStepRow.IsApproval) {
                          //bpStepRow.StepStatus = RAFBPStepStatus.RequestApproval;
                          bpStepRow.StepStatus =
                            RAFBPStepStatus.InitiateRequest;
                          if (bpStepRow.StepType === RAFBPStepType.Review) {
                            bpStepRow.StepStatus =
                              RAFBPStepStatus.RequestReview;
                          }
                        } else {
                          if (
                            isNotNullAndUndefined(bpStepRow.AssigneeUID) ||
                            isNotNullAndUndefined(bpStepRow.AssignTeamUID)
                          ) {
                            bpStepRow.StepStatus = RAFBPStepStatus.Assigned;
                          } else {
                            bpStepRow.StepStatus = RAFBPStepStatus.ToDo;
                          }
                        }
                      }
                    } else if (index === 1) {
                      if (bpStepRow.IsApproval) {
                        //bpStepRow.StepStatus = RAFBPStepStatus.RequestApproval;
                        bpStepRow.StepStatus = RAFBPStepStatus.InitiateRequest;
                        if (bpStepRow.StepType === RAFBPStepType.Review) {
                          bpStepRow.StepStatus = RAFBPStepStatus.RequestReview;
                        }
                      } else {
                        if (
                          isNotNullAndUndefined(bpStepRow.AssigneeUID) ||
                          isNotNullAndUndefined(bpStepRow.AssignTeamUID)
                        ) {
                          bpStepRow.StepStatus = RAFBPStepStatus.Assigned;
                        } else {
                          bpStepRow.StepStatus = RAFBPStepStatus.ToDo;
                        }
                      }
                    } else {
                      bpStepRow.StepStatus = RAFBPStepStatus.Queued;
                    }
                  } else {
                    if (bpStepRow.StepType === RAFBPStepType.Start) {
                      bpStepRow.FormData = isNotNullAndUndefined(formData)
                        ? formData
                        : null;
                      bpStepRow.StepStatus = RAFBPStepStatus.Completed;
                      bpStepRow.CompletedBy = msalInstance.currentUserName;
                      bpStepRow.CompletedByUID = msalInstance.currentUserId;
                      bpStepRow.CompletedDate = utcNewDate;
                    } else {
                      if (bpStepRow.IsApproval) {
                        // bpStepRow.StepStatus = RAFBPStepStatus.RequestApproval;
                        bpStepRow.StepStatus = RAFBPStepStatus.InitiateRequest;
                        if (bpStepRow.StepType === RAFBPStepType.Review) {
                          bpStepRow.StepStatus = RAFBPStepStatus.RequestReview;
                        }
                      } else {
                        if (
                          isNotNullAndUndefined(bpStepRow.AssigneeUID) ||
                          isNotNullAndUndefined(bpStepRow.AssignTeamUID)
                        ) {
                          bpStepRow.StepStatus = RAFBPStepStatus.Assigned;
                        } else {
                          bpStepRow.StepStatus = RAFBPStepStatus.ToDo;
                        }
                      }
                    }
                  }
                  bpStepRowsToSave.push(bpStepRow);
                });

                //Save Business Process Step(s)
                if (
                  isNotNullAndUndefined(bpStepRowsToSave) &&
                  bpStepRowsToSave.length > 0
                ) {
                  if (bpTemplateRow.IsOfflineProcess) {
                    SaveBusinessProcessStepsFB(bpStepRowsToSave).then(
                      (bpStepsResponse) => {
                        resolve({
                          bpTemplateRow: bpTemplateRow,
                          bpStepTemplateRows: bpTemplateStepResponse,
                          bpRow: businessProcess,
                          bpStepRows: bpStepsResponse,
                        });
                      }
                    );
                  } else {
                    CreateBusinessProcessStepsAPI(bpStepRowsToSave).then(
                      (bpStepsResponse) => {
                        resolve({
                          bpTemplateRow: bpTemplateRow,
                          bpStepTemplateRows: bpTemplateStepResponse,
                          bpRow: businessProcess,
                          bpStepRows: bpStepsResponse,
                        });
                      }
                    );
                  }
                } else {
                  resolve({
                    bpTemplateRow: bpTemplateRow,
                    bpStepTemplateRows: bpTemplateStepResponse,
                    bpRow: businessProcess,
                    bpStepRows: bpStepRows,
                  });
                }
              } else {
                showWarningToast("Sorry something went wrong !");
                resolve(null);
              }
            });
          }
        );
      } else {
        showWarningToast("You do not have permission to start this process.");
        resolve(null);
      }
    });
    //})
  });
}

function CreateProcessonStartProcess(
  bpTemplateRow: BusinessProcessTemplateRow,
  bpFormData?: any,
  bpDuedate?: Date,
  bpRelatedTo?: string,
  bpRelatedToType?: string,
  bpRelatedToUID?: string,
  completedProcessRow?: BusinessProcessRow,
  formLibrary?: FormLibraryRow,
  objEntity?: EntityRow,
  formQueryAttributes?: QueryAttributeJM[]
) {
  return new Promise<BusinessProcessRow>((resolve, reject) => {
    let businessProcess: BusinessProcessRow = new BusinessProcessRow();
    const utcNewDate = moment.utc().format() as any;
    businessProcess.UID = Guid.newGuid();
    businessProcess.StartMode = bpTemplateRow.StartMode;
    businessProcess.ExecutionOrder = bpTemplateRow.ExecutionOrder;
    businessProcess.ProcessStatus = RAFBPStatus.Started;
    //businessProcess.FormData = bpFormData;
    businessProcess.FormDataModel = bpTemplateRow.FormDataModel;
    businessProcess.VersionNumber = bpTemplateRow.VersionNumber;
    businessProcess.VersionUID = bpTemplateRow.VersionUID;
    businessProcess.Category = bpTemplateRow.Category;
    businessProcess.CategoryUID = bpTemplateRow.CategoryUID;
    businessProcess.Entity = bpTemplateRow.Entity;
    businessProcess.EntityUID = bpTemplateRow.EntityUID;
    const loggedinUserName = msalInstance.currentUserName;
    const loggedinUserId = msalInstance.currentUserId;
    businessProcess.ProcessStarterUID = isNotNullAndUndefined(
      bpTemplateRow.InitiatorUID
    )
      ? bpTemplateRow.InitiatorUID
      : loggedinUserId;
    businessProcess.ProcessStarter = isNotNullAndUndefined(
      bpTemplateRow.Initiator
    )
      ? bpTemplateRow.Initiator
      : loggedinUserName;

    businessProcess.StartDate = utcNewDate;
    if (isNotNullAndUndefined(completedProcessRow)) {
      businessProcess.StartDate = CalculateNextScheduleByRRuleExpression(
        completedProcessRow.EndDate,
        bpTemplateRow.RRuleExpression
      );
      businessProcess.ProcessStatus = RAFBPStatus.Scheduled;
    }
    // businessProcess.ProcessName =
    //   bpTemplateRow.ProcessName +
    //   " started on " +
    //   moment(businessProcess.StartDate).format("DD/MM/YYYY") +
    //   " by " +
    //   businessProcess.ProcessStarter;

    businessProcess.ProcessName = bpTemplateRow.ProcessName;

    if (isNotNullAndUndefined(bpDuedate)) {
      businessProcess.DueDate = bpDuedate;
    } else {
      businessProcess.DueDate = CalculateDeadline(
        businessProcess.StartDate,
        null,
        bpTemplateRow.ProcessDeadlineUnits,
        bpTemplateRow.ProcessDeadlineInterval,
        bpTemplateRow.ProcessDeadlineCalculation
      );
    }
    businessProcess.Description = bpTemplateRow.Description;
    businessProcess.StepCount = bpTemplateRow.StepCount;
    businessProcess.CompletedCount = 0;
    businessProcess.BpTemplateUID = bpTemplateRow.UID;
    businessProcess.BpTemplate = bpTemplateRow.ProcessName;
    businessProcess.FormName = bpTemplateRow.FormName;
    businessProcess.FormUID = bpTemplateRow.FormUID;
    businessProcess.FormType = bpTemplateRow.FormType;
    businessProcess.IconName = bpTemplateRow.IconName;

    // businessProcess.RelatedTo = isNotNullAndUndefined(bpRelatedTo)
    //   ? bpRelatedTo
    //   : businessProcess.ProcessName;
    // businessProcess.RelatedToType = isNotNullAndUndefined(bpRelatedToType)
    //   ? bpRelatedToType
    //   : "business_process";
    // businessProcess.RelatedToUID = isNotNullAndUndefined(bpRelatedToUID)
    //   ? bpRelatedToUID
    //   : businessProcess.UID;
    businessProcess.ThemeTemplate = bpTemplateRow.ThemeTemplate;

    let objectData = {
      FormLibrary: formLibrary.Title,
      FormLibraryUID: formLibrary.UID,
    };

    if (isNotNullAndUndefined(bpFormData)) {
      objectData = bpFormData;
    }

    //set SecondaryRelatedTo values and save form data if form type is primary form
    let primaryFormItem: RAFDataModelRow;
    let formComponents: RAFDataModelRow[] = formLibrary.DataModel.Components;
    if (isNotEmptyArray(formComponents)) {
      let startupComponent: RAFDataModelRow = formComponents[0];
      primaryFormItem = startupComponent.Components[0];
    }
    let primaryFormAttribute: QueryAttributeJM;
    if (
      isNotNullAndUndefined(primaryFormItem) &&
      primaryFormItem.DataType === RAFDataType.Lookup &&
      primaryFormItem.AttributeSettings.UIType === RAFDataType.PrimaryForm
    ) {
      primaryFormAttribute = isNotEmptyArray(formQueryAttributes)
        ? formQueryAttributes.find(
            (x) => x.AttributeName === primaryFormItem.Name
          )
        : null;
    } else if (
      isNotNullAndUndefined(primaryFormItem) &&
      primaryFormItem.DataType === RAFDataType.Lookup
    ) {
      primaryFormAttribute = isNotEmptyArray(formQueryAttributes)
        ? formQueryAttributes.find(
            (x) => x.AttributeName === primaryFormItem.Name
          )
        : null;
    }

    if (isNotNullAndUndefined(primaryFormAttribute)) {
      let secondaryRelatedToType = primaryFormAttribute.RelatedEntities;
      let secondaryRelatedTo = bpFormData[primaryFormAttribute.PropertyName];
      let secondaryRelatedToUID =
        bpFormData[primaryFormAttribute.PropertyName + "UID"];

      if (
        isNotNullAndUndefined(secondaryRelatedTo) &&
        isNotNullAndUndefined(secondaryRelatedToUID)
      ) {
        businessProcess.SecondaryRelatedTo = secondaryRelatedTo;
        businessProcess.SecondaryRelatedToType = secondaryRelatedToType;
        businessProcess.SecondaryRelatedToUID = secondaryRelatedToUID;
        objectData[RAFProcessFormPropertyName.BusinessProcesss] =
          businessProcess.ProcessName;
        objectData[RAFProcessFormPropertyName.BusinessProcesssUID] =
          businessProcess.UID;
        objectData[RAFProcessFormPropertyName.ProcessStatus] =
          RAFBPStatus.InProgress;
        objectData[RAFProcessFormPropertyName.StepCount] =
          businessProcess.StepCount;
        objectData[RAFProcessFormPropertyName.DueDate] =
          businessProcess.DueDate;
        objectData[RAFProcessFormPropertyName.Description] =
          businessProcess.Description;
        objectData[RAFProcessFormPropertyName.CompletedCount] = 1;
        objectData[RAFProcessFormPropertyName.OverDueStepCount] =
          businessProcess.StepCount - 1;

        SaveFormDataListAPI(objectData, objEntity.UID).then(
          (formDataResponse) => {
            businessProcess.ProcessStatus = RAFBPStatus.InProgress;
            businessProcess.CompletedCount = 1;

            businessProcess.RelatedTo = isNotNullAndUndefined(formDataResponse)
              ? formDataResponse.objectName
              : businessProcess.ProcessName;
            businessProcess.RelatedToType = isNotNullAndUndefined(objEntity)
              ? objEntity.EntityName
              : "business_process";
            businessProcess.RelatedToUID = isNotNullAndUndefined(
              formDataResponse
            )
              ? formDataResponse.entityId
              : businessProcess.UID;

            //set SecondaryRelatedTo values
            /*let primaryFormItem: RAFDataModelRow;
      let formComponents: RAFDataModelRow[] = formLibrary.DataModel.Components;
      if (isNotEmptyArray(formComponents)) {
        let startupComponent: RAFDataModelRow = formComponents[0];
        primaryFormItem = startupComponent.Components[0];
      }
      let primaryFormAttribute: QueryAttributeJM;
      if (
        isNotNullAndUndefined(primaryFormItem) &&
        primaryFormItem.DataType === RAFDataType.Lookup
      ) {
        primaryFormAttribute = isNotEmptyArray(formQueryAttributes)
          ? formQueryAttributes.find(
              (x) => x.AttributeName === primaryFormItem.Name
            )
          : null;
      }

      if (isNotNullAndUndefined(primaryFormAttribute)) {
        let secondaryRelatedToType = primaryFormAttribute.RelatedEntities;
        let secondaryRelatedTo = bpFormData[primaryFormAttribute.PropertyName];
        let secondaryRelatedToUID =
          bpFormData[primaryFormAttribute.PropertyName + "UID"];

        businessProcess.SecondaryRelatedTo = secondaryRelatedTo;
        businessProcess.SecondaryRelatedToType = secondaryRelatedToType;
        businessProcess.SecondaryRelatedToUID = secondaryRelatedToUID;
      }*/

            if (bpTemplateRow.IsRecurring) {
              CheckActiveProcess(bpTemplateRow.VersionUID).then(
                (bpResponse) => {
                  bpTemplateRow.IsOfflineProcess = false;
                  if (
                    isNotNullAndUndefined(bpResponse[0]) &&
                    isNotNullAndUndefined(bpResponse[0].UID)
                  ) {
                    resolve(bpResponse[0]);
                  } else {
                    if (bpTemplateRow.IsOfflineProcess) {
                      SaveBusinessProcessFB(businessProcess, false).then(
                        (processResponse) => {
                          resolve(processResponse);
                        }
                      );
                    } else {
                      SaveBusinessProcessAPI(businessProcess).then(
                        (processResponse) => {
                          resolve(processResponse);
                        }
                      );
                    }
                  }
                }
              );
            } else {
              if (bpTemplateRow.IsOfflineProcess) {
                SaveBusinessProcessFB(businessProcess, false).then(
                  (processResponse) => {
                    resolve(processResponse);
                  }
                );
              } else {
                SaveBusinessProcessAPI(businessProcess).then(
                  (processResponse) => {
                    resolve(processResponse);
                  }
                );
              }
            }
          }
        );
      } else {
        // let saveRequestData = getSaveRequest(objectData, null);
        // saveRequestData.EntityName = primaryFormAttribute.RelatedEntities;
        SaveFormDataListAPI(
          objectData,
          null,
          primaryFormAttribute.RelatedEntities
        ).then((formDataResponse) => {
          objectData[primaryFormAttribute.PropertyName] =
            formDataResponse.objectName;
          objectData[primaryFormAttribute.PropertyName + "UID"] =
            formDataResponse.entityId;
          objectData[RAFProcessFormPropertyName.BusinessProcesss] =
            businessProcess.ProcessName;
          objectData[RAFProcessFormPropertyName.BusinessProcesssUID] =
            businessProcess.UID;
          objectData[RAFProcessFormPropertyName.ProcessStatus] =
            RAFBPStatus.InProgress;
          objectData[RAFProcessFormPropertyName.StepCount] =
            businessProcess.StepCount;
          objectData[RAFProcessFormPropertyName.DueDate] =
            businessProcess.DueDate;
          objectData[RAFProcessFormPropertyName.Description] =
            businessProcess.Description;
          objectData[RAFProcessFormPropertyName.CompletedCount] = 1;
          objectData[RAFProcessFormPropertyName.OverDueStepCount] =
            businessProcess.StepCount - 1;

          businessProcess.SecondaryRelatedTo = formDataResponse.objectName;
          businessProcess.SecondaryRelatedToType = secondaryRelatedToType;
          businessProcess.SecondaryRelatedToUID = formDataResponse.entityId;

          SaveFormDataListAPI(objectData, objEntity.UID).then(
            (formDataResponse) => {
              businessProcess.ProcessStatus = RAFBPStatus.InProgress;
              businessProcess.CompletedCount = 1;

              businessProcess.RelatedTo = isNotNullAndUndefined(
                formDataResponse
              )
                ? formDataResponse.objectName
                : businessProcess.ProcessName;
              businessProcess.RelatedToType = isNotNullAndUndefined(objEntity)
                ? objEntity.EntityName
                : "business_process";
              businessProcess.RelatedToUID = isNotNullAndUndefined(
                formDataResponse
              )
                ? formDataResponse.entityId
                : businessProcess.UID;

              //set SecondaryRelatedTo values
              /*let primaryFormItem: RAFDataModelRow;
         let formComponents: RAFDataModelRow[] = formLibrary.DataModel.Components;
         if (isNotEmptyArray(formComponents)) {
           let startupComponent: RAFDataModelRow = formComponents[0];
           primaryFormItem = startupComponent.Components[0];
         }
         let primaryFormAttribute: QueryAttributeJM;
         if (
           isNotNullAndUndefined(primaryFormItem) &&
           primaryFormItem.DataType === RAFDataType.Lookup
         ) {
           primaryFormAttribute = isNotEmptyArray(formQueryAttributes)
             ? formQueryAttributes.find(
                 (x) => x.AttributeName === primaryFormItem.Name
               )
             : null;
         }
   
         if (isNotNullAndUndefined(primaryFormAttribute)) {
           let secondaryRelatedToType = primaryFormAttribute.RelatedEntities;
           let secondaryRelatedTo = bpFormData[primaryFormAttribute.PropertyName];
           let secondaryRelatedToUID =
             bpFormData[primaryFormAttribute.PropertyName + "UID"];
   
           businessProcess.SecondaryRelatedTo = secondaryRelatedTo;
           businessProcess.SecondaryRelatedToType = secondaryRelatedToType;
           businessProcess.SecondaryRelatedToUID = secondaryRelatedToUID;
         }*/

              if (bpTemplateRow.IsRecurring) {
                CheckActiveProcess(bpTemplateRow.VersionUID).then(
                  (bpResponse) => {
                    bpTemplateRow.IsOfflineProcess = false;
                    if (
                      isNotNullAndUndefined(bpResponse[0]) &&
                      isNotNullAndUndefined(bpResponse[0].UID)
                    ) {
                      resolve(bpResponse[0]);
                    } else {
                      if (bpTemplateRow.IsOfflineProcess) {
                        SaveBusinessProcessFB(businessProcess, false).then(
                          (processResponse) => {
                            resolve(processResponse);
                          }
                        );
                      } else {
                        SaveBusinessProcessAPI(businessProcess).then(
                          (processResponse) => {
                            resolve(processResponse);
                          }
                        );
                      }
                    }
                  }
                );
              } else {
                if (bpTemplateRow.IsOfflineProcess) {
                  SaveBusinessProcessFB(businessProcess, false).then(
                    (processResponse) => {
                      resolve(processResponse);
                    }
                  );
                } else {
                  SaveBusinessProcessAPI(businessProcess).then(
                    (processResponse) => {
                      resolve(processResponse);
                    }
                  );
                }
              }
            }
          );
        });
      }
    } else {
      objectData[RAFProcessFormPropertyName.BusinessProcesss] =
        businessProcess.ProcessName;
      objectData[RAFProcessFormPropertyName.BusinessProcesssUID] =
        businessProcess.UID;
      objectData[RAFProcessFormPropertyName.ProcessStatus] =
        RAFBPStatus.InProgress;
      objectData[RAFProcessFormPropertyName.StepCount] =
        businessProcess.StepCount;
      objectData[RAFProcessFormPropertyName.DueDate] = businessProcess.DueDate;
      objectData[RAFProcessFormPropertyName.Description] =
        businessProcess.Description;
      objectData[RAFProcessFormPropertyName.CompletedCount] = 1;
      objectData[RAFProcessFormPropertyName.OverDueStepCount] =
        businessProcess.StepCount - 1;

      SaveFormDataListAPI(objectData, objEntity.UID).then(
        (formDataResponse) => {
          businessProcess.ProcessStatus = RAFBPStatus.InProgress;
          businessProcess.CompletedCount = 1;

          businessProcess.RelatedTo = isNotNullAndUndefined(formDataResponse)
            ? formDataResponse.objectName
            : businessProcess.ProcessName;
          businessProcess.RelatedToType = isNotNullAndUndefined(objEntity)
            ? objEntity.EntityName
            : "business_process";
          businessProcess.RelatedToUID = isNotNullAndUndefined(formDataResponse)
            ? formDataResponse.entityId
            : businessProcess.UID;

          //set SecondaryRelatedTo values
          /*let primaryFormItem: RAFDataModelRow;
      let formComponents: RAFDataModelRow[] = formLibrary.DataModel.Components;
      if (isNotEmptyArray(formComponents)) {
        let startupComponent: RAFDataModelRow = formComponents[0];
        primaryFormItem = startupComponent.Components[0];
      }
      let primaryFormAttribute: QueryAttributeJM;
      if (
        isNotNullAndUndefined(primaryFormItem) &&
        primaryFormItem.DataType === RAFDataType.Lookup
      ) {
        primaryFormAttribute = isNotEmptyArray(formQueryAttributes)
          ? formQueryAttributes.find(
              (x) => x.AttributeName === primaryFormItem.Name
            )
          : null;
      }

      if (isNotNullAndUndefined(primaryFormAttribute)) {
        let secondaryRelatedToType = primaryFormAttribute.RelatedEntities;
        let secondaryRelatedTo = bpFormData[primaryFormAttribute.PropertyName];
        let secondaryRelatedToUID =
          bpFormData[primaryFormAttribute.PropertyName + "UID"];

        businessProcess.SecondaryRelatedTo = secondaryRelatedTo;
        businessProcess.SecondaryRelatedToType = secondaryRelatedToType;
        businessProcess.SecondaryRelatedToUID = secondaryRelatedToUID;
      }*/

          if (bpTemplateRow.IsRecurring) {
            CheckActiveProcess(bpTemplateRow.VersionUID).then((bpResponse) => {
              bpTemplateRow.IsOfflineProcess = false;
              if (
                isNotNullAndUndefined(bpResponse[0]) &&
                isNotNullAndUndefined(bpResponse[0].UID)
              ) {
                resolve(bpResponse[0]);
              } else {
                if (bpTemplateRow.IsOfflineProcess) {
                  SaveBusinessProcessFB(businessProcess, false).then(
                    (processResponse) => {
                      resolve(processResponse);
                    }
                  );
                } else {
                  SaveBusinessProcessAPI(businessProcess).then(
                    (processResponse) => {
                      resolve(processResponse);
                    }
                  );
                }
              }
            });
          } else {
            if (bpTemplateRow.IsOfflineProcess) {
              SaveBusinessProcessFB(businessProcess, false).then(
                (processResponse) => {
                  resolve(processResponse);
                }
              );
            } else {
              SaveBusinessProcessAPI(businessProcess).then(
                (processResponse) => {
                  resolve(processResponse);
                }
              );
            }
          }
        }
      );
    }
  });
}

//startup form start process method ends

export function UpdateStepConnectorJSON(bpSteps?: BusinessProcessStepRow[]) {
  if (isNotNullAndUndefined(bpSteps) && bpSteps.length > 0) {
    bpSteps.forEach((bpStep) => {
      let stepConnectorJson = R.clone(bpStep.StepConnectorJson);
      if (
        isNotNullAndUndefined(stepConnectorJson) &&
        stepConnectorJson.length > 0
      ) {
        stepConnectorJson.forEach((stepConnector) => {
          if (
            isNotNullAndUndefined(stepConnector.TargetID) &&
            stepConnector.TargetID !== RAFBPAction.Continue &&
            stepConnector.TargetID !== RAFBPAction.End
          ) {
            let targetStep = bpSteps.find(
              (x) => x.BpStepTemplateUID === stepConnector.TargetID
            );
            if (isNotNullAndUndefined(targetStep)) {
              stepConnector.TargetID = targetStep.UID;
            }
          }
        });
        bpStep.StepConnectorJson = stepConnectorJson;
      }
    });
  }

  return bpSteps;
}

export function UpdateStepConnectorByStepID(
  bpSteps: BusinessProcessStepRow[],
  stepIDChanges: { oldStepUID: string; newStepUID: string }[]
) {
  if (isNotNullAndUndefined(bpSteps) && bpSteps.length > 0) {
    bpSteps.forEach((bpStep) => {
      if (bpStep.IsActive) {
        bpStep.StepConnectorJson.forEach((stepConnector) => {
          if (
            isNotNullAndUndefined(stepConnector.TargetID) &&
            stepConnector.TargetID !== RAFBPAction.Continue &&
            stepConnector.TargetID !== RAFBPAction.End
          ) {
            let targetStep = isNotNullAndUndefined(stepIDChanges)
              ? stepIDChanges.find(
                  (x) => x.oldStepUID === stepConnector.TargetID
                )
              : null;

            if (isNotNullAndUndefined(targetStep)) {
              stepConnector.TargetID = targetStep.newStepUID;
            }
          }
        });
      }
    });
  }
  return bpSteps;
}

export function UpdateParentStep(bpSteps?: BusinessProcessStepRow[]) {
  if (isNotNullAndUndefined(bpSteps) && bpSteps.length > 0) {
    bpSteps.forEach((bpStep) => {
      if (isNotNullAndUndefined(bpStep.ParentStepUID)) {
        let parentStep = bpSteps.find(
          (x) => x.BpStepTemplateUID === bpStep.ParentStepUID
        );
        if (isNotNullAndUndefined(parentStep)) {
          bpStep.ParentStepUID = parentStep.UID;
          bpStep.ParentStep = parentStep.Title;
        }
      }
    });
  }
  return bpSteps;
}

export function StartProcess(bpStartRequest?: BPStartRequest) {
  const bptemplateId: string = bpStartRequest.bptemplateId;
  const bpFormData: any = bpStartRequest.bpFormData;
  const bpDuedate: Date = bpStartRequest.bpDuedate;
  const bpRelatedTo: string = bpStartRequest.bpRelatedTo;
  const bpRelatedToType: string = bpStartRequest.bpRelatedToType;
  const bpRelatedToUID: string = bpStartRequest.bpRelatedToUID;
  return new Promise<{
    bpTemplateRow: BusinessProcessTemplateRow;
    bpRow: BusinessProcessRow;
  }>((resolve, reject) => {
    RetrieveBusinessProcessTemplate(bptemplateId).then((bpTemplateResponse) => {
      CreateBusinessProcess(
        bpTemplateResponse,
        bpFormData,
        bpDuedate,
        bpRelatedTo,
        bpRelatedToType,
        bpRelatedToUID
      ).then((bpResponse) => {
        resolve({ bpTemplateRow: bpTemplateResponse, bpRow: bpResponse });
      });
    });
  });
}

export function RetrieveBusinessProcessTemplate(bptemplateId?: string) {
  return new Promise<BusinessProcessTemplateRow>((resolve, reject) => {
    if (isNotNullAndUndefined(bptemplateId)) {
      const rafBPTemplateRepository: BpTemplateRepository =
        new BpTemplateRepository();

      rafBPTemplateRepository
        .getRAFBPTemplateByUID(bptemplateId)
        .then((bpTemplateDto) => {
          if (isNotNullAndUndefined(bpTemplateDto)) {
            let businessProcessTemplateRow: BusinessProcessTemplateRow =
              ConvertBPTemplateDtoToBusinessProcessTemplateRow(bpTemplateDto);
            getInitiatorType(businessProcessTemplateRow).then(
              (initiatorType) => {
                businessProcessTemplateRow.InitiatorType = initiatorType;
                resolve(businessProcessTemplateRow);
              }
            );
          } else {
            repositoryActions
              .postDataAndGetResponse(
                "BusinessProcessTemplate/Retrieve",
                { EntityId: bptemplateId },
                null,
                ContentType.applicationJson
              )
              .then((response) => {
                if (
                  isNotNullAndUndefined(response) &&
                  isNotNullAndUndefined(response.data) &&
                  isNotNullAndUndefined(response.data.Entity)
                ) {
                  const businessProcessTemplateDto: IBusinessProcessTemplateDto =
                    response.data.Entity as IBusinessProcessTemplateDto;
                  let businessProcessTemplateRow: BusinessProcessTemplateRow =
                    ConvertBPTemplateDtoToBusinessProcessTemplateRow(
                      businessProcessTemplateDto
                    );
                  getInitiatorType(businessProcessTemplateRow).then(
                    (initiatorType) => {
                      businessProcessTemplateRow.InitiatorType = initiatorType;
                      resolve(businessProcessTemplateRow);
                    }
                  );
                } else {
                  resolve(null);
                }
              })
              .catch((error) => error);
          }
        });
    } else {
      resolve(null);
    }
  });
}

export function RetrieveBusinessProcessById(bpId: string) {
  return new Promise<BusinessProcessRow>((resolve, reject) => {
    if (isNotNullAndUndefined(bpId)) {
      //local db call
      const rafBusinessProcessRepository: BusinessProcessRepository =
        new BusinessProcessRepository();

      rafBusinessProcessRepository
        .getBusinessProcessByUID(bpId)
        .then((businessProcessDto) => {
          if (isNotNullAndUndefined(businessProcessDto)) {
            let businessProcessRow: BusinessProcessRow =
              ConvertBusinessProcessDtoToBusinessProcessRow(businessProcessDto);
            resolve(businessProcessRow);
          } else {
            repositoryActions
              .postDataAndGetResponse(
                "BusinessProcess/Retrieve",
                { EntityId: bpId },
                null,
                ContentType.applicationJson
              )
              .then((response) => {
                if (
                  isNotNullAndUndefined(response) &&
                  isNotNullAndUndefined(response.data) &&
                  isNotNullAndUndefined(response.data.Entity)
                ) {
                  const businessProcessDto: IBusinessProcessDto = response.data
                    .Entity as IBusinessProcessDto;
                  let businessProcessRow: BusinessProcessRow =
                    ConvertBusinessProcessDtoToBusinessProcessRow(
                      businessProcessDto
                    );
                  resolve(businessProcessRow);
                } else {
                  resolve(null);
                }
              })
              .catch((error) => error);
          }
        });
    } else {
      resolve(null);
    }
  });
}

export function GetRelatedToBusinessProcess(bpUID?: string) {
  let relatedToUID = bpUID;
  let relatedFilter: RAFCustomFilter = {};
  relatedFilter.Condition = "and";
  relatedFilter.Rules = [];

  let filter1: RAFCustomFilter = {};
  let filterVal1: string[] = [];
  filterVal1.push(relatedToUID);
  filter1.Operator = RAFCustomOperator.Equal;
  filter1.Value = filterVal1;
  filter1.Field = propertyOf<BusinessProcessRow>("RelatedToUID");
  relatedFilter.Rules.push(filter1);

  let filter2: RAFCustomFilter = {};
  let filterVal2: string[] = [];
  filterVal2.push(RAFBPStatus.Completed);
  filter2.Operator = RAFCustomOperator.Equal;
  filter2.Value = filterVal2;
  filter2.Field = propertyOf<BusinessProcessRow>("ProcessStatus");
  relatedFilter.Rules.push(filter2);

  let listServiceRequest = new ListServiceRequest();
  listServiceRequest.CustomFilter = relatedFilter;
  listServiceRequest.Skip = 0;
  listServiceRequest.Take = 0;

  //listServiceRequest.ViewName = "all_processes";

  return new Promise<BusinessProcessRow[]>((resolve, reject) => {
    repositoryActions
      .postDataAndGetResponse(
        "BusinessProcess/List",
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data)
        ) {
          resolve(response.data.Entities);
        }
      })
      .catch((error) => error);
  });
}

export function GetBusinessProcessTemplateSteps(
  bpTemplate: BusinessProcessTemplateRow
) {
  //need to check online or offline and get steps from api or db
  let isOfflineProcess = false;
  //if (isNotNullAndUndefined(bpTemplate) && bpTemplate.IsOfflineProcess) {
  if (isNotNullAndUndefined(bpTemplate) && isOfflineProcess) {
    const rafBpStepTemplateRepository: BpStepTemplateRepository =
      new BpStepTemplateRepository();

    return new Promise<BusinessProcessStepTemplateRow[]>((resolve, reject) => {
      rafBpStepTemplateRepository
        .getAllRAFBPTemplateSteps(bpTemplate.UID)
        .then((allBPTemplateSteps) => {
          let bpStepTemplate = sortItem(allBPTemplateSteps, "StepOrder");
          resolve(bpStepTemplate);
        });
    });
  } else {
    return new Promise<BusinessProcessStepTemplateRow[]>((resolve, reject) => {
      if (
        isNotNullAndUndefined(bpTemplate) &&
        isNotNullAndUndefined(bpTemplate.UID)
      ) {
        const rafBpStepTemplateRepository: BpStepTemplateRepository =
          new BpStepTemplateRepository();
        rafBpStepTemplateRepository
          .getAllRAFBPTemplateSteps(bpTemplate.UID)
          .then((allBPTemplateSteps) => {
            if (
              isNotNullAndUndefined(allBPTemplateSteps) &&
              allBPTemplateSteps.length > 0
            ) {
              let bpStepTemplate = sortItem(allBPTemplateSteps, "StepOrder");
              resolve(bpStepTemplate);
            } else {
              let relatedFilter: RAFCustomFilter = {};
              relatedFilter.Condition = "and";
              relatedFilter.Rules = [];
              let filter1: RAFCustomFilter = {};
              let filterVal1: string[] = [];
              filterVal1.push(bpTemplate.UID);
              filter1.Operator = RAFCustomOperator.Equal;
              filter1.Value = filterVal1;
              filter1.Field =
                propertyOf<BusinessProcessStepTemplateRow>("BpTemplateUID");
              relatedFilter.Rules.push(filter1);
              let listServiceRequest = new ListServiceRequest();
              listServiceRequest.CustomFilter = relatedFilter;
              listServiceRequest.Skip = 0;
              listServiceRequest.Take = 0;
              //listServiceRequest.ViewName = "all_bptemplate_steps";

              let sortBy = {
                field: propertyOf<BusinessProcessStepTemplateRow>("StepOrder"),
                order: "ascending",
              };
              let sortQuery: string[] = [];
              if (
                isNotNullAndUndefined(sortBy) &&
                isNotNullAndUndefined(sortBy.field)
              ) {
                sortQuery.push(
                  sortBy.order === "descending"
                    ? `${sortBy.field} desc`
                    : sortBy.field
                );
              }

              listServiceRequest.Sort = sortQuery;

              repositoryActions
                .postDataAndGetResponse(
                  "BusinessProcessStepTemplate/List",
                  listServiceRequest,
                  null,
                  ContentType.applicationJson
                )
                .then((response) => {
                  if (
                    isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.data) &&
                    isNotNullAndUndefined(response.data.Entities)
                  ) {
                    let bpTemplateSteps: BusinessProcessStepTemplateRow[] =
                      response.data.Entities;

                    let bpStepTemplateRows: BusinessProcessStepTemplateRow[] =
                      [];

                    bpTemplateSteps.forEach(async (item) => {
                      let bpStep =
                        ConvertBPStepTemplateDtoToBPStepTemplateRow(item);
                      bpStepTemplateRows.push(bpStep);
                      const db = await RAFDatabase.get();
                      if (isNotNullAndUndefined(db.bp_step_templates)) {
                        //await InsertBPStepTemplateFB(item);
                        //await createUpdateBPStepTemplate(item);
                      }
                    });

                    resolve(bpStepTemplateRows);
                  } else {
                    resolve(null);
                  }
                })
                .catch((error) => error);
            }
          });
      } else {
        resolve(null);
      }
    });
  }
}

function CreateBusinessProcess(
  bpTemplateRow: BusinessProcessTemplateRow,
  bpFormData?: any,
  bpDuedate?: Date,
  bpRelatedTo?: string,
  bpRelatedToType?: string,
  bpRelatedToUID?: string,
  completedProcessRow?: BusinessProcessRow
) {
  return new Promise<BusinessProcessRow>((resolve, reject) => {
    let businessProcess: BusinessProcessRow = new BusinessProcessRow();
    const utcNewDate = moment.utc().format() as any;
    businessProcess.UID = Guid.newGuid();
    businessProcess.StartMode = bpTemplateRow.StartMode;
    businessProcess.ExecutionOrder = bpTemplateRow.ExecutionOrder;
    businessProcess.ProcessStatus = RAFBPStatus.Started;
    businessProcess.FormData = bpFormData;
    businessProcess.FormDataModel = bpTemplateRow.FormDataModel;
    businessProcess.VersionNumber = bpTemplateRow.VersionNumber;
    businessProcess.VersionUID = bpTemplateRow.VersionUID;
    businessProcess.Category = bpTemplateRow.Category;
    businessProcess.CategoryUID = bpTemplateRow.CategoryUID;
    const loggedinUserName = msalInstance.currentUserName;
    const loggedinUserId = msalInstance.currentUserId;
    businessProcess.ProcessStarterUID = isNotNullAndUndefined(
      bpTemplateRow.InitiatorUID
    )
      ? bpTemplateRow.InitiatorUID
      : loggedinUserId;
    businessProcess.ProcessStarter = isNotNullAndUndefined(
      bpTemplateRow.Initiator
    )
      ? bpTemplateRow.Initiator
      : loggedinUserName;

    businessProcess.StartDate = utcNewDate;
    if (isNotNullAndUndefined(completedProcessRow)) {
      businessProcess.StartDate = CalculateNextScheduleByRRuleExpression(
        completedProcessRow.EndDate,
        bpTemplateRow.RRuleExpression
      );
      businessProcess.ProcessStatus = RAFBPStatus.Scheduled;
    }
    // businessProcess.ProcessName =
    //   bpTemplateRow.ProcessName +
    //   " started on " +
    //   moment(businessProcess.StartDate).format("DD/MM/YYYY") +
    //   " by " +
    //   businessProcess.ProcessStarter;

    businessProcess.ProcessName = bpTemplateRow.ProcessName;

    if (isNotNullAndUndefined(bpDuedate)) {
      businessProcess.DueDate = bpDuedate;
    } else {
      businessProcess.DueDate = CalculateDeadline(
        businessProcess.StartDate,
        null,
        bpTemplateRow.ProcessDeadlineUnits,
        bpTemplateRow.ProcessDeadlineInterval,
        bpTemplateRow.ProcessDeadlineCalculation
      );
    }
    businessProcess.Description = bpTemplateRow.Description;
    businessProcess.StepCount = bpTemplateRow.StepCount;
    businessProcess.CompletedCount = 0;
    businessProcess.BpTemplateUID = bpTemplateRow.UID;
    businessProcess.BpTemplate = bpTemplateRow.ProcessName;
    businessProcess.FormName = bpTemplateRow.FormName;
    businessProcess.FormUID = bpTemplateRow.FormUID;
    businessProcess.FormType = bpTemplateRow.FormType;
    businessProcess.IconName = bpTemplateRow.IconName;

    businessProcess.RelatedTo = isNotNullAndUndefined(bpRelatedTo)
      ? bpRelatedTo
      : businessProcess.ProcessName;
    businessProcess.RelatedToType = isNotNullAndUndefined(bpRelatedToType)
      ? bpRelatedToType
      : "business_process";
    businessProcess.RelatedToUID = isNotNullAndUndefined(bpRelatedToUID)
      ? bpRelatedToUID
      : businessProcess.UID;
    businessProcess.ThemeTemplate = bpTemplateRow.ThemeTemplate;
    // commented--user form will handle later
    /*
    if (businessProcess.FormType === RAFFormType.FormComponent) {
      let formData: FormDataRow = new FormDataRow();
      businessProcess.FormData[RAFFormFieldName.BusinessProcesss] =
        businessProcess.ProcessName;
      businessProcess.FormData[RAFFormFieldName.BusinessProcesssUID] =
        businessProcess.UID;

      formData.DataJson = businessProcess.FormData;

      RetrieveFormLibraryById(bpTemplateRow.FormUID).then((formLibraryRow) => {
        formData.FormLibraryUID = formLibraryRow.UID;
        formData.FormLibrary = formLibraryRow.Title;
        formData.Entity = formLibraryRow.Entity;
        formData.IconName = formLibraryRow.IconName;
        formData.Category = formLibraryRow.FormType;
        // formData.BusinessProcess = businessProcess.ProcessName;
        // formData.BusinessProcessUID = businessProcess.UID;

        SaveFormDataAPI(formData).then((formDataResponse) => {
          if (bpTemplateRow.IsRecurring) {
            CheckActiveProcess(bpTemplateRow.VersionUID).then((bpResponse) => {
              bpTemplateRow.IsOfflineProcess = false;
              if (
                isNotNullAndUndefined(bpResponse[0]) &&
                isNotNullAndUndefined(bpResponse[0].UID)
              ) {
                resolve(bpResponse[0]);
              } else {
                if (bpTemplateRow.IsOfflineProcess) {
                  SaveBusinessProcessFB(businessProcess, false).then(
                    (processResponse) => {
                      resolve(processResponse);
                    }
                  );
                } else {
                  SaveBusinessProcessAPI(businessProcess).then(
                    (processResponse) => {
                      resolve(processResponse);
                    }
                  );
                }
              }
            });
          } else {
            if (bpTemplateRow.IsOfflineProcess) {
              SaveBusinessProcessFB(businessProcess, false).then(
                (processResponse) => {
                  resolve(processResponse);
                }
              );
            } else {
              SaveBusinessProcessAPI(businessProcess).then(
                (processResponse) => {
                  resolve(processResponse);
                }
              );
            }
          }
        });
      });
    } else if (businessProcess.FormType === RAFFormType.UserForm) {
      let formData: FormDataRow = new FormDataRow();
      RetrieveFormLibraryById(bpTemplateRow.FormUID).then((formLibrary) => {
        getEntityByName(formLibrary.Entity).then((objEntity) => {
          getFields(objEntity.EntityName).then((viewAttributes) => {
            let processField = isNotNullAndUndefined(viewAttributes)
              ? viewAttributes.find(
                  (x) => x.RelatedEntities === RAFEntityName.BusinessProcess
                )
              : null;
            let processStatusField = isNotNullAndUndefined(viewAttributes)
              ? viewAttributes.find(
                  (x) =>
                    x.AttributeName === "process_status" &&
                    x.DataType === RAFDataType.Dropdown
                )
              : null;
            let processFieldName = isNotNullAndUndefined(processField)
              ? processField.PropertyName
              : null;
            let processUIDFieldName = isNotNullAndUndefined(processField)
              ? processField.PropertyName + "UID"
              : null;
            let processStatusFieldName = isNotNullAndUndefined(
              processStatusField
            )
              ? processStatusField.PropertyName
              : null;

            formData.FormLibraryUID = formLibrary.UID;
            formData.FormLibrary = formLibrary.Title;
            formData.Entity = formLibrary.Entity;
            formData.IconName = formLibrary.IconName;
            formData.Category = formLibrary.FormType;
            // formData.BusinessProcess = businessProcess.ProcessName;
            // formData.BusinessProcessUID = businessProcess.UID;

            let objectData = businessProcess.FormData;
            objectData.FormLibrary = formLibrary.Title;
            objectData.FormLibraryUID = formLibrary.UID;
            if (
              isNotNullAndUndefined(processFieldName) &&
              isNotNullAndUndefined(processUIDFieldName) &&
              isNotNullAndUndefined(processStatusFieldName)
            ) {
              objectData[processFieldName] = businessProcess.ProcessName;
              objectData[processUIDFieldName] = businessProcess.UID;
              objectData[processStatusFieldName] =
                businessProcess.ProcessStatus;
            }

            SaveFormDataListAPI(objectData, objEntity.UID).then(
              (formDataResponse) => {
                if (bpTemplateRow.IsRecurring) {
                  CheckActiveProcess(bpTemplateRow.VersionUID).then(
                    (bpResponse) => {
                      bpTemplateRow.IsOfflineProcess = false;
                      if (
                        isNotNullAndUndefined(bpResponse[0]) &&
                        isNotNullAndUndefined(bpResponse[0].UID)
                      ) {
                        resolve(bpResponse[0]);
                      } else {
                        if (bpTemplateRow.IsOfflineProcess) {
                          SaveBusinessProcessFB(businessProcess, false).then(
                            (processResponse) => {
                              resolve(processResponse);
                            }
                          );
                        } else {
                          SaveBusinessProcessAPI(businessProcess).then(
                            (processResponse) => {
                              resolve(processResponse);
                            }
                          );
                        }
                      }
                    }
                  );
                } else {
                  if (bpTemplateRow.IsOfflineProcess) {
                    SaveBusinessProcessFB(businessProcess, false).then(
                      (processResponse) => {
                        resolve(processResponse);
                      }
                    );
                  } else {
                    SaveBusinessProcessAPI(businessProcess).then(
                      (processResponse) => {
                        resolve(processResponse);
                      }
                    );
                  }
                }
              }
            );
          });
        });
      });
    } else {
      if (bpTemplateRow.IsRecurring) {
        CheckActiveProcess(bpTemplateRow.VersionUID).then((bpResponse) => {
          bpTemplateRow.IsOfflineProcess = false;
          if (
            isNotNullAndUndefined(bpResponse[0]) &&
            isNotNullAndUndefined(bpResponse[0].UID)
          ) {
            resolve(bpResponse[0]);
          } else {
            if (bpTemplateRow.IsOfflineProcess) {
              SaveBusinessProcessFB(businessProcess, false).then(
                (processResponse) => {
                  resolve(processResponse);
                }
              );
            } else {
              SaveBusinessProcessAPI(businessProcess).then(
                (processResponse) => {
                  resolve(processResponse);
                }
              );
            }
          }
        });
      } else {
        if (bpTemplateRow.IsOfflineProcess) {
          SaveBusinessProcessFB(businessProcess, false).then(
            (processResponse) => {
              resolve(processResponse);
            }
          );
        } else {
          SaveBusinessProcessAPI(businessProcess).then((processResponse) => {
            resolve(processResponse);
          });
        }
      }
    }
    */
    if (bpTemplateRow.IsRecurring) {
      CheckActiveProcess(bpTemplateRow.VersionUID).then((bpResponse) => {
        bpTemplateRow.IsOfflineProcess = false;
        if (
          isNotNullAndUndefined(bpResponse[0]) &&
          isNotNullAndUndefined(bpResponse[0].UID)
        ) {
          resolve(bpResponse[0]);
        } else {
          if (bpTemplateRow.IsOfflineProcess) {
            SaveBusinessProcessFB(businessProcess, false).then(
              (processResponse) => {
                resolve(processResponse);
              }
            );
          } else {
            SaveBusinessProcessAPI(businessProcess).then((processResponse) => {
              resolve(processResponse);
            });
          }
        }
      });
    } else {
      if (bpTemplateRow.IsOfflineProcess) {
        SaveBusinessProcessFB(businessProcess, false).then(
          (processResponse) => {
            resolve(processResponse);
          }
        );
      } else {
        SaveBusinessProcessAPI(businessProcess).then((processResponse) => {
          resolve(processResponse);
        });
      }
    }
  });
}

function GenerateBusinessProcessStep(
  businessProcess: BusinessProcessRow,
  bpTemplate: BusinessProcessTemplateRow,
  bpTemplateStep: BusinessProcessStepTemplateRow,
  bpStepsRow?: BusinessProcessStepRow[]
) {
  let bpStepRow: BusinessProcessStepRow = new BusinessProcessStepRow();
  bpStepRow.UID = Guid.newGuid();
  bpStepRow.Title = bpTemplateStep.Title;
  bpStepRow.StepType = bpTemplateStep.StepType;
  bpStepRow.StepOrder = bpTemplateStep.StepOrder;
  bpStepRow.DisplayOrder = bpTemplateStep.DisplayOrder;
  bpStepRow.AssigneeUID = bpTemplateStep.AssigneeUID;
  bpStepRow.Assignee = bpTemplateStep.Assignee;
  bpStepRow.AssignTeam = bpTemplateStep.AssignTeam;
  bpStepRow.AssignTeamUID = bpTemplateStep.AssignTeamUID;
  bpStepRow.Attachment = bpTemplateStep.Attachment;
  bpStepRow.AttachmentUID = bpTemplateStep.AttachmentUID;
  bpStepRow.IsActive = true;
  bpStepRow.AssigneeType = bpTemplateStep.AssigneeType;
  //Check if step template assignee is Process Starter
  if (bpStepRow.AssigneeType === RAFProcessAssigneeType.ProcessStarter) {
    bpStepRow.AssigneeUID = businessProcess.ProcessStarterUID;
    bpStepRow.Assignee = businessProcess.ProcessStarter;
    bpStepRow.AssignTeam = null;
    bpStepRow.AssignTeamUID = null;
  }
  // if (bpStepRow.Assignee === RAFProcessAssigneeType.ProcessStarter) {
  //   bpStepRow.AssigneeUID = businessProcess.ProcessStarterUID;
  //   bpStepRow.Assignee = businessProcess.ProcessStarter;
  //   bpStepRow.AssignTeam = null;
  //   bpStepRow.AssignTeamUID = null;
  // }

  bpStepRow.BpStepTemplateUID = bpTemplateStep.UID;
  bpStepRow.BpStepTemplate = bpTemplateStep.Title;
  bpStepRow.Description = bpTemplateStep.Description;
  bpStepRow.FormData = bpTemplateStep.FormData;
  bpStepRow.FormDataModel = bpTemplateStep.FormDataModel;
  bpStepRow.BusinessProcessUID = businessProcess.UID;
  bpStepRow.BusinessProcess = businessProcess.ProcessName;

  bpStepRow.IsApproval = bpTemplateStep.IsApproval;
  bpStepRow.IsRequired = bpTemplateStep.IsRequired;
  bpStepRow.IsDependent = bpTemplateStep.IsDependent;
  bpStepRow.IsConditional = bpTemplateStep.IsConditional;
  bpStepRow.ParentBpStepTemplate = bpTemplateStep.ParentStep;
  bpStepRow.ParentBpStepTemplateUID = bpTemplateStep.ParentStepUID;
  bpStepRow.DependentBpStepTemplate = bpTemplateStep.DependentStep;
  bpStepRow.DependentBpStepTemplateUID = bpTemplateStep.DependentStepUID;
  bpStepRow.StepCount = bpTemplateStep.StepCount;
  //bpStepRow.Entity = bpTemplateStep.Entity;
  bpStepRow.FormName = bpTemplateStep.FormName;
  bpStepRow.FormUID = bpTemplateStep.FormUID;
  //bpStepRow.ExecutionOrder = bpTemplateStep.ExecutionOrder;
  bpStepRow.ExecutionOrder =
    bpTemplateStep.StepType === RAFBPStepType.Group ||
    bpTemplateStep.StepType === RAFBPStepType.Stage
      ? bpTemplateStep.ExecutionOrder
      : null;
  bpStepRow.FormType = bpTemplateStep.FormType;
  bpStepRow.ParentStep = bpTemplateStep.ParentStep;
  bpStepRow.ParentStepUID = bpTemplateStep.ParentStepUID;
  bpStepRow.StepConnectorJson = bpTemplateStep.StepConnectorJson;

  // bpStepRow.RelatedTo = businessProcess.RelatedTo;
  // bpStepRow.RelatedToType = businessProcess.RelatedToType;
  // bpStepRow.RelatedToUID = businessProcess.RelatedToUID;

  bpStepRow.Partner = bpTemplateStep.Partner;
  bpStepRow.PartnerUID = bpTemplateStep.PartnerUID;

  bpStepRow.PageLayout = bpTemplateStep.PageLayout;
  bpStepRow.PageLayoutUID = bpTemplateStep.PageLayoutUID;
  bpStepRow.RelatedEntities = bpTemplateStep.RelatedEntities;
  bpStepRow.EnableStopGate = bpTemplateStep.EnableStopGate;
  bpStepRow.SecondaryRelatedTo = bpTemplateStep.SecondaryRelatedTo;
  bpStepRow.SecondaryRelatedToUID = bpTemplateStep.SecondaryRelatedToUID;
  bpStepRow.SecondaryRelatedToType = bpTemplateStep.SecondaryRelatedToType;

  //Update Business Process Step status

  let bpStepStatus: string = RAFBPStepStatus.Queued;

  //if (bpStepRow.IsDependent === true || bpStepRow.IsConditional === true || bpTemplateStep.StepStatus === RAFBPStepTemplateStatus.Hidden) {
  if (bpTemplateStep.StepStatus === RAFBPStepTemplateStatus.Hidden) {
    bpStepStatus = RAFBPStepStatus.Hidden;
  } else {
    /*if (isNotNullAndUndefined(bpStepRow.AssigneeUID))
                                        bpStepStatus = bpStepRow.IsApproval ? RAFBPStepStatus.AwaitingforApproval : RAFBPStepStatus.Assigned;
                                    else
                                        bpStepStatus = RAFBPStepStatus.ToDo;*/
  }

  bpStepRow.StepStatus = bpStepStatus;

  if (
    bpTemplateStep.StepDeadlineCalculation !== null &&
    bpTemplateStep.StepDeadlineCalculation !== RAFDeadlineCalculation.Notset
  ) {
    if (
      bpTemplateStep.StepDeadlineCalculation ===
        RAFDeadlineCalculation.AfterProcessStart ||
      bpTemplateStep.StepDeadlineCalculation ===
        RAFDeadlineCalculation.AfterStartDate ||
      bpTemplateStep.StepDeadlineCalculation ===
        RAFDeadlineCalculation.BeforeStartDate
    ) {
      bpStepRow.DueDate = CalculateDeadline(
        businessProcess.StartDate,
        null,
        bpTemplateStep.StepDeadlineUnits,
        bpTemplateStep.StepDeadlineInterval,
        bpTemplateStep.StepDeadlineCalculation
      );
    } else if (
      bpTemplateStep.StepDeadlineCalculation ===
      RAFDeadlineCalculation.BeforeProcessDeadline
    ) {
      bpStepRow.DueDate = CalculateDeadline(
        businessProcess.DueDate,
        null,
        bpTemplateStep.StepDeadlineUnits,
        bpTemplateStep.StepDeadlineInterval,
        bpTemplateStep.StepDeadlineCalculation
      );
    } else if (
      bpTemplateStep.StepDeadlineCalculation ===
        RAFDeadlineCalculation.AfterPreviousTaskComplete ||
      bpTemplateStep.StepDeadlineCalculation ==
        RAFDeadlineCalculation.AfterAnotherStepComplete
    ) {
      let dependentStepTemplateId = bpTemplateStep.DependentStepUID;
      let dependentStepRow =
        bpStepsRow &&
        bpStepsRow.find((x) => x.BpStepTemplateUID === dependentStepTemplateId);
      //calculate deadline
      if (isNotNullAndUndefined(dependentStepRow)) {
        bpStepRow.DueDate = CalculateDeadline(
          null,
          dependentStepRow.CompletedDate,
          bpTemplateStep.StepDeadlineUnits,
          bpTemplateStep.StepDeadlineInterval,
          bpTemplateStep.StepDeadlineCalculation
        );
      }
    }
  }
  return bpStepRow;
}

function CalculateDeadline(
  startDate?: Date,
  completedDate?: Date,
  units?: string,
  interval?: number,
  calculation?: string
) {
  let dueDate: Date = null;
  if (!IsNullOrWhiteSpace(calculation) && isNotNullAndUndefined(interval)) {
    switch (units) {
      case RAFDeadlineUnit.BusinessDays:
        if (!IsNullOrWhiteSpace(calculation)) {
          switch (calculation) {
            case RAFDeadlineCalculation.AfterProcessStart:
            case RAFDeadlineCalculation.AfterStartDate: {
              if (startDate !== null) {
                interval = GetInterval(startDate, interval);
                //dueDate = startDate.AddDays(interval);

                dueDate = setZeroHours(
                  moment(startDate).add(interval, "days").toDate()
                );
              }

              break;
            }

            case RAFDeadlineCalculation.AfterPreviousTaskComplete: {
              if (completedDate !== null) {
                interval = GetInterval(completedDate, interval);
                dueDate = setZeroHours(
                  moment(completedDate).add(interval, "days").toDate()
                );
              }

              break;
            }
            case RAFDeadlineCalculation.AfterAnotherStepComplete: {
              if (completedDate !== null) {
                interval = GetInterval(completedDate, interval);
                dueDate = setZeroHours(
                  moment(completedDate).add(interval, "days").toDate()
                );
              }

              break;
            }
            case RAFDeadlineCalculation.BeforeProcessDeadline:
            case RAFDeadlineCalculation.BeforeStartDate: {
              if (completedDate !== null) {
                interval = GetInterval(completedDate, interval);
                dueDate = setZeroHours(
                  moment(startDate).add(-interval, "days").toDate()
                );
              }

              break;
            }

            default:
              break;
          }
        }
        break;
      case RAFDeadlineUnit.CalendarDays: {
        switch (calculation) {
          case RAFDeadlineCalculation.AfterProcessStart:
          case RAFDeadlineCalculation.AfterStartDate: {
            if (startDate !== null) {
              if (interval > 0) {
                dueDate = setZeroHours(
                  moment(startDate).add(interval, "days").toDate()
                );
              }
            }

            break;
          }

          case RAFDeadlineCalculation.AfterPreviousTaskComplete: {
            if (completedDate !== null) {
              if (interval > 0) {
                dueDate = setZeroHours(
                  moment(completedDate).add(interval, "days").toDate()
                );
              }
            }

            break;
          }
          case RAFDeadlineCalculation.AfterAnotherStepComplete: {
            if (completedDate !== null) {
              if (interval > 0) {
                dueDate = setZeroHours(
                  moment(completedDate).add(interval, "days").toDate()
                );
              }
            }

            break;
          }

          case RAFDeadlineCalculation.BeforeProcessDeadline:
          case RAFDeadlineCalculation.BeforeStartDate: {
            if (completedDate !== null) {
              if (interval > 0) {
                dueDate = setZeroHours(
                  moment(startDate).add(interval, "days").toDate()
                );
              }
            }

            break;
          }

          default:
            break;
        }
        break;
      }
      case RAFDeadlineUnit.Weeks: {
        switch (calculation) {
          case RAFDeadlineCalculation.AfterProcessStart:
          case RAFDeadlineCalculation.AfterStartDate: {
            if (startDate !== null) {
              if (interval > 0) {
                const w: number = interval * 7;
                dueDate = setZeroHours(
                  moment(startDate).add(w, "days").toDate()
                );
              }
            }

            break;
          }

          case RAFDeadlineCalculation.AfterPreviousTaskComplete: {
            if (completedDate !== null) {
              if (interval > 0) {
                const w: number = interval * 7;
                dueDate = setZeroHours(
                  moment(completedDate).add(w, "days").toDate()
                );
              }
            }

            break;
          }
          case RAFDeadlineCalculation.AfterAnotherStepComplete: {
            if (completedDate !== null) {
              if (interval > 0) {
                const w: number = interval * 7;
                dueDate = setZeroHours(
                  moment(completedDate).add(w, "days").toDate()
                );
              }
            }

            break;
          }

          case RAFDeadlineCalculation.BeforeProcessDeadline:
          case RAFDeadlineCalculation.BeforeStartDate: {
            if (completedDate !== null) {
              if (interval > 0) {
                const w: number = interval * 7;
                dueDate = setZeroHours(
                  moment(startDate).add(w, "days").toDate()
                );
              }
            }

            break;
          }

          default:
            break;
        }
        break;
      }
      case RAFDeadlineUnit.Months: {
        switch (calculation) {
          case RAFDeadlineCalculation.AfterProcessStart:
          case RAFDeadlineCalculation.AfterStartDate: {
            if (startDate !== null) {
              if (interval > 0) {
                dueDate = setZeroHours(
                  moment(startDate).add(interval, "months").toDate()
                );
              }
            }

            break;
          }

          case RAFDeadlineCalculation.AfterPreviousTaskComplete: {
            if (completedDate !== null) {
              if (interval > 0) {
                dueDate = setZeroHours(
                  moment(completedDate).add(interval, "months").toDate()
                );
              }
            }

            break;
          }
          case RAFDeadlineCalculation.AfterAnotherStepComplete: {
            if (completedDate !== null) {
              if (interval > 0) {
                dueDate = setZeroHours(
                  moment(completedDate).add(interval, "months").toDate()
                );
              }
            }

            break;
          }
          case RAFDeadlineCalculation.BeforeProcessDeadline:
          case RAFDeadlineCalculation.BeforeStartDate: {
            if (completedDate !== null) {
              if (interval > 0) {
                dueDate = setZeroHours(
                  moment(startDate).add(-interval, "months").toDate()
                );
              }
            }

            break;
          }

          default:
            break;
        }
        break;
      }
      case RAFDeadlineUnit.Years: {
        switch (calculation) {
          case RAFDeadlineCalculation.AfterProcessStart:
          case RAFDeadlineCalculation.AfterStartDate: {
            if (startDate !== null) {
              if (interval > 0) {
                dueDate = setZeroHours(
                  moment(startDate).add(interval, "years").toDate()
                );
              }
            }

            break;
          }

          case RAFDeadlineCalculation.AfterPreviousTaskComplete: {
            if (completedDate !== null) {
              if (interval > 0) {
                dueDate = setZeroHours(
                  moment(completedDate).add(interval, "years").toDate()
                );
              }
            }

            break;
          }
          case RAFDeadlineCalculation.AfterAnotherStepComplete: {
            if (completedDate !== null) {
              if (interval > 0) {
                dueDate = setZeroHours(
                  moment(completedDate).add(interval, "years").toDate()
                );
              }
            }

            break;
          }
          case RAFDeadlineCalculation.BeforeProcessDeadline:
          case RAFDeadlineCalculation.BeforeStartDate: {
            if (completedDate !== null) {
              if (interval > 0) {
                dueDate = setZeroHours(
                  moment(startDate).add(-interval, "years").toDate()
                );
              }
            }

            break;
          }

          default:
            break;
        }
        break;
      }
    }
  }
  return dueDate;
}

function GetInterval(date?: Date, interval?: number) {
  let finalInterval = 0;
  if (interval !== null && date !== null) {
    let temp: Date = setZeroHours(moment(date).add(interval, "days").toDate());
    finalInterval = interval;

    for (
      var d: Date = setZeroHours(moment(date).add(1, "days").toDate());
      d <= temp;
      d = setZeroHours(moment(d).add(1, "days").toDate())
    ) {
      if (d.getDay() === 6 || d.getDay() === 0) {
        finalInterval = finalInterval + 1;
      }
    }
  }

  return finalInterval;
}

export function CheckActiveProcess(
  versionUID?: string,
  bpTemplateUID?: string
) {
  let relatedFilter: RAFCustomFilter = {};
  relatedFilter.Condition = "and";
  relatedFilter.Rules = [];

  if (isNotNullAndUndefined(versionUID)) {
    let filter1: RAFCustomFilter = {};
    let filterVal1: string[] = [];
    filterVal1.push(versionUID);
    filter1.Operator = RAFCustomOperator.Equal;
    filter1.Value = filterVal1;
    filter1.Field = propertyOf<BusinessProcessRow>("VersionUID");
    relatedFilter.Rules.push(filter1);
  }
  if (isNotNullAndUndefined(bpTemplateUID)) {
    let filter1: RAFCustomFilter = {};
    let filterVal1: string[] = [];
    filterVal1.push(bpTemplateUID);
    filter1.Operator = RAFCustomOperator.Equal;
    filter1.Value = filterVal1;
    filter1.Field = propertyOf<BusinessProcessRow>("BpTemplateUID");
    relatedFilter.Rules.push(filter1);
  }

  let relatedFilter1: RAFCustomFilter = {};
  relatedFilter1.Condition = "or";
  relatedFilter1.Rules = [];

  let filter2: RAFCustomFilter = {};
  let filterVal2: string[] = [];
  filterVal2.push(RAFBPStatus.Started);
  filter2.Operator = RAFCustomOperator.Equal;
  filter2.Value = filterVal2;
  filter2.Field = propertyOf<BusinessProcessRow>("ProcessStatus");
  relatedFilter1.Rules.push(filter2);

  let filter3: RAFCustomFilter = {};
  let filterVal3: string[] = [];
  filterVal3.push(RAFBPStatus.InProgress);
  filter3.Operator = RAFCustomOperator.Equal;
  filter3.Value = filterVal3;
  filter3.Field = propertyOf<BusinessProcessRow>("ProcessStatus");
  relatedFilter1.Rules.push(filter3);

  let filter4: RAFCustomFilter = {};
  let filterVal4: string[] = [];
  filterVal4.push(RAFBPStatus.Scheduled);
  filter4.Operator = RAFCustomOperator.Equal;
  filter4.Value = filterVal4;
  filter4.Field = propertyOf<BusinessProcessRow>("ProcessStatus");
  relatedFilter1.Rules.push(filter4);

  let filter5: RAFCustomFilter = {};
  let filterVal5: string[] = [];
  filterVal5.push(msalInstance.currentUserId);
  filter5.Operator = RAFCustomOperator.Equal;
  filter5.Value = filterVal5;
  filter5.Field = propertyOf<BusinessProcessRow>("ProcessStarterUID");
  relatedFilter.Rules.push(filter5);

  relatedFilter.Rules.push(relatedFilter1);

  let listServiceRequest = new ListServiceRequest();
  listServiceRequest.CustomFilter = relatedFilter;
  listServiceRequest.Skip = 0;
  listServiceRequest.Take = 0;
  return new Promise<BusinessProcessRow[]>((resolve, reject) => {
    repositoryActions
      .postDataAndGetResponse(
        "BusinessProcess/List",
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data)
        ) {
          resolve(response.data.Entities);
        } else {
          resolve(null);
        }
      })
      .catch((error) => error);
  });
}

export function GetbusinessProcessSteps(
  processId?: string,
  viewName?: string,
  bpTemplate?: BusinessProcessTemplateRow
) {
  //need to check online or offline and get steps from api or db
  if (isNotNullAndUndefined(bpTemplate)) bpTemplate.IsOfflineProcess = false;
  if (isNotNullAndUndefined(bpTemplate) && bpTemplate.IsOfflineProcess) {
    const rafBpStepsRepository: BusinessProcessStepRepository =
      new BusinessProcessStepRepository();

    return new Promise<BusinessProcessStepRow[]>((resolve, reject) => {
      rafBpStepsRepository
        .getAllRAFBPStepsByProcessId(processId)
        .then((allBusinessProcessSteps) => {
          resolve(allBusinessProcessSteps);
        });
    });
  } else {
    let relatedFilter: RAFCustomFilter = {};
    relatedFilter.Condition = "and";
    relatedFilter.Rules = [];

    let filter: RAFCustomFilter = {};
    let filterVal: string[] = [];
    filterVal.push(processId);
    filter.Operator = RAFCustomOperator.Equal;
    filter.Value = filterVal;
    filter.Field = propertyOf<BusinessProcessStepRow>("BusinessProcessUID");
    relatedFilter.Rules.push(filter);

    let filter1: RAFCustomFilter = {};
    let filterVal1: string[] = [];
    filterVal1.push("true");
    filter1.Operator = RAFCustomOperator.Equal;
    filter1.Value = filterVal1;
    filter1.Field = propertyOf<BusinessProcessStepRow>("IsActive");
    relatedFilter.Rules.push(filter1);

    let listServiceRequest = new ListServiceRequest();
    listServiceRequest.CustomFilter = relatedFilter;
    listServiceRequest.Skip = 0;
    listServiceRequest.Take = 0;
    //listServiceRequest.ViewName = 'all_bp_steps';
    listServiceRequest.ViewName = viewName;

    return new Promise<BusinessProcessStepRow[]>((resolve, reject) => {
      repositoryActions
        .postDataAndGetResponse(
          "BusinessProcessStep/List",
          listServiceRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data)
          ) {
            let bpStepsResponse: BusinessProcessStepTemplateRow[] =
              response.data.Entities;

            let businessProcessSteps: BusinessProcessStepTemplateRow[] = [];
            bpStepsResponse.forEach((item) => {
              let bpStep = ConvertBPStepDtoToBPStepRow(item);
              businessProcessSteps.push(bpStep);
            });
            resolve(businessProcessSteps);
          }
        })
        .catch((error) => error);
    });
  }
}

export function SaveBusinessProcessFB(
  businessProcessRow: BusinessProcessRow,
  isUpdate: boolean
) {
  let progressDiv = showProgress("body", true);
  return new Promise<BusinessProcessRow>((resolve, reject) => {
    const rafBusinessProcessRepository: BusinessProcessRepository =
      new BusinessProcessRepository();
    if (IsNullOrWhiteSpace(businessProcessRow.UID)) {
      businessProcessRow.UID = Guid.newGuid();
    }
    //rafBusinessProcessRepository.insertRAFBusinessProcess(removeUndefinedKeys(businessProcess));
    if (isUpdate) {
      rafBusinessProcessRepository
        .updateRAFBusinessProcess(businessProcessRow)
        .then((response) => {
          hideProgress(progressDiv);
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.UID)
          ) {
            resolve(businessProcessRow);
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    } else {
      rafBusinessProcessRepository
        .insertRAFBusinessProcess(businessProcessRow)
        .then((response) => {
          hideProgress(progressDiv);
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.UID)
          ) {
            resolve(businessProcessRow);
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    }
  });
}

export function SaveBusinessProcessAPI(businessProcessRow: BusinessProcessRow) {
  let progressDiv = showProgress("body", true);
  return new Promise<BusinessProcessRow>((resolve, reject) => {
    repositoryActions
      .postDataAndGetResponse(
        "BusinessProcess/Save",
        getSaveRequest(businessProcessRow, businessProcessRow.UID),
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        hideProgress(progressDiv);
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.EntityId)
        ) {
          resolve(businessProcessRow);
        } else {
          resolve(null);
        }
      })
      .catch((error) => error);
  });
}

export function SaveBusinessProcessTemplateAPI(
  bpTemplate: BusinessProcessTemplateRow
) {
  let progressDiv = showProgress("body", true);
  return new Promise<BusinessProcessTemplateRow>((resolve, reject) => {
    repositoryActions
      .postDataAndGetResponse(
        "BusinessProcessTemplate/Save",
        getSaveRequest(bpTemplate, bpTemplate.UID),
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        hideProgress(progressDiv);
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.EntityId)
        ) {
          resolve(bpTemplate);
        }
      })
      .catch((error) => error);
  });
}

function SaveBusinessProcessStepsAPI(bpStepRows: BusinessProcessStepRow[]) {
  //bulksave stepRows
  let saveRequest: SaveRequest<BusinessProcessStepRow>[] = [];
  if (isNotNullAndUndefined(bpStepRows)) {
    bpStepRows.forEach((item) => {
      //Commented due to this will implemente in audit transaction
      /*if (item.IsActive === false) {
        saveRequest.push({ Entity: item, EntityId: item.UID });
      } else {
        saveRequest.push({ Entity: item });
      }*/
      saveRequest.push({ Entity: item, EntityId: item.UID });
    });
  }

  return new Promise<BusinessProcessStepRow[]>((resolve, reject) => {
    repositoryActions
      .postDataAndGetResponse(
        "BusinessProcessStep/SaveList",
        saveRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data)
        ) {
          resolve(bpStepRows);
        } else {
          resolve(null);
        }
      })
      .catch((error) => error);
  });
}

function CreateBusinessProcessStepsAPI(bpStepRows: BusinessProcessStepRow[]) {
  //bulksave stepRows
  let saveRequest: SaveRequest<BusinessProcessStepRow>[] = [];
  if (isNotNullAndUndefined(bpStepRows)) {
    bpStepRows.forEach((item) => {
      //Commented due to this will implemente in audit transaction
      /*if (item.IsActive === false) {
        saveRequest.push({ Entity: item, EntityId: item.UID });
      } else {
        saveRequest.push({ Entity: item });
      }*/
      saveRequest.push({ Entity: item, EntityId: item.UID });
    });
  }

  return new Promise<BusinessProcessStepRow[]>((resolve, reject) => {
    repositoryActions
      .postDataAndGetResponse(
        "BusinessProcessStep/CreateList",
        saveRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data)
        ) {
          resolve(bpStepRows);
        } else {
          resolve(null);
        }
      })
      .catch((error) => error);
  });
}

function SaveBusinessProcessStepsFB(bpStepRows: BusinessProcessStepRow[]) {
  // //bulksave stepRows
  // let saveRequest: SaveRequest<BusinessProcessStepRow>[] = [];
  // if (isNotNullAndUndefined(bpStepRows)) {
  //   bpStepRows.forEach((item) => {
  //     if (item.IsActive === false) {
  //       saveRequest.push({ Entity: item, EntityId: item.UID });
  //     } else {
  //       saveRequest.push({ Entity: item });
  //     }
  //   });
  // }
  return new Promise<BusinessProcessStepRow[]>((resolve, reject) => {
    const rafBusinessProcessStepRepository: BusinessProcessStepRepository =
      new BusinessProcessStepRepository();
    rafBusinessProcessStepRepository
      .insertRAFBusinessProcessSteps(bpStepRows)
      .then((response) => {
        if (isNotNullAndUndefined(response)) {
          resolve(bpStepRows);
        } else {
          resolve(null);
        }
      })
      .catch((error) => error);
  });
}

export function SaveBPStepAndUpdateBP(
  currentBPStepRow: BusinessProcessStepRow,
  allbpStepRows: BusinessProcessStepRow[],
  businessProcessRow: BusinessProcessRow,
  bpTemplateStepsRow: BusinessProcessStepTemplateRow[],
  objectData?: any
) {
  return new Promise<{
    allBPRows: BusinessProcessStepRow[];
    dependentBPRows: BusinessProcessStepRow[];
  }>((resolve, reject) => {
    //Need to update current step and create next step, dependent step and update process completed step count
    //Update current BPStep status in BPSteps Array

    let reOrderedBPSteps = GetOrderedBPStepTemplateArray(
      bpTemplateStepsRow,
      null
    );
    let bpTemplateSteps = GetBPStepTemplateArrayOrdered(reOrderedBPSteps);

    let bpStepRowIndex =
      allbpStepRows &&
      allbpStepRows.findIndex((x) => x.UID === currentBPStepRow.UID);
    if (bpStepRowIndex >= 0) {
      allbpStepRows[bpStepRowIndex].StepStatus = currentBPStepRow.StepStatus;
      allbpStepRows[bpStepRowIndex].CompletedDate =
        currentBPStepRow.CompletedDate;
      allbpStepRows[bpStepRowIndex].FormData = currentBPStepRow.FormData;
    }
    let nextBPStep = GetNextStepByPrompt(currentBPStepRow, null, allbpStepRows);

    SaveBusinessProcessStepAPI(currentBPStepRow).then((updatebpStepRow) => {
      //Save current bp step
      let dependentSteps: BusinessProcessStepRow[] = [];
      if (
        businessProcessRow.ExecutionOrder === RAFExecutionOrder.StepByStep ||
        businessProcessRow.ExecutionOrder === RAFExecutionOrder.Conditional
      ) {
        if (
          currentBPStepRow.StepStatus === RAFBPStepStatus.Completed ||
          currentBPStepRow.StepStatus === RAFBPStepStatus.Approved ||
          currentBPStepRow.StepStatus === RAFBPStepStatus.ResponseReceived ||
          currentBPStepRow.StepStatus === RAFBPStepStatus.Reviewed ||
          currentBPStepRow.StepStatus === RAFBPStepStatus.Rejected ||
          currentBPStepRow.StepStatus === RAFBPStepStatus.RequestforInfo ||
          currentBPStepRow.StepStatus === RAFBPStepStatus.Skipped
        ) {
          //need to evaluate stepconnector for gotostep
          if (
            currentBPStepRow.StepStatus === RAFBPStepStatus.Rejected ||
            currentBPStepRow.StepStatus === RAFBPStepStatus.RequestforInfo
          ) {
            let stepIndex = GetCurrentAndNextStepIndex(
              currentBPStepRow,
              allbpStepRows,
              nextBPStep
            );
            if (stepIndex.nextIndex < stepIndex.currentIndex) {
              //Regenerate steps and save
              RegenerateBPSteps(
                stepIndex.currentIndex,
                allbpStepRows,
                stepIndex.nextIndex,
                businessProcessRow
              ).then((allBPStepResponse) => {
                let allBPSteps: BusinessProcessStepRow[] =
                  allBPStepResponse.filter((x) => x.IsActive === true);
                EvaluateConditionAndSaveStep(
                  currentBPStepRow,
                  allBPSteps,
                  businessProcessRow,
                  bpTemplateSteps,
                  objectData,
                  null,
                  null
                ).then((result) => {
                  resolve(result);
                });
              });
            } else {
              //complete the parent step if all the child steps are completed
              CheckAndSaveParentStep(allbpStepRows, updatebpStepRow).then(
                (parentBPSteps) => {
                  // dependentSteps = GetDependentBusinessProcessStep(
                  //   allbpStepRows,
                  //   updatebpStepRow
                  // ); //get steps depending on current step
                  if (
                    isNotNullAndUndefined(parentBPSteps) &&
                    parentBPSteps.length > 0
                  ) {
                    parentBPSteps.forEach((parentBPStepRow) => {
                      if (
                        isNotNullAndUndefined(parentBPStepRow) &&
                        isNotNullAndUndefined(parentBPStepRow.UID)
                      ) {
                        // update parent step status in BP Steps array
                        allbpStepRows[
                          allbpStepRows.findIndex(
                            (x) => x.UID === parentBPStepRow.UID
                          )
                        ].StepStatus = parentBPStepRow.StepStatus;
                        allbpStepRows[
                          allbpStepRows.findIndex(
                            (x) => x.UID === parentBPStepRow.UID
                          )
                        ].CompletedDate = parentBPStepRow.EndDate;
                      }
                    });
                  }

                  EvaluateConditionAndSaveStep(
                    currentBPStepRow,
                    allbpStepRows,
                    businessProcessRow,
                    bpTemplateSteps,
                    objectData,
                    null,
                    nextBPStep
                  ).then((result) => {
                    resolve(result);
                  });
                }
              );
            }
          } else {
            UpdateNextBPStep(
              allbpStepRows,
              updatebpStepRow,
              businessProcessRow,
              bpTemplateSteps
            ).then((nextBPStepRowResponse) => {
              //check for next step. if step exist, make it ready otherwise complete the process
              let updatedbpStepRows: BusinessProcessStepRow[] =
                isNotNullAndUndefined(nextBPStepRowResponse.allbpStepRows)
                  ? nextBPStepRowResponse.allbpStepRows
                  : allbpStepRows;
              if (
                isNotNullAndUndefined(
                  nextBPStepRowResponse.updatedNextBPStepRow
                )
              ) {
                //complete the parent step if all the child steps are completed
                CheckAndSaveParentStep(updatedbpStepRows, updatebpStepRow).then(
                  (parentBPSteps) => {
                    dependentSteps = GetDependentBusinessProcessStep(
                      updatedbpStepRows,
                      updatebpStepRow
                    ); //get steps depending on current step
                    if (
                      isNotNullAndUndefined(parentBPSteps) &&
                      parentBPSteps.length > 0
                    ) {
                      parentBPSteps.forEach((parentBPStepRow) => {
                        if (
                          isNotNullAndUndefined(parentBPStepRow) &&
                          isNotNullAndUndefined(parentBPStepRow.UID)
                        ) {
                          // update parent step status in BP Steps array
                          allbpStepRows[
                            allbpStepRows.findIndex(
                              (x) => x.UID === parentBPStepRow.UID
                            )
                          ].StepStatus = parentBPStepRow.StepStatus;
                          allbpStepRows[
                            allbpStepRows.findIndex(
                              (x) => x.UID === parentBPStepRow.UID
                            )
                          ].CompletedDate = parentBPStepRow.EndDate;
                        }
                      });
                    }

                    EvaluateConditionAndSaveStep(
                      currentBPStepRow,
                      updatedbpStepRows,
                      businessProcessRow,
                      bpTemplateSteps,
                      objectData,
                      null,
                      nextBPStepRowResponse.updatedNextBPStepRow
                    ).then((result) => {
                      resolve(result);
                    });
                  }
                );
              } else {
                //complete the parent step if all the child steps are completed
                CheckAndSaveParentStep(updatedbpStepRows, updatebpStepRow).then(
                  (parentBPSteps) => {
                    dependentSteps = GetDependentBusinessProcessStep(
                      updatedbpStepRows,
                      updatebpStepRow
                    ); //get steps depending on current step

                    if (
                      isNotNullAndUndefined(parentBPSteps) &&
                      parentBPSteps.length > 0
                    ) {
                      parentBPSteps.forEach((parentBPStepRow) => {
                        if (
                          isNotNullAndUndefined(parentBPStepRow) &&
                          isNotNullAndUndefined(parentBPStepRow.UID)
                        ) {
                          // update parent step status in BP Steps array
                          allbpStepRows[
                            allbpStepRows.findIndex(
                              (x) => x.UID === parentBPStepRow.UID
                            )
                          ].StepStatus = parentBPStepRow.StepStatus;
                          allbpStepRows[
                            allbpStepRows.findIndex(
                              (x) => x.UID === parentBPStepRow.UID
                            )
                          ].CompletedDate = parentBPStepRow.EndDate;
                        }
                      });
                    }

                    EvaluateConditionAndSaveStep(
                      currentBPStepRow,
                      updatedbpStepRows,
                      businessProcessRow,
                      bpTemplateSteps,
                      objectData,
                      null,
                      null
                    ).then((result) => {
                      resolve(result);
                    });
                  }
                );
              }
            });
          }
        } else {
          EvaluateConditionAndSaveStep(
            currentBPStepRow,
            allbpStepRows,
            businessProcessRow,
            bpTemplateSteps,
            objectData,
            null,
            null
          ).then((result) => {
            resolve(result);
          });
        }
      } else {
        if (
          (currentBPStepRow.StepStatus === RAFBPStepStatus.Rejected ||
            currentBPStepRow.StepStatus === RAFBPStepStatus.RequestforInfo) &&
          isNotNullAndUndefined(nextBPStep)
        ) {
          let stepIndex = GetCurrentAndNextStepIndex(
            currentBPStepRow,
            allbpStepRows,
            nextBPStep
          );
          if (
            stepIndex.nextIndex < stepIndex.currentIndex ||
            (stepIndex.nextIndex === 0 && stepIndex.currentIndex === 0)
          ) {
            //Regenerate steps and save
            RegenerateBPSteps(
              stepIndex.currentIndex,
              allbpStepRows,
              stepIndex.nextIndex,
              businessProcessRow
            ).then((allBPStepResponse) => {
              let allBPSteps: BusinessProcessStepRow[] =
                allBPStepResponse.filter((x) => x.IsActive === true);
              EvaluateConditionAndSaveStep(
                currentBPStepRow,
                allBPSteps,
                businessProcessRow,
                bpTemplateSteps,
                objectData,
                null,
                null
              ).then((result) => {
                resolve(result);
              });
            });
          } else {
            //complete the parent step if all the child steps are completed

            CheckAndSaveParentStep(allbpStepRows, updatebpStepRow).then(
              (parentBPSteps) => {
                // dependentSteps = GetDependentBusinessProcessStep(
                //   allbpStepRows,
                //   updatebpStepRow
                // ); //get steps depending on current step
                if (
                  isNotNullAndUndefined(parentBPSteps) &&
                  parentBPSteps.length > 0
                ) {
                  parentBPSteps.forEach((parentBPStepRow) => {
                    if (
                      isNotNullAndUndefined(parentBPStepRow) &&
                      isNotNullAndUndefined(parentBPStepRow.UID)
                    ) {
                      // update parent step status in BP Steps array
                      allbpStepRows[
                        allbpStepRows.findIndex(
                          (x) => x.UID === parentBPStepRow.UID
                        )
                      ].StepStatus = parentBPStepRow.StepStatus;
                      allbpStepRows[
                        allbpStepRows.findIndex(
                          (x) => x.UID === parentBPStepRow.UID
                        )
                      ].CompletedDate = parentBPStepRow.EndDate;
                    }
                  });
                }

                EvaluateConditionAndSaveStep(
                  currentBPStepRow,
                  allbpStepRows,
                  businessProcessRow,
                  bpTemplateSteps,
                  objectData,
                  null,
                  nextBPStep
                ).then((result) => {
                  resolve(result);
                });
              }
            );
          }
        } else {
          //complete the parent step if all the child steps are completed
          CheckAndSaveParentStep(allbpStepRows, updatebpStepRow).then(
            (parentBPSteps) => {
              if (
                isNotNullAndUndefined(parentBPSteps) &&
                parentBPSteps.length > 0
              ) {
                parentBPSteps.forEach((parentBPStepRow) => {
                  if (
                    isNotNullAndUndefined(parentBPStepRow) &&
                    isNotNullAndUndefined(parentBPStepRow.UID)
                  ) {
                    // update parent step status in BP Steps array
                    allbpStepRows[
                      allbpStepRows.findIndex(
                        (x) => x.UID === parentBPStepRow.UID
                      )
                    ].StepStatus = parentBPStepRow.StepStatus;
                    allbpStepRows[
                      allbpStepRows.findIndex(
                        (x) => x.UID === parentBPStepRow.UID
                      )
                    ].CompletedDate = parentBPStepRow.EndDate;
                  }
                });
              }
              // dependentSteps = GetDependentBusinessProcessStep(
              //   allbpStepRows,
              //   updatebpStepRow
              // ); //get steps depending on current step

              //Calculate deadline and save the dependent bp step
              if (
                isNotNullAndUndefined(dependentSteps) &&
                dependentSteps.length > 0
              ) {
                let promiseArr = dependentSteps.map(function (item) {
                  let dependentTemplateStep =
                    bpTemplateSteps &&
                    bpTemplateSteps.find(
                      (x) => x.UID === item.BpStepTemplateUID
                    );
                  //let currentTemplateStep = bpTemplateSteps && bpTemplateSteps.find(x => x.UID === updatebpStepRow.BpStepTemplateUID)
                  //console.log('dependentTemplateStep', dependentTemplateStep)
                  item.DueDate = CalculateDeadline(
                    businessProcessRow.StartDate,
                    updatebpStepRow.CompletedDate,
                    dependentTemplateStep.StepDeadlineUnits,
                    dependentTemplateStep.StepDeadlineInterval,
                    dependentTemplateStep.StepDeadlineCalculation
                  );
                  //console.log('item.DueDate', item.DueDate )
                  //Create BP Step
                  return SaveBusinessProcessStepAPI(item).then(
                    (dependentStepResponse) => {
                      return item;
                    }
                  );
                });
                Promise.all(promiseArr)
                  .then(function (resultsArray) {
                    for (var i = 0; i < resultsArray.length; i++) {
                      const item = resultsArray[i];
                      if (
                        isNotNullAndUndefined(item) &&
                        isNotNullAndUndefined(item.UID)
                      ) {
                        allbpStepRows[
                          allbpStepRows.findIndex((x) => x.UID === item.UID)
                        ].DueDate = item.DueDate;
                      }
                    }
                    //do next set of action
                    EvaluateConditionAndSaveStep(
                      currentBPStepRow,
                      allbpStepRows,
                      businessProcessRow,
                      bpTemplateSteps,
                      objectData,
                      dependentSteps,
                      nextBPStep
                    ).then((result) => {
                      resolve(result);
                    });
                  })
                  .catch(function (err) {
                    // do something when any of the promises in array are rejected
                  });
              } else {
                EvaluateConditionAndSaveStep(
                  currentBPStepRow,
                  allbpStepRows,
                  businessProcessRow,
                  bpTemplateSteps,
                  objectData,
                  dependentSteps,
                  nextBPStep
                ).then((result) => {
                  resolve(result);
                });
              }
            }
          );
        }
      }
    });
  });
}

function GetCurrentAndNextStepIndex(
  currentBPStep?: BusinessProcessStepRow,
  allbpSteps?: BusinessProcessStepRow[],
  nextStepRow?: BusinessProcessStepRow
) {
  let stepIndex: { currentIndex?: number; nextIndex?: number } = {};
  let allProcessSteps = GetOrderedBPStepArray(allbpSteps);
  let currentStepIndex: number =
    isNotNullAndUndefined(allProcessSteps) &&
    isNotNullAndUndefined(currentBPStep)
      ? allProcessSteps.findIndex((x) => x.UID === currentBPStep.UID)
      : -1;
  let nextStepIndex: number =
    isNotNullAndUndefined(allProcessSteps) && isNotNullAndUndefined(nextStepRow)
      ? allProcessSteps.findIndex((x) => x.UID === nextStepRow.UID)
      : -1;
  stepIndex = { currentIndex: currentStepIndex, nextIndex: nextStepIndex };
  return stepIndex;
}

function RegenerateBPSteps(
  currentStepIndex?: number,
  allbpSteps?: BusinessProcessStepRow[],
  nextStepIndex?: number,
  businessProcess?: BusinessProcessRow
) {
  return new Promise<BusinessProcessStepRow[]>((resolve, reject) => {
    //storing in this array to update connector JSON
    let stepIDChanges: { oldStepUID: string; newStepUID: string }[] = [];
    let allProcessSteps = GetOrderedBPStepArray(allbpSteps);
    let allupdatedBpSteps = R.clone(allProcessSteps);
    let stepIndex = R.clone(nextStepIndex);
    let bpStepsToSave: BusinessProcessStepRow[] = [];
    if (currentStepIndex > -1 && nextStepIndex > -1) {
      while (nextStepIndex <= currentStepIndex) {
        let bpStep: BusinessProcessStepRow = R.clone(
          allProcessSteps[nextStepIndex]
        );
        //Commented due to this will implemente in audit transaction
        /* let bpStepClone = R.clone(allProcessSteps[nextStepIndex]);
      //update isActive and push into array
        bpStep.IsActive = false;
        bpStepsToSave.push({ ...bpStep });

        //create new step by update isActive and step status
        if (bpStepClone.IsApproval) {
          //bpStepClone.StepStatus = RAFBPStepStatus.RequestApproval;
          bpStepClone.StepStatus = RAFBPStepStatus.InitiateRequest;
          if (bpStepClone.StepType === RAFBPStepType.Review) {
            bpStepClone.StepStatus = RAFBPStepStatus.RequestReview;
          }
        } else {
          if (
            isNotNullAndUndefined(bpStepClone.AssigneeUID) ||
            isNotNullAndUndefined(bpStepClone.AssignTeamUID)
          ) {
            bpStepClone.StepStatus = RAFBPStepStatus.Assigned;
          } else {
            bpStepClone.StepStatus = RAFBPStepStatus.ToDo;
          }
        }
        bpStepClone.FormData = null;

        bpStepClone.UID = Guid.newGuid();
        bpStepClone.IsActive = true;
        
        stepIDChanges.push({
          oldStepUID: bpStep.UID,
          newStepUID: bpStepClone.UID,
        });

        bpStepsToSave.push({ ...bpStepClone });

        */

        //update step status
        /*if (bpStep.IsApproval) {
          bpStep.StepStatus = RAFBPStepStatus.InitiateRequest;
          if (bpStep.StepType === RAFBPStepType.Review) {
            bpStep.StepStatus = RAFBPStepStatus.RequestReview;
          }
        } else {
          if (
            isNotNullAndUndefined(bpStep.AssigneeUID) ||
            isNotNullAndUndefined(bpStep.AssignTeamUID)
          ) {
            bpStep.StepStatus = RAFBPStepStatus.Assigned;
          } else {
            bpStep.StepStatus = RAFBPStepStatus.ToDo;
          }
        }
        */

        if (
          businessProcess.ExecutionOrder === RAFExecutionOrder.StepByStep ||
          businessProcess.ExecutionOrder === RAFExecutionOrder.Conditional
        ) {
          bpStep.StepStatus = RAFBPStepStatus.Queued;
          if (nextStepIndex === stepIndex) {
            if (bpStep.IsApproval) {
              bpStep.StepStatus = RAFBPStepStatus.InitiateRequest;
              if (bpStep.StepType === RAFBPStepType.Review) {
                bpStep.StepStatus = RAFBPStepStatus.RequestReview;
              }
            } else {
              if (
                isNotNullAndUndefined(bpStep.AssigneeUID) ||
                isNotNullAndUndefined(bpStep.AssignTeamUID)
              ) {
                bpStep.StepStatus = RAFBPStepStatus.Assigned;
              } else {
                bpStep.StepStatus = RAFBPStepStatus.ToDo;
              }
            }
          }
        } else {
          if (bpStep.IsApproval) {
            bpStep.StepStatus = RAFBPStepStatus.InitiateRequest;
            if (bpStep.StepType === RAFBPStepType.Review) {
              bpStep.StepStatus = RAFBPStepStatus.RequestReview;
            }
          } else {
            if (
              isNotNullAndUndefined(bpStep.AssigneeUID) ||
              isNotNullAndUndefined(bpStep.AssignTeamUID)
            ) {
              bpStep.StepStatus = RAFBPStepStatus.Assigned;
            } else {
              bpStep.StepStatus = RAFBPStepStatus.ToDo;
            }
          }
        }

        bpStep.FormData = null;
        bpStepsToSave.push({ ...bpStep });
        nextStepIndex++;
      }

      //UpdateStepConnectorJSon from Dictonary
      // let bpStepArrayToSave = UpdateStepConnectorByStepID(
      //   bpStepsToSave,
      //   stepIDChanges
      // );

      //Save process steps list

      if (isNotNullAndUndefined(bpStepsToSave) && bpStepsToSave.length > 0) {
        bpStepsToSave.forEach((item) => {
          allupdatedBpSteps[
            allProcessSteps.findIndex((x) => x.UID === item.UID)
          ] = item;
        });
      }
      //SaveBusinessProcessStepsAPI(bpStepArrayToSave).then((allBPSteps) => {
      SaveBusinessProcessStepsAPI(bpStepsToSave).then((allBPSteps) => {
        resolve(allupdatedBpSteps);
      });
    } else {
      resolve(allbpSteps);
    }
  });
}

function EvaluateConditionAndSaveStep(
  currentBPStepRow: BusinessProcessStepRow,
  allbpStepRows: BusinessProcessStepRow[],
  businessProcessRow: BusinessProcessRow,
  bpTemplateSteps: BusinessProcessStepTemplateRow[],
  objectData?: any,
  dependentSteps?: BusinessProcessStepRow[],
  nextBPStepRow?: BusinessProcessStepRow
) {
  return new Promise<{
    allBPRows: BusinessProcessStepRow[];
    dependentBPRows: BusinessProcessStepRow[];
  }>((resolve, reject) => {
    const utcNewDate = moment.utc().format() as any;
    const bpTemplateStep: BusinessProcessStepTemplateRow =
      bpTemplateSteps &&
      bpTemplateSteps.find((x) => x.UID === currentBPStepRow.BpStepTemplateUID); //get template step
    //if rules exist for this template step, run rules and get the matching template steps with actions
    EvaluateConditionForStep(bpTemplateStep, objectData).then(function (
      actionSteps
    ) {
      //console.log('SaveStepAndUpdateDependentSteps isMatch', actionSteps);
      //GetbusinessProcessSteps(businessProcessRow.UID).then(
      //(allupdatedBpSteps) => {
      if (isNotNullAndUndefined(actionSteps) && actionSteps.length > 0) {
        //for each matching template step, find corresponding bp row and update its status based on action

        let promiseArr =
          actionSteps &&
          actionSteps.map(function (item) {
            if (
              isNotNullAndUndefined(item) &&
              isNotNullAndUndefined(item.value) &&
              isNotNullAndUndefined(item.action)
            ) {
              const objStep: BusinessProcessStepRow = allbpStepRows.find(
                (x) => x.BpStepTemplateUID === item.value
              ); //find corresponding bp row
              if (isNotNullAndUndefined(objStep)) {
                if (item.action === RAFBusinessAction.showStep) {
                  if (objStep.IsApproval) {
                    //objStep.StepStatus = RAFBPStepStatus.RequestApproval;
                    objStep.StepStatus = RAFBPStepStatus.InitiateRequest;
                    if (objStep.StepType === RAFBPStepType.Review) {
                      objStep.StepStatus = RAFBPStepStatus.RequestReview;
                    }
                  } else {
                    if (
                      isNotNullAndUndefined(objStep.AssigneeUID) ||
                      isNotNullAndUndefined(objStep.AssignTeamUID)
                    ) {
                      objStep.StepStatus = RAFBPStepStatus.Assigned;
                    } else {
                      objStep.StepStatus = RAFBPStepStatus.ToDo;
                    }
                  }
                } else if (item.action === RAFBusinessAction.hideStep) {
                  objStep.StepStatus = RAFBPStepStatus.Hidden;
                }

                return SaveBusinessProcessStepAPI(objStep).then(
                  (dependentStepResponse) => {
                    return objStep;
                  }
                );
              }
            }
          });

        Promise.all(promiseArr)
          .then(function (resultsArray) {
            for (var i = 0; i < resultsArray.length; i++) {
              const objStep = resultsArray[i];
              if (
                isNotNullAndUndefined(objStep) &&
                isNotNullAndUndefined(objStep.BpStepTemplateUID)
              ) {
                allbpStepRows[
                  allbpStepRows.findIndex(
                    (x) => x.BpStepTemplateUID === objStep.BpStepTemplateUID
                  )
                ].StepStatus = objStep.StepStatus;
              }
            }

            //update completed step count in business process
            const completedSteps: BusinessProcessStepRow[] =
              isNotNullAndUndefined(allbpStepRows)
                ? allbpStepRows.filter(
                    (x) =>
                      (x.StepStatus === RAFBPStepStatus.Completed ||
                        x.StepStatus === RAFBPStepStatus.Skipped ||
                        x.StepStatus === RAFBPStepStatus.Rejected ||
                        x.StepStatus === RAFBPStepStatus.Approved ||
                        x.StepStatus === RAFBPStepStatus.ResponseReceived ||
                        x.StepStatus === RAFBPStepStatus.Reviewed) &&
                      IsNullOrWhiteSpace(x.ParentBpStepTemplateUID)
                  )
                : null;
            let totalSteps = 0;
            if (
              businessProcessRow.ExecutionOrder ===
              RAFExecutionOrder.Conditional
            ) {
              totalSteps = isNotNullAndUndefined(allbpStepRows)
                ? allbpStepRows.filter((x) =>
                    IsNullOrWhiteSpace(x.ParentBpStepTemplateUID)
                  ).length
                : 0;
              businessProcessRow.StepCount = totalSteps;
              businessProcessRow.CompletedCount = isNotNullAndUndefined(
                completedSteps
              )
                ? completedSteps.length
                : 0;
            } else {
              totalSteps = isNotNullAndUndefined(bpTemplateSteps)
                ? bpTemplateSteps.filter((x) =>
                    IsNullOrWhiteSpace(x.ParentStepUID)
                  ).length
                : 0;
            }
            if (
              isNotNullAndUndefined(completedSteps) &&
              completedSteps.length > 0 &&
              completedSteps.length === totalSteps
            ) {
              businessProcessRow.ProcessStatus = RAFBPStatus.Completed;
              businessProcessRow.CompletedCount = completedSteps.length;
              businessProcessRow.EndDate = utcNewDate;
              businessProcessRow.ModifiedBy = msalInstance.currentUserName;
              businessProcessRow.ModifiedByUID = msalInstance.currentUserId;
            } else {
              businessProcessRow.CompletedCount =
                isNotNullAndUndefined(allbpStepRows) &&
                allbpStepRows.filter(
                  (x) =>
                    (x.StepStatus === RAFBPStepStatus.Completed ||
                      x.StepStatus === RAFBPStepStatus.Skipped ||
                      x.StepStatus === RAFBPStepStatus.Rejected ||
                      x.StepStatus === RAFBPStepStatus.Approved ||
                      x.StepStatus === RAFBPStepStatus.ResponseReceived ||
                      x.StepStatus === RAFBPStepStatus.Reviewed) &&
                    IsNullOrWhiteSpace(x.ParentBpStepTemplateUID)
                ).length;
              businessProcessRow.ProcessStatus = RAFBPStatus.InProgress;
            }
            if (
              businessProcessRow.RelatedToType !== RAFEntityName.BusinessProcess
            ) {
              updateRelatedProcessStatus(
                businessProcessRow.RelatedToUID,
                businessProcessRow.ProcessStatus,
                businessProcessRow.RelatedToType,
                nextBPStepRow,
                allbpStepRows,
                businessProcessRow,
                currentBPStepRow,
                bpTemplateSteps
              ).then(() => {
                SaveBusinessProcessAPI(businessProcessRow).then(() => {
                  resolve({
                    allBPRows: allbpStepRows,
                    dependentBPRows: dependentSteps,
                  });
                });
              });
            } else {
              SaveBusinessProcessAPI(businessProcessRow).then((bpResponse) => {
                resolve({
                  allBPRows: allbpStepRows,
                  dependentBPRows: dependentSteps,
                });
              });
            }
          })
          .catch(function (err) {
            // do something when any of the promises in array are rejected
          });
      } else {
        //update completed step count in business process
        const completedSteps: BusinessProcessStepRow[] = isNotNullAndUndefined(
          allbpStepRows
        )
          ? allbpStepRows.filter(
              (x) =>
                (x.StepStatus === RAFBPStepStatus.Completed ||
                  x.StepStatus === RAFBPStepStatus.Skipped ||
                  x.StepStatus === RAFBPStepStatus.Approved ||
                  x.StepStatus === RAFBPStepStatus.ResponseReceived ||
                  x.StepStatus === RAFBPStepStatus.Rejected ||
                  x.StepStatus === RAFBPStepStatus.Reviewed) &&
                IsNullOrWhiteSpace(x.ParentBpStepTemplateUID)
            )
          : null;
        let totalSteps = 0;
        if (
          businessProcessRow.ExecutionOrder === RAFExecutionOrder.Conditional
        ) {
          totalSteps = isNotNullAndUndefined(allbpStepRows)
            ? allbpStepRows.filter((x) =>
                IsNullOrWhiteSpace(x.ParentBpStepTemplateUID)
              ).length
            : 0;
          businessProcessRow.CompletedCount = isNotNullAndUndefined(
            completedSteps
          )
            ? completedSteps.length
            : 0;
          businessProcessRow.StepCount = totalSteps;
        } else {
          totalSteps = isNotNullAndUndefined(bpTemplateSteps)
            ? bpTemplateSteps.filter((x) => IsNullOrWhiteSpace(x.ParentStepUID))
                .length
            : 0;
          businessProcessRow.StepCount = totalSteps;
        }

        if (
          isNotNullAndUndefined(completedSteps) &&
          completedSteps.length > 0 &&
          completedSteps.length === totalSteps
        ) {
          businessProcessRow.ProcessStatus = RAFBPStatus.Completed;
          businessProcessRow.CompletedCount = completedSteps.length;
          businessProcessRow.EndDate = utcNewDate;
          businessProcessRow.ModifiedBy = msalInstance.currentUserName;
          businessProcessRow.ModifiedByUID = msalInstance.currentUserId;

          if (businessProcessRow.ProcessStatus === RAFBPStatus.Completed) {
            RetrieveBusinessProcessTemplate(
              businessProcessRow.BpTemplateUID
            ).then((bpTemplateRow) => {
              //bpTemplateRow.IsOfflineProcess = true;
              if (
                bpTemplateRow.StartMode === "Conditional" &&
                isNotNullAndUndefined(bpTemplateRow.RRuleExpression) &&
                bpTemplateRow.IsOfflineProcess &&
                bpTemplateRow.IsRecurring
              ) {
                StartProcessAndSaveProcessSteps(
                  null,
                  bpTemplateRow,
                  businessProcessRow
                ).then((bpResponse) => {
                  let businessProcess: BusinessProcessRow = bpResponse.bpRow;
                  bpTemplateRow.NextRunDate = businessProcess.StartDate;
                  bpTemplateRow.LastRunDate = businessProcessRow.EndDate;
                  SaveBusinessProcessTemplateAPI(bpTemplateRow).then(
                    (bpTemplateResponse) => {}
                  );
                });
              }
            });
          }
        } else {
          businessProcessRow.CompletedCount =
            isNotNullAndUndefined(allbpStepRows) &&
            allbpStepRows.filter(
              (x) =>
                (x.StepStatus === RAFBPStepStatus.Completed ||
                  x.StepStatus === RAFBPStepStatus.Skipped ||
                  x.StepStatus === RAFBPStepStatus.Approved ||
                  x.StepStatus === RAFBPStepStatus.ResponseReceived ||
                  x.StepStatus === RAFBPStepStatus.Rejected ||
                  x.StepStatus === RAFBPStepStatus.Reviewed) &&
                IsNullOrWhiteSpace(x.ParentBpStepTemplateUID)
            ).length;
          businessProcessRow.ProcessStatus = RAFBPStatus.InProgress;
        }

        if (
          businessProcessRow.RelatedToType !== RAFEntityName.BusinessProcess
        ) {
          updateRelatedProcessStatus(
            businessProcessRow.RelatedToUID,
            businessProcessRow.ProcessStatus,
            businessProcessRow.RelatedToType,
            nextBPStepRow,
            allbpStepRows,
            businessProcessRow,
            currentBPStepRow,
            bpTemplateSteps
          ).then((relatedRow) => {
            SaveBusinessProcessAPI(businessProcessRow).then((bpResponse) => {
              resolve({
                allBPRows: allbpStepRows,
                dependentBPRows: dependentSteps,
              });
            });
          });
        } else {
          SaveBusinessProcessAPI(businessProcessRow).then((bpResponse) => {
            resolve({
              allBPRows: allbpStepRows,
              dependentBPRows: dependentSteps,
            });
          });
        }
      }
      //});
    });
  });
}

export function CompleteBusinessProcess(
  businessProcess?: BusinessProcessRow,
  businessProcessTemplate?: BusinessProcessTemplateRow,
  businessProcessSteps?: BusinessProcessStepRow[],
  stepStatus?: string
) {
  const utcNewDate = moment.utc().format() as any;
  businessProcess.ProcessStatus = RAFBPStatus.Completed;
  businessProcess.EndDate = utcNewDate;
  businessProcess.LastActivityDate = new Date();
  businessProcessTemplate.IsOfflineProcess = false;

  return new Promise<{
    businessProcess: BusinessProcessRow;
    businessProcessSteps: BusinessProcessStepRow[];
  }>((resolve, reject) => {
    if (
      businessProcessTemplate.StartMode === "Conditional" &&
      isNotNullAndUndefined(businessProcessTemplate.RRuleExpression) &&
      businessProcessTemplate.IsOfflineProcess &&
      businessProcessTemplate.IsRecurring
    ) {
      StartProcessAndSaveProcessSteps(
        null,
        businessProcessTemplate,
        businessProcess
      ).then((bpResponse) => {
        let businessProcess: BusinessProcessRow = bpResponse.bpRow;
        businessProcessTemplate.NextRunDate = businessProcess.StartDate;
        businessProcessTemplate.LastRunDate = businessProcess.EndDate;
        SaveBusinessProcessTemplateAPI(businessProcessTemplate).then(
          (bpTemplateResponse) => {
            SaveBusinessProcessAPI(businessProcess).then((processResponse) => {
              if (isNotNullAndUndefined(processResponse)) {
                resolve({
                  businessProcess,
                  businessProcessSteps: bpResponse.bpStepRows,
                });
              } else {
                resolve(null);
              }
            });
          }
        );
      });
    } else {
      //Set step status to skipped and save business process

      let updatedbpStepRows: BusinessProcessStepRow[] =
        R.clone(businessProcessSteps);
      let bpStepRowsToSave: BusinessProcessStepRow[] = [];
      updatedbpStepRows.forEach((bpStep) => {
        if (
          bpStep.StepStatus !== RAFBPStepStatus.Completed &&
          bpStep.StepStatus !== RAFBPStepStatus.Approved &&
          bpStep.StepStatus !== RAFBPStepStatus.ResponseReceived &&
          bpStep.StepStatus !== RAFBPStepStatus.Reviewed &&
          bpStep.StepStatus !== RAFBPStepStatus.Rejected &&
          bpStep.StepStatus !== RAFBPStepStatus.RequestforInfo &&
          bpStep.StepStatus !== RAFBPStepStatus.Skipped
        ) {
          //bpStep.StepStatus = RAFBPStepStatus.Skipped;
          bpStep.StepStatus = stepStatus;
          bpStep.CompletedBy = msalInstance.currentUserName;
          bpStep.CompletedByUID = msalInstance.currentUserId;
          bpStep.CompletedDate = utcNewDate;
          bpStepRowsToSave.push({ ...bpStep });
        }

        //update nextBPStep in allbpStep array
        updatedbpStepRows[
          updatedbpStepRows.findIndex((x) => x.UID === bpStep.UID)
        ] = bpStep;
      });

      if (
        isNotNullAndUndefined(bpStepRowsToSave) &&
        bpStepRowsToSave.length > 0
      ) {
        SaveBusinessProcessStepsAPI(bpStepRowsToSave).then(
          (bpStepsToUpdate) => {
            const completedSteps: BusinessProcessStepRow[] =
              isNotNullAndUndefined(updatedbpStepRows)
                ? updatedbpStepRows.filter(
                    (x) =>
                      (x.StepStatus === RAFBPStepStatus.Completed ||
                        x.StepStatus === RAFBPStepStatus.Skipped ||
                        x.StepStatus === RAFBPStepStatus.Rejected ||
                        x.StepStatus === RAFBPStepStatus.Approved ||
                        x.StepStatus === RAFBPStepStatus.ResponseReceived ||
                        x.StepStatus === RAFBPStepStatus.Reviewed) &&
                      IsNullOrWhiteSpace(x.ParentBpStepTemplateUID)
                  )
                : null;

            businessProcess.CompletedCount = isNotNullAndUndefined(
              completedSteps
            )
              ? completedSteps.length
              : 0;

            SaveBusinessProcessAPI(businessProcess).then((bpResponse) => {
              if (isNotNullAndUndefined(bpResponse)) {
                resolve({
                  businessProcess,
                  businessProcessSteps: updatedbpStepRows,
                });
              } else {
                resolve(null);
              }
            });
          }
        );
      }
    }
  });
}

function updateRelatedProcessStatus(
  relatedToUID?: string,
  bpStatus?: string,
  relatedToType?: string,
  nextBPStepRow?: BusinessProcessStepRow,
  allbpStepRows?: BusinessProcessStepRow[],
  businessProcessRow?: BusinessProcessRow,
  currentBPStepRow?: BusinessProcessStepRow,
  bpTemplateSteps?: BusinessProcessStepTemplateRow[]
) {
  let moduleName: string = getURLPrefixByModuleName(relatedToType);
  // switch (relatedToType) {
  //   case RAFEntityName.Deal:
  //     moduleName = "Deal";
  //     break;
  //   case RAFEntityName.Complaint:
  //     moduleName = "Complaint";
  //     break;
  //   case RAFEntityName.Contact:
  //     moduleName = "Contact";
  //     break;
  //   case RAFEntityName.Account:
  //     moduleName = "Account";
  //     break;
  //   case RAFEntityName.Ticket:
  //     moduleName = "Ticket";
  //     break;
  //   case RAFEntityName.Task:
  //     moduleName = "Task";
  //     break;
  //   case RAFEntityName.ContentLibrary:
  //     moduleName = "ContentLibrary";
  //     break;
  //   default:
  //     break;
  // }
  return new Promise<boolean>((resolve, reject) => {
    RetrieveRecord(relatedToUID, relatedToType).then((responseEntity) => {
      let relatedRow = responseEntity;
      if (isNotNullAndUndefined(relatedRow)) {
        let lastStep = allbpStepRows[allbpStepRows.length - 1];
        if (bpStatus === RAFBPStatus.Completed) {
          //if business process completed update related module ProcessStatus
          if (isNotNullAndUndefined(lastStep)) {
            if (
              businessProcessRow.ExecutionOrder === RAFExecutionOrder.AnyOrder
            ) {
              relatedRow.ProcessStatus = RAFBPStepStatus.Completed;
            } else {
              if (lastStep.StepStatus === RAFBPStepStatus.Approved) {
                relatedRow.ProcessStatus = "Approval Done";
              } else if (lastStep.StepStatus === RAFBPStepStatus.Reviewed) {
                relatedRow.ProcessStatus = "Review Done";
              } else {
                relatedRow.ProcessStatus = lastStep.Title + " " + bpStatus;
              }
            }
            repositoryActions
              .postDataAndGetResponse(
                moduleName + "/Save",
                getSaveRequest(relatedRow, relatedRow.UID),
                null,
                ContentType.applicationJson
              )
              .then((response) => {
                resolve(true);
              });
          }
        } else {
          // let activeBPStepRow: BusinessProcessStepRow =
          //   allbpStepRows &&
          //   allbpStepRows.find((x) => x.Title === relatedRow.ProcessStatus);
          // let activeBPStepRowIndex =
          //   allbpStepRows &&
          //   allbpStepRows.findIndex(
          //     (x) => x.Title === relatedRow.ProcessStatus
          //   );
          if (
            businessProcessRow.ExecutionOrder === RAFExecutionOrder.AnyOrder
          ) {
            //if parallel process set next active business process step
            /*if (isNotNullAndUndefined(activeBPStepRow) && (activeBPStepRow.StepStatus === RAFBPStepStatus.Completed || activeBPStepRow.StepStatus === RAFBPStepStatus.Approved || activeBPStepRow.StepStatus === RAFBPStepStatus.Rejected || activeBPStepRow.StepStatus === RAFBPStepStatus.Skipped)) {
                                                                                                                                //check next step is group or item
                                                                                                                                if (allbpStepRows[activeBPStepRowIndex + 1].StepType === RAFBPStepType.Item)
                                                                                                                                    nextBPStepRow = allbpStepRows[activeBPStepRowIndex + 1];
                                                                                                                                else
                                                                                                                                    nextBPStepRow = allbpStepRows[activeBPStepRowIndex + 2];
                                                                                                
                                                                                                                                while (nextBPStepRow) {
                                                                                                                                    if (isNotNullAndUndefined(nextBPStepRow) && (nextBPStepRow.StepStatus !== RAFBPStepStatus.Completed && nextBPStepRow.StepStatus !== RAFBPStepStatus.Approved && nextBPStepRow.StepStatus !== RAFBPStepStatus.Rejected && nextBPStepRow.StepStatus !== RAFBPStepStatus.Skipped)) {
                                                                                                                                        //check next step is group or item
                                                                                                                                        if (allbpStepRows[activeBPStepRowIndex + 1].StepType === RAFBPStepType.Item)
                                                                                                                                            nextBPStepRow = allbpStepRows[activeBPStepRowIndex + 1];
                                                                                                                                        else
                                                                                                                                            nextBPStepRow = allbpStepRows[activeBPStepRowIndex + 2];
                                                                                                                                        break;
                                                                                                                                    }
                                                                                                                                    else {
                                                                                                                                        activeBPStepRowIndex++;
                                                                                                                                        //check next step is group or item
                                                                                                                                        if (allbpStepRows[activeBPStepRowIndex + 1].StepType === RAFBPStepType.Item)
                                                                                                                                            nextBPStepRow = allbpStepRows[activeBPStepRowIndex + 1];
                                                                                                                                        else
                                                                                                                                            nextBPStepRow = allbpStepRows[activeBPStepRowIndex + 2];
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                            if (isNotNullAndUndefined(nextBPStepRow)) {
                                                                                                                                relatedRow.ProcessStatus = nextBPStepRow.Title;
                                                                                                                                repositoryActions.postDataAndGetResponse(moduleName + '/Save', getSaveRequest(relatedRow, relatedRow.UID), null, ContentType.applicationJson)
                                                                                                                                    .then((response) => {
                                                                                                                                        resolve(true);
                                                                                                                                    })
                                                                                                                            }
                                                                                                                            else {
                                                                                                                                resolve(true);
                                                                                                                            }*/
            if (
              isNotNullAndUndefined(currentBPStepRow) &&
              currentBPStepRow.FormUID === businessProcessRow.FormUID
            ) {
              let formName = currentBPStepRow.FormName;

              let relatedMergeRow = deepMerge(
                relatedRow,
                currentBPStepRow.FormData[formName]
              );
              let rootBPStepRow = GetRootParentStep(
                nextBPStepRow,
                allbpStepRows
              );
              if (isNotNullAndUndefined(rootBPStepRow)) {
                relatedMergeRow.ProcessStatus = rootBPStepRow.Title;
              }

              repositoryActions
                .postDataAndGetResponse(
                  moduleName + "/Save",
                  getSaveRequest(relatedMergeRow, relatedRow.UID),
                  null,
                  ContentType.applicationJson
                )
                .then((response) => {
                  resolve(true);
                });
            } else {
              let nextBPStepRow = GetNextStepByPrompt(
                currentBPStepRow,
                bpTemplateSteps,
                allbpStepRows
              );
              if (isNotNullAndUndefined(nextBPStepRow)) {
                relatedRow.ProcessStatus = nextBPStepRow.Title;
              }

              let rootBPStepRow = GetRootParentStep(
                nextBPStepRow,
                allbpStepRows
              );
              if (isNotNullAndUndefined(rootBPStepRow)) {
                relatedRow.ProcessStatus = rootBPStepRow.Title;
              }

              repositoryActions
                .postDataAndGetResponse(
                  moduleName + "/Save",
                  getSaveRequest(relatedRow, relatedRow.UID),
                  null,
                  ContentType.applicationJson
                )
                .then((response) => {
                  resolve(true);
                });
            }
          } else {
            //if sequential process set next active business process step
            /*if (isNotNullAndUndefined(activeBPStepRow) && (activeBPStepRow.StepStatus === RAFBPStepStatus.Completed || activeBPStepRow.StepStatus === RAFBPStepStatus.Approved || activeBPStepRow.StepStatus === RAFBPStepStatus.Rejected || activeBPStepRow.StepStatus === RAFBPStepStatus.Skipped)) {
                                                                                                                                //check next step is group or item
                                                                                                                                if (allbpStepRows[activeBPStepRowIndex + 1].StepType === RAFBPStepType.Item)
                                                                                                                                    nextBPStepRow = allbpStepRows[activeBPStepRowIndex + 1];
                                                                                                                                else
                                                                                                                                    nextBPStepRow = allbpStepRows[activeBPStepRowIndex + 2];
                                                                                                                            }*/

            if (
              isNotNullAndUndefined(currentBPStepRow) &&
              currentBPStepRow.FormUID === businessProcessRow.FormUID
            ) {
              let formName = currentBPStepRow.FormName;
              let relatedMergeRow = deepMerge(
                relatedRow,
                currentBPStepRow.FormData[formName]
              );
              /*if (isNotNullAndUndefined(nextBPStepRow)) {
                                                                                                                                                  relatedMergeRow.ProcessStatus = nextBPStepRow.Title;
                                                                                                                                              }
                                                                                                              */
              let rootBPStepRow = GetRootParentStep(
                nextBPStepRow,
                allbpStepRows
              );
              if (isNotNullAndUndefined(rootBPStepRow)) {
                relatedMergeRow.ProcessStatus = rootBPStepRow.Title;
              }

              repositoryActions
                .postDataAndGetResponse(
                  moduleName + "/Save",
                  getSaveRequest(relatedMergeRow, relatedRow.UID),
                  null,
                  ContentType.applicationJson
                )
                .then((response) => {
                  resolve(true);
                });
            } else {
              /*if (isNotNullAndUndefined(nextBPStepRow)) {
                                                                                                                                                  relatedRow.ProcessStatus = nextBPStepRow.Title;
                                                                                                                                              }*/

              let rootBPStepRow = GetRootParentStep(
                nextBPStepRow,
                allbpStepRows
              );
              if (isNotNullAndUndefined(rootBPStepRow)) {
                relatedRow.ProcessStatus = rootBPStepRow.Title;
              }

              repositoryActions
                .postDataAndGetResponse(
                  moduleName + "/Save",
                  getSaveRequest(relatedRow, relatedRow.UID),
                  null,
                  ContentType.applicationJson
                )
                .then((response) => {
                  resolve(true);
                });
            }
          }
        }
      } else {
        resolve(false);
      }
      /*if (relatedRow.ProcessStatus !== bpStatus) {
                                                                                  relatedRow.ProcessStatus = bpStatus;
                                                                                  repositoryActions.postDataAndGetResponse(moduleName + '/Save', getSaveRequest(relatedRow, relatedRow.UID), null, ContentType.applicationJson)
                                                                                      .then((response) => {
                                                                                          resolve(true);
                                                                                      })
                                                                              }
                                                                              else {
                                                                                  relatedRow.ProcessStatus = nextBPStepRow.Title;
                                                                                  repositoryActions.postDataAndGetResponse(moduleName + '/Save', getSaveRequest(relatedRow, relatedRow.UID), null, ContentType.applicationJson)
                                                                                      .then((response) => {
                                                                                          resolve(true);
                                                                                      })
                                                                              }*/
    });
  });
}

function CheckAndSaveParentStep(
  allbpStepRows?: BusinessProcessStepRow[],
  updatedbpStepRow?: BusinessProcessStepRow,
  bpTemplateRow?: BusinessProcessTemplateRow
) {
  //complete parent step if children steps are completed
  return new Promise<BusinessProcessStepRow[]>((resolve, reject) => {
    const utcNewDate = moment.utc().format() as any;
    let updatedParentBPStepRow: BusinessProcessStepRow[] = [];
    let parentBPStepRow: BusinessProcessStepRow = isNotNullAndUndefined(
      allbpStepRows
    )
      ? allbpStepRows.find(
          (x) =>
            x.BpStepTemplateUID === updatedbpStepRow.ParentBpStepTemplateUID
        )
      : null; //get parent step
    let childBpStepRows: BusinessProcessStepRow[] =
      isNotNullAndUndefined(parentBPStepRow) &&
      isNotNullAndUndefined(allbpStepRows)
        ? allbpStepRows.filter(
            (x) =>
              x.ParentBpStepTemplateUID === parentBPStepRow.BpStepTemplateUID
          )
        : null; //get all children of parent
    let completedChildBpStepRows: BusinessProcessStepRow[] =
      isNotNullAndUndefined(parentBPStepRow) &&
      isNotNullAndUndefined(allbpStepRows)
        ? allbpStepRows.filter(
            (x) =>
              x.ParentBpStepTemplateUID === parentBPStepRow.BpStepTemplateUID &&
              (x.StepStatus === RAFBPStepStatus.Completed ||
                x.StepStatus === RAFBPStepStatus.Approved ||
                x.StepStatus === RAFBPStepStatus.ResponseReceived ||
                x.StepStatus === RAFBPStepStatus.Reviewed ||
                x.StepStatus === RAFBPStepStatus.Rejected ||
                x.StepStatus === RAFBPStepStatus.Skipped ||
                x.StepStatus === RAFBPStepStatus.RequestforInfo)
          )
        : null; //get completed/approved/rejected/skipped childrens
    if (isNotNullAndUndefined(childBpStepRows)) {
      if (
        isNotNullAndUndefined(completedChildBpStepRows) &&
        completedChildBpStepRows.length === childBpStepRows.length &&
        isNotNullAndUndefined(parentBPStepRow) &&
        parentBPStepRow.StepStatus !== RAFBPStepStatus.Completed &&
        isNullOrUndefined(parentBPStepRow.AssigneeUID)
      ) {
        parentBPStepRow.StepStatus = RAFBPStepStatus.Completed;
        parentBPStepRow.CompletedDate = utcNewDate;
        parentBPStepRow.CompletedBy = msalInstance.currentUserName;
        parentBPStepRow.CompletedByUID = msalInstance.currentUserId;
        //allbpStepRows[bpStepRowIndex].StepStatus = RAFBPStepStatus.Completed;
        //let { children, ...resetParentStepRow } = parentBPStepRow as any;
        if (isNotNullAndUndefined(bpTemplateRow))
          bpTemplateRow.IsOfflineProcess = false;

        if (
          isNotNullAndUndefined(bpTemplateRow) &&
          bpTemplateRow.IsOfflineProcess
        ) {
          SaveBusinessProcessStepFB(parentBPStepRow).then((bpStepResponse) => {
            updatedParentBPStepRow.push({ ...bpStepResponse });

            CheckAndSaveParentStep(
              allbpStepRows,
              parentBPStepRow,
              bpTemplateRow
            ).then((nestedParentStepRow) => {
              if (isNotNullAndUndefined(nestedParentStepRow)) {
                updatedParentBPStepRow.push(R.clone(nestedParentStepRow));
              }
              resolve({ ...updatedParentBPStepRow });
            });
          });
        } else {
          SaveBusinessProcessStepAPI(parentBPStepRow).then((bpStepResponse) => {
            updatedParentBPStepRow.push({ ...bpStepResponse });

            CheckAndSaveParentStep(
              allbpStepRows,
              parentBPStepRow,
              bpTemplateRow
            ).then((nestedParentStepRow) => {
              if (isNotNullAndUndefined(nestedParentStepRow)) {
                updatedParentBPStepRow.push(R.clone(nestedParentStepRow));
              }
              resolve({ ...updatedParentBPStepRow });
            });
          });
        }
      } else {
        resolve(null);
      }
    } else {
      resolve(null);
    }
  }).catch((error) => error);
}

function SaveNextStep(
  allbpStepRows: BusinessProcessStepRow[],
  nextBPStepTemplateRow: BusinessProcessStepRow,
  bpTemplateSteps: BusinessProcessStepTemplateRow[],
  _businessProcessRow: BusinessProcessRow,
  bpTemplate?: BusinessProcessTemplateRow
) {
  return new Promise<{
    updatedNextBPStepRow: BusinessProcessStepRow;
    allbpStepRows: BusinessProcessStepRow[];
  }>((resolve, reject) => {
    let nextBPStepTemplateIndex = isNotNullAndUndefined(bpTemplateSteps)
      ? bpTemplateSteps.findIndex((x) => x.UID === nextBPStepTemplateRow.UID)
      : null;
    let nextBPStepIndex = isNotNullAndUndefined(allbpStepRows)
      ? allbpStepRows.findIndex((x) => x.UID === nextBPStepTemplateRow.UID)
      : null;
    let nextBPStepRow: BusinessProcessStepRow = null;
    let updatedbpStepRows: BusinessProcessStepRow[] = R.clone(allbpStepRows);
    if (isNotNullAndUndefined(nextBPStepTemplateRow)) {
      //Create next step
      let i = nextBPStepIndex;
      let nextChildBPStep: BusinessProcessStepRow = R.clone(allbpStepRows[i]);
      while (isNotNullAndUndefined(nextChildBPStep)) {
        if (nextChildBPStep.IsApproval) {
          //nextChildBPStep.StepStatus = RAFBPStepStatus.RequestApproval;
          nextChildBPStep.StepStatus = RAFBPStepStatus.InitiateRequest;
          if (nextChildBPStep.StepType === RAFBPStepType.Review) {
            nextChildBPStep.StepStatus = RAFBPStepStatus.RequestReview;
          }
        } else {
          if (
            isNotNullAndUndefined(nextChildBPStep.AssigneeUID) ||
            isNotNullAndUndefined(nextChildBPStep.AssignTeamUID)
          ) {
            nextChildBPStep.StepStatus = RAFBPStepStatus.Assigned;
          } else nextChildBPStep.StepStatus = RAFBPStepStatus.ToDo;
        }
        let nextChild = SaveBusinessProcessStepAPI(nextChildBPStep);
        if (isNotNullAndUndefined(nextChildBPStep)) {
          updatedbpStepRows.push({ ...nextChildBPStep });
        }
        if (
          nextChildBPStep.StepType === RAFBPStepType.Group ||
          nextChildBPStep.StepType === RAFBPStepType.Stage
        ) {
          i++;
          nextChildBPStep = allbpStepRows[i];
        } else {
          nextChildBPStep = null;
        }
      }
      resolve({
        updatedNextBPStepRow: allbpStepRows[i],
        allbpStepRows: updatedbpStepRows,
      });
    } else {
      resolve({
        updatedNextBPStepRow: nextBPStepRow,
        allbpStepRows: updatedbpStepRows,
      });
    }
  }).catch((error) => error);
}

export function SaveBusinessProcessStepAPI(
  updatedbpStepRow: BusinessProcessStepRow
) {
  delete updatedbpStepRow["children"];
  const businessProcessStepDto: IBusinessProcessStepDto =
    ConvertBPStepRowToBPStepDto(updatedbpStepRow);
  return new Promise<BusinessProcessStepRow>((resolve, reject) => {
    repositoryActions
      .postDataAndGetResponse(
        "BusinessProcessStep/Save",
        getSaveRequest(businessProcessStepDto, updatedbpStepRow.UID),
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.Entity)
        ) {
          resolve(response.data.Entity);
        }
      })
      .catch((error) => error);
  });
}

export function SaveBusinessProcessStepFB(
  updatedbpStepRow: BusinessProcessStepRow
) {
  delete updatedbpStepRow["children"];
  const businessProcessStepDto: IBusinessProcessStepDto =
    ConvertBPStepRowToBPStepDto(updatedbpStepRow);
  if (IsNullOrWhiteSpace(businessProcessStepDto.UID)) {
    businessProcessStepDto.UID = Guid.newGuid();
  }

  return new Promise<BusinessProcessStepRow>((resolve, reject) => {
    const rafBusinessProcessStepRepository: BusinessProcessStepRepository =
      new BusinessProcessStepRepository();
    rafBusinessProcessStepRepository
      .updateRAFBusinessProcessStep(businessProcessStepDto)
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.UID)
        ) {
          resolve(response);
        } else {
          resolve(null);
        }
      })
      .catch((error) => error);
  });
}

export function SaveBusinessProcessStepTemplateAPI(
  updatedbpStepTemplateRow1: BusinessProcessStepTemplateRow
) {
  let updatedbpStepTemplateRow: BusinessProcessStepTemplateRow = R.clone(
    updatedbpStepTemplateRow1
  );
  delete updatedbpStepTemplateRow["children"];
  let id = isNotNullAndUndefined(updatedbpStepTemplateRow.UID)
    ? updatedbpStepTemplateRow.UID
    : null;

  const businessProcessStepTemplateDto: IBusinessProcessStepTemplateDto =
    ConvertBPStepTemplateRowToBPStepTemplateDto(updatedbpStepTemplateRow);
  return new Promise<BusinessProcessStepTemplateRow>((resolve, reject) => {
    repositoryActions
      .postDataAndGetResponse(
        "BusinessProcessStepTemplate/Save",
        getSaveRequest(businessProcessStepTemplateDto, id),
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.EntityId)
        ) {
          let businessProcessStepTemplate: BusinessProcessStepTemplateRow =
            updatedbpStepTemplateRow;
          businessProcessStepTemplate.UID = response.data.EntityId;
          console.log("resolve api", businessProcessStepTemplate);
          resolve(businessProcessStepTemplate);
        } else {
          resolve(null);
        }
      })
      .catch((error) => error);
  });
}

function GetDependentBusinessProcessStep(
  bpStepRows: BusinessProcessStepRow[],
  currentBPStepRow: BusinessProcessStepRow
) {
  //get steps depending on this step
  let dependentStepRows: BusinessProcessStepRow[] = [];
  if (isNotNullAndUndefined(bpStepRows) && bpStepRows.length > 0) {
    bpStepRows.forEach((item) => {
      if (
        item.DependentBpStepTemplateUID ===
          currentBPStepRow.BpStepTemplateUID &&
        item.BusinessProcessUID === currentBPStepRow.BusinessProcessUID
      ) {
        //item.StepStatus = RAFBPStepStatus.ToDo;
        //SaveBusinessProcessStep(item).then(bpResponse => {
        dependentStepRows.push(item);
        //})
      }
    });
  }
  return dependentStepRows;
}

/*export function CanCompleteBPStep(bpStepRows: BusinessProcessStepRow[], currentBPStepRow: BusinessProcessStepRow) {
    let canComplete: boolean = false;
    const currentStepIndex = bpStepRows && bpStepRows.findIndex(x => x.UID === currentBPStepRow.UID);
    let previousStepRow = bpStepRows && bpStepRows[currentStepIndex - 1];
    let validateStepRow;
    if (isNotNullAndUndefined(previousStepRow)) {
        if (previousStepRow.StepType === RAFBPStepType.Group) {
            validateStepRow = bpStepRows && bpStepRows[currentStepIndex - 2];
        }
        else {
            validateStepRow = previousStepRow;
        }
    }
    return new Promise<boolean>((resolve, reject) => {
        if (isNotNullAndUndefined(validateStepRow) && validateStepRow.StepStatus === RAFBPStepStatus.Completed) {
            canComplete = true;
        }
        resolve(canComplete);
    });

}*/

function UpdateNextBPStepStatus(
  allbpStepRows?: BusinessProcessStepRow[],
  currentbpStepRow?: BusinessProcessStepRow,
  businessProcessRow?: BusinessProcessRow,
  bpTemplateSteps?: BusinessProcessStepTemplateRow[]
) {
  //check for next step. if step exist, make it ready otherwise complete the process
  let currentBpStepIndex =
    allbpStepRows &&
    allbpStepRows.findIndex((x) => x.UID === currentbpStepRow.UID);
  let nextBPStepRow: BusinessProcessStepRow = null;
  let updatedbpStepRows: BusinessProcessStepRow[] = R.clone(allbpStepRows);
  return new Promise<{
    updatedNextBPStepRow: BusinessProcessStepRow;
    allbpStepRows: BusinessProcessStepRow[];
  }>((resolve, reject) => {
    //Commented for updated next step based on decision
    /*if (allbpStepRows && allbpStepRows.length > 0 && currentBpStepIndex >= 0 && currentBpStepIndex < allbpStepRows.length - 1) {
                                        //check next step is group or item
                                        if (allbpStepRows[currentBpStepIndex + 1].StepType === RAFBPStepType.Item || allbpStepRows[currentBpStepIndex + 1].StepType === RAFBPStepType.Task || allbpStepRows[currentBpStepIndex + 1].StepType === RAFBPStepType.ToDo || allbpStepRows[currentBpStepIndex + 1].StepType === RAFBPStepType.Heading || allbpStepRows[currentBpStepIndex + 1].StepType === RAFBPStepType.Decision)
                                            nextBPStepRow = allbpStepRows[currentBpStepIndex + 1];
                                        else
                                            nextBPStepRow = allbpStepRows[currentBpStepIndex + 2];
                                    }*/
    //Get siblings step for the current step
    RetrieveBusinessProcessTemplate(businessProcessRow.BpTemplateUID).then(
      (bpTemplate) => {
        let currentBpStepTemplateRow: BusinessProcessStepTemplateRow =
          isNotNullAndUndefined(bpTemplateSteps) &&
          bpTemplateSteps.find(
            (x) => x.UID === currentbpStepRow.BpStepTemplateUID
          );
        //let siblingBPStepRows: BusinessProcessStepTemplateRow[] = isNotNullAndUndefined(bpTemplateSteps) && bpTemplateSteps.filter(x => x.ParentStepUID === currentBpStepTemplateRow.ParentStepUID && x.UID !== currentBpStepTemplateRow.UID && x.StepOrder > currentbpStepRow.StepOrder);
        let currentBPStepIndex =
          isNotNullAndUndefined(bpTemplateSteps) &&
          bpTemplateSteps.findIndex(
            (x) => x.UID === currentBpStepTemplateRow.UID
          );
        /*if (currentbpStepRow.StepType === RAFBPStepType.Decision) {
                                                                        const promptAnswer = currentbpStepRow.FormData.Prompt;
                                                                        const nextStepConnector: ConnectorRow = currentBpStepTemplateRow.StepConnectorJson.find(x => x.Annotations[0].Content === promptAnswer)
                                                                        const nextStepUID = nextStepConnector.TargetID;
                                                                        let nextBPStepTemplateRow = siblingBPStepRows && siblingBPStepRows.find(x => x.UID === nextStepUID);
                                                                        nextBPStepRow = GenerateBusinessProcessStep(businessProcessRow, bpTemplate, nextBPStepTemplateRow, allbpStepRows);
                                                                    }
                                                                    else {
                                                                        let nextBPStepTemplateRow = GetNextStepTemplate(currentbpStepRow, bpTemplateSteps);
                                                                        //nextBPStepRow = GetNextStep(currentbpStepRow, bpTemplateSteps, allbpStepRows);
                                                                        if (isNotNullAndUndefined(nextBPStepTemplateRow)) {
                                                                            nextBPStepRow = GenerateBusinessProcessStep(businessProcessRow, bpTemplate, nextBPStepTemplateRow, allbpStepRows);
                                                                        }
                                                                    }*/
        if (isNotNullAndUndefined(currentbpStepRow.FormData)) {
          const promptAnswer = currentbpStepRow.FormData.Prompt;
          if (isNotNullAndUndefined(promptAnswer)) {
            const nextStepConnector =
              isNotNullAndUndefined(
                currentBpStepTemplateRow.StepConnectorJson
              ) &&
              currentBpStepTemplateRow.StepConnectorJson.find(
                (x) =>
                  isNotNullAndUndefined(x.Annotations) &&
                  isNotNullAndUndefined(x.Annotations[0]) &&
                  x.Annotations[0].Content === promptAnswer
              );
            let nextStepUID = null;
            if (isNotNullAndUndefined(nextStepConnector)) {
              nextStepUID = nextStepConnector.TargetID;
            }
            if (isNotNullAndUndefined(nextStepUID)) {
              if (nextStepUID === RAFBPAction.Continue) {
                let nextBPStepTemplateRow =
                  bpTemplateSteps[currentBPStepIndex + 1];

                SaveNextStep(
                  updatedbpStepRows,
                  nextBPStepTemplateRow,
                  bpTemplateSteps,
                  businessProcessRow,
                  bpTemplate
                ).then((nextBPStepRowResponse) => {
                  let updatedbpStepRows: BusinessProcessStepRow[] =
                    isNotNullAndUndefined(nextBPStepRowResponse.allbpStepRows)
                      ? nextBPStepRowResponse.allbpStepRows
                      : allbpStepRows;
                  resolve({
                    updatedNextBPStepRow:
                      nextBPStepRowResponse.updatedNextBPStepRow,
                    allbpStepRows: updatedbpStepRows,
                  });
                });
              } else if (nextStepUID === RAFBPAction.End) {
                nextBPStepRow = null;
                if (isNotNullAndUndefined(nextBPStepRow)) {
                  //Update next Step status
                  if (nextBPStepRow.IsApproval) {
                    //nextBPStepRow.StepStatus = RAFBPStepStatus.RequestApproval;
                    nextBPStepRow.StepStatus = RAFBPStepStatus.InitiateRequest;
                    if (nextBPStepRow.StepType === RAFBPStepType.Review) {
                      nextBPStepRow.StepStatus = RAFBPStepStatus.RequestReview;
                    }
                  } else {
                    if (
                      isNotNullAndUndefined(nextBPStepRow.AssigneeUID) ||
                      isNotNullAndUndefined(nextBPStepRow.AssignTeamUID)
                    )
                      nextBPStepRow.StepStatus = RAFBPStepStatus.Assigned;
                    else nextBPStepRow.StepStatus = RAFBPStepStatus.ToDo;
                  }

                  //Create next step
                  SaveBusinessProcessStepAPI(nextBPStepRow).then(
                    (bpstepResponse) => {
                      updatedbpStepRows.push({ ...nextBPStepRow });
                      resolve({
                        updatedNextBPStepRow: nextBPStepRow,
                        allbpStepRows: updatedbpStepRows,
                      });
                    }
                  );

                  //resolve(nextBPStepRow);
                } else {
                  resolve({
                    updatedNextBPStepRow: nextBPStepRow,
                    allbpStepRows: updatedbpStepRows,
                  });
                }
              } else {
                let siblingBPStepRows: BusinessProcessStepTemplateRow[] =
                  isNotNullAndUndefined(bpTemplateSteps) &&
                  bpTemplateSteps.filter(
                    (x) =>
                      x.ParentStepUID ===
                        currentBpStepTemplateRow.ParentStepUID &&
                      x.UID !== currentBpStepTemplateRow.UID
                  );
                let nextBPStepTemplateRow =
                  siblingBPStepRows &&
                  siblingBPStepRows.find((x) => x.UID === nextStepUID);
                nextBPStepRow = GenerateBusinessProcessStep(
                  businessProcessRow,
                  bpTemplate,
                  nextBPStepTemplateRow,
                  allbpStepRows
                );
                if (isNotNullAndUndefined(nextBPStepRow)) {
                  //Update next Step status
                  if (nextBPStepRow.IsApproval) {
                    //nextBPStepRow.StepStatus = RAFBPStepStatus.RequestApproval;
                    nextBPStepRow.StepStatus = RAFBPStepStatus.InitiateRequest;
                    if (nextBPStepRow.StepType === RAFBPStepType.Review) {
                      nextBPStepRow.StepStatus = RAFBPStepStatus.RequestReview;
                    }
                  } else {
                    if (
                      isNotNullAndUndefined(nextBPStepRow.AssigneeUID) ||
                      isNotNullAndUndefined(nextBPStepRow.AssignTeamUID)
                    )
                      nextBPStepRow.StepStatus = RAFBPStepStatus.Assigned;
                    else nextBPStepRow.StepStatus = RAFBPStepStatus.ToDo;
                  }

                  //Create next step
                  SaveBusinessProcessStepAPI(nextBPStepRow).then(
                    (bpstepResponse) => {
                      updatedbpStepRows.push({ ...nextBPStepRow });
                      resolve({
                        updatedNextBPStepRow: nextBPStepRow,
                        allbpStepRows: updatedbpStepRows,
                      });
                    }
                  );

                  //resolve(nextBPStepRow);
                } else {
                  resolve({
                    updatedNextBPStepRow: nextBPStepRow,
                    allbpStepRows: updatedbpStepRows,
                  });
                }
              }
            }
          }
        }
      }
    );
  });
}

function UpdateNextBPStep(
  allbpStepRows?: BusinessProcessStepRow[],
  currentbpStepRow?: BusinessProcessStepRow,
  businessProcessRow?: BusinessProcessRow,
  bpTemplateSteps?: BusinessProcessStepTemplateRow[]
) {
  //check for next step. if step exist, make it ready otherwise complete the process
  let currentBpStepIndex =
    allbpStepRows &&
    allbpStepRows.findIndex((x) => x.UID === currentbpStepRow.UID);
  let nextBPStepRow: BusinessProcessStepRow = null;
  let updatedbpStepRows: BusinessProcessStepRow[] = R.clone(allbpStepRows);
  return new Promise<{
    updatedNextBPStepRow: BusinessProcessStepRow;
    allbpStepRows: BusinessProcessStepRow[];
  }>((resolve, reject) => {
    if (isNotNullAndUndefined(currentbpStepRow.FormData)) {
      const promptAnswer = currentbpStepRow.FormData.Prompt;
      if (isNotNullAndUndefined(promptAnswer)) {
        const nextStepConnector =
          isNotNullAndUndefined(currentbpStepRow.StepConnectorJson) &&
          currentbpStepRow.StepConnectorJson.find(
            (x) =>
              isNotNullAndUndefined(x.Annotations) &&
              isNotNullAndUndefined(x.Annotations[0]) &&
              x.Annotations[0].Content === promptAnswer
          );
        let nextStepUID = null;
        if (isNotNullAndUndefined(nextStepConnector)) {
          nextStepUID = nextStepConnector.TargetID;
        }
        if (isNotNullAndUndefined(nextStepUID)) {
          if (nextStepUID === RAFBPAction.Continue) {
            let nextBPStepRow = allbpStepRows[currentBpStepIndex + 1];
            if (isNotNullAndUndefined(nextBPStepRow)) {
              SaveNextStep(
                updatedbpStepRows,
                nextBPStepRow,
                bpTemplateSteps,
                businessProcessRow
              ).then((nextBPStepRowResponse) => {
                let updatedbpStepRows: BusinessProcessStepRow[] =
                  isNotNullAndUndefined(nextBPStepRowResponse.allbpStepRows)
                    ? nextBPStepRowResponse.allbpStepRows
                    : allbpStepRows;
                resolve({
                  updatedNextBPStepRow:
                    nextBPStepRowResponse.updatedNextBPStepRow,
                  allbpStepRows: updatedbpStepRows,
                });
              });
            } else {
              resolve({
                updatedNextBPStepRow: null,
                allbpStepRows: updatedbpStepRows,
              });
            }
          } else if (nextStepUID === RAFBPAction.End) {
            nextBPStepRow = null;
            if (isNotNullAndUndefined(nextBPStepRow)) {
              //Update next Step status
              if (nextBPStepRow.IsApproval) {
                //nextBPStepRow.StepStatus = RAFBPStepStatus.RequestApproval;
                nextBPStepRow.StepStatus = RAFBPStepStatus.InitiateRequest;
                if (nextBPStepRow.StepType === RAFBPStepType.Review) {
                  nextBPStepRow.StepStatus = RAFBPStepStatus.RequestReview;
                }
              } else {
                if (
                  isNotNullAndUndefined(nextBPStepRow.AssigneeUID) ||
                  isNotNullAndUndefined(nextBPStepRow.AssignTeamUID)
                )
                  nextBPStepRow.StepStatus = RAFBPStepStatus.Assigned;
                else nextBPStepRow.StepStatus = RAFBPStepStatus.ToDo;
              }

              //Create next step
              SaveBusinessProcessStepAPI(nextBPStepRow).then(
                (bpstepResponse) => {
                  updatedbpStepRows.push({ ...nextBPStepRow });
                  resolve({
                    updatedNextBPStepRow: nextBPStepRow,
                    allbpStepRows: updatedbpStepRows,
                  });
                }
              );

              //resolve(nextBPStepRow);
            } else {
              resolve({
                updatedNextBPStepRow: nextBPStepRow,
                allbpStepRows: updatedbpStepRows,
              });
            }
          } else {
            let siblingBPStepRows: BusinessProcessStepRow[] =
              isNotNullAndUndefined(allbpStepRows) &&
              allbpStepRows.filter(
                (x) =>
                  x.ParentStepUID === currentbpStepRow.ParentStepUID &&
                  x.UID !== currentbpStepRow.UID
              );
            let nextBPStepRow =
              siblingBPStepRows &&
              siblingBPStepRows.find((x) => x.UID === nextStepUID);

            if (isNotNullAndUndefined(nextBPStepRow)) {
              //Update next Step status
              if (nextBPStepRow.IsApproval) {
                //nextBPStepRow.StepStatus = RAFBPStepStatus.RequestApproval;
                nextBPStepRow.StepStatus = RAFBPStepStatus.InitiateRequest;
                if (nextBPStepRow.StepType === RAFBPStepType.Review) {
                  nextBPStepRow.StepStatus = RAFBPStepStatus.RequestReview;
                }
              } else {
                if (
                  isNotNullAndUndefined(nextBPStepRow.AssigneeUID) ||
                  isNotNullAndUndefined(nextBPStepRow.AssignTeamUID)
                )
                  nextBPStepRow.StepStatus = RAFBPStepStatus.Assigned;
                else nextBPStepRow.StepStatus = RAFBPStepStatus.ToDo;
              }

              //Create next step
              SaveBusinessProcessStepAPI(nextBPStepRow).then(
                (bpstepResponse) => {
                  updatedbpStepRows.push({ ...nextBPStepRow });
                  resolve({
                    updatedNextBPStepRow: nextBPStepRow,
                    allbpStepRows: updatedbpStepRows,
                  });
                }
              );

              //resolve(nextBPStepRow);
            } else {
              resolve({
                updatedNextBPStepRow: nextBPStepRow,
                allbpStepRows: updatedbpStepRows,
              });
            }
          }
        } else {
          resolve({
            updatedNextBPStepRow: null,
            allbpStepRows: updatedbpStepRows,
          });
        }
      }
    }
  });
}

//dont delete this function
/*export function StartProcessAndGetMatchingSteps(bpStartRequest?: BPStartRequest) {
    const bptemplateId: string = bpStartRequest.bptemplateId;
    const bpFormData: any = bpStartRequest.bpFormData;
    const bpDuedate: Date = bpStartRequest.bpDuedate;
    const bpRelatedTo: string = bpStartRequest.bpRelatedTo;
    const bpRelatedToType: string = bpStartRequest.bpRelatedToType;
    const bpRelatedToUID: string = bpStartRequest.bpRelatedToUID;
    const objectData: any = bpStartRequest.objectData;

    return new Promise<{ bpTemplateRow: BusinessProcessTemplateRow, bpStepTemplateRows: BusinessProcessStepTemplateRow[], bpRow: BusinessProcessRow, bpStepRows: BusinessProcessStepRow[] }>((resolve, reject) => {

        StartProcessAndSaveSteps(bpStartRequest).then(bpResponse => {
            if (isNotNullAndUndefined(bpResponse) && isNotNullAndUndefined(bpResponse.bpRow) && isNotNullAndUndefined(bpResponse.bpRow.UID)) {
                const allSteps: BusinessProcessStepTemplateRow[] = bpResponse.bpStepTemplateRows;
                let matchingSteps: BusinessProcessStepTemplateRow[] = [];
                if (isNotNullAndUndefined(allSteps) && allSteps.length > 0) {


                    let promiseArr = allSteps.map(function (step) {
                        console.log('StartProcessAndGetMatchingSteps1 allSteps loop step', step);
                        const bpStep = step;
                        return EvaluateConditionForStep(step, objectData).then(function (isMatch) {
                            if (isMatch)
                                return bpStep;
                            else
                                return null;
                        })
                    });

                    Promise.all(promiseArr).then(function (resultsArray) {
                        console.log('StartProcessAndGetMatchingSteps5 Promise.all resultsArray', resultsArray);
                        for (var i = 0; i < resultsArray.length; i++) {
                            const step = resultsArray[i];
                            if (isNotNullAndUndefined(step) && isNotNullAndUndefined(step.UID)) {
                                matchingSteps.push(step);
                            }
                        }
                        resolve({ bpTemplateRow: bpResponse.bpTemplateRow, bpStepTemplateRows: matchingSteps, bpRow: bpResponse.bpRow, bpStepRows: bpResponse.bpStepRows });
                    }).catch(function (err) {
                        // do something when any of the promises in array are rejected
                    })

                }
                else {
                    resolve({ bpTemplateRow: bpResponse.bpTemplateRow, bpStepTemplateRows: bpResponse.bpStepTemplateRows, bpRow: bpResponse.bpRow, bpStepRows: bpResponse.bpStepRows });
                }

            }
        })
    })

}*/

function EvaluateConditionForStep(
  step: BusinessProcessStepTemplateRow,
  objectData?: any
) {
  const bpStep = step;
  // return the promise to array
  return new Promise<{ action: string; value: any }[]>(
    (resolve /*, reject*/) => {
      if (isNotNullAndUndefined(bpStep)) {
        return GetBusinessRulesByRelatedTo(bpStep.UID).then(function (
          businessRules
        ) {
          //a Promise
          //do something with the resource that has been modified with someFunction
          //return res;

          //console.log('EvaluateConditionForStep2 businessRules', businessRules);
          const rules: RuleProperties[] =
            ConvertBusinessRuleJMToRuleProperties(businessRules);
          //console.log('EvaluateConditionForStep3 rules', rules, objectData);

          if (isNotNullAndUndefined(rules) && rules.length > 0) {
            return runRulesEngine1(rules, objectData).then(
              (businessActions) => {
                //console.log('EvaluateConditionForStep4 businessActions', businessActions);
                if (isNotNullAndUndefined(businessActions)) {
                  const retVal: { action: string; value: any }[] = [];
                  for (var i = 0; i < businessActions.length; i++) {
                    const action: BusinessAction = businessActions[i];
                    if (
                      isNotNullAndUndefined(action) &&
                      isNotNullAndUndefined(action.Name) &&
                      isNotNullAndUndefined(action.Param)
                    ) {
                      if (
                        action.Name === RAFBusinessAction.showStep ||
                        action.Name === RAFBusinessAction.hideStep ||
                        action.Name === RAFBusinessAction.hideField ||
                        action.Name === RAFBusinessAction.showField
                      ) {
                        retVal.push({
                          action: action.Name,
                          value: action.Param.Value,
                        });
                      }
                    }
                  }
                  resolve(retVal);
                } else {
                  resolve(null);
                }
              }
            );
          } else {
            resolve(null);
          }
        });
      } else {
        resolve(null);
      }
    }
  );
}

export function GetBusinessRulesByRelatedTo(relatedToUID?: string) {
  let relatedFilter: RAFCustomFilter = {};
  relatedFilter.Condition = "and";
  relatedFilter.Rules = [];
  let filter1: RAFCustomFilter = {};
  let filterVal1: string[] = [];
  filterVal1.push(relatedToUID);
  filter1.Operator = RAFCustomOperator.Equal;
  filter1.Value = filterVal1;
  filter1.Field = propertyOf<BusinessRuleRow>("ProcessRelatedtoUID");
  relatedFilter.Rules.push(filter1);
  let listServiceRequest = new ListServiceRequest();
  listServiceRequest.CustomFilter = relatedFilter;
  listServiceRequest.Skip = 0;
  listServiceRequest.Take = 0;
  //listServiceRequest.ViewName = 'all_bptemplate_steps';
  return new Promise<BusinessRuleRow[]>((resolve, reject) => {
    repositoryActions
      .postDataAndGetResponse(
        "BusinessRule/List",
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data)
        ) {
          resolve(response.data.Entities);
        }
      })
      .catch((error) => error);
  });
}

export function MergeBPStepAndTemplateStep(
  businessProcess: BusinessProcessRow,
  bpSteps: BusinessProcessStepRow[],
  bpTemplate: BusinessProcessTemplateRow,
  bpTemplateStepsRow: BusinessProcessStepTemplateRow[]
) {
  let bpStepRows: BusinessProcessStepRow[] = [];
  let bpStepsRowClone: BusinessProcessStepRow[] = R.clone(bpSteps);
  let nextStepIndex: number = null;
  //let bpStepTemplatesRow = GenerateBusinessProcessTemplateStepOrder(bpTemplateSteps);
  let completedSteps: BusinessProcessStepRow[] = [];

  let reOrderedBPSteps = GetOrderedBPStepTemplateArray(
    bpTemplateStepsRow,
    null
  );
  let bpTemplateSteps = GetBPStepTemplateArrayOrdered(reOrderedBPSteps);

  /*bpTemplateSteps && bpTemplateSteps.sort(function (x, y) {
                      return (x.CreatedDate === y.CreatedDate) ? 0 : x.CreatedDate ? -1 : 1;
                  });*/
  if (isNotNullAndUndefined(bpTemplateSteps) && bpTemplateSteps.length > 0) {
    bpTemplateSteps &&
      bpTemplateSteps.forEach((bpTemplateStep, index) => {
        let bpStepRow: BusinessProcessStepRow = {};
        if (isNotNullAndUndefined(bpSteps)) {
          bpStepRow = bpSteps.find(
            (x) => x.BpStepTemplateUID === bpTemplateStep.UID
          );
        }
        if (
          isNotNullAndUndefined(bpStepRow) &&
          !IsNullOrWhiteSpace(bpStepRow.UID)
        ) {
          //Calculate deadline and save the dependent bp step
          if (
            isNotNullAndUndefined(bpTemplateStep.IsDependent) &&
            bpTemplateStep.IsDependent === true
          ) {
            let dependentStepRow = bpStepsRowClone.find(
              (x) =>
                x.BpStepTemplateUID === bpStepRow.DependentBpStepTemplateUID
            );
            bpStepRow.DueDate = CalculateDeadline(
              businessProcess.StartDate,
              dependentStepRow && dependentStepRow.CompletedDate,
              bpTemplateStep.StepDeadlineUnits,
              bpTemplateStep.StepDeadlineInterval,
              bpTemplateStep.StepDeadlineCalculation
            );
          }
          bpStepRows.push({ ...bpStepRow });
        } else {
          if (
            bpTemplateStep.StepStatus === RAFBPStepTemplateStatus.Active ||
            bpTemplateStep.StepStatus === RAFBPStepTemplateStatus.Hidden
          ) {
            bpStepRow = GenerateBusinessProcessStep(
              businessProcess,
              bpTemplate,
              bpTemplateStep,
              bpSteps
            );
            bpStepRows.push({ ...bpStepRow });
            if (
              isNullOrUndefined(nextStepIndex) &&
              isNotNullAndUndefined(bpTemplate) &&
              (bpTemplate.ExecutionOrder === RAFExecutionOrder.StepByStep ||
                bpTemplate.ExecutionOrder === RAFExecutionOrder.Conditional)
            ) {
              let bpTemplateStepChildRows: BusinessProcessStepTemplateRow[] =
                bpTemplateStep["children"];
              if (
                bpTemplateStepChildRows &&
                bpTemplateStepChildRows.length > 0
              ) {
                bpTemplateStepChildRows.length > 0 &&
                  bpTemplateStepChildRows.forEach((item, j) => {
                    let stepRow: BusinessProcessStepRow =
                      bpStepsRowClone &&
                      bpStepsRowClone.find(
                        (x) => x.BpStepTemplateUID === item.UID
                      );
                    if (
                      stepRow &&
                      (stepRow.StepStatus === RAFBPStepStatus.Completed ||
                        stepRow.StepStatus ===
                          RAFBPStepStatus.ResponseReceived ||
                        stepRow.StepStatus === RAFBPStepStatus.Approved ||
                        stepRow.StepStatus === RAFBPStepStatus.Reviewed ||
                        stepRow.StepStatus === RAFBPStepStatus.Rejected ||
                        stepRow.StepStatus === RAFBPStepStatus.Skipped)
                    ) {
                      completedSteps.push(stepRow);
                    } else {
                      nextStepIndex = j;
                    }
                  });
              } else {
                nextStepIndex = index + 1;
              }

              if (
                bpTemplateStep.StepType === RAFBPStepType.Item ||
                bpTemplateStep.StepType === RAFBPStepType.Task ||
                bpTemplateStep.StepType === RAFBPStepType.ToDo ||
                bpTemplateStep.StepType === RAFBPStepType.Instruction ||
                bpTemplateStep.StepType === RAFBPStepType.Heading ||
                bpTemplateStep.StepType === RAFBPStepType.Approval ||
                bpTemplateStep.StepType === RAFBPStepType.Review ||
                bpTemplateStep.StepType === RAFBPStepType.Decision ||
                bpTemplateStep.StepType === RAFBPStepType.Question ||
                bpTemplateStep.StepType === RAFBPStepType.Note
              ) {
                if (index === 0) nextStepIndex = index;
                else nextStepIndex = index + 1;
              }
            }
          }
        }
      });
  } else {
    bpStepsRowClone &&
      bpStepsRowClone.forEach((bpStepRow) => {
        bpStepRows.push({ ...bpStepRow });
      });
  }

  //Update Business Process Step status
  if (
    isNotNullAndUndefined(businessProcess) &&
    isNotNullAndUndefined(businessProcess.ExecutionOrder)
  ) {
    if (
      businessProcess.ExecutionOrder === RAFExecutionOrder.StepByStep ||
      businessProcess.ExecutionOrder === RAFExecutionOrder.Conditional
    ) {
      //if sequential, find next step. check if there is already bp row. if bp row does not exist change status to TO-DO

      if (isNotNullAndUndefined(bpStepRows)) {
        let i = 0;
        let firstBPStep: BusinessProcessStepRow = bpStepRows[i];
        while (
          isNotNullAndUndefined(firstBPStep) &&
          firstBPStep.StepStatus === RAFBPStepStatus.Queued
        ) {
          if (firstBPStep.IsApproval) {
            //firstBPStep.StepStatus = RAFBPStepStatus.RequestApproval;
            firstBPStep.StepStatus = RAFBPStepStatus.InitiateRequest;
            if (firstBPStep.StepType === RAFBPStepType.Review) {
              firstBPStep.StepStatus = RAFBPStepStatus.RequestReview;
            }
          } else {
            if (
              isNotNullAndUndefined(firstBPStep.AssigneeUID) ||
              isNotNullAndUndefined(firstBPStep.AssignTeamUID)
            ) {
              firstBPStep.StepStatus = RAFBPStepStatus.Assigned;
            } else {
              firstBPStep.StepStatus = RAFBPStepStatus.ToDo;
            }
          }

          if (
            firstBPStep.StepType === RAFBPStepType.Group ||
            firstBPStep.StepType === RAFBPStepType.Stage
          ) {
            i++;
            firstBPStep = bpStepRows[i];
          } else {
            firstBPStep = null;
          }
        }
      }
    } else {
      if (isNotNullAndUndefined(bpStepRows))
        bpStepRows.forEach((stepRow) => {
          //if ((isNullOrUndefined(stepRow.IsDependent) || stepRow.IsDependent === false) && (isNullOrUndefined(stepRow.IsConditional) || stepRow.IsConditional === false)) {
          let bpStepRow: BusinessProcessStepRow = {};
          if (isNotNullAndUndefined(bpStepsRowClone)) {
            bpStepRow = bpStepsRowClone.find((x) => x.UID === stepRow.UID);
          }
          if (isNotNullAndUndefined(bpStepRow)) {
          } else {
            if (isNotNullAndUndefined(bpStepsRowClone)) {
              bpStepRow = bpStepsRowClone.find(
                (x) => x.BpStepTemplateUID === stepRow.BpStepTemplateUID
              );
            }
          }
          if (
            isNotNullAndUndefined(bpStepRow) &&
            !IsNullOrWhiteSpace(bpStepRow.UID)
          ) {
          } else {
            if (stepRow.StepStatus !== RAFBPStepTemplateStatus.Hidden) {
              if (stepRow.IsApproval) {
                //stepRow.StepStatus = RAFBPStepStatus.RequestApproval;
                stepRow.StepStatus = RAFBPStepStatus.InitiateRequest;

                if (stepRow.StepType === RAFBPStepType.Review) {
                  stepRow.StepStatus = RAFBPStepStatus.RequestReview;
                }
              } else {
                if (
                  isNotNullAndUndefined(stepRow.AssigneeUID) ||
                  isNotNullAndUndefined(stepRow.AssignTeamUID)
                ) {
                  stepRow.StepStatus = RAFBPStepStatus.Assigned;
                } else {
                  stepRow.StepStatus = RAFBPStepStatus.ToDo;
                }
              }
            }
          }
          //}
        });
    }
  }

  return bpStepRows;
}

export function GetTaskByBPStepUID(bpStepUID?: string) {
  let relatedFilter: RAFCustomFilter = {};
  relatedFilter.Condition = "and";
  relatedFilter.Rules = [];
  let filter1: RAFCustomFilter = {};
  let filterVal1: string[] = [];
  filterVal1.push(bpStepUID);
  filter1.Operator = RAFCustomOperator.Equal;
  filter1.Value = filterVal1;
  filter1.Field = propertyOf<TaskRow>("BPStepUID");
  relatedFilter.Rules.push(filter1);
  let listServiceRequest = new ListServiceRequest();
  listServiceRequest.CustomFilter = relatedFilter;
  listServiceRequest.Skip = 0;
  listServiceRequest.Take = 0;
  //listServiceRequest.ViewName = "all_tasks";
  return new Promise<TaskRow>((resolve, reject) => {
    repositoryActions
      .postDataAndGetResponse(
        "Task/List",
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.Entities)
        ) {
          let taskRow: TaskRow[] = response.data.Entities;
          resolve(taskRow[0]);
        } else {
          resolve(null);
        }
      })
      .catch((error) => error);
  });
}

export function RetrieveBusinessProcessStepById(bpStepId?: string) {
  return new Promise<BusinessProcessStepRow>((resolve, reject) => {
    repositoryActions
      .postDataAndGetResponse(
        "BusinessProcessStep/Retrieve",
        { EntityId: bpStepId },
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data)
        ) {
          let businessProcessStep: BusinessProcessStepRow =
            ConvertBPStepDtoToBPStepRow(response.data.Entity);
          resolve(businessProcessStep);
        }
      })
      .catch((error) => error);
  });
}

export function GetBPStepsAndTemplateStepsById(bpStepUID?: string) {
  return new Promise<{
    currentBPStepRow: BusinessProcessStepRow;
    allbpStepRows: BusinessProcessStepRow[];
    businessProcessRow: BusinessProcessRow;
    bpTemplateSteps: BusinessProcessStepTemplateRow[];
  }>((resolve, reject) => {
    RetrieveBusinessProcessStepById(bpStepUID).then((bpStepResponse) => {
      RetrieveBusinessProcessById(bpStepResponse.BusinessProcessUID).then(
        (bpResponse) => {
          //1st
          GetbusinessProcessSteps(bpResponse.UID, "all_bp_steps").then(
            (bpStepsResponse) => {
              RetrieveBusinessProcessTemplate(bpResponse.BpTemplateUID).then(
                (bpTemplate) => {
                  GetBusinessProcessTemplateSteps(bpTemplate).then(
                    (bpTemplateStepsReponse) => {
                      const mergedSteps: BusinessProcessStepRow[] =
                        MergeBPStepAndTemplateStep(
                          bpResponse,
                          bpStepsResponse,
                          bpTemplate,
                          bpTemplateStepsReponse
                        );
                      if (isNotNullAndUndefined(mergedSteps)) {
                        let businessProcessSteps: BusinessProcessStepRow[] =
                          mergedSteps;
                        let businessProcessStepRows =
                          GenerateBusinessProcessStepsOrder(
                            businessProcessSteps
                          );
                        resolve({
                          currentBPStepRow: bpStepResponse,
                          allbpStepRows: mergedSteps,
                          businessProcessRow: bpResponse,
                          bpTemplateSteps: bpTemplateStepsReponse,
                        });
                      } else {
                        resolve({
                          currentBPStepRow: bpStepResponse,
                          allbpStepRows: bpStepsResponse,
                          businessProcessRow: bpResponse,
                          bpTemplateSteps: bpTemplateStepsReponse,
                        });
                      }
                    }
                  );
                }
              );
            }
          );
        }
      );
    });
  });
}

export function CompleteTaskByStepID(
  businessProcessStepRow?: BusinessProcessStepRow,
  taskStatus?: string
) {
  //Get Task by StepID
  return new Promise<BusinessProcessStepRow>((resolve, reject) => {
    GetTaskByBPStepUID(businessProcessStepRow.UID).then((taskRow) => {
      if (
        isNotNullAndUndefined(taskRow) &&
        taskRow.TaskStatus === RAFActionStatus.Planned
      ) {
        //if task exist complete the task and bpstep
        taskRow.TaskStatus = isNotNullAndUndefined(taskStatus)
          ? taskStatus
          : RAFActionStatus.Completed;
        //taskRow.CompletedDate = new Date();
        // taskRow.CompletedBy = msalInstance.currentUserName;
        //taskRow.CompletedByUID = msalInstance.currentUserId;
        completeTaskAndRemoveFromFocusList(taskRow)
          .then((response) => {
            resolve(businessProcessStepRow);
          })
          .catch((error) => error);
      } else {
        //complete bpstep
        resolve(businessProcessStepRow);
      }
    });
  });
}

export function CompleteTaskAndStep(
  businessProcessStepRow?: BusinessProcessStepRow,
  businessProcessStepRows?: BusinessProcessStepRow[],
  businessProcessRow?: BusinessProcessRow,
  bpTemplateSteps?: BusinessProcessStepTemplateRow[],
  objectData?: any,
  taskStatus?: string
) {
  if (businessProcessStepRow.StepType === RAFBPStepType.Decision) {
  } else {
    if (isNullOrUndefined(businessProcessStepRow.FormData)) {
      businessProcessStepRow.FormData = {};
    }
    businessProcessStepRow.FormData["Prompt"] = taskStatus;
  }

  let updatedCurrentStep: BusinessProcessStepRow = {};
  let otherObj = {};

  Object.entries(businessProcessStepRow).forEach(([key, value], index) => {
    //console.log(key, value, index,key.startsWith("FormData_Module_"));
    if (key.startsWith("FormData_Module_")) {
      otherObj[key.replace("FormData_Module_", "")] = value;
    } else if (key.startsWith("FormData_Form_")) {
      otherObj[key.replace("FormData_Form_", "")] = value;
    } else {
      updatedCurrentStep[key] = value;
    }
  });

  //Get Task by StepID
  return new Promise<{
    allBPRows: BusinessProcessStepRow[];
    dependentBPRows: BusinessProcessStepRow[];
  }>((resolve, reject) => {
    GetTaskByBPStepUID(updatedCurrentStep.UID).then((taskRow) => {
      if (
        isNotNullAndUndefined(taskRow) &&
        taskRow.TaskStatus === RAFActionStatus.Planned
      ) {
        //if task exist complete the task and bpstep
        CompleteTaskByStepID(updatedCurrentStep, taskStatus).then(
          (bpResponse) => {
            //complete step
            SaveBPStepAndUpdateBP(
              bpResponse,
              businessProcessStepRows,
              businessProcessRow,
              bpTemplateSteps,
              objectData
            ).then((result) => {
              resolve({
                allBPRows: result.allBPRows,
                dependentBPRows: result.dependentBPRows,
              });
            });
          }
        );
      } else {
        //complete step
        SaveBPStepAndUpdateBP(
          updatedCurrentStep,
          businessProcessStepRows,
          businessProcessRow,
          bpTemplateSteps,
          objectData
        ).then((result) => {
          resolve({
            allBPRows: result.allBPRows,
            dependentBPRows: result.dependentBPRows,
          });
        });
      }
    });
  });
}

export function GenerateBusinessProcessStepsOrder(
  businessProcessSteps: BusinessProcessStepRow[]
) {
  let i;
  let groupIndex = 0;
  let index = 0;

  const data = businessProcessSteps,
    bpsteps = (function (data, root) {
      const temp = {};
      data.forEach((obj) => {
        Object.assign(
          (temp[obj.BpStepTemplateUID] = temp[obj.BpStepTemplateUID] || {}),
          obj
        );
        temp[obj.ParentBpStepTemplateUID] =
          temp[obj.ParentBpStepTemplateUID] || {};
        temp[obj.ParentBpStepTemplateUID].children =
          temp[obj.ParentBpStepTemplateUID].children || [];
        temp[obj.ParentBpStepTemplateUID].children.push(
          temp[obj.BpStepTemplateUID]
        );
      });
      return temp[root] && temp[root].children;
    })(data, undefined);

  let allbpStepRows: BusinessProcessStepRow[] = flattenArray(bpsteps);

  for (i = 0; i < allbpStepRows.length; i++) {
    if (isNotNullAndUndefined(allbpStepRows[i].ParentBpStepTemplateUID)) {
      groupIndex = groupIndex + 0.1;
      let order = index + groupIndex;
      allbpStepRows[i].StepOrder = order;
    } else {
      index = index + 1;
      groupIndex = 0;
      allbpStepRows[i].StepOrder = index;
    }
  }
  return allbpStepRows;
}

export function GenerateBusinessProcessTemplateStepOrder(
  bpStepTemplateRow: BusinessProcessStepTemplateRow[]
) {
  let i;
  let groupIndex = 0;
  let index = 0;

  const data = bpStepTemplateRow,
    bpsteps = (function (data, root) {
      const temp = {};
      data.forEach((obj) => {
        Object.assign((temp[obj.UID] = temp[obj.UID] || {}), obj);
        temp[obj.ParentStepUID] = temp[obj.ParentStepUID] || {};
        temp[obj.ParentStepUID].children =
          temp[obj.ParentStepUID].children || [];
        temp[obj.ParentStepUID].children.push(temp[obj.UID]);
      });
      return temp[root] && temp[root].children;
    })(data, undefined);

  let allbpStepRows: BusinessProcessStepTemplateRow[] = flattenArray(bpsteps);

  for (i = 0; i < allbpStepRows.length; i++) {
    if (isNotNullAndUndefined(allbpStepRows[i].ParentStepUID)) {
      groupIndex = groupIndex + 0.1;
      let order = index + groupIndex;
      allbpStepRows[i].StepOrder = order;
    } else {
      index = index + 1;
      groupIndex = 0;
      allbpStepRows[i].StepOrder = index;
    }
  }
  return allbpStepRows;
}

export function getBusinessProcessTemplateListByBpTemplateUID(
  businessTemplateUID
) {
  let relatedFilter: RAFCustomFilter = {};
  relatedFilter.Condition = "and";
  relatedFilter.Rules = [];
  let filter1: RAFCustomFilter = {};
  let filterVal1: string[] = [];
  filterVal1.push(businessTemplateUID);
  filter1.Operator = RAFCustomOperator.Equal;
  filter1.Value = filterVal1;
  filter1.Field = propertyOf<BusinessProcessRow>("BpTemplateUID");
  relatedFilter.Rules.push(filter1);
  let listServiceRequest = new ListServiceRequest();
  listServiceRequest.CustomFilter = relatedFilter;
  listServiceRequest.Skip = 0;
  listServiceRequest.Take = 0;
  //listServiceRequest.ViewName = viewName; //'';

  return new Promise<BusinessProcessRow[]>((resolve, reject) => {
    repositoryActions
      .postDataAndGetResponse(
        "BusinessProcess/List",
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data)
        ) {
          resolve(response.data.Entities);
        }
      })
      .catch((error) => error);
  });
}

export function getBusinessProcessTemplateListByProcessName(
  processName,
  viewName?: string,
  isModuleProcess?: boolean,
  formUID?: string
) {
  let relatedFilter: RAFCustomFilter = {};
  relatedFilter.Condition = "and";
  relatedFilter.Rules = [];

  let filter1: RAFCustomFilter = {};
  let filterVal1: string[] = [];
  filterVal1.push(processName);
  filter1.Operator = RAFCustomOperator.Equal;
  filter1.Value = filterVal1;
  filter1.Field = propertyOf<BusinessProcessTemplateRow>("ProcessName");
  relatedFilter.Rules.push(filter1);

  if (isModuleProcess === true && isNotNullAndUndefined(formUID)) {
    let filter2: RAFCustomFilter = {};
    let filterVal2: string[] = [];
    filterVal2.push(formUID);
    filter2.Operator = RAFCustomOperator.Equal;
    filter2.Value = filterVal2;
    filter2.Field = propertyOf<BusinessProcessTemplateRow>("FormUID");
    relatedFilter.Rules.push(filter2);
  }

  let listServiceRequest = new ListServiceRequest();
  listServiceRequest.CustomFilter = relatedFilter;
  listServiceRequest.Skip = 0;
  listServiceRequest.Take = 0;
  if (isNotNullAndUndefined(viewName)) listServiceRequest.ViewName = viewName; //'';

  let url =
    isModuleProcess === true
      ? "BusinessProcessTemplate/ListByModule"
      : "BusinessProcessTemplate/List";

  return new Promise<BusinessProcessTemplateRow[]>((resolve, reject) => {
    repositoryActions
      .postDataAndGetResponse(
        url,
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data)
        ) {
          resolve(response.data.Entities);
        } else {
          resolve(null);
        }
      })
      .catch((error) => error);
  });
}

export function getBusinessProcessTemplateListAllByProcessName(
  processName,
  viewName?: string,
  isModuleProcess?: boolean,
  formUID?: string
) {
  let relatedFilter: RAFCustomFilter = {};
  relatedFilter.Condition = "and";
  relatedFilter.Rules = [];

  let filter1: RAFCustomFilter = {};
  let filterVal1: string[] = [];
  filterVal1.push(processName);
  filter1.Operator = RAFCustomOperator.Equal;
  filter1.Value = filterVal1;
  filter1.Field = propertyOf<BusinessProcessTemplateRow>("ProcessName");
  relatedFilter.Rules.push(filter1);

  if (isModuleProcess === true && isNotNullAndUndefined(formUID)) {
    let filter2: RAFCustomFilter = {};
    let filterVal2: string[] = [];
    filterVal2.push(formUID);
    filter2.Operator = RAFCustomOperator.Equal;
    filter2.Value = filterVal2;
    filter2.Field = propertyOf<BusinessProcessTemplateRow>("FormUID");
    relatedFilter.Rules.push(filter2);
  }

  let listServiceRequest = new ListServiceRequest();
  listServiceRequest.CustomFilter = relatedFilter;
  listServiceRequest.Skip = 0;
  listServiceRequest.Take = 0;
  if (isNotNullAndUndefined(viewName)) listServiceRequest.ViewName = viewName; //'';

  let url =
    isModuleProcess === true
      ? "BusinessProcessTemplate/ListByModule"
      : "BusinessProcessTemplate/ListAll";

  return new Promise<BusinessProcessTemplateRow[]>((resolve, reject) => {
    repositoryActions
      .postDataAndGetResponse(
        url,
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data)
        ) {
          resolve(response.data.Entities);
        } else {
          resolve(null);
        }
      })
      .catch((error) => error);
  });
}

export function getBusinessProcessListByProcessName(
  processName?: string,
  viewName?: string
) {
  let relatedFilter: RAFCustomFilter = {};
  relatedFilter.Condition = "and";
  relatedFilter.Rules = [];
  let filter1: RAFCustomFilter = {};
  let filterVal1: string[] = [];
  filterVal1.push(processName);
  filter1.Operator = RAFCustomOperator.Equal;
  filter1.Value = filterVal1;
  filter1.Field = propertyOf<BusinessProcessRow>("ProcessName");
  relatedFilter.Rules.push(filter1);
  let listServiceRequest = new ListServiceRequest();
  listServiceRequest.CustomFilter = relatedFilter;
  listServiceRequest.Skip = 0;
  listServiceRequest.Take = 0;
  viewName = "all_processes";
  if (isNotNullAndUndefined(viewName)) listServiceRequest.ViewName = viewName;

  return new Promise<BusinessProcessRow[]>((resolve, reject) => {
    repositoryActions
      .postDataAndGetResponse(
        "BusinessProcess/List",
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data)
        ) {
          resolve(response.data.Entities);
        }
      })
      .catch((error) => error);
  });
}

export function showStartProcessButton(
  processTemplateRow?: BusinessProcessTemplateRow,
  loggedinUserId?: string
) {
  let showStartBtn = false;
  if (
    isNotNullAndUndefined(processTemplateRow) &&
    processTemplateRow.FormName !== RAFModuleName.Contact &&
    processTemplateRow.FormName !== RAFModuleName.Deal &&
    processTemplateRow.FormName !== RAFModuleName.Complaint &&
    processTemplateRow.FormName !== RAFModuleName.Ticket &&
    (isNullOrUndefined(processTemplateRow.InitiatorUID) ||
      processTemplateRow.InitiatorUID === loggedinUserId)
  ) {
    showStartBtn = true;
  }
  return showStartBtn;
}

export function getBusinessProcessSummary(bpTemplateUID?: string) {
  let relatedFilter: RAFCustomFilter = {};
  relatedFilter.Condition = "and";
  relatedFilter.Rules = [];
  let filter1: RAFCustomFilter = {};
  let filterVal1: string[] = [];
  filterVal1.push(bpTemplateUID);
  filter1.Operator = RAFCustomOperator.Equal;
  filter1.Value = filterVal1;
  filter1.Field = propertyOf<BusinessProcessRow>("BpTemplateUID");
  relatedFilter.Rules.push(filter1);
  let listServiceRequest = new ListServiceRequest();
  listServiceRequest.CustomFilter = relatedFilter;
  return new Promise<{ status: string; count: number }[]>(
    (resolve /*, reject*/) => {
      repositoryActions
        .postDataAndGetResponse(
          "BusinessProcess/GroupByStatus",
          listServiceRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data)
          ) {
            let processData: string[] = response.data.Entities;

            let runningProcess =
              processData &&
              processData.find(
                (x) => x["ProcessStatus"] === RAFBPStatus.InProgress
              );
            let scheduledProcess =
              processData &&
              processData.find(
                (x) => x["ProcessStatus"] === RAFBPStatus.Scheduled
              );
            let startedProcess =
              processData &&
              processData.find(
                (x) => x["ProcessStatus"] === RAFBPStatus.Started
              );
            let completedProcess =
              processData &&
              processData.find(
                (x) => x["ProcessStatus"] === RAFBPStatus.Completed
              );
            let inProgressCount = 0;
            if (isNotNullAndUndefined(runningProcess)) {
              inProgressCount =
                inProgressCount + runningProcess["ProcessStatusCount"];
            }
            if (isNotNullAndUndefined(startedProcess)) {
              inProgressCount =
                inProgressCount + startedProcess["ProcessStatusCount"];
            }

            let retVal: { status: string; count: number }[] = [
              { status: RAFBPStatus.InProgress, count: inProgressCount },
              {
                status: RAFBPStatus.Scheduled,
                count: isNotNullAndUndefined(scheduledProcess)
                  ? scheduledProcess["ProcessStatusCount"]
                  : 0,
              },
              {
                status: RAFBPStatus.Completed,
                count: isNotNullAndUndefined(completedProcess)
                  ? completedProcess["ProcessStatusCount"]
                  : 0,
              },
            ];
            resolve(retVal);
          }
        })
        .catch((error) => error);
    }
  );
}

export function CalculateNextStartDate(
  completedDate?: Date,
  cronExpression?: string
) {
  let nextStartDate = null;
  if (completedDate !== null && isNotNullAndUndefined(cronExpression)) {
    //convert cron expression into date
  }
  return nextStartDate;
}

export function findPreviousBPStepTemplate(
  bpstepTemplateRows?: BusinessProcessStepTemplateRow[],
  currentBPStepTemplateRow?: BusinessProcessStepTemplateRow
) {
  return new Promise<BusinessProcessStepTemplateRow>((resolve, reject) => {
    if (
      isNotNullAndUndefined(bpstepTemplateRows) &&
      isNotNullAndUndefined(currentBPStepTemplateRow)
    ) {
      let previousBPStepRow: BusinessProcessStepTemplateRow =
        bpstepTemplateRows &&
        bpstepTemplateRows.find(
          (x) =>
            x.StepOrder === currentBPStepTemplateRow.StepOrder - 1 &&
            x.ParentStepUID === currentBPStepTemplateRow.ParentStepUID
        );
      resolve(previousBPStepRow);
    } else {
      resolve(null);
    }
  });
}

export function GenerateStepConnector(
  previousBPStepTemplateRow?: BusinessProcessStepTemplateRow,
  currentBPStepTemplateRow?: BusinessProcessStepTemplateRow
) {
  let businessProcessStepTemplate: BusinessProcessStepTemplateRow = {
    ...previousBPStepTemplateRow,
  };

  businessProcessStepTemplate.StepConnectorJson = [
    {
      Id: 1,
      SourceID: businessProcessStepTemplate.UID,
      TargetID: currentBPStepTemplateRow.UID,
    },
  ];

  return businessProcessStepTemplate;
}

export function SaveBPStepTemplateAPI(
  bpStepTemplateRow?: BusinessProcessStepTemplateRow
) {
  const businessProcessStepTemplateDto: IBusinessProcessStepTemplateDto =
    ConvertBPStepTemplateRowToBPStepTemplateDto(bpStepTemplateRow);
  return new Promise<string>((resolve, reject) => {
    repositoryActions
      .postDataAndGetResponse(
        "BusinessProcessStepTemplate/Save",
        getSaveRequest(
          businessProcessStepTemplateDto,
          businessProcessStepTemplateDto.UID
        ),
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data)
        ) {
          resolve(response.data.EntityId);
        } else {
          resolve(null);
        }
      })
      .catch((error) => error);
  });
}

export function InsertBPStepTemplateFB(
  bpStepTemplate?: BusinessProcessStepTemplateRow
) {
  return new Promise<string>((resolve, reject) => {
    const businessProcessStepTemplateDto: IBusinessProcessStepTemplateDto =
      ConvertBPStepTemplateRowToBPStepTemplateDto(bpStepTemplate);
    if (isNotNullAndUndefined(businessProcessStepTemplateDto)) {
      const rafBusinessProcessStepTemplateRepository: BpStepTemplateRepository =
        new BpStepTemplateRepository();
      rafBusinessProcessStepTemplateRepository
        .insertRAFBusinessProcessTemplateStep(businessProcessStepTemplateDto)
        .then((bpTemplateReponse) => {
          if (
            isNotNullAndUndefined(bpTemplateReponse) &&
            isNotNullAndUndefined(bpTemplateReponse.UID)
          ) {
            resolve(bpTemplateReponse.UID);
          } else {
            SaveBPStepTemplateAPI(businessProcessStepTemplateDto).then(
              (bpStepTemplateResponse) => {
                if (isNotNullAndUndefined(bpStepTemplateResponse)) {
                  resolve(businessProcessStepTemplateDto.UID);
                }
              }
            );
          }
        })
        .catch((error) => error);
    } else {
      resolve(null);
    }
  });
}

export const UpdateBPTemplateStepFB = (
  bpStepTemplate?: BusinessProcessStepTemplateRow
) => {
  return new Promise<string>(async (resolve, reject) => {
    const businessProcessStepTemplateDto: IBusinessProcessStepTemplateDto =
      ConvertBPStepTemplateRowToBPStepTemplateDto(bpStepTemplate);

    if (isNotNullAndUndefined(businessProcessStepTemplateDto)) {
      const rafBpStepTemplateRepository: BpStepTemplateRepository =
        new BpStepTemplateRepository();
      let updatedBPStepTemplateResponse =
        await rafBpStepTemplateRepository.updateRAFBusinessProcessTemplateStep(
          businessProcessStepTemplateDto
        );
      if (
        isNotNullAndUndefined(updatedBPStepTemplateResponse) &&
        isNotNullAndUndefined(updatedBPStepTemplateResponse.UID)
      ) {
        resolve(updatedBPStepTemplateResponse.UID);
      } else {
        SaveBPStepTemplateAPI(businessProcessStepTemplateDto).then(
          (bpStepTemplateResponse) => {
            if (isNotNullAndUndefined(bpStepTemplateResponse)) {
              resolve(businessProcessStepTemplateDto.UID);
            }
          }
        );
      }
    } else {
      resolve(null);
    }
  });
};

export function UpdateBPStepTemplateByFieldFB(
  bpStepTemplate: BusinessProcessStepTemplateRow,
  field,
  value
) {
  return new Promise<string>((resolve, reject) => {
    const businessProcessStepTemplateDto: IBusinessProcessStepTemplateDto =
      ConvertBPStepTemplateRowToBPStepTemplateDto(bpStepTemplate);
    const rafBusinessProcessStepTemplateRepository: BpStepTemplateRepository =
      new BpStepTemplateRepository();
    rafBusinessProcessStepTemplateRepository
      .updateRAFBusinessProcessTemplateStepByField(
        businessProcessStepTemplateDto.UID,
        field,
        value
      )
      .then((response) => {
        console.log("response", response);
        if (isNotNullAndUndefined(response)) {
          resolve(bpStepTemplate.UID);
        } else {
          resolve(null);
        }
      })
      .catch((error) => error);
  });
}

export const createUpdateBPStepTemplate = (
  bpStepTemplate?: BusinessProcessStepTemplateRow
) => {
  return new Promise<string>(async (resolve, reject) => {
    const businessProcessStepTemplateDto: IBusinessProcessStepTemplateDto =
      ConvertBPStepTemplateRowToBPStepTemplateDto(bpStepTemplate);

    if (
      isNotNullAndUndefined(businessProcessStepTemplateDto) &&
      isNotNullAndUndefined(businessProcessStepTemplateDto.UID)
    ) {
      let objectUID = await UpdateBPTemplateStepFB(
        businessProcessStepTemplateDto
      );
      resolve(objectUID);
    } else if (isNotNullAndUndefined(businessProcessStepTemplateDto)) {
      let objectUID = await InsertBPStepTemplateFB(
        businessProcessStepTemplateDto
      );
      resolve(objectUID);
    } else {
      resolve(null);
    }
  });
};

//

export function GetAllSiblingBPStepTemplate(
  bpstepTemplateRows?: BusinessProcessStepTemplateRow[],
  currentBPStepTemplateRow?: BusinessProcessStepTemplateRow
) {
  return new Promise<BusinessProcessStepTemplateRow[]>((resolve, reject) => {
    if (
      isNotNullAndUndefined(bpstepTemplateRows) &&
      isNotNullAndUndefined(currentBPStepTemplateRow)
    ) {
      let siblingBPStepTemplateRows: BusinessProcessStepTemplateRow[] =
        bpstepTemplateRows &&
        bpstepTemplateRows.filter(
          (x) =>
            x.ParentStepUID === currentBPStepTemplateRow.ParentStepUID &&
            x.UID !== currentBPStepTemplateRow.UID
        );
      resolve(siblingBPStepTemplateRows);
    } else {
      resolve(null);
    }
  });
}

export function GetAllChildBPStepTemplate(
  bpstepTemplateRows?: BusinessProcessStepTemplateRow[],
  currentBPStepTemplateRow?: BusinessProcessStepTemplateRow
) {
  let childBPStepTemplateRows: BusinessProcessStepTemplateRow[] = [];
  if (
    isNotNullAndUndefined(bpstepTemplateRows) &&
    isNotNullAndUndefined(currentBPStepTemplateRow)
  ) {
    childBPStepTemplateRows =
      bpstepTemplateRows &&
      bpstepTemplateRows.filter(
        (x) => x.ParentStepUID === currentBPStepTemplateRow.UID
      );
    return childBPStepTemplateRows;
  } else {
    return null;
  }
}

export function GetNextStepByPrompt(
  processtep?: BusinessProcessStepRow,
  allBpTemplateSteps?: BusinessProcessStepTemplateRow[],
  allBpStepsRow?: BusinessProcessStepRow[]
) {
  let nextBPStepRow: BusinessProcessStepRow;
  let bpSteps = GetOrderedBPStepArray(allBpStepsRow);
  let updatedBpStep = UpdateStepConnectorJSON(bpSteps);
  let allBpSteps = R.clone(updatedBpStep);
  let currentBPStep =
    updatedBpStep && updatedBpStep.find((x) => x.UID === processtep.UID);
  const promptAnswer =
    isNotNullAndUndefined(currentBPStep) &&
    isNotNullAndUndefined(currentBPStep.FormData)
      ? currentBPStep.FormData.Prompt
      : null;
  const nextStepConnector: ConnectorRow =
    isNotNullAndUndefined(currentBPStep) &&
    isNotNullAndUndefined(currentBPStep.StepConnectorJson)
      ? currentBPStep.StepConnectorJson.find(
          (x) => x.Annotations[0].Content === promptAnswer
        )
      : null;
  const nextStepUID =
    isNotNullAndUndefined(nextStepConnector) && nextStepConnector.TargetID;

  if (IsNullOrWhiteSpace(nextStepUID)) return null;
  if (nextStepUID === RAFBPAction.Continue) {
    if (currentBPStep.ParentStepUID !== null) {
      //let nextBPStepTemplateRow = isNotNullAndUndefined(allBpTemplateSteps) ? allBpTemplateSteps.find(x => x.ParentStepUID === currentBpStepTemplate.ParentStepUID && x.StepOrder === currentBpStepTemplate.StepOrder + 1) : null;
      let siblingBPSteps = isNotNullAndUndefined(allBpSteps)
        ? allBpSteps.filter(
            (x) => x.ParentStepUID === currentBPStep.ParentStepUID
          )
        : null;
      let currentStepIndex = isNotNullAndUndefined(siblingBPSteps)
        ? siblingBPSteps.findIndex((x) => x.UID === currentBPStep.UID)
        : null;
      nextBPStepRow = siblingBPSteps[currentStepIndex + 1];

      // let nextBPStepRow = isNotNullAndUndefined(allBpSteps)
      //   ? allBpSteps.find(
      //       (x) => x.BpStepTemplateUID === nextBPStepTemplateRow.UID
      //     )
      //   : null;
      return nextBPStepRow;
    } else {
      //let nextBPStepTemplateRow = isNotNullAndUndefined(allBpTemplateSteps) ? allBpTemplateSteps.find(x => x.ParentStepUID === null && x.StepOrder === currentBpStepTemplate.StepOrder + 1) : null;
      let siblingBPSteps = isNotNullAndUndefined(allBpSteps)
        ? allBpSteps.filter((x) => x.ParentStepUID === null)
        : null;
      let currentStepIndex = isNotNullAndUndefined(siblingBPSteps)
        ? siblingBPSteps.findIndex((x) => x.UID === currentBPStep.UID)
        : null;
      nextBPStepRow = siblingBPSteps[currentStepIndex + 1];
      // let nextBPStepRow = isNotNullAndUndefined(allBpSteps)
      //   ? allBpSteps.find(
      //       (x) => x.BpStepTemplateUID === nextBPStepTemplateRow.UID
      //     )
      //   : null;
      return nextBPStepRow;
    }
  } else if (nextStepUID === RAFBPAction.End) {
    return null;
  } else {
    nextBPStepRow = isNotNullAndUndefined(allBpSteps)
      ? allBpSteps.find((x) => x.UID === nextStepUID)
      : null;
    return nextBPStepRow;
  }
}

export function GetNextStep(
  currentBPStep?: BusinessProcessStepRow,
  bpTemplateStepsRow?: BusinessProcessStepTemplateRow[],
  allBpStepsRow?: BusinessProcessStepRow[]
) {
  let reOrderedBPSteps = GetOrderedBPStepTemplateArray(
    bpTemplateStepsRow,
    null
  );
  let bpTemplateSteps = GetBPStepTemplateArrayOrdered(reOrderedBPSteps);

  const allBpTemplateSteps = bpTemplateSteps.map((obj) => {
    if (isNullOrUndefined(obj.ParentStepUID)) {
      return { ...obj, ParentStepUID: null };
    }
    return obj;
  });
  let allBpSteps = GetOrderedBPStepArray(allBpStepsRow);

  const nextStepConnector: ConnectorRow =
    isNotNullAndUndefined(currentBPStep) &&
    isNotNullAndUndefined(currentBPStep.StepConnectorJson) &&
    isNotNullAndUndefined(currentBPStep.StepConnectorJson[0])
      ? currentBPStep.StepConnectorJson[0]
      : null;
  const nextStepUID = isNotNullAndUndefined(nextStepConnector)
    ? nextStepConnector.TargetID
    : null;

  if (IsNullOrWhiteSpace(nextStepUID)) return null;
  if (nextStepUID === RAFBPAction.Continue) {
    if (currentBPStep.ParentStepUID !== null) {
      let siblingBPSteps = isNotNullAndUndefined(allBpSteps)
        ? allBpSteps.filter(
            (x) => x.ParentStepUID === currentBPStep.ParentStepUID
          )
        : null;
      let nextBPStep = siblingBPSteps && siblingBPSteps[0];

      if (isNotNullAndUndefined(nextBPStep)) {
        let nextBPStepRow = isNotNullAndUndefined(allBpSteps)
          ? allBpSteps.find((x) => x.UID === nextBPStep.UID)
          : null;
        return nextBPStepRow;
      }
    } else {
      let nextBPStep: BusinessProcessStepRow = null;
      if (
        currentBPStep.StepType === RAFBPStepType.Group ||
        currentBPStep.StepType === RAFBPStepType.Stage
      ) {
        // nextBPStepTemplateRow = isNotNullAndUndefined(allBpTemplateSteps) && allBpTemplateSteps.find(x => x.ParentStepUID === currentBpStepTemplate.UID && x.StepOrder === 1);
        let siblingBPSteps = isNotNullAndUndefined(allBpSteps)
          ? allBpSteps.filter((x) => x.ParentStepUID === null)
          : null;
        nextBPStep = siblingBPSteps && siblingBPSteps[0];
      } else {
        //nextBPStepTemplateRow = isNotNullAndUndefined(allBpTemplateSteps) ? allBpTemplateSteps.find(x => x.ParentStepUID === null && x.StepOrder === currentBpStepTemplate.StepOrder + 1) : null;
        let siblingBPSteps = isNotNullAndUndefined(allBpSteps)
          ? allBpSteps.filter((x) => x.ParentStepUID === null)
          : null;
        let currentStepIndex = isNotNullAndUndefined(siblingBPSteps)
          ? siblingBPSteps.findIndex((x) => x.UID === currentBPStep.UID)
          : null;
        nextBPStep = allBpSteps[currentStepIndex + 1];
      }
      if (isNotNullAndUndefined(nextBPStep)) {
        let nextBPStepRow = isNotNullAndUndefined(allBpSteps)
          ? allBpSteps.find((x) => x.UID === nextBPStep.UID)
          : null;
        return nextBPStepRow;
      }
    }
  } else if (nextStepUID === RAFBPAction.End) {
    return null;
  }
}

export function GetNextStepTemplate(
  currentBPStep?: BusinessProcessStepRow,
  allBpTemplateSteps?: BusinessProcessStepTemplateRow[]
) {
  const currentBpStepTemplate: BusinessProcessStepTemplateRow =
    isNotNullAndUndefined(allBpTemplateSteps)
      ? allBpTemplateSteps.find(
          (x) => x.UID === currentBPStep.BpStepTemplateUID
        )
      : null;
  const nextStepConnector: ConnectorRow =
    isNotNullAndUndefined(currentBpStepTemplate) &&
    isNotNullAndUndefined(currentBpStepTemplate.StepConnectorJson) &&
    isNotNullAndUndefined(currentBpStepTemplate.StepConnectorJson[0])
      ? currentBpStepTemplate.StepConnectorJson[0]
      : null;
  const nextStepUID = isNotNullAndUndefined(nextStepConnector)
    ? nextStepConnector.TargetID
    : null;
  if (IsNullOrWhiteSpace(nextStepUID)) return null;
  let nextBPStepTemplateRow = isNotNullAndUndefined(allBpTemplateSteps)
    ? allBpTemplateSteps.find((x) => x.UID === nextStepUID)
    : null;
  return nextBPStepTemplateRow;
}

export function GenerateStepConnectorJSon(
  currentBPStepRow?: BusinessProcessStepTemplateRow | BusinessProcessStepRow
) {
  let stepConnectorJson: ConnectorRow[] = [];
  if (
    currentBPStepRow.StepType === RAFBPStepType.ToDo ||
    currentBPStepRow.StepType === RAFBPStepType.Instruction ||
    currentBPStepRow.StepType === RAFBPStepType.Task ||
    currentBPStepRow.StepType === RAFBPStepType.Group ||
    currentBPStepRow.StepType === RAFBPStepType.Stage ||
    currentBPStepRow.StepType === RAFBPStepType.Question ||
    currentBPStepRow.StepType === RAFBPStepType.Note
  ) {
    stepConnectorJson = [
      {
        Id: 1,
        SourceID: null,
        TargetID: RAFBPAction.Continue,
        Annotations: [{ Content: RAFBPStepStatus.Completed }],
      },
      {
        Id: 2,
        SourceID: null,
        TargetID: RAFBPAction.Continue,
        Annotations: [{ Content: RAFBPStepStatus.Skipped }],
      },
    ];
  } else if (currentBPStepRow.StepType === RAFBPStepType.Decision) {
    stepConnectorJson = [
      {
        Id: 1,
        SourceID: null,
        TargetID: RAFBPAction.Continue,
        Annotations: [{ Content: "Yes" }],
      },
      {
        Id: 2,
        SourceID: null,
        TargetID: RAFBPAction.Continue,
        Annotations: [{ Content: "No" }],
      },
    ];
  }
  // else if (currentBPStepRow.StepType === RAFBPStepType.Approval) {
  //   stepConnectorJson = [
  //     {
  //       Id: 1,
  //       SourceID: null,
  //       TargetID: RAFBPAction.Continue,
  //       Annotations: [{ Content: RAFBPStepStatus.Approved }],
  //     },
  //     {
  //       Id: 2,
  //       SourceID: null,
  //       TargetID: RAFBPAction.Continue,
  //       Annotations: [{ Content: RAFBPStepStatus.Rejected }],
  //     },
  //     {
  //       Id: 3,
  //       SourceID: null,
  //       TargetID: RAFBPAction.Continue,
  //       Annotations: [{ Content: RAFBPStepStatus.RequestforInfo }],
  //     },
  //   ];
  // }
  else if (currentBPStepRow.StepType === RAFBPStepType.Review) {
    stepConnectorJson = [
      {
        Id: 1,
        SourceID: null,
        TargetID: RAFBPAction.Continue,
        Annotations: [{ Content: RAFBPStepStatus.Reviewed }],
      },
      {
        Id: 2,
        SourceID: null,
        TargetID: RAFBPAction.Continue,
        Annotations: [{ Content: RAFBPStepStatus.Rejected }],
      },
      {
        Id: 3,
        SourceID: null,
        TargetID: RAFBPAction.Continue,
        Annotations: [{ Content: RAFBPStepStatus.RequestforInfo }],
      },
    ];
  } else if (currentBPStepRow.StepType === RAFBPStepType.Approval) {
    stepConnectorJson = [
      {
        Id: 1,
        SourceID: null,
        TargetID: RAFBPAction.Continue,
        Annotations: [{ Content: "Approve" }],
      },
      {
        Id: 2,
        SourceID: null,
        TargetID: RAFBPAction.Continue,
        Annotations: [{ Content: "Reject" }],
      },
    ];
  }

  return stepConnectorJson;
}

function CalculateNextScheduleByRRuleExpression(
  completedDate?: Date,
  rRuleExpression?: string
) {
  let ruleExpression = rRuleExpression + "COUNT=1";
  //str = str.slice(0, -1);

  // Parse a RRule string, return a RRule object
  let rrule = RRule.fromString(ruleExpression);
  //let nextSchedule = rrule.all(function (completedDate, i) { return i < 1 })
  let nextScheduleDate = rrule.after(completedDate, true);

  return nextScheduleDate;
}

export function findNextStep1(
  currentStep?: BusinessProcessStepRow,
  mergedStepsRow?: BusinessProcessStepRow[],
  bptemplateSteps?: BusinessProcessStepTemplateRow[]
) {
  let nextStep: BusinessProcessStepRow;
  let mergedSteps = GetOrderedBPStepArray(mergedStepsRow);
  let currentStepIndex = mergedSteps.findIndex(
    (x) => x.BpStepTemplateUID === currentStep.BpStepTemplateUID
  );
  const currentStepTemplateRow: BusinessProcessStepTemplateRow =
    isNotNullAndUndefined(bptemplateSteps)
      ? bptemplateSteps.find((x) => x.UID === currentStep.BpStepTemplateUID)
      : null;
  let nextStepUID;
  if (currentStep.StepType === RAFBPStepType.Decision) {
    const promptAnswer = currentStep.FormData.Prompt;
    const nextStepConnector: ConnectorRow =
      isNotNullAndUndefined(currentStepTemplateRow) &&
      isNotNullAndUndefined(currentStepTemplateRow.StepConnectorJson)
        ? currentStepTemplateRow.StepConnectorJson.find(
            (x) =>
              isNotNullAndUndefined(x.Annotations) &&
              isNotNullAndUndefined(x.Annotations[0]) &&
              x.Annotations[0].Content === promptAnswer
          )
        : null;
    nextStepUID = isNotNullAndUndefined(nextStepConnector)
      ? nextStepConnector.TargetID
      : null;
  } else {
    const promptAnswer = currentStep.StepStatus;
    const nextStepConnector: ConnectorRow =
      isNotNullAndUndefined(currentStepTemplateRow) &&
      isNotNullAndUndefined(currentStepTemplateRow.StepConnectorJson)
        ? currentStepTemplateRow.StepConnectorJson.find(
            (x) =>
              isNotNullAndUndefined(x.Annotations) &&
              isNotNullAndUndefined(x.Annotations[0]) &&
              x.Annotations[0].Content === promptAnswer
          )
        : null;
    nextStepUID = isNotNullAndUndefined(nextStepConnector)
      ? nextStepConnector.TargetID
      : null;
  }
  //if (IsNullOrWhiteSpace(nextStepUID))
  //    return null;

  if (nextStepUID === RAFBPAction.End) {
    return null;
  } else if (nextStepUID === RAFBPAction.Continue) {
  } else {
    let nextStep = isNotNullAndUndefined(mergedSteps)
      ? mergedSteps.find((x) => x.BpStepTemplateUID === nextStepUID)
      : null;
    return nextStep;
  }

  /*if (isNullOrUndefined(currentStep.ParentBpStepTemplateUID)) {
                      nextStep = mergedSteps[currentStepIndex+1];
                  }
                  else {
                      nextStep = mergedSteps[currentStepIndex + 1];
                  }*/

  nextStep = mergedSteps[currentStepIndex + 1];

  if (
    isNotNullAndUndefined(nextStep) &&
    (nextStep.StepType === RAFBPStepType.Group ||
      nextStep.StepType === RAFBPStepType.Stage)
  ) {
    //nextStep = mergedSteps.find(x => x.ParentBpStepTemplateUID === nextStep.BpStepTemplateUID && x.StepOrder === 1);
    let siblingSteps = mergedSteps.filter(
      (x) => x.ParentBpStepTemplateUID === nextStep.BpStepTemplateUID
    );
    nextStep = siblingSteps && siblingSteps[0];
  }

  if (nextStep === null) {
    if (isNotNullAndUndefined(currentStep.ParentBpStepTemplateUID)) {
      const objParentStep = mergedSteps.find(
        (x) => x.BpStepTemplateUID === currentStep.ParentBpStepTemplateUID
      );
      if (objParentStep !== null) {
        nextStep = findNextStep(objParentStep, mergedSteps);
      }
    } else {
      nextStep = null; //laststep
    }
  }

  return nextStep;
}

export function findNextStep(
  currentStep?: BusinessProcessStepRow,
  mergedStepsRow?: BusinessProcessStepRow[],
  businessProcessSteps?: BusinessProcessStepRow[]
) {
  let nextStep: BusinessProcessStepRow;
  let mergedSteps = GetOrderedBPStepArray(mergedStepsRow);
  let currentStepIndex = mergedSteps.findIndex(
    (x) => x.UID === currentStep.UID
  );
  const currentStepRow: BusinessProcessStepRow = isNotNullAndUndefined(
    businessProcessSteps
  )
    ? businessProcessSteps.find((x) => x.UID === currentStep.UID)
    : null;
  let nextStepUID;
  if (currentStep.StepType === RAFBPStepType.Decision) {
    const promptAnswer = currentStep.FormData.Prompt;
    const nextStepConnector: ConnectorRow =
      isNotNullAndUndefined(currentStepRow) &&
      isNotNullAndUndefined(currentStepRow.StepConnectorJson)
        ? currentStepRow.StepConnectorJson.find(
            (x) =>
              isNotNullAndUndefined(x.Annotations) &&
              isNotNullAndUndefined(x.Annotations[0]) &&
              x.Annotations[0].Content === promptAnswer
          )
        : null;
    nextStepUID = isNotNullAndUndefined(nextStepConnector)
      ? nextStepConnector.TargetID
      : null;
  } else {
    const promptAnswer = currentStep.StepStatus;
    const nextStepConnector: ConnectorRow =
      isNotNullAndUndefined(currentStepRow) &&
      isNotNullAndUndefined(currentStepRow.StepConnectorJson)
        ? currentStepRow.StepConnectorJson.find(
            (x) =>
              isNotNullAndUndefined(x.Annotations) &&
              isNotNullAndUndefined(x.Annotations[0]) &&
              x.Annotations[0].Content === promptAnswer
          )
        : null;
    nextStepUID = isNotNullAndUndefined(nextStepConnector)
      ? nextStepConnector.TargetID
      : null;
  }

  if (nextStepUID === RAFBPAction.End) {
    return null;
  } else if (nextStepUID === RAFBPAction.Continue) {
  } else {
    let nextStep = isNotNullAndUndefined(mergedSteps)
      ? mergedSteps.find((x) => x.UID === nextStepUID)
      : null;
    return nextStep;
  }

  nextStep = mergedSteps[currentStepIndex + 1];

  if (
    isNotNullAndUndefined(nextStep) &&
    (nextStep.StepType === RAFBPStepType.Group ||
      nextStep.StepType === RAFBPStepType.Stage)
  ) {
    let siblingSteps = mergedSteps.filter(
      (x) => x.ParentStepUID === nextStep.UID
    );
    nextStep = siblingSteps && siblingSteps[0];
  }

  if (nextStep === null) {
    if (isNotNullAndUndefined(currentStep.ParentStepUID)) {
      const objParentStep = mergedSteps.find(
        (x) => x.UID === currentStep.ParentStepUID
      );
      if (objParentStep !== null) {
        nextStep = findNextStep(objParentStep, mergedSteps);
      }
    } else {
      nextStep = null; //laststep
    }
  }

  return nextStep;
}

export function CompleteProcessStep(
  stepStatus?: string,
  currentStep?: BusinessProcessStepRow,
  businessProcessSteps?: BusinessProcessStepRow[],
  bpTemplateSteps?: BusinessProcessStepTemplateRow[],
  businessProcess?: BusinessProcessRow
) {
  let bpStepdialogDiv = document.querySelector("#bpStepDialog_dialog-content");
  let activeStepComment = "";

  if (isNotNullAndUndefined(bpStepdialogDiv)) {
    let commentDiv = bpStepdialogDiv.querySelector('p[id^="pMessage"]');
    if (isNotNullAndUndefined(commentDiv)) {
      activeStepComment = activeStepComment + commentDiv.innerHTML;
    }
  }

  const utcNewDate = moment.utc().format() as any;

  currentStep.CompletedDate = utcNewDate;
  currentStep.StepStatus = stepStatus;
  // if (currentStep.StepType === RAFBPStepType.Decision) {
  // } else {
  //   if (isNullOrUndefined(currentStep.FormData)) {
  //     currentStep.FormData = {};
  //   }
  //   currentStep.FormData["Prompt"] = stepStatus;
  // }

  // let updatedCurrentStep: BusinessProcessStepRow = {};
  // let otherObj = {};

  // Object.entries(currentStep).forEach(([key, value], index) => {
  //   //console.log(key, value, index,key.startsWith("FormData_Module_"));
  //   if (key.startsWith("FormData_Module_")) {
  //     otherObj[key.replace("FormData_Module_", "")] = value;
  //   } else if (key.startsWith("FormData_Form_")) {
  //     otherObj[key.replace("FormData_Form_", "")] = value;
  //   } else {
  //     updatedCurrentStep[key] = value;
  //   }
  // });

  /*Object.entries(otherObj).forEach(([key, value], index) => {
                      const entityId = key;
                      const rafObject = value;
                      //call save api by sending this entityid and rafObject
              
                  });
                  return;*/

  let progressDiv = showProgress("body", true);
  let taskStatus = currentStep.StepStatus;
  if (currentStep.StepType === RAFBPStepType.Question) {
    //set step status completed if step type is Question
    currentStep.StepStatus = RAFBPStepStatus.Completed;
    taskStatus = RAFBPStepStatus.Completed;
  }

  return new Promise<{
    processStep: BusinessProcessStepRow;
    businessProcess: BusinessProcessRow;
  }>((resolve, reject) => {
    CompleteTaskAndStep(
      currentStep,
      businessProcessSteps,
      businessProcess,
      bpTemplateSteps,
      null,
      taskStatus
    ).then((result) => {
      if (activeStepComment.length > 0) {
        let activityRow: ActivityRow = new ActivityRow();
        activityRow.UserUID = msalInstance.currentUserId;
        activityRow.UserName = msalInstance.currentUserName;

        activityRow.RelatedToType = businessProcess.RelatedToType;
        activityRow.Action = "added";
        activityRow.IsSystem = false;
        activityRow.RelatedToUID = businessProcess.RelatedToUID;
        activityRow.RelatedTo = businessProcess.RelatedTo;
        activityRow.Message = activeStepComment;

        activityRow.SecondaryRelatedTo = currentStep.Title;
        activityRow.SecondaryRelatedToType = RAFEntityName.BusinessProcessStep;
        activityRow.SecondaryRelatedToUID = currentStep.UID;
        activityRow.Action = RAFActivityAction.Added;
        repositoryActions
          .postDataAndGetResponse(
            "Activity/Save",
            getSaveRequest(activityRow, null),
            { ...this.props },
            ContentType.applicationJson
          )
          .then((response) => {
            hideProgress(progressDiv);
            if (
              isNotNullAndUndefined(response) &&
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.EntityId)
            ) {
              showSuccessToast("Comment added", "Success!");
              resolve({
                processStep: currentStep,
                businessProcess: businessProcess,
              });
            }
          });
      } else {
        hideProgress(progressDiv);
        resolve({ processStep: currentStep, businessProcess: businessProcess });
      }
    });
  }).catch((e) => e);
}

export function GetOrderedBPStepArray(
  bpSteps?: BusinessProcessStepRow[],
  parentId?: string
) {
  let outVal: BusinessProcessStepRow[] = [];
  let childSteps: BusinessProcessStepRow[] = [];
  if (IsNullOrWhiteSpace(parentId)) {
    childSteps =
      isNotNullAndUndefined(bpSteps) &&
      bpSteps.filter((x) => IsNullOrWhiteSpace(x.ParentStepUID));
  } else {
    childSteps =
      isNotNullAndUndefined(bpSteps) &&
      bpSteps.filter((x) => x.ParentStepUID === parentId);
  }

  for (var i = 0, len = childSteps.length; i < len; i++) {
    let obj = childSteps[i];
    outVal.push({ ...obj });

    let childSteps1 = GetOrderedBPStepArray(bpSteps, obj.UID);

    if (isNotNullAndUndefined(childSteps1) && childSteps1.length > 0) {
      childSteps1.forEach((item) => {
        outVal.push({ ...item });
      });
    }
  }
  return outVal;
}

export function GetOrderedBPStepTemplateArray(
  bpSteps?: BusinessProcessStepTemplateRow[],
  parentId?: string
) {
  let outVal: BusinessProcessStepTemplateRow[] = [];
  let childSteps: BusinessProcessStepTemplateRow[] = [];
  if (IsNullOrWhiteSpace(parentId)) {
    childSteps =
      isNotNullAndUndefined(bpSteps) &&
      bpSteps.filter((x) => IsNullOrWhiteSpace(x.ParentStepUID));
  } else {
    childSteps =
      isNotNullAndUndefined(bpSteps) &&
      bpSteps.filter((x) => x.ParentStepUID === parentId);
  }

  for (var i = 0, len = childSteps.length; i < len; i++) {
    let obj = childSteps[i];
    outVal.push({ ...obj });

    let childSteps1 = GetOrderedBPStepTemplateArray(bpSteps, obj.UID);

    if (isNotNullAndUndefined(childSteps1) && childSteps1.length > 0) {
      childSteps1.forEach((item) => {
        outVal.push({ ...item });
      });
    }
  }
  return outVal;
}

export function GetBPStepTemplateArrayOrdered(
  bpSteps?: BusinessProcessStepTemplateRow[]
) {
  let updatedBpSteps: BusinessProcessStepTemplateRow[] = bpSteps.filter(
    (x) =>
      x.StepType !== RAFBPStepType.Start && x.StepType !== RAFBPStepType.End
  );

  for (var i = 0, len = bpSteps.length; i < len; i++) {
    let obj = bpSteps[i];
    if (obj.StepType === RAFBPStepType.Start) {
      updatedBpSteps.splice(0, 0, obj);
    } else if (obj.StepType === RAFBPStepType.End) {
      updatedBpSteps.splice(bpSteps.length - 1, 0, obj);
    }
  }
  return updatedBpSteps;
}

function GetRootParentStep(
  nextBPStepRow?: BusinessProcessStepRow,
  allBPSteps?: BusinessProcessStepRow[]
) {
  let parentStep: BusinessProcessStepRow = R.clone(nextBPStepRow);
  if (isNotNullAndUndefined(parentStep)) {
    while (!IsNullOrWhiteSpace(parentStep.ParentBpStepTemplateUID)) {
      parentStep = allBPSteps.find(
        (x) => x.BpStepTemplateUID === parentStep.ParentBpStepTemplateUID
      );
    }
  }
  return parentStep;
}

export function getInitiatorType(
  businessProcessTemplateRow:
    | BusinessProcessTemplateRow
    | IBusinessProcessTemplateDto
) {
  return new Promise<string>((resolve, reject) => {
    if (isNotNullAndUndefined(businessProcessTemplateRow)) {
      if (isNotNullAndUndefined(businessProcessTemplateRow.InitiatorUID)) {
        getAllTeams().then((allTeams) => {
          let team = isNotNullAndUndefined(allTeams)
            ? allTeams.find(
                (x) => x.UID === businessProcessTemplateRow.InitiatorUID
              )
            : null;
          if (isNotNullAndUndefined(team) && isNotNullAndUndefined(team.UID)) {
            resolve("Team");
          } else {
            resolve("User");
          }
        });
      } else {
        resolve("All Users");
      }
    } else {
      resolve(null);
    }
  });
}

export function retrieveBusinessProcessStepTemplate(bpTemplateStepUID) {
  return new Promise<BusinessProcessStepTemplateRow>(
    async (resolve, reject) => {
      //need to check online or offline and get steps from api or db
      let isOfflineProcess = false;
      //if (isNotNullAndUndefined(bpTemplate) && bpTemplate.IsOfflineProcess) {
      const rafBpStepTemplateRepository: BpStepTemplateRepository =
        new BpStepTemplateRepository();

      if (isNotNullAndUndefined(bpTemplateStepUID) && isOfflineProcess) {
        let bpTemplateSteps =
          await rafBpStepTemplateRepository.getRAFBPTemplateStepByUID(
            bpTemplateStepUID
          );
        resolve(bpTemplateSteps);
      } else {
        let bpTemplateSteps =
          await rafBpStepTemplateRepository.getRAFBPTemplateStepByUID(
            bpTemplateStepUID
          );

        if (
          isNotNullAndUndefined(bpTemplateSteps) &&
          isNotNullAndUndefined(bpTemplateSteps.UID)
        ) {
          resolve(bpTemplateSteps);
        } else {
          repositoryActions
            .postDataAndGetResponse(
              "BusinessProcessStepTemplate/Retrieve",
              { EntityId: bpTemplateStepUID },
              null,
              ContentType.applicationJson
            )
            .then((response) => {
              if (
                isNotNullAndUndefined(response) &&
                isNotNullAndUndefined(response.data)
              ) {
                let businessProcessStepTemplate: BusinessProcessStepTemplateRow =
                  ConvertBPStepTemplateDtoToBPStepTemplateRow(
                    response.data.Entity
                  );
                resolve(businessProcessStepTemplate);
              } else {
                resolve(null);
              }
            })
            .catch((error) => error);
        }
      }
    }
  );
}

export function getBPStepTemplateProcessStarter(
  bpStep: BusinessProcessStepTemplateRow | IBusinessProcessStepTemplateDto
): string {
  let processStarter = "None";
  if (isNotNullAndUndefined(bpStep)) {
    if (
      isNotNullAndUndefined(bpStep.AssignTeamUID) &&
      IsNullOrWhiteSpace(bpStep.AssigneeUID)
    ) {
      processStarter = RAFProcessAssigneeType.Team;
    } else if (bpStep.Assignee === RAFProcessAssigneeType.ProcessStarter) {
      processStarter = RAFProcessAssigneeType.ProcessStarter;
    } else if (
      isNotNullAndUndefined(bpStep.AssigneeUID) &&
      bpStep.IsApproval === true
    ) {
      processStarter = RAFProcessAssigneeType.Approver;
      if (bpStep.StepType === RAFBPStepType.Review) {
        processStarter = RAFProcessAssigneeType.Reviewer;
      }
    } else if (isNotNullAndUndefined(bpStep.AssigneeUID)) {
      processStarter = RAFProcessAssigneeType.User;
    }
  }
  return processStarter;
}

export function getBPStepTemplateRelatedModules(
  bpStep: BusinessProcessStepTemplateRow | IBusinessProcessStepTemplateDto
): any {
  let relatedModules = [];
  if (isNotNullAndUndefined(bpStep)) {
    if (
      isNotNullAndUndefined(bpStep.RelatedEntities) &&
      bpStep.RelatedEntities.length > 0
    ) {
      bpStep.RelatedEntities.forEach((relatedEntity) => {
        relatedModules.push(relatedEntity.Type);
      });
    }
  }
  return relatedModules;
}

export function getBPStepProcessStarter(
  bpStep: BusinessProcessStepRow | IBusinessProcessStepDto
): string {
  let processStarter = "None";
  if (isNotNullAndUndefined(bpStep)) {
    if (
      isNotNullAndUndefined(bpStep.AssignTeamUID) &&
      IsNullOrWhiteSpace(bpStep.AssigneeUID)
    ) {
      processStarter = RAFProcessAssigneeType.Team;
    } else if (bpStep.Assignee === RAFProcessAssigneeType.ProcessStarter) {
      processStarter = RAFProcessAssigneeType.ProcessStarter;
    } else if (
      isNotNullAndUndefined(bpStep.AssigneeUID) &&
      bpStep.IsApproval === true
    ) {
      processStarter = RAFProcessAssigneeType.Approver;
      if (bpStep.StepType === RAFBPStepType.Review) {
        processStarter = RAFProcessAssigneeType.Reviewer;
      }
    } else if (isNotNullAndUndefined(bpStep.AssigneeUID)) {
      processStarter = RAFProcessAssigneeType.User;
    }
  }
  return processStarter;
}

export function endCurrentBusinessProcess(
  allbpStepRows: BusinessProcessStepRow[],
  businessProcessRow: BusinessProcessRow,
  bpTemplateSteps: BusinessProcessStepTemplateRow[]
) {
  return new Promise<BusinessProcessStepRow[]>((resolve, reject) => {
    GetbusinessProcessSteps(businessProcessRow.UID).then(
      (allupdatedBpSteps) => {
        const utcNewDate = moment.utc().format() as any;
        //update completed step count in business process
        const completedSteps: BusinessProcessStepRow[] = isNotNullAndUndefined(
          allbpStepRows
        )
          ? allbpStepRows.filter(
              (x) =>
                (x.StepStatus === RAFBPStepStatus.Completed ||
                  x.StepStatus === RAFBPStepStatus.Skipped ||
                  x.StepStatus === RAFBPStepStatus.Approved ||
                  x.StepStatus === RAFBPStepStatus.ResponseReceived ||
                  x.StepStatus === RAFBPStepStatus.Rejected ||
                  x.StepStatus === RAFBPStepStatus.Reviewed) &&
                IsNullOrWhiteSpace(x.ParentBpStepTemplateUID)
            )
          : null;
        let totalSteps = 0;
        if (
          businessProcessRow.ExecutionOrder === RAFExecutionOrder.Conditional
        ) {
          totalSteps = isNotNullAndUndefined(allupdatedBpSteps)
            ? allupdatedBpSteps.filter((x) =>
                IsNullOrWhiteSpace(x.ParentBpStepTemplateUID)
              ).length
            : 0;
          businessProcessRow.CompletedCount = isNotNullAndUndefined(
            completedSteps
          )
            ? completedSteps.length
            : 0;
          businessProcessRow.StepCount = totalSteps;
        } else {
          totalSteps = isNotNullAndUndefined(bpTemplateSteps)
            ? bpTemplateSteps.filter((x) => IsNullOrWhiteSpace(x.ParentStepUID))
                .length
            : 0;
          businessProcessRow.StepCount = totalSteps;
        }

        // if (
        //   isNotNullAndUndefined(completedSteps) &&
        //   completedSteps.length > 0 &&
        //   completedSteps.length === totalSteps
        // ) {
        businessProcessRow.ProcessStatus = RAFBPStatus.Completed;
        businessProcessRow.CompletedCount = completedSteps.length;
        businessProcessRow.EndDate = utcNewDate;
        businessProcessRow.ModifiedBy = msalInstance.currentUserName;
        businessProcessRow.ModifiedByUID = msalInstance.currentUserId;

        if (businessProcessRow.ProcessStatus === RAFBPStatus.Completed) {
          RetrieveBusinessProcessTemplate(
            businessProcessRow.BpTemplateUID
          ).then((bpTemplateRow) => {
            //bpTemplateRow.IsOfflineProcess = true;
            if (
              bpTemplateRow.StartMode === "Conditional" &&
              isNotNullAndUndefined(bpTemplateRow.RRuleExpression) &&
              bpTemplateRow.IsOfflineProcess === true &&
              bpTemplateRow.IsRecurring === true
            ) {
              StartProcessAndSaveProcessSteps(
                null,
                bpTemplateRow,
                businessProcessRow
              ).then((bpResponse) => {
                let businessProcess: BusinessProcessRow = bpResponse.bpRow;
                bpTemplateRow.NextRunDate = businessProcess.StartDate;
                bpTemplateRow.LastRunDate = businessProcessRow.EndDate;
                SaveBusinessProcessTemplateAPI(bpTemplateRow).then(
                  (bpTemplateResponse) => {}
                );
              });
            }
          });
        }
        //}
        //  else {
        //   businessProcessRow.CompletedCount =
        //     isNotNullAndUndefined(allbpStepRows) &&
        //     allbpStepRows.filter(
        //       (x) =>
        //         (x.StepStatus === RAFBPStepStatus.Completed ||
        //           x.StepStatus === RAFBPStepStatus.Skipped ||
        //           x.StepStatus === RAFBPStepStatus.Approved ||
        //           x.StepStatus === RAFBPStepStatus.Rejected ||
        //           x.StepStatus === RAFBPStepStatus.Reviewed) &&
        //         IsNullOrWhiteSpace(x.ParentBpStepTemplateUID)
        //     ).length;
        //   businessProcessRow.ProcessStatus = RAFBPStatus.InProgress;
        // }

        // if (
        //   businessProcessRow.RelatedToType !== RAFEntityName.BusinessProcess   ///code not complete
        // ) {
        //   updateRelatedProcessStatus(
        //     businessProcessRow.RelatedToUID,
        //     businessProcessRow.ProcessStatus,
        //     businessProcessRow.RelatedToType,
        //     nextBPStepRow,
        //     allbpStepRows,
        //     businessProcessRow,
        //     currentBPStepRow,
        //     bpTemplateSteps
        //   ).then((relatedRow) => {
        //     SaveBusinessProcessAPI(businessProcessRow).then(
        //       (bpResponse) => {
        //         resolve(allbpStepRows);
        //       }
        //     );
        //   });
        // } else {
        // }
        SaveBusinessProcessAPI(businessProcessRow).then((bpResponse) => {
          resolve(allbpStepRows);
        });
      }
    );
  });
}

export function SaveFormDataAPI(formData: FormDataRow) {
  let progressDiv = showProgress("body", true);
  let id = isNotNullAndUndefined(formData.UID) ? formData.UID : null;
  let title: string = Date.now().toLocaleString();
  if (isNullOrUndefined(id)) {
    formData.Title = "Record_" + title.replaceAll(",", "");
  }
  return new Promise<FormDataRow>((resolve, reject) => {
    repositoryActions
      .postDataAndGetResponse(
        "FormData/Save",
        getSaveRequest(formData, id),
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        hideProgress(progressDiv);
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.EntityId)
        ) {
          resolve(formData);
        }
      })
      .catch((error) => error);
  });
}

export function SaveFormDataListAPI(
  objectData?: any,
  entityUID?: string,
  entityName?: string
) {
  let progressDiv = showProgress("body", true);
  let url = "DataList/Save";
  let uid = isNotNullAndUndefined(objectData.UID) ? objectData.UID : null;
  let saveRequestData = getSaveRequest(objectData, uid);
  if (isNotNullAndUndefined(entityUID)) {
    saveRequestData.EntityUID = entityUID;
  }
  //saveRequestData.EntityId = objectData.UID;
  if (isNotNullAndUndefined(entityName)) {
    saveRequestData.EntityName = entityName;
  }

  return new Promise<{ entityId: string; objectName: string }>(
    (resolve, reject) => {
      repositoryActions
        .postDataAndGetResponse(
          url,
          saveRequestData,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          hideProgress(progressDiv);
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.EntityId)
          ) {
            resolve({
              entityId: response.data.EntityId,
              objectName: response.data.ObjectName,
            });
          } else {
            resolve(null);
          }
        })
        .catch((error) => error);
    }
  );
}

export function RetrieveDataListAPI(objectData, entityUID) {
  let progressDiv = showProgress("body", true);
  let url = "DataList/Save";
  let saveRequestData = getSaveRequest(objectData, null);
  saveRequestData.EntityUID = entityUID;

  return new Promise<FormDataRow>((resolve, reject) => {
    repositoryActions
      .postDataAndGetResponse(
        url,
        saveRequestData,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        hideProgress(progressDiv);
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.EntityId)
        ) {
          resolve(response.data.EntityId);
        }
      })
      .catch((error) => error);
  });
}

export function RetrieveRelatedFormData(relatedToUID, relatedEntityUID) {
  let relatedFilter: RAFCustomFilter = {};
  relatedFilter.Condition = "and";
  relatedFilter.Rules = [];

  let filter1: RAFCustomFilter = {};
  let filterVal1: string[] = [];
  filterVal1.push(relatedToUID);
  filter1.Operator = RAFCustomOperator.Equal;
  filter1.Value = filterVal1;
  filter1.Field = RAFProcessFormPropertyName.BusinessProcesssUID;
  relatedFilter.Rules.push(filter1);

  let listServiceRequest = new ListServiceRequest();
  listServiceRequest.CustomFilter = relatedFilter;
  listServiceRequest.Skip = 0;
  listServiceRequest.Take = 0;

  //listServiceRequest.ViewName = "all_processes";
  listServiceRequest.EntityUID = relatedEntityUID;

  return new Promise<FormDataRow[]>((resolve, reject) => {
    repositoryActions
      .postDataAndGetResponse(
        "DataList/List",
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (isNotNullAndUndefined(response)) {
          if (response.status === 204) {
            resolve(null);
          } else if (
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.Entity)
          ) {
            resolve(response.data.Entity);
          } else if (
            response.data &&
            response.data.ResultTable &&
            response.data.ResultTable.length > 0
          ) {
            resolve(response.data.ResultTable[0]);
          } else {
            resolve(null);
          }
        } else {
          resolve(null);
        }
      })
      .catch((error) => error);
  });
}

export function RetrieveRelatedData(relatedToUID, relatedEntityUID) {
  return new Promise<any>((resolve, reject) => {
    repositoryActions
      .postDataAndGetResponse(
        "DataList/Retrieve",
        {
          EntityId: relatedToUID,
          EntityUID: relatedEntityUID,
        },
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (isNotNullAndUndefined(response)) {
          if (response.status === 204) {
            resolve(null);
          } else {
            if (
              response.data &&
              response.data.ResultTable &&
              response.data.ResultTable.length > 0
            ) {
              resolve(response.data.ResultTable[0]);
            } else {
              resolve(null);
            }
          }
        } else {
          resolve(null);
        }
      })
      .catch((error) => error);
  });
}

//bp_formDraggComponent_start
export const bpStepFormRightComponentFields = [
  {
    UID: Guid.newGuid(),
    Name: RAFDataType.RichText + "_" + Guid.newGuid(),
    DisplayName: "Html Content",
    DataType: RAFDataType.RichText,
    PageLayoutId: "componentTemplate_" + Guid.newGuid(),
  },
  {
    UID: Guid.newGuid(),
    Name: RAFDataType.EmbedForm + "_" + Guid.newGuid(),
    DisplayName: "Embed Form",
    DataType: RAFDataType.EmbedForm,
    PageLayoutId: "componentTemplate_" + Guid.newGuid(),
    RAFFormAction: "Update",
    FormType: null,
    EntityName: null,
    IsUpdate: true,
    RelatedTo: null,
    RelatedToUID: null,
    RelatedToType: null,
    RelatedAttribute: null,
    RelatedAttributeUID: null,
    Components: [],
  },
  // {
  //   UID: Guid.newGuid(),
  //   Name: RAFDataType.LinkForm + "_" + Guid.newGuid(),
  //   DisplayName: "Link Form",
  //   DataType: RAFDataType.LinkForm,
  //   PageLayoutId: "componentTemplate_" + Guid.newGuid(),
  //   FormType: null,
  //   EntityName: null,
  //   IsUpdate: false,
  //   RelatedTo: null,
  //   RelatedToUID: null,
  //   RelatedToType: null,
  //   RelatedAttribute: null,
  //   RelatedAttributeUID: null,
  //   Components: [],
  // },
  // {
  //   UID: Guid.newGuid(),
  //   //Name: RAFDataType.Module + "_" + Guid.newGuid(),
  //   Name: RAFDataType.Form + "_" + Guid.newGuid(),
  //   DisplayName: "Form Library",
  //   //DataType: RAFDataType.Module,
  //   DataType: RAFDataType.Form,
  //   RAFFormAction: "Create",
  //   PageLayoutId: "componentTemplate_" + Guid.newGuid(),
  //   Components: [],
  // },
  {
    UID: Guid.newGuid(),
    Name: RAFDataType.Component + "_" + Guid.newGuid(),
    DisplayName: "Form Editor",
    DataType: RAFDataType.Component,
    RAFFormAction: "Create",
    PageLayoutId: "componentTemplate_" + Guid.newGuid(),
    Components: [],
  },
  {
    UID: Guid.newGuid(),
    Name: RAFDataType.Signature + "_" + Guid.newGuid(),
    DisplayName: "Signature",
    DataType: RAFDataType.Signature,
    PageLayoutId: "componentTemplate_" + Guid.newGuid(),
  },
];

export function checkIsItemComponentDropped(
  formDataModel: RAFFormDataModel,
  attribUID: string,
  dataType?: string
) {
  let isItemExist = false;
  let pageLayoutIds = [];
  let pageLayoutDataTypes = [];
  let components =
    isNotNullAndUndefined(formDataModel.components) &&
    isNotNullAndUndefined(formDataModel.components)
      ? formDataModel.components
      : null;
  if (
    isNullOrUndefined(components) ||
    (isNotNullAndUndefined(components) && components.length < 1)
  ) {
    isItemExist = false;
  } else if (isNotNullAndUndefined(components) && components.length > 0) {
    components.forEach((item) => {
      if (
        isNotNullAndUndefined(item) &&
        isNotNullAndUndefined(item.component)
      ) {
        pageLayoutIds.push(item.component.UID);
        pageLayoutDataTypes.push(item.component.DataType);
      }
    });
  }
  if (
    isNotNullAndUndefined(attribUID) &&
    isNotNullAndUndefined(pageLayoutIds) &&
    pageLayoutIds.length > 0
  ) {
    isItemExist = pageLayoutIds.includes(attribUID);
  } else if (
    isNotNullAndUndefined(dataType) &&
    isNotNullAndUndefined(pageLayoutIds) &&
    pageLayoutIds.length > 0
  ) {
    isItemExist = pageLayoutDataTypes.includes(dataType);
  }

  return isItemExist;
}

//bp_formDraggComponent_end

export function getRelatedProcessListByRelatedId(relatedToUID) {
  let relatedFilter: RAFCustomFilter = {};
  relatedFilter.Condition = "and";
  relatedFilter.Rules = [];

  let filter1: RAFCustomFilter = {};
  let filterVal1: string[] = [];
  filterVal1.push(relatedToUID);
  filter1.Operator = RAFCustomOperator.Equal;
  filter1.Value = filterVal1;
  filter1.Field = propertyOf<BusinessProcessRow>("RelatedToUID");
  relatedFilter.Rules.push(filter1);

  let listServiceRequest = new ListServiceRequest();
  listServiceRequest.CustomFilter = relatedFilter;
  listServiceRequest.Skip = 0;
  listServiceRequest.Take = 0;

  //listServiceRequest.ViewName = "all_processes";

  return new Promise<BusinessProcessRow[]>((resolve, reject) => {
    repositoryActions
      .postDataAndGetResponse(
        "BusinessProcess/List",
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data)
        ) {
          resolve(response.data.Entities);
        }
      })
      .catch((error) => error);
  });
}

/*Updated process step save method */

export function SaveProcessStepAndUpdateBP(
  currentBPStepRow: BusinessProcessStepRow,
  allbpSteps: BusinessProcessStepRow[],
  businessProcessRow: BusinessProcessRow,
  bpTemplateStepsRow: BusinessProcessStepTemplateRow[],
  bpTemplate?: BusinessProcessTemplateRow,
  objEntity?: EntityRow,
  formLibrary?: FormLibraryRow
) {
  return new Promise<{
    allBPRows: BusinessProcessStepRow[];
    dependentBPRows: BusinessProcessStepRow[];
    businessProcess: BusinessProcessRow;
    businessProcessStep: BusinessProcessStepRow;
  }>((resolve, reject) => {
    //Need to update current step and create next step, dependent step and update process completed step count
    //Update current BPStep status in BPSteps Array

    let reOrderedBPSteps = GetOrderedBPStepTemplateArray(
      bpTemplateStepsRow,
      null
    );
    let bpTemplateSteps = GetBPStepTemplateArrayOrdered(reOrderedBPSteps);

    let bpStepRowIndex =
      allbpSteps && allbpSteps.findIndex((x) => x.UID === currentBPStepRow.UID);
    if (bpStepRowIndex >= 0) {
      allbpSteps[bpStepRowIndex].StepStatus = currentBPStepRow.StepStatus;
      allbpSteps[bpStepRowIndex].CompletedDate = currentBPStepRow.CompletedDate;
      allbpSteps[bpStepRowIndex].FormData = currentBPStepRow.FormData;
    }
    let nextBPStep = GetNextStepByPrompt(currentBPStepRow, null, allbpSteps);
    if (isNotNullAndUndefined(bpTemplate)) bpTemplate.IsOfflineProcess = false;
    if (isNotNullAndUndefined(bpTemplate) && bpTemplate.IsOfflineProcess) {
      SaveBusinessProcessStepFB(currentBPStepRow).then((updatebpStepRow) => {
        //updatebpStepRow in allBPStepRow array
        if (bpStepRowIndex >= 0) {
          allbpSteps[bpStepRowIndex] = updatebpStepRow;
        }

        let dependentSteps: BusinessProcessStepRow[] = [];
        if (
          businessProcessRow.ExecutionOrder === RAFExecutionOrder.StepByStep ||
          businessProcessRow.ExecutionOrder === RAFExecutionOrder.Conditional
        ) {
          if (
            currentBPStepRow.StepStatus === RAFBPStepStatus.Completed ||
            currentBPStepRow.StepStatus === RAFBPStepStatus.Approved ||
            currentBPStepRow.StepStatus === RAFBPStepStatus.ResponseReceived ||
            currentBPStepRow.StepStatus === RAFBPStepStatus.Reviewed ||
            currentBPStepRow.StepStatus === RAFBPStepStatus.Rejected ||
            currentBPStepRow.StepStatus === RAFBPStepStatus.RequestforInfo ||
            currentBPStepRow.StepStatus === RAFBPStepStatus.Skipped
          ) {
            //get targetId by step prompt value and return targetId
            getTargetIdByStepConnectorJson(currentBPStepRow).then(
              (targetID) => {
                if (
                  targetID !== RAFBPAction.Continue &&
                  targetID !== RAFBPAction.End &&
                  isNotNullAndUndefined(targetID)
                ) {
                  let stepIndex = GetCurrentAndNextStepIndex(
                    currentBPStepRow,
                    allbpSteps,
                    nextBPStep
                  );
                  if (stepIndex.nextIndex < stepIndex.currentIndex) {
                    //Regenerate steps and save
                    RegenerateBPSteps(
                      stepIndex.currentIndex,
                      allbpSteps,
                      stepIndex.nextIndex,
                      businessProcessRow
                    ).then((allBPStepResponse) => {
                      let allBPSteps: BusinessProcessStepRow[] =
                        allBPStepResponse.filter((x) => x.IsActive === true);
                      EvaluateAndSaveProcessStep(
                        updatebpStepRow,
                        allBPSteps,
                        businessProcessRow,
                        bpTemplateSteps,
                        null,
                        null,
                        bpTemplate,
                        objEntity,
                        formLibrary
                      ).then((result) => {
                        resolve(result);
                      });
                    });
                  } else {
                    UpdateNextProcessStepStatus(
                      allbpSteps,
                      updatebpStepRow,
                      businessProcessRow,
                      bpTemplateSteps
                    ).then((nextBPStepRowResponse) => {
                      //check for next step. if step exist, make it ready otherwise complete the process
                      let updatedbpStepRows: BusinessProcessStepRow[] =
                        isNotNullAndUndefined(
                          nextBPStepRowResponse.allbpStepRows
                        )
                          ? nextBPStepRowResponse.allbpStepRows
                          : allbpSteps;

                      //complete the parent step if all the child steps are completed
                      CheckAndSaveParentStep(
                        updatedbpStepRows,
                        updatebpStepRow,
                        bpTemplate
                      ).then((parentBPSteps) => {
                        dependentSteps = GetDependentBusinessProcessStep(
                          updatedbpStepRows,
                          updatebpStepRow
                        ); //get steps depending on current step
                        if (
                          isNotNullAndUndefined(parentBPSteps) &&
                          parentBPSteps.length > 0
                        ) {
                          parentBPSteps.forEach((parentBPStepRow) => {
                            if (
                              isNotNullAndUndefined(parentBPStepRow) &&
                              isNotNullAndUndefined(parentBPStepRow.UID)
                            ) {
                              // update parent step status in BP Steps array
                              // allbpStepRows[
                              //   allbpStepRows.findIndex(
                              //     (x) => x.UID === parentBPStepRow.UID
                              //   )
                              // ].StepStatus = parentBPStepRow.StepStatus;
                              // allbpStepRows[
                              //   allbpStepRows.findIndex(
                              //     (x) => x.UID === parentBPStepRow.UID
                              //   )
                              // ].CompletedDate = parentBPStepRow.EndDate;

                              updatedbpStepRows[
                                updatedbpStepRows.findIndex(
                                  (x) => x.UID === parentBPStepRow.UID
                                )
                              ] = parentBPStepRow;
                            }
                          });
                        }

                        EvaluateAndSaveProcessStep(
                          currentBPStepRow,
                          updatedbpStepRows,
                          businessProcessRow,
                          bpTemplateSteps,
                          null,
                          nextBPStepRowResponse.updatedNextBPStepRow,
                          bpTemplate,
                          objEntity,
                          formLibrary
                        ).then((result) => {
                          resolve(result);
                        });
                      });
                    });
                  }
                } else {
                  UpdateNextProcessStepStatus(
                    allbpSteps,
                    updatebpStepRow,
                    businessProcessRow,
                    bpTemplateSteps
                  ).then((nextBPStepRowResponse) => {
                    //check for next step. if step exist, make it ready otherwise complete the process
                    let updatedbpStepRows: BusinessProcessStepRow[] =
                      isNotNullAndUndefined(nextBPStepRowResponse.allbpStepRows)
                        ? nextBPStepRowResponse.allbpStepRows
                        : allbpSteps;
                    //complete the parent step if all the child steps are completed
                    CheckAndSaveParentStep(
                      updatedbpStepRows,
                      updatebpStepRow,
                      bpTemplate
                    ).then((parentBPSteps) => {
                      dependentSteps = GetDependentBusinessProcessStep(
                        updatedbpStepRows,
                        updatebpStepRow
                      ); //get steps depending on current step
                      if (
                        isNotNullAndUndefined(parentBPSteps) &&
                        parentBPSteps.length > 0
                      ) {
                        parentBPSteps.forEach((parentBPStepRow) => {
                          if (
                            isNotNullAndUndefined(parentBPStepRow) &&
                            isNotNullAndUndefined(parentBPStepRow.UID)
                          ) {
                            // update parent step status in BP Steps array
                            // allbpStepRows[
                            //   allbpStepRows.findIndex(
                            //     (x) => x.UID === parentBPStepRow.UID
                            //   )
                            // ].StepStatus = parentBPStepRow.StepStatus;
                            // allbpStepRows[
                            //   allbpStepRows.findIndex(
                            //     (x) => x.UID === parentBPStepRow.UID
                            //   )
                            // ].CompletedDate = parentBPStepRow.EndDate;

                            allbpSteps[
                              allbpSteps.findIndex(
                                (x) => x.UID === parentBPStepRow.UID
                              )
                            ] = parentBPStepRow;
                          }
                        });
                      }

                      EvaluateAndSaveProcessStep(
                        currentBPStepRow,
                        updatedbpStepRows,
                        businessProcessRow,
                        bpTemplateSteps,
                        null,
                        nextBPStepRowResponse.updatedNextBPStepRow,
                        bpTemplate,
                        objEntity,
                        formLibrary
                      ).then((result) => {
                        resolve(result);
                      });
                    });
                  });
                }
              }
            );
          } else {
            EvaluateAndSaveProcessStep(
              currentBPStepRow,
              allbpSteps,
              businessProcessRow,
              bpTemplateSteps,
              null,
              null,
              bpTemplate,
              objEntity,
              formLibrary
            ).then((result) => {
              resolve(result);
            });
          }
        } else {
          if (
            currentBPStepRow.StepStatus === RAFBPStepStatus.Completed ||
            currentBPStepRow.StepStatus === RAFBPStepStatus.Approved ||
            currentBPStepRow.StepStatus === RAFBPStepStatus.ResponseReceived ||
            currentBPStepRow.StepStatus === RAFBPStepStatus.Reviewed ||
            currentBPStepRow.StepStatus === RAFBPStepStatus.Rejected ||
            currentBPStepRow.StepStatus === RAFBPStepStatus.RequestforInfo ||
            currentBPStepRow.StepStatus === RAFBPStepStatus.Skipped
          ) {
            //get targetId by step prompt value and return targetId
            getTargetIdByStepConnectorJson(currentBPStepRow).then(
              (targetID) => {
                if (
                  targetID !== RAFBPAction.Continue &&
                  targetID !== RAFBPAction.End &&
                  isNotNullAndUndefined(targetID) &&
                  isNotNullAndUndefined(nextBPStep)
                ) {
                  let stepIndex = GetCurrentAndNextStepIndex(
                    currentBPStepRow,
                    allbpSteps,
                    nextBPStep
                  );
                  if (
                    stepIndex.nextIndex < stepIndex.currentIndex ||
                    (stepIndex.nextIndex === 0 && stepIndex.currentIndex === 0)
                  ) {
                    //Regenerate steps and save
                    RegenerateBPSteps(
                      stepIndex.currentIndex,
                      allbpSteps,
                      stepIndex.nextIndex,
                      businessProcessRow
                    ).then((allBPStepResponse) => {
                      let allBPSteps: BusinessProcessStepRow[] =
                        allBPStepResponse.filter((x) => x.IsActive === true);
                      EvaluateAndSaveProcessStep(
                        currentBPStepRow,
                        allBPSteps,
                        businessProcessRow,
                        bpTemplateSteps,
                        null,
                        null,
                        bpTemplate,
                        objEntity,
                        formLibrary
                      ).then((result) => {
                        resolve(result);
                      });
                    });
                  } else {
                    //complete the parent step if all the child steps are completed

                    CheckAndSaveParentStep(
                      allbpSteps,
                      updatebpStepRow,
                      bpTemplate
                    ).then((parentBPSteps) => {
                      // dependentSteps = GetDependentBusinessProcessStep(
                      //   allbpStepRows,
                      //   updatebpStepRow
                      // ); //get steps depending on current step
                      if (
                        isNotNullAndUndefined(parentBPSteps) &&
                        parentBPSteps.length > 0
                      ) {
                        parentBPSteps.forEach((parentBPStepRow) => {
                          if (
                            isNotNullAndUndefined(parentBPStepRow) &&
                            isNotNullAndUndefined(parentBPStepRow.UID)
                          ) {
                            // update parent step status in BP Steps array
                            // allbpStepRows[
                            //   allbpStepRows.findIndex(
                            //     (x) => x.UID === parentBPStepRow.UID
                            //   )
                            // ].StepStatus = parentBPStepRow.StepStatus;
                            // allbpStepRows[
                            //   allbpStepRows.findIndex(
                            //     (x) => x.UID === parentBPStepRow.UID
                            //   )
                            // ].CompletedDate = parentBPStepRow.EndDate;

                            allbpSteps[
                              allbpSteps.findIndex(
                                (x) => x.UID === parentBPStepRow.UID
                              )
                            ] = parentBPStepRow;
                          }
                        });
                      }

                      EvaluateAndSaveProcessStep(
                        currentBPStepRow,
                        allbpSteps,
                        businessProcessRow,
                        bpTemplateSteps,
                        null,
                        nextBPStep,
                        bpTemplate,
                        objEntity,
                        formLibrary
                      ).then((result) => {
                        resolve(result);
                      });
                    });
                  }
                } else {
                  //complete the parent step if all the child steps are completed
                  CheckAndSaveParentStep(
                    allbpSteps,
                    updatebpStepRow,
                    bpTemplate
                  ).then((parentBPSteps) => {
                    if (
                      isNotNullAndUndefined(parentBPSteps) &&
                      parentBPSteps.length > 0
                    ) {
                      parentBPSteps.forEach((parentBPStepRow) => {
                        if (
                          isNotNullAndUndefined(parentBPStepRow) &&
                          isNotNullAndUndefined(parentBPStepRow.UID)
                        ) {
                          // update parent step status in BP Steps array
                          // allbpStepRows[
                          //   allbpStepRows.findIndex(
                          //     (x) => x.UID === parentBPStepRow.UID
                          //   )
                          // ].StepStatus = parentBPStepRow.StepStatus;
                          // allbpStepRows[
                          //   allbpStepRows.findIndex(
                          //     (x) => x.UID === parentBPStepRow.UID
                          //   )
                          // ].CompletedDate = parentBPStepRow.EndDate;

                          allbpSteps[
                            allbpSteps.findIndex(
                              (x) => x.UID === parentBPStepRow.UID
                            )
                          ] = parentBPStepRow;
                        }
                      });
                    }
                    // dependentSteps = GetDependentBusinessProcessStep(
                    //   allbpStepRows,
                    //   updatebpStepRow
                    // ); //get steps depending on current step

                    //Calculate deadline and save the dependent bp step
                    if (
                      isNotNullAndUndefined(dependentSteps) &&
                      dependentSteps.length > 0
                    ) {
                      let promiseArr = dependentSteps.map(function (item) {
                        let dependentTemplateStep =
                          bpTemplateSteps &&
                          bpTemplateSteps.find(
                            (x) => x.UID === item.BpStepTemplateUID
                          );
                        //let currentTemplateStep = bpTemplateSteps && bpTemplateSteps.find(x => x.UID === updatebpStepRow.BpStepTemplateUID)
                        //console.log('dependentTemplateStep', dependentTemplateStep)
                        item.DueDate = CalculateDeadline(
                          businessProcessRow.StartDate,
                          updatebpStepRow.CompletedDate,
                          dependentTemplateStep.StepDeadlineUnits,
                          dependentTemplateStep.StepDeadlineInterval,
                          dependentTemplateStep.StepDeadlineCalculation
                        );
                        //console.log('item.DueDate', item.DueDate )
                        //Create BP Step
                        return SaveBusinessProcessStepAPI(item).then(
                          (dependentStepResponse) => {
                            return item;
                          }
                        );
                      });
                      Promise.all(promiseArr)
                        .then(function (resultsArray) {
                          for (var i = 0; i < resultsArray.length; i++) {
                            const item = resultsArray[i];
                            if (
                              isNotNullAndUndefined(item) &&
                              isNotNullAndUndefined(item.UID)
                            ) {
                              allbpSteps[
                                allbpSteps.findIndex((x) => x.UID === item.UID)
                              ].DueDate = item.DueDate;
                            }
                          }
                          //do next set of action
                          EvaluateAndSaveProcessStep(
                            currentBPStepRow,
                            allbpSteps,
                            businessProcessRow,
                            bpTemplateSteps,
                            dependentSteps,
                            nextBPStep,
                            bpTemplate,
                            objEntity,
                            formLibrary
                          ).then((result) => {
                            resolve(result);
                          });
                        })
                        .catch(function (err) {
                          // do something when any of the promises in array are rejected
                        });
                    } else {
                      EvaluateAndSaveProcessStep(
                        currentBPStepRow,
                        allbpSteps,
                        businessProcessRow,
                        bpTemplateSteps,
                        dependentSteps,
                        nextBPStep,
                        bpTemplate,
                        objEntity,
                        formLibrary
                      ).then((result) => {
                        resolve(result);
                      });
                    }
                  });
                }
              }
            );
          } else {
            EvaluateAndSaveProcessStep(
              currentBPStepRow,
              allbpSteps,
              businessProcessRow,
              bpTemplateSteps,
              null,
              null,
              bpTemplate,
              objEntity,
              formLibrary
            ).then((result) => {
              resolve(result);
            });
          }
        }
      });
    } else {
      SaveBusinessProcessStepAPI(currentBPStepRow).then((updatebpStepRow) => {
        //updatebpStepRow in allBPStepRow array
        if (bpStepRowIndex >= 0) {
          allbpSteps[bpStepRowIndex] = updatebpStepRow;
        }

        let dependentSteps: BusinessProcessStepRow[] = [];
        if (
          businessProcessRow.ExecutionOrder === RAFExecutionOrder.StepByStep ||
          businessProcessRow.ExecutionOrder === RAFExecutionOrder.Conditional
        ) {
          if (
            currentBPStepRow.StepStatus === RAFBPStepStatus.Completed ||
            currentBPStepRow.StepStatus === RAFBPStepStatus.Approved ||
            currentBPStepRow.StepStatus === RAFBPStepStatus.ResponseReceived ||
            currentBPStepRow.StepStatus === RAFBPStepStatus.Reviewed ||
            currentBPStepRow.StepStatus === RAFBPStepStatus.Rejected ||
            currentBPStepRow.StepStatus === RAFBPStepStatus.RequestforInfo ||
            currentBPStepRow.StepStatus === RAFBPStepStatus.Skipped
          ) {
            //get targetId by step prompt value and return targetId
            getTargetIdByStepConnectorJson(currentBPStepRow).then(
              (targetID) => {
                if (
                  targetID !== RAFBPAction.Continue &&
                  targetID !== RAFBPAction.End &&
                  isNotNullAndUndefined(targetID)
                ) {
                  let stepIndex = GetCurrentAndNextStepIndex(
                    currentBPStepRow,
                    allbpSteps,
                    nextBPStep
                  );
                  if (stepIndex.nextIndex < stepIndex.currentIndex) {
                    //Regenerate steps and save
                    RegenerateBPSteps(
                      stepIndex.currentIndex,
                      allbpSteps,
                      stepIndex.nextIndex,
                      businessProcessRow
                    ).then((allBPStepResponse) => {
                      let allBPSteps: BusinessProcessStepRow[] =
                        allBPStepResponse.filter((x) => x.IsActive === true);
                      EvaluateAndSaveProcessStep(
                        updatebpStepRow,
                        allBPSteps,
                        businessProcessRow,
                        bpTemplateSteps,
                        null,
                        null,
                        bpTemplate,
                        objEntity,
                        formLibrary
                      ).then((result) => {
                        resolve(result);
                      });
                    });
                  } else {
                    UpdateNextProcessStepStatus(
                      allbpSteps,
                      updatebpStepRow,
                      businessProcessRow,
                      bpTemplateSteps
                    ).then((nextBPStepRowResponse) => {
                      //check for next step. if step exist, make it ready otherwise complete the process
                      let updatedbpStepRows: BusinessProcessStepRow[] =
                        isNotNullAndUndefined(
                          nextBPStepRowResponse.allbpStepRows
                        )
                          ? nextBPStepRowResponse.allbpStepRows
                          : allbpSteps;

                      //complete the parent step if all the child steps are completed
                      CheckAndSaveParentStep(
                        updatedbpStepRows,
                        updatebpStepRow,
                        bpTemplate
                      ).then((parentBPSteps) => {
                        dependentSteps = GetDependentBusinessProcessStep(
                          updatedbpStepRows,
                          updatebpStepRow
                        ); //get steps depending on current step
                        if (
                          isNotNullAndUndefined(parentBPSteps) &&
                          parentBPSteps.length > 0
                        ) {
                          parentBPSteps.forEach((parentBPStepRow) => {
                            if (
                              isNotNullAndUndefined(parentBPStepRow) &&
                              isNotNullAndUndefined(parentBPStepRow.UID)
                            ) {
                              // update parent step status in BP Steps array
                              // allbpStepRows[
                              //   allbpStepRows.findIndex(
                              //     (x) => x.UID === parentBPStepRow.UID
                              //   )
                              // ].StepStatus = parentBPStepRow.StepStatus;
                              // allbpStepRows[
                              //   allbpStepRows.findIndex(
                              //     (x) => x.UID === parentBPStepRow.UID
                              //   )
                              // ].CompletedDate = parentBPStepRow.EndDate;

                              allbpSteps[
                                allbpSteps.findIndex(
                                  (x) => x.UID === parentBPStepRow.UID
                                )
                              ] = parentBPStepRow;
                            }
                          });
                        }

                        EvaluateAndSaveProcessStep(
                          currentBPStepRow,
                          updatedbpStepRows,
                          businessProcessRow,
                          bpTemplateSteps,
                          null,
                          nextBPStepRowResponse.updatedNextBPStepRow,
                          bpTemplate,
                          objEntity,
                          formLibrary
                        ).then((result) => {
                          resolve(result);
                        });
                      });
                    });
                  }
                } else {
                  UpdateNextProcessStepStatus(
                    allbpSteps,
                    updatebpStepRow,
                    businessProcessRow,
                    bpTemplateSteps
                  ).then((nextBPStepRowResponse) => {
                    //check for next step. if step exist, make it ready otherwise complete the process
                    let updatedbpStepRows: BusinessProcessStepRow[] =
                      isNotNullAndUndefined(nextBPStepRowResponse.allbpStepRows)
                        ? nextBPStepRowResponse.allbpStepRows
                        : allbpSteps;
                    //complete the parent step if all the child steps are completed
                    CheckAndSaveParentStep(
                      updatedbpStepRows,
                      updatebpStepRow,
                      bpTemplate
                    ).then((parentBPSteps) => {
                      dependentSteps = GetDependentBusinessProcessStep(
                        updatedbpStepRows,
                        updatebpStepRow
                      ); //get steps depending on current step
                      if (
                        isNotNullAndUndefined(parentBPSteps) &&
                        parentBPSteps.length > 0
                      ) {
                        parentBPSteps.forEach((parentBPStepRow) => {
                          if (
                            isNotNullAndUndefined(parentBPStepRow) &&
                            isNotNullAndUndefined(parentBPStepRow.UID)
                          ) {
                            // update parent step status in BP Steps array
                            // allbpStepRows[
                            //   allbpStepRows.findIndex(
                            //     (x) => x.UID === parentBPStepRow.UID
                            //   )
                            // ].StepStatus = parentBPStepRow.StepStatus;
                            // allbpStepRows[
                            //   allbpStepRows.findIndex(
                            //     (x) => x.UID === parentBPStepRow.UID
                            //   )
                            // ].CompletedDate = parentBPStepRow.EndDate;

                            allbpSteps[
                              allbpSteps.findIndex(
                                (x) => x.UID === parentBPStepRow.UID
                              )
                            ] = parentBPStepRow;
                          }
                        });
                      }

                      EvaluateAndSaveProcessStep(
                        currentBPStepRow,
                        updatedbpStepRows,
                        businessProcessRow,
                        bpTemplateSteps,
                        null,
                        nextBPStepRowResponse.updatedNextBPStepRow,
                        bpTemplate,
                        objEntity,
                        formLibrary
                      ).then((result) => {
                        resolve(result);
                      });
                    });
                  });
                }
              }
            );
          } else {
            EvaluateAndSaveProcessStep(
              currentBPStepRow,
              allbpSteps,
              businessProcessRow,
              bpTemplateSteps,
              null,
              null,
              bpTemplate,
              objEntity,
              formLibrary
            ).then((result) => {
              resolve(result);
            });
          }
        } else {
          if (
            currentBPStepRow.StepStatus === RAFBPStepStatus.Completed ||
            currentBPStepRow.StepStatus === RAFBPStepStatus.Approved ||
            currentBPStepRow.StepStatus === RAFBPStepStatus.ResponseReceived ||
            currentBPStepRow.StepStatus === RAFBPStepStatus.Reviewed ||
            currentBPStepRow.StepStatus === RAFBPStepStatus.Rejected ||
            currentBPStepRow.StepStatus === RAFBPStepStatus.RequestforInfo ||
            currentBPStepRow.StepStatus === RAFBPStepStatus.Skipped
          ) {
            //get targetId by step prompt value and return targetId
            getTargetIdByStepConnectorJson(currentBPStepRow).then(
              (targetID) => {
                if (
                  targetID !== RAFBPAction.Continue &&
                  targetID !== RAFBPAction.End &&
                  isNotNullAndUndefined(targetID) &&
                  isNotNullAndUndefined(nextBPStep)
                ) {
                  let stepIndex = GetCurrentAndNextStepIndex(
                    currentBPStepRow,
                    allbpSteps,
                    nextBPStep
                  );

                  if (
                    stepIndex.nextIndex < stepIndex.currentIndex ||
                    (stepIndex.nextIndex === 0 && stepIndex.currentIndex === 0)
                  ) {
                    //Regenerate steps and save
                    RegenerateBPSteps(
                      stepIndex.currentIndex,
                      allbpSteps,
                      stepIndex.nextIndex,
                      businessProcessRow
                    ).then((allBPStepResponse) => {
                      let allBPSteps: BusinessProcessStepRow[] =
                        allBPStepResponse.filter((x) => x.IsActive === true);
                      EvaluateAndSaveProcessStep(
                        currentBPStepRow,
                        allBPSteps,
                        businessProcessRow,
                        bpTemplateSteps,
                        null,
                        null,
                        bpTemplate,
                        objEntity,
                        formLibrary
                      ).then((result) => {
                        resolve(result);
                      });
                    });
                  } else {
                    //update the status to skipped for the step in between current and nextstep
                    UpdateNextBPStepStatusAsSkipped(
                      allbpSteps,
                      updatebpStepRow,
                      nextBPStep,
                      businessProcessRow
                    ).then((allbpStepsResponse) => {
                      //complete the parent step if all the child steps are completed
                      CheckAndSaveParentStep(
                        allbpStepsResponse,
                        updatebpStepRow,
                        bpTemplate
                      ).then((parentBPSteps) => {
                        // dependentSteps = GetDependentBusinessProcessStep(
                        //   allbpStepRows,
                        //   updatebpStepRow
                        // ); //get steps depending on current step
                        if (
                          isNotNullAndUndefined(parentBPSteps) &&
                          parentBPSteps.length > 0
                        ) {
                          parentBPSteps.forEach((parentBPStepRow) => {
                            if (
                              isNotNullAndUndefined(parentBPStepRow) &&
                              isNotNullAndUndefined(parentBPStepRow.UID)
                            ) {
                              // update parent step status in BP Steps array
                              // allbpStepRows[
                              //   allbpStepRows.findIndex(
                              //     (x) => x.UID === parentBPStepRow.UID
                              //   )
                              // ].StepStatus = parentBPStepRow.StepStatus;
                              // allbpStepRows[
                              //   allbpStepRows.findIndex(
                              //     (x) => x.UID === parentBPStepRow.UID
                              //   )
                              // ].CompletedDate = parentBPStepRow.EndDate;

                              allbpStepsResponse[
                                allbpStepsResponse.findIndex(
                                  (x) => x.UID === parentBPStepRow.UID
                                )
                              ] = parentBPStepRow;
                            }
                          });
                        }

                        EvaluateAndSaveProcessStep(
                          currentBPStepRow,
                          allbpStepsResponse,
                          businessProcessRow,
                          bpTemplateSteps,
                          null,
                          nextBPStep,
                          bpTemplate,
                          objEntity,
                          formLibrary
                        ).then((result) => {
                          resolve(result);
                        });
                      });
                    });
                  }
                } else if (targetID === RAFBPAction.End) {
                  //update the status to skipped for the step in between current and nextstep
                  UpdateNextBPStepStatusAsSkipped(
                    allbpSteps,
                    updatebpStepRow,
                    nextBPStep,
                    businessProcessRow
                  ).then((allbpStepsResponse) => {
                    //complete the parent step if all the child steps are completed
                    CheckAndSaveParentStep(
                      allbpStepsResponse,
                      updatebpStepRow,
                      bpTemplate
                    ).then((parentBPSteps) => {
                      // dependentSteps = GetDependentBusinessProcessStep(
                      //   allbpStepRows,
                      //   updatebpStepRow
                      // ); //get steps depending on current step
                      if (
                        isNotNullAndUndefined(parentBPSteps) &&
                        parentBPSteps.length > 0
                      ) {
                        parentBPSteps.forEach((parentBPStepRow) => {
                          if (
                            isNotNullAndUndefined(parentBPStepRow) &&
                            isNotNullAndUndefined(parentBPStepRow.UID)
                          ) {
                            // update parent step status in BP Steps array
                            // allbpStepRows[
                            //   allbpStepRows.findIndex(
                            //     (x) => x.UID === parentBPStepRow.UID
                            //   )
                            // ].StepStatus = parentBPStepRow.StepStatus;
                            // allbpStepRows[
                            //   allbpStepRows.findIndex(
                            //     (x) => x.UID === parentBPStepRow.UID
                            //   )
                            // ].CompletedDate = parentBPStepRow.EndDate;

                            allbpStepsResponse[
                              allbpStepsResponse.findIndex(
                                (x) => x.UID === parentBPStepRow.UID
                              )
                            ] = parentBPStepRow;
                          }
                        });
                      }

                      EvaluateAndSaveProcessStep(
                        currentBPStepRow,
                        allbpStepsResponse,
                        businessProcessRow,
                        bpTemplateSteps,
                        null,
                        nextBPStep,
                        bpTemplate,
                        objEntity,
                        formLibrary
                      ).then((result) => {
                        resolve(result);
                      });
                    });
                  });
                } else {
                  if (
                    isNotNullAndUndefined(nextBPStep) &&
                    nextBPStep.StepType === RAFBPStepType.AutoComplete
                  ) {
                    UpdateNextProcessStep(
                      allbpSteps,
                      nextBPStep,
                      bpTemplateSteps,
                      businessProcessRow,
                      bpTemplate
                    ).then((nextBPStepRowResponse) => {
                      let updatedbpStepRows: BusinessProcessStepRow[] =
                        isNotNullAndUndefined(
                          nextBPStepRowResponse.allbpStepRows
                        )
                          ? nextBPStepRowResponse.allbpStepRows
                          : allbpSteps;
                      //complete the parent step if all the child steps are completed
                      CheckAndSaveParentStep(
                        updatedbpStepRows,
                        nextBPStepRowResponse.updatedNextBPStepRow,
                        bpTemplate
                      ).then((parentBPSteps) => {
                        if (
                          isNotNullAndUndefined(parentBPSteps) &&
                          parentBPSteps.length > 0
                        ) {
                          parentBPSteps.forEach((parentBPStepRow) => {
                            if (
                              isNotNullAndUndefined(parentBPStepRow) &&
                              isNotNullAndUndefined(parentBPStepRow.UID)
                            ) {
                              // update parent step status in BP Steps array
                              // allbpStepRows[
                              //   allbpStepRows.findIndex(
                              //     (x) => x.UID === parentBPStepRow.UID
                              //   )
                              // ].StepStatus = parentBPStepRow.StepStatus;
                              // allbpStepRows[
                              //   allbpStepRows.findIndex(
                              //     (x) => x.UID === parentBPStepRow.UID
                              //   )
                              // ].CompletedDate = parentBPStepRow.EndDate;

                              updatedbpStepRows[
                                updatedbpStepRows.findIndex(
                                  (x) => x.UID === parentBPStepRow.UID
                                )
                              ] = parentBPStepRow;
                            }
                          });
                        }
                        // dependentSteps = GetDependentBusinessProcessStep(
                        //   allbpStepRows,
                        //   updatebpStepRow
                        // ); //get steps depending on current step

                        //Calculate deadline and save the dependent bp step
                        if (
                          isNotNullAndUndefined(dependentSteps) &&
                          dependentSteps.length > 0
                        ) {
                          let promiseArr = dependentSteps.map(function (item) {
                            let dependentTemplateStep =
                              bpTemplateSteps &&
                              bpTemplateSteps.find(
                                (x) => x.UID === item.BpStepTemplateUID
                              );
                            //let currentTemplateStep = bpTemplateSteps && bpTemplateSteps.find(x => x.UID === updatebpStepRow.BpStepTemplateUID)
                            //console.log('dependentTemplateStep', dependentTemplateStep)
                            item.DueDate = CalculateDeadline(
                              businessProcessRow.StartDate,
                              updatebpStepRow.CompletedDate,
                              dependentTemplateStep.StepDeadlineUnits,
                              dependentTemplateStep.StepDeadlineInterval,
                              dependentTemplateStep.StepDeadlineCalculation
                            );
                            //console.log('item.DueDate', item.DueDate )
                            //Create BP Step
                            return SaveBusinessProcessStepAPI(item).then(
                              (dependentStepResponse) => {
                                return item;
                              }
                            );
                          });
                          Promise.all(promiseArr)
                            .then(function (resultsArray) {
                              for (var i = 0; i < resultsArray.length; i++) {
                                const item = resultsArray[i];
                                if (
                                  isNotNullAndUndefined(item) &&
                                  isNotNullAndUndefined(item.UID)
                                ) {
                                  updatedbpStepRows[
                                    updatedbpStepRows.findIndex(
                                      (x) => x.UID === item.UID
                                    )
                                  ].DueDate = item.DueDate;
                                }
                              }
                              //do next set of action
                              EvaluateAndSaveProcessStep(
                                currentBPStepRow,
                                updatedbpStepRows,
                                businessProcessRow,
                                bpTemplateSteps,
                                dependentSteps,
                                nextBPStepRowResponse.updatedNextBPStepRow,
                                bpTemplate,
                                objEntity,
                                formLibrary
                              ).then((result) => {
                                resolve(result);
                              });
                            })
                            .catch(function (err) {
                              // do something when any of the promises in array are rejected
                            });
                        } else {
                          EvaluateAndSaveProcessStep(
                            currentBPStepRow,
                            updatedbpStepRows,
                            businessProcessRow,
                            bpTemplateSteps,
                            dependentSteps,
                            nextBPStepRowResponse.updatedNextBPStepRow,
                            bpTemplate,
                            objEntity,
                            formLibrary
                          ).then((result) => {
                            resolve(result);
                          });
                        }
                      });
                    });
                  } else {
                    //complete the parent step if all the child steps are completed --#
                    CheckAndSaveParentStep(
                      allbpSteps,
                      updatebpStepRow,
                      bpTemplate
                    ).then((parentBPSteps) => {
                      if (
                        isNotNullAndUndefined(parentBPSteps) &&
                        parentBPSteps.length > 0
                      ) {
                        parentBPSteps.forEach((parentBPStepRow) => {
                          if (
                            isNotNullAndUndefined(parentBPStepRow) &&
                            isNotNullAndUndefined(parentBPStepRow.UID)
                          ) {
                            // update parent step status in BP Steps array
                            // allbpStepRows[
                            //   allbpStepRows.findIndex(
                            //     (x) => x.UID === parentBPStepRow.UID
                            //   )
                            // ].StepStatus = parentBPStepRow.StepStatus;
                            // allbpStepRows[
                            //   allbpStepRows.findIndex(
                            //     (x) => x.UID === parentBPStepRow.UID
                            //   )
                            // ].CompletedDate = parentBPStepRow.EndDate;

                            allbpSteps[
                              allbpSteps.findIndex(
                                (x) => x.UID === parentBPStepRow.UID
                              )
                            ] = parentBPStepRow;
                          }
                        });
                      }
                      // dependentSteps = GetDependentBusinessProcessStep(
                      //   allbpStepRows,
                      //   updatebpStepRow
                      // ); //get steps depending on current step

                      //Calculate deadline and save the dependent bp step
                      if (
                        isNotNullAndUndefined(dependentSteps) &&
                        dependentSteps.length > 0
                      ) {
                        let promiseArr = dependentSteps.map(function (item) {
                          let dependentTemplateStep =
                            bpTemplateSteps &&
                            bpTemplateSteps.find(
                              (x) => x.UID === item.BpStepTemplateUID
                            );
                          //let currentTemplateStep = bpTemplateSteps && bpTemplateSteps.find(x => x.UID === updatebpStepRow.BpStepTemplateUID)
                          //console.log('dependentTemplateStep', dependentTemplateStep)
                          item.DueDate = CalculateDeadline(
                            businessProcessRow.StartDate,
                            updatebpStepRow.CompletedDate,
                            dependentTemplateStep.StepDeadlineUnits,
                            dependentTemplateStep.StepDeadlineInterval,
                            dependentTemplateStep.StepDeadlineCalculation
                          );
                          //console.log('item.DueDate', item.DueDate )
                          //Create BP Step
                          return SaveBusinessProcessStepAPI(item).then(
                            (dependentStepResponse) => {
                              return item;
                            }
                          );
                        });
                        Promise.all(promiseArr)
                          .then(function (resultsArray) {
                            for (var i = 0; i < resultsArray.length; i++) {
                              const item = resultsArray[i];
                              if (
                                isNotNullAndUndefined(item) &&
                                isNotNullAndUndefined(item.UID)
                              ) {
                                allbpSteps[
                                  allbpSteps.findIndex(
                                    (x) => x.UID === item.UID
                                  )
                                ].DueDate = item.DueDate;
                              }
                            }
                            //do next set of action
                            EvaluateAndSaveProcessStep(
                              currentBPStepRow,
                              allbpSteps,
                              businessProcessRow,
                              bpTemplateSteps,
                              dependentSteps,
                              nextBPStep,
                              bpTemplate,
                              objEntity,
                              formLibrary
                            ).then((result) => {
                              resolve(result);
                            });
                          })
                          .catch(function (err) {
                            // do something when any of the promises in array are rejected
                          });
                      } else {
                        EvaluateAndSaveProcessStep(
                          currentBPStepRow,
                          allbpSteps,
                          businessProcessRow,
                          bpTemplateSteps,
                          dependentSteps,
                          nextBPStep,
                          bpTemplate,
                          objEntity,
                          formLibrary
                        ).then((result) => {
                          resolve(result);
                        });
                      }
                    });
                  }
                }
              }
            );
          } else {
            EvaluateAndSaveProcessStep(
              currentBPStepRow,
              allbpSteps,
              businessProcessRow,
              bpTemplateSteps,
              null,
              null,
              bpTemplate,
              objEntity,
              formLibrary
            ).then((result) => {
              resolve(result);
            });
          }
        }
      });
    }
  });
}

export function CompleteBusinessProcessStep(
  stepStatus?: string,
  currentStep?: BusinessProcessStepRow,
  businessProcessSteps?: BusinessProcessStepRow[],
  bpTemplateSteps?: BusinessProcessStepTemplateRow[],
  businessProcess?: BusinessProcessRow,
  bpTemplate?: BusinessProcessTemplateRow,
  bpStepFormData?: any,
  formLibrary?: FormLibraryRow,
  objEntity?: EntityRow
) {
  let bpStepdialogDiv = document.querySelector("#bpStepDialog_dialog-content");
  let activeStepComment = "";
  const utcNewDate = moment.utc().format() as any;
  if (isNotNullAndUndefined(bpStepdialogDiv)) {
    let commentDiv = bpStepdialogDiv.querySelector('p[id^="pMessage"]');
    if (isNotNullAndUndefined(commentDiv)) {
      activeStepComment = activeStepComment + commentDiv.innerHTML;
    }
  }
  currentStep.CompletedDate = utcNewDate;
  currentStep.StepStatus = stepStatus;

  let progressDiv = showProgress("body", true);
  let taskStatus = currentStep.StepStatus;
  if (currentStep.StepType === RAFBPStepType.Question) {
    //set step status completed if step type is Question
    currentStep.StepStatus = RAFBPStepStatus.Completed;
    taskStatus = RAFBPStepStatus.Completed;
  }

  if (
    currentStep.StepType === RAFBPStepType.Decision ||
    currentStep.StepType === RAFBPStepType.Approval
  ) {
  } else {
    if (isNullOrUndefined(currentStep.FormData)) {
      currentStep.FormData = {};
    }
    currentStep.FormData["Prompt"] = taskStatus;
  }

  let updatedCurrentStep: BusinessProcessStepRow = {};
  let otherObj = {};

  Object.entries(currentStep).forEach(([key, value], index) => {
    //console.log(key, value, index,key.startsWith("FormData_Module_"));
    if (key.startsWith("FormData_Module_")) {
      otherObj[key.replace("FormData_Module_", "")] = value;
    } else if (key.startsWith("FormData_Form_")) {
      otherObj[key.replace("FormData_Form_", "")] = value;
    } else {
      updatedCurrentStep[key] = value;
    }
  });

  return new Promise<{
    processStep: BusinessProcessStepRow;
    businessProcess: BusinessProcessRow;
    processSteps: BusinessProcessStepRow[];
  }>((resolve, reject) => {
    if (
      isNotNullAndUndefined(currentStep.FormUID) &&
      currentStep.FormType === RAFFormType.FormComponent
    ) {
      delete currentStep.FormData["Prompt"];
      currentStep.FormData[RAFFormFieldName.BusinessProcesss] =
        businessProcess.ProcessName;
      currentStep.FormData[RAFFormFieldName.BusinessProcesssUID] =
        businessProcess.UID;
      currentStep.FormData[RAFFormFieldName.BusinessProcesssStep] =
        currentStep.Title;
      currentStep.FormData[RAFFormFieldName.BusinessProcesssStepUID] =
        currentStep.UID;

      let formData: FormDataRow = new FormDataRow();
      formData.DataJson = currentStep.FormData;

      RetrieveFormLibraryById(currentStep.FormUID).then((formLibraryRow) => {
        formData.FormLibraryUID = formLibraryRow.UID;
        formData.FormLibrary = formLibraryRow.Title;
        formData.Entity = formLibraryRow.Entity;
        formData.IconName = formLibraryRow.IconName;
        formData.Category = formLibraryRow.FormType;

        SaveFormDataAPI(formData).then((formDataResponse) => {
          SaveProcessStepAndUpdateBP(
            updatedCurrentStep,
            businessProcessSteps,
            businessProcess,
            bpTemplateSteps,
            bpTemplate
          ).then((result) => {
            if (activeStepComment.length > 0) {
              let activityRow: ActivityRow = new ActivityRow();
              activityRow.UserUID = msalInstance.currentUserId;
              activityRow.UserName = msalInstance.currentUserName;

              activityRow.RelatedToType = businessProcess.RelatedToType;
              activityRow.Action = "added";
              activityRow.IsSystem = false;
              activityRow.RelatedToUID = businessProcess.RelatedToUID;
              activityRow.RelatedTo = businessProcess.RelatedTo;
              activityRow.Message = activeStepComment;

              activityRow.SecondaryRelatedTo = currentStep.Title;
              activityRow.SecondaryRelatedToType =
                RAFEntityName.BusinessProcessStep;
              activityRow.SecondaryRelatedToUID = currentStep.UID;
              activityRow.Action = RAFActivityAction.Added;
              repositoryActions
                .postDataAndGetResponse(
                  "Activity/Save",
                  getSaveRequest(activityRow, null),
                  { ...this.props },
                  ContentType.applicationJson
                )
                .then((response) => {
                  hideProgress(progressDiv);
                  if (
                    isNotNullAndUndefined(response) &&
                    isNotNullAndUndefined(response.data) &&
                    isNotNullAndUndefined(response.data.EntityId)
                  ) {
                    showSuccessToast("Comment added", "Success!");
                    resolve({
                      processStep: result.businessProcessStep,
                      businessProcess: result.businessProcess,
                      processSteps: result.allBPRows,
                    });
                  }
                });
            } else {
              hideProgress(progressDiv);
              resolve({
                processStep: result.businessProcessStep,
                businessProcess: result.businessProcess,
                processSteps: result.allBPRows,
              });
            }
          });
        });
      });
    } else if (
      isNotNullAndUndefined(businessProcess.FormUID) &&
      businessProcess.FormType === RAFFormType.FormFlow
    ) {
      if (isNotNullAndUndefined(bpStepFormData)) {
        SaveProcessStepAndUpdateBP(
          updatedCurrentStep,
          businessProcessSteps,
          businessProcess,
          bpTemplateSteps,
          bpTemplate,
          objEntity,
          formLibrary
        ).then((result) => {
          if (activeStepComment.length > 0) {
            let activityRow: ActivityRow = new ActivityRow();
            activityRow.UserUID = msalInstance.currentUserId;
            activityRow.UserName = msalInstance.currentUserName;

            activityRow.RelatedToType = businessProcess.RelatedToType;
            activityRow.Action = "added";
            activityRow.IsSystem = false;
            activityRow.RelatedToUID = businessProcess.RelatedToUID;
            activityRow.RelatedTo = businessProcess.RelatedTo;
            activityRow.Message = activeStepComment;

            activityRow.SecondaryRelatedTo = currentStep.Title;
            activityRow.SecondaryRelatedToType =
              RAFEntityName.BusinessProcessStep;
            activityRow.SecondaryRelatedToUID = currentStep.UID;
            activityRow.Action = RAFActivityAction.Added;
            repositoryActions
              .postDataAndGetResponse(
                "Activity/Save",
                getSaveRequest(activityRow, null),
                { ...this.props },
                ContentType.applicationJson
              )
              .then((response) => {
                hideProgress(progressDiv);
                if (
                  isNotNullAndUndefined(response) &&
                  isNotNullAndUndefined(response.data) &&
                  isNotNullAndUndefined(response.data.EntityId)
                ) {
                  showSuccessToast("Comment added", "Success!");
                  resolve({
                    processStep: result.businessProcessStep,
                    businessProcess: result.businessProcess,
                    processSteps: result.allBPRows,
                  });
                }
              });
          } else {
            hideProgress(progressDiv);
            resolve({
              processStep: result.businessProcessStep,
              businessProcess: result.businessProcess,
              processSteps: result.allBPRows,
            });
          }
        });
      } else {
        SaveProcessStepAndUpdateBP(
          updatedCurrentStep,
          businessProcessSteps,
          businessProcess,
          bpTemplateSteps,
          bpTemplate,
          objEntity,
          formLibrary
        ).then((result) => {
          if (activeStepComment.length > 0) {
            let activityRow: ActivityRow = new ActivityRow();
            activityRow.UserUID = msalInstance.currentUserId;
            activityRow.UserName = msalInstance.currentUserName;

            activityRow.RelatedToType = businessProcess.RelatedToType;
            activityRow.Action = "added";
            activityRow.IsSystem = false;
            activityRow.RelatedToUID = businessProcess.RelatedToUID;
            activityRow.RelatedTo = businessProcess.RelatedTo;
            activityRow.Message = activeStepComment;

            activityRow.SecondaryRelatedTo = currentStep.Title;
            activityRow.SecondaryRelatedToType =
              RAFEntityName.BusinessProcessStep;
            activityRow.SecondaryRelatedToUID = currentStep.UID;
            activityRow.Action = RAFActivityAction.Added;
            repositoryActions
              .postDataAndGetResponse(
                "Activity/Save",
                getSaveRequest(activityRow, null),
                { ...this.props },
                ContentType.applicationJson
              )
              .then((response) => {
                hideProgress(progressDiv);
                if (
                  isNotNullAndUndefined(response) &&
                  isNotNullAndUndefined(response.data) &&
                  isNotNullAndUndefined(response.data.EntityId)
                ) {
                  showSuccessToast("Comment added", "Success!");
                  resolve({
                    processStep: result.businessProcessStep,
                    businessProcess: result.businessProcess,
                    processSteps: result.allBPRows,
                  });
                }
              });
          } else {
            hideProgress(progressDiv);
            resolve({
              processStep: result.businessProcessStep,
              businessProcess: result.businessProcess,
              processSteps: result.allBPRows,
            });
          }
        });
      }
    } else {
      SaveProcessStepAndUpdateBP(
        updatedCurrentStep,
        businessProcessSteps,
        businessProcess,
        bpTemplateSteps,
        bpTemplate,
        objEntity,
        formLibrary
      ).then((result) => {
        if (activeStepComment.length > 0) {
          let activityRow: ActivityRow = new ActivityRow();
          activityRow.UserUID = msalInstance.currentUserId;
          activityRow.UserName = msalInstance.currentUserName;

          activityRow.RelatedToType = businessProcess.RelatedToType;
          activityRow.Action = "added";
          activityRow.IsSystem = false;
          activityRow.RelatedToUID = businessProcess.RelatedToUID;
          activityRow.RelatedTo = businessProcess.RelatedTo;
          activityRow.Message = activeStepComment;

          activityRow.SecondaryRelatedTo = currentStep.Title;
          activityRow.SecondaryRelatedToType =
            RAFEntityName.BusinessProcessStep;
          activityRow.SecondaryRelatedToUID = currentStep.UID;
          activityRow.Action = RAFActivityAction.Added;
          repositoryActions
            .postDataAndGetResponse(
              "Activity/Save",
              getSaveRequest(activityRow, null),
              { ...this.props },
              ContentType.applicationJson
            )
            .then((response) => {
              hideProgress(progressDiv);
              if (
                isNotNullAndUndefined(response) &&
                isNotNullAndUndefined(response.data) &&
                isNotNullAndUndefined(response.data.EntityId)
              ) {
                showSuccessToast("Comment added", "Success!");
                resolve({
                  processStep: result.businessProcessStep,
                  businessProcess: result.businessProcess,
                  processSteps: result.allBPRows,
                });
              }
            });
        } else {
          hideProgress(progressDiv);
          resolve({
            processStep: result.businessProcessStep,
            businessProcess: result.businessProcess,
            processSteps: result.allBPRows,
          });
        }
      });
    }
  }).catch((e) => e);
}

function EvaluateAndSaveProcessStep(
  currentBPStepRow: BusinessProcessStepRow,
  allbpStepRows: BusinessProcessStepRow[],
  businessProcessRow: BusinessProcessRow,
  bpTemplateSteps: BusinessProcessStepTemplateRow[],
  dependentSteps?: BusinessProcessStepRow[],
  nextBPStepRow?: BusinessProcessStepRow,
  bpTemplate?: BusinessProcessTemplateRow,
  objEntity?: EntityRow,
  formLibrary?: FormLibraryRow
) {
  return new Promise<{
    allBPRows: BusinessProcessStepRow[];
    dependentBPRows: BusinessProcessStepRow[];
    businessProcess: BusinessProcessRow;
    businessProcessStep: BusinessProcessStepRow;
  }>((resolve, reject) => {
    //update completed step count in business process
    const completedSteps: BusinessProcessStepRow[] = isNotNullAndUndefined(
      allbpStepRows
    )
      ? allbpStepRows.filter(
          (x) =>
            (x.StepStatus === RAFBPStepStatus.Completed ||
              x.StepStatus === RAFBPStepStatus.Skipped ||
              x.StepStatus === RAFBPStepStatus.ResponseReceived ||
              x.StepStatus === RAFBPStepStatus.Approved ||
              x.StepStatus === RAFBPStepStatus.Rejected ||
              x.StepStatus === RAFBPStepStatus.Reviewed) &&
            IsNullOrWhiteSpace(x.ParentBpStepTemplateUID)
        )
      : null;
    let totalSteps = 0;
    const utcNewDate = moment.utc().format() as any;
    if (businessProcessRow.ExecutionOrder === RAFExecutionOrder.Conditional) {
      totalSteps = isNotNullAndUndefined(allbpStepRows)
        ? allbpStepRows.filter((x) =>
            IsNullOrWhiteSpace(x.ParentBpStepTemplateUID)
          ).length
        : 0;
      businessProcessRow.CompletedCount = isNotNullAndUndefined(completedSteps)
        ? completedSteps.length
        : 0;
      businessProcessRow.StepCount = totalSteps;
    } else {
      // totalSteps = isNotNullAndUndefined(bpTemplateSteps)
      //   ? bpTemplateSteps.filter((x) => IsNullOrWhiteSpace(x.ParentStepUID))
      //       .length
      //   : 0;
      totalSteps = isNotNullAndUndefined(allbpStepRows)
        ? allbpStepRows.filter((x) =>
            IsNullOrWhiteSpace(x.ParentBpStepTemplateUID)
          ).length
        : 0;
      businessProcessRow.CompletedCount = isNotNullAndUndefined(completedSteps)
        ? completedSteps.length
        : 0;
      businessProcessRow.StepCount = totalSteps;
    }
    if (isNotNullAndUndefined(bpTemplate)) bpTemplate.IsOfflineProcess = false;
    /* Commented status will update by API
    if (
      isNotNullAndUndefined(completedSteps) &&
      completedSteps.length > 0 &&
      completedSteps.length === totalSteps
    ) {
      businessProcessRow.ProcessStatus = RAFBPStatus.Completed;
      businessProcessRow.CompletedCount = completedSteps.length;
      businessProcessRow.EndDate = new Date();
      
    } else {
      businessProcessRow.CompletedCount =
        isNotNullAndUndefined(allbpStepRows) &&
        allbpStepRows.filter(
          (x) =>
            (x.StepStatus === RAFBPStepStatus.Completed ||
              x.StepStatus === RAFBPStepStatus.Skipped ||
              x.StepStatus === RAFBPStepStatus.Approved ||
              x.StepStatus === RAFBPStepStatus.Rejected ||
              x.StepStatus === RAFBPStepStatus.Reviewed) &&
            IsNullOrWhiteSpace(x.ParentBpStepTemplateUID)
        ).length;
      businessProcessRow.ProcessStatus = RAFBPStatus.InProgress;
    }
    */

    if (businessProcessRow.FormType === "Entity") {
      updateRelatedProcessStatus(
        businessProcessRow.RelatedToUID,
        businessProcessRow.ProcessStatus,
        businessProcessRow.RelatedToType,
        nextBPStepRow,
        allbpStepRows,
        businessProcessRow,
        currentBPStepRow,
        bpTemplateSteps
      ).then((relatedRow) => {
        //SaveBusinessProcessAPI(businessProcessRow).then((bpResponse) => {
        resolve({
          allBPRows: allbpStepRows,
          dependentBPRows: dependentSteps,
          businessProcess: businessProcessRow,
          businessProcessStep: currentBPStepRow,
        });
        //});
      });
    } else {
      if (
        isNotNullAndUndefined(completedSteps) &&
        completedSteps.length > 0 &&
        completedSteps.length === totalSteps
      ) {
        businessProcessRow.ProcessStatus = RAFBPStatus.Completed;
        businessProcessRow.CompletedCount = completedSteps.length;
        businessProcessRow.EndDate = utcNewDate;
        businessProcessRow.ModifiedBy = msalInstance.currentUserName;
        businessProcessRow.ModifiedByUID = msalInstance.currentUserId;

        if (isNotNullAndUndefined(bpTemplate) && bpTemplate.IsOfflineProcess) {
          SaveBusinessProcessFB(businessProcessRow, true).then((bpResponse) => {
            resolve({
              allBPRows: allbpStepRows,
              dependentBPRows: dependentSteps,
              businessProcess: businessProcessRow,
              businessProcessStep: currentBPStepRow,
            });
          });
        } else {
          SaveBusinessProcessAPI(businessProcessRow).then((bpResponse) => {
            resolve({
              allBPRows: allbpStepRows,
              dependentBPRows: dependentSteps,
              businessProcess: businessProcessRow,
              businessProcessStep: currentBPStepRow,
            });
          });
        }
      } else {
        businessProcessRow.ProcessStatus = RAFBPStatus.InProgress;
        businessProcessRow.CompletedCount = completedSteps.length;
        businessProcessRow.StepCount = totalSteps;
        if (isNotNullAndUndefined(bpTemplate) && bpTemplate.IsOfflineProcess) {
          SaveBusinessProcessFB(businessProcessRow, true).then((bpResponse) => {
            resolve({
              allBPRows: allbpStepRows,
              dependentBPRows: dependentSteps,
              businessProcess: businessProcessRow,
              businessProcessStep: currentBPStepRow,
            });
          });
        } else {
          if (
            isNotNullAndUndefined(businessProcessRow.FormUID) &&
            businessProcessRow.FormType === RAFFormType.FormFlow
          ) {
            if (currentBPStepRow.StepStatus === RAFBPStepStatus.Completed) {
              if (isNotNullAndUndefined(businessProcessRow.EntityUID)) {
                RetrieveRelatedData(
                  businessProcessRow.RelatedToUID,
                  objEntity.UID
                ).then((formDataResponse) => {
                  let bpStepFormData = currentBPStepRow.FormData;
                  let objectData;

                  if (isNotNullAndUndefined(bpStepFormData)) {
                    objectData = deepMerge(formDataResponse, bpStepFormData);
                  } else {
                    objectData = formDataResponse;
                  }
                  if (isNotNullAndUndefined(objectData)) {
                    objectData[RAFProcessFormPropertyName.BusinessProcesssUID] =
                      businessProcessRow.UID;
                    objectData[RAFProcessFormPropertyName.BusinessProcesss] =
                      businessProcessRow.ProcessName;
                    objectData[RAFProcessFormPropertyName.ProcessStatus] =
                      businessProcessRow.ProcessStatus;
                    objectData[RAFProcessFormPropertyName.CompletedCount] =
                      businessProcessRow.CompletedCount;
                    objectData[RAFProcessFormPropertyName.StepCount] =
                      businessProcessRow.StepCount;
                    objectData[RAFProcessFormPropertyName.OverDueStepCount] =
                      businessProcessRow.StepCount -
                      businessProcessRow.CompletedCount;
                  }

                  SaveFormDataListAPI(objectData, objEntity.UID).then(
                    (formDataResponse) => {
                      SaveBusinessProcessAPI(businessProcessRow).then(
                        (bpResponse) => {
                          resolve({
                            allBPRows: allbpStepRows,
                            dependentBPRows: dependentSteps,
                            businessProcess: businessProcessRow,
                            businessProcessStep: currentBPStepRow,
                          });
                        }
                      );
                    }
                  );
                });
              } else {
                RetrieveRelatedFormData(
                  businessProcessRow.UID,
                  objEntity.UID
                ).then((formDataResponse) => {
                  let bpStepFormData = currentBPStepRow.FormData;
                  let objectData;

                  if (isNotNullAndUndefined(bpStepFormData)) {
                    objectData = deepMerge(formDataResponse, bpStepFormData);
                  } else {
                    objectData = formDataResponse;
                  }
                  if (isNotNullAndUndefined(objectData)) {
                    objectData[RAFProcessFormPropertyName.BusinessProcesssUID] =
                      businessProcessRow.UID;
                    objectData[RAFProcessFormPropertyName.BusinessProcesss] =
                      businessProcessRow.ProcessName;
                    objectData[RAFProcessFormPropertyName.ProcessStatus] =
                      businessProcessRow.ProcessStatus;
                    objectData[RAFProcessFormPropertyName.CompletedCount] =
                      businessProcessRow.CompletedCount;
                    objectData[RAFProcessFormPropertyName.StepCount] =
                      businessProcessRow.StepCount;
                    objectData[RAFProcessFormPropertyName.OverDueStepCount] =
                      businessProcessRow.StepCount -
                      businessProcessRow.CompletedCount;
                  }

                  SaveFormDataListAPI(objectData, objEntity.UID).then(
                    (formDataResponse) => {
                      SaveBusinessProcessAPI(businessProcessRow).then(
                        (bpResponse) => {
                          resolve({
                            allBPRows: allbpStepRows,
                            dependentBPRows: dependentSteps,
                            businessProcess: businessProcessRow,
                            businessProcessStep: currentBPStepRow,
                          });
                        }
                      );
                    }
                  );
                });
              }
            } else {
              SaveBusinessProcessAPI(businessProcessRow).then((bpResponse) => {
                resolve({
                  allBPRows: allbpStepRows,
                  dependentBPRows: dependentSteps,
                  businessProcess: businessProcessRow,
                  businessProcessStep: currentBPStepRow,
                });
              });
            }
          }
        }
      }
    }
  });
}

function UpdateNextProcessStepStatus(
  allbpStepRows?: BusinessProcessStepRow[],
  currentbpStepRow?: BusinessProcessStepRow,
  businessProcessRow?: BusinessProcessRow,
  bpTemplateSteps?: BusinessProcessStepTemplateRow[]
) {
  //check for next step. if step exist, make it ready otherwise complete the process
  let currentBpStepIndex =
    allbpStepRows &&
    allbpStepRows.findIndex((x) => x.UID === currentbpStepRow.UID);
  let nextBPStepRow: BusinessProcessStepRow = null;
  let updatedbpStepRows: BusinessProcessStepRow[] = R.clone(allbpStepRows);
  return new Promise<{
    updatedNextBPStepRow: BusinessProcessStepRow;
    allbpStepRows: BusinessProcessStepRow[];
  }>((resolve, reject) => {
    if (isNotNullAndUndefined(currentbpStepRow.FormData)) {
      const promptAnswer = currentbpStepRow.FormData.Prompt;
      if (isNotNullAndUndefined(promptAnswer)) {
        const nextStepConnector =
          isNotNullAndUndefined(currentbpStepRow.StepConnectorJson) &&
          currentbpStepRow.StepConnectorJson.find(
            (x) =>
              isNotNullAndUndefined(x.Annotations) &&
              isNotNullAndUndefined(x.Annotations[0]) &&
              x.Annotations[0].Content === promptAnswer
          );
        let nextStepUID = null;
        if (isNotNullAndUndefined(nextStepConnector)) {
          nextStepUID = nextStepConnector.TargetID;
        }
        if (isNotNullAndUndefined(nextStepUID)) {
          if (nextStepUID === RAFBPAction.Continue) {
            let nextBPStepRow = allbpStepRows[currentBpStepIndex + 1];
            if (isNotNullAndUndefined(nextBPStepRow)) {
              UpdateNextProcessStep(
                updatedbpStepRows,
                nextBPStepRow,
                bpTemplateSteps,
                businessProcessRow
              ).then((nextBPStepRowResponse) => {
                let updatedbpStepRows: BusinessProcessStepRow[] =
                  isNotNullAndUndefined(nextBPStepRowResponse.allbpStepRows)
                    ? nextBPStepRowResponse.allbpStepRows
                    : allbpStepRows;
                resolve({
                  updatedNextBPStepRow:
                    nextBPStepRowResponse.updatedNextBPStepRow,
                  allbpStepRows: updatedbpStepRows,
                });
              });
            } else {
              resolve({
                updatedNextBPStepRow: null,
                allbpStepRows: updatedbpStepRows,
              });
            }
          } else if (nextStepUID === RAFBPAction.End) {
            nextBPStepRow = null;
            if (isNotNullAndUndefined(nextBPStepRow)) {
              //Update next Step status
              if (nextBPStepRow.IsApproval) {
                //nextBPStepRow.StepStatus = RAFBPStepStatus.RequestApproval;
                nextBPStepRow.StepStatus = RAFBPStepStatus.InitiateRequest;
                if (nextBPStepRow.StepType === RAFBPStepType.Review) {
                  nextBPStepRow.StepStatus = RAFBPStepStatus.RequestReview;
                }
              } else {
                if (
                  isNotNullAndUndefined(nextBPStepRow.AssigneeUID) ||
                  isNotNullAndUndefined(nextBPStepRow.AssignTeamUID)
                )
                  nextBPStepRow.StepStatus = RAFBPStepStatus.Assigned;
                else nextBPStepRow.StepStatus = RAFBPStepStatus.ToDo;
              }

              //Create next step
              // SaveBusinessProcessStepAPI(nextBPStepRow).then(
              //   (bpstepResponse) => {
              //     updatedbpStepRows.push({ ...nextBPStepRow });
              //     resolve({
              //       updatedNextBPStepRow: nextBPStepRow,
              //       allbpStepRows: updatedbpStepRows,
              //     });
              //   }
              // );

              //update nextBPStep in allbpStep array
              updatedbpStepRows[
                updatedbpStepRows.findIndex((x) => x.UID === nextBPStepRow.UID)
              ] = nextBPStepRow;

              //resolve(nextBPStepRow);
              resolve({
                updatedNextBPStepRow: nextBPStepRow,
                allbpStepRows: updatedbpStepRows,
              });
            } else {
              //check the steps and change status to skipped

              const completedSteps: BusinessProcessStepRow[] =
                isNotNullAndUndefined(updatedbpStepRows)
                  ? updatedbpStepRows.filter(
                      (x) =>
                        (x.StepStatus === RAFBPStepStatus.Completed ||
                          x.StepStatus === RAFBPStepStatus.Skipped ||
                          x.StepStatus === RAFBPStepStatus.ResponseReceived ||
                          x.StepStatus === RAFBPStepStatus.Approved ||
                          x.StepStatus === RAFBPStepStatus.Rejected ||
                          x.StepStatus === RAFBPStepStatus.Reviewed) &&
                        IsNullOrWhiteSpace(x.ParentBpStepTemplateUID)
                    )
                  : null;
              let totalSteps = 0;
              if (
                businessProcessRow.ExecutionOrder ===
                RAFExecutionOrder.Conditional
              ) {
                totalSteps = isNotNullAndUndefined(updatedbpStepRows)
                  ? updatedbpStepRows.filter((x) =>
                      IsNullOrWhiteSpace(x.ParentBpStepTemplateUID)
                    ).length
                  : 0;
              } else {
                totalSteps = isNotNullAndUndefined(bpTemplateSteps)
                  ? bpTemplateSteps.filter((x) =>
                      IsNullOrWhiteSpace(x.ParentStepUID)
                    ).length
                  : 0;
              }

              if (completedSteps && completedSteps.length === totalSteps) {
                resolve({
                  updatedNextBPStepRow: nextBPStepRow,
                  allbpStepRows: updatedbpStepRows,
                });
              } else {
                UpdateNextBPStepStatusAsSkipped(
                  allbpStepRows,
                  currentbpStepRow,
                  nextBPStepRow,
                  businessProcessRow
                ).then((updatedBpSteps) => {
                  resolve({
                    updatedNextBPStepRow: nextBPStepRow,
                    allbpStepRows: updatedBpSteps,
                  });
                });
              }
            }
          } else {
            let siblingBPStepRows: BusinessProcessStepRow[] =
              isNotNullAndUndefined(allbpStepRows) &&
              allbpStepRows.filter(
                (x) =>
                  x.ParentStepUID === currentbpStepRow.ParentStepUID &&
                  x.UID !== currentbpStepRow.UID
              );
            let nextBPStepRow =
              siblingBPStepRows &&
              siblingBPStepRows.find((x) => x.UID === nextStepUID);

            if (isNotNullAndUndefined(nextBPStepRow)) {
              //Update next Step status
              // if (nextBPStepRow.IsApproval) {
              //   nextBPStepRow.StepStatus = RAFBPStepStatus.InitiateRequest;
              //   if (nextBPStepRow.StepType === RAFBPStepType.Review) {
              //     nextBPStepRow.StepStatus = RAFBPStepStatus.RequestReview;
              //   }
              // } else {
              //   if (
              //     isNotNullAndUndefined(nextBPStepRow.AssigneeUID) ||
              //     isNotNullAndUndefined(nextBPStepRow.AssignTeamUID)
              //   )
              //     nextBPStepRow.StepStatus = RAFBPStepStatus.Assigned;
              //   else nextBPStepRow.StepStatus = RAFBPStepStatus.ToDo;
              // }

              //Create next step
              // SaveBusinessProcessStepAPI(nextBPStepRow).then(
              //   (bpstepResponse) => {
              //     updatedbpStepRows.push({ ...nextBPStepRow });
              //     resolve({
              //       updatedNextBPStepRow: nextBPStepRow,
              //       allbpStepRows: updatedbpStepRows,
              //     });
              //   }
              // );

              UpdateNextProcessStep(
                updatedbpStepRows,
                nextBPStepRow,
                bpTemplateSteps,
                businessProcessRow
              ).then((nextBPStepRowResponse) => {
                let allUpdatedbpStepRows: BusinessProcessStepRow[] =
                  isNotNullAndUndefined(nextBPStepRowResponse.allbpStepRows)
                    ? nextBPStepRowResponse.allbpStepRows
                    : allbpStepRows;
                // resolve({
                //   updatedNextBPStepRow:
                //     nextBPStepRowResponse.updatedNextBPStepRow,
                //   allbpStepRows: updatedbpStepRows,
                // });
                //update nextBPStep in allbpStep array
                // allUpdatedbpStepRows[
                //   allUpdatedbpStepRows.findIndex(
                //     (x) => x.UID === nextBPStepRow.UID
                //   )
                // ] = nextBPStepRow;

                //resolve(nextBPStepRow);

                UpdateNextBPStepStatusAsSkipped(
                  allUpdatedbpStepRows,
                  currentbpStepRow,
                  nextBPStepRow,
                  businessProcessRow
                ).then((updatedBpSteps) => {
                  // resolve({
                  //   updatedNextBPStepRow: nextBPStepRow,
                  //   allbpStepRows: updatedbpStepRows,
                  // });

                  resolve({
                    updatedNextBPStepRow: nextBPStepRow,
                    allbpStepRows: updatedBpSteps,
                  });
                });
              });
            } else {
              resolve({
                updatedNextBPStepRow: nextBPStepRow,
                allbpStepRows: updatedbpStepRows,
              });
            }
          }
        } else {
          resolve({
            updatedNextBPStepRow: null,
            allbpStepRows: updatedbpStepRows,
          });
        }
      }
    }
  });
}

function UpdateNextBPStepStatusAsSkipped(
  allbpStepRows?: BusinessProcessStepRow[],
  currentBpStep?: BusinessProcessStepRow,
  nextBPStep?: BusinessProcessStepRow,
  businessProcess?: BusinessProcessRow
) {
  let updatedbpStepRows: BusinessProcessStepRow[] = R.clone(allbpStepRows);
  const utcNewDate = moment.utc().format() as any;
  return new Promise<BusinessProcessStepRow[]>(async (resolve, reject) => {
    if (
      businessProcess.ExecutionOrder === RAFExecutionOrder.StepByStep ||
      businessProcess.ExecutionOrder === RAFExecutionOrder.Conditional
    ) {
      let currentBpStepIndex =
        allbpStepRows &&
        allbpStepRows.findIndex((x) => x.UID === currentBpStep.UID);
      let nextBpStepIndex;
      if (isNotNullAndUndefined(nextBPStep)) {
        nextBpStepIndex =
          allbpStepRows &&
          allbpStepRows.findIndex((x) => x.UID === nextBPStep.UID);
      } else {
        nextBpStepIndex = allbpStepRows && allbpStepRows.length;
      }

      let i = currentBpStepIndex + 1;

      let nextChildBPStep: BusinessProcessStepRow = R.clone(allbpStepRows[i]);
      while (isNotNullAndUndefined(nextChildBPStep)) {
        if (i < nextBpStepIndex) {
          if (
            nextChildBPStep.StepStatus !== RAFBPStepStatus.Completed &&
            nextChildBPStep.StepStatus !== RAFBPStepStatus.Approved &&
            nextChildBPStep.StepStatus !== RAFBPStepStatus.ResponseReceived &&
            nextChildBPStep.StepStatus !== RAFBPStepStatus.Reviewed &&
            nextChildBPStep.StepStatus !== RAFBPStepStatus.Rejected &&
            nextChildBPStep.StepStatus !== RAFBPStepStatus.RequestforInfo &&
            nextChildBPStep.StepStatus !== RAFBPStepStatus.Skipped
          ) {
            nextChildBPStep.StepStatus = RAFBPStepStatus.Skipped;
            nextChildBPStep.CompletedBy = msalInstance.currentUserName;
            nextChildBPStep.CompletedByUID = msalInstance.currentUserId;
            nextChildBPStep.CompletedDate = utcNewDate;
          }
          let nextChildStep = await SaveBusinessProcessStepAPI(nextChildBPStep);
          if (isNotNullAndUndefined(nextChildBPStep)) {
            //update nextBPStep in allbpStep array
            updatedbpStepRows[
              updatedbpStepRows.findIndex((x) => x.UID === nextChildBPStep.UID)
            ] = nextChildBPStep;
          }
        }
        i++;
        nextChildBPStep = allbpStepRows[i];
      }
    } else {
      let bpStepsToUpdate =
        allbpStepRows &&
        allbpStepRows.filter((x) => x.UID !== currentBpStep.UID);

      let i = 0;

      let nextChildBPStep: BusinessProcessStepRow = R.clone(bpStepsToUpdate[i]);
      while (isNotNullAndUndefined(nextChildBPStep)) {
        if (
          nextChildBPStep.StepStatus !== RAFBPStepStatus.Completed &&
          nextChildBPStep.StepStatus !== RAFBPStepStatus.Approved &&
          nextChildBPStep.StepStatus !== RAFBPStepStatus.ResponseReceived &&
          nextChildBPStep.StepStatus !== RAFBPStepStatus.Reviewed &&
          nextChildBPStep.StepStatus !== RAFBPStepStatus.Rejected &&
          nextChildBPStep.StepStatus !== RAFBPStepStatus.RequestforInfo &&
          nextChildBPStep.StepStatus !== RAFBPStepStatus.Skipped
        ) {
          nextChildBPStep.StepStatus = RAFBPStepStatus.Skipped;
          nextChildBPStep.CompletedBy = msalInstance.currentUserName;
          nextChildBPStep.CompletedByUID = msalInstance.currentUserId;
          nextChildBPStep.CompletedDate = utcNewDate;
        }
        let nextChildStep = await SaveBusinessProcessStepAPI(nextChildBPStep);
        if (isNotNullAndUndefined(nextChildBPStep)) {
          //update nextBPStep in allbpStep array
          updatedbpStepRows[
            updatedbpStepRows.findIndex((x) => x.UID === nextChildBPStep.UID)
          ] = nextChildBPStep;
        }

        i++;
        nextChildBPStep = bpStepsToUpdate[i];
      }
    }
    resolve(updatedbpStepRows);
  });
}

function UpdateNextProcessStep(
  allbpStepRows: BusinessProcessStepRow[],
  nextBPStepTemplateRow: BusinessProcessStepRow,
  bpTemplateSteps: BusinessProcessStepTemplateRow[],
  _businessProcessRow: BusinessProcessRow,
  bpTemplate?: BusinessProcessTemplateRow
) {
  return new Promise<{
    updatedNextBPStepRow: BusinessProcessStepRow;
    allbpStepRows: BusinessProcessStepRow[];
  }>((resolve, reject) => {
    const utcNewDate = moment.utc().format() as any;
    let nextBPStepTemplateIndex = isNotNullAndUndefined(bpTemplateSteps)
      ? bpTemplateSteps.findIndex((x) => x.UID === nextBPStepTemplateRow.UID)
      : null;
    let nextBPStepIndex = isNotNullAndUndefined(allbpStepRows)
      ? allbpStepRows.findIndex((x) => x.UID === nextBPStepTemplateRow.UID)
      : null;
    let nextBPStepRow: BusinessProcessStepRow = null;
    let updatedbpStepRows: BusinessProcessStepRow[] = R.clone(allbpStepRows);
    if (isNotNullAndUndefined(nextBPStepTemplateRow)) {
      //Create next step
      let i = nextBPStepIndex;
      let nextChildBPStep: BusinessProcessStepRow = R.clone(allbpStepRows[i]);
      while (isNotNullAndUndefined(nextChildBPStep)) {
        if (
          nextChildBPStep.StepStatus !== RAFBPStepStatus.Completed &&
          nextChildBPStep.StepStatus !== RAFBPStepStatus.Approved &&
          nextChildBPStep.StepStatus !== RAFBPStepStatus.ResponseReceived &&
          nextChildBPStep.StepStatus !== RAFBPStepStatus.Reviewed &&
          nextChildBPStep.StepStatus !== RAFBPStepStatus.Rejected &&
          nextChildBPStep.StepStatus !== RAFBPStepStatus.RequestforInfo &&
          nextChildBPStep.StepStatus !== RAFBPStepStatus.Skipped
        ) {
          if (nextChildBPStep.IsApproval) {
            //nextChildBPStep.StepStatus = RAFBPStepStatus.RequestApproval;
            nextChildBPStep.StepStatus = RAFBPStepStatus.InitiateRequest;
            if (nextChildBPStep.StepType === RAFBPStepType.Review) {
              nextChildBPStep.StepStatus = RAFBPStepStatus.RequestReview;
            }
          } else {
            if (
              isNotNullAndUndefined(nextChildBPStep.AssigneeUID) ||
              isNotNullAndUndefined(nextChildBPStep.AssignTeamUID)
            ) {
              nextChildBPStep.StepStatus = RAFBPStepStatus.Assigned;
            } else if (
              nextChildBPStep.StepType === RAFBPStepType.AutoComplete
            ) {
              nextChildBPStep.StepStatus = RAFBPStepStatus.Completed;
              nextChildBPStep.CompletedBy = msalInstance.currentUserName;
              nextChildBPStep.CompletedByUID = msalInstance.currentUserId;
              nextChildBPStep.CompletedDate = utcNewDate;
            } else {
              nextChildBPStep.StepStatus = RAFBPStepStatus.ToDo;
            }
          }
        }

        let nextChild = SaveBusinessProcessStepAPI(nextChildBPStep);
        if (isNotNullAndUndefined(nextChildBPStep)) {
          //updatedbpStepRows.push({ ...nextChildBPStep });

          //update nextBPStep in allbpStep array
          updatedbpStepRows[
            updatedbpStepRows.findIndex((x) => x.UID === nextChildBPStep.UID)
          ] = nextChildBPStep;
        }
        if (
          nextChildBPStep.StepType === RAFBPStepType.Group ||
          nextChildBPStep.StepType === RAFBPStepType.Stage ||
          nextChildBPStep.StepType === RAFBPStepType.AutoComplete
        ) {
          i++;
          nextChildBPStep = allbpStepRows[i];
        } else {
          nextChildBPStep = null;
        }
      }

      resolve({
        updatedNextBPStepRow: updatedbpStepRows[i],
        allbpStepRows: updatedbpStepRows,
      });
    } else {
      resolve({
        updatedNextBPStepRow: nextBPStepRow,
        allbpStepRows: updatedbpStepRows,
      });
    }
  }).catch((error) => error);
}

function getTargetIdByStepConnectorJson(currentStep?: BusinessProcessStepRow) {
  let retVal;
  return new Promise<string>((resolve, reject) => {
    let stepConnector = currentStep.StepConnectorJson;
    let promptAnswer = isNotNullAndUndefined(currentStep.FormData["Prompt"])
      ? currentStep.FormData["Prompt"]
      : null;
    if (
      isNotNullAndUndefined(stepConnector) &&
      isNotNullAndUndefined(promptAnswer)
    ) {
      let targetItem = stepConnector.find(
        (x) => x.Annotations[0].Content === promptAnswer
      );
      if (isNotNullAndUndefined(targetItem)) {
        retVal = targetItem.TargetID;
      }
    }
    resolve(retVal);
  });
}

//New bp template step save methods

export function CreateParentAndStep(
  allbpSteps: BusinessProcessStepTemplateRow[],
  bpStepTemplate: BusinessProcessStepTemplateRow,
  parentStepUID: string,
  bpTemplate: BusinessProcessTemplateRow
) {
  return new Promise<{
    objectID: string;
    bpTemplateSteps: BusinessProcessStepTemplateRow[];
  }>((resolve, reject) => {
    let progressDiv = showProgress("body", true);
    let parentStep = isNotNullAndUndefined(allbpSteps)
      ? allbpSteps.find((x) => x.UID === parentStepUID)
      : null;
    if (isNotNullAndUndefined(parentStep)) {
      bpStepTemplate.ParentStep = parentStep.Title;
      bpStepTemplate.ParentStepUID = parentStep.UID;
      //SaveBPStepTemplateAPI(bpStepTemplate).then((bpStepResponse) => {
      InsertBPStepTemplateFB(bpStepTemplate).then((bpStepResponse) => {
        bpStepTemplate.UID = bpStepResponse;
        allbpSteps.push({ ...bpStepTemplate });
        hideProgress(progressDiv);
        let response: {
          objectID: string;
          bpTemplateSteps: BusinessProcessStepTemplateRow[];
        } = { objectID: bpStepResponse, bpTemplateSteps: allbpSteps };
        resolve(response);
      });
    } else {
      let parentBPStepRow = new BusinessProcessStepTemplateRow();
      parentBPStepRow.Title = "Page";
      parentBPStepRow.BpTemplate = bpTemplate.ProcessName;
      parentBPStepRow.BpTemplateUID = bpTemplate.UID;
      parentBPStepRow.ExecutionOrder = RAFExecutionOrder.AnyOrder;
      parentBPStepRow.AdditionalInfo = false;
      parentBPStepRow.AssigneeType = RAFProcessAssigneeType.None;
      parentBPStepRow.StepType = RAFBPStepType.Section;
      parentBPStepRow.StepStatus = RAFBPStepTemplateStatus.Active;
      parentBPStepRow.ParentStep = null;
      parentBPStepRow.ParentStepUID = null;
      parentBPStepRow.StepConnectorJson =
        GenerateStepConnectorJSon(parentBPStepRow);
      //SaveBPStepTemplateAPI(parentBPStepRow).then((parentBPStepResponse) => {
      InsertBPStepTemplateFB(parentBPStepRow).then((parentBPStepResponse) => {
        bpStepTemplate.ParentStepUID = parentBPStepResponse;
        bpStepTemplate.ParentStep = parentBPStepRow.Title;
        allbpSteps.push({ ...bpStepTemplate });
        //SaveBPStepTemplateAPI(bpStepTemplate).then((bpStepResponse) => {
        InsertBPStepTemplateFB(bpStepTemplate).then((bpStepResponse) => {
          bpStepTemplate.UID = bpStepResponse;
          allbpSteps.push({ ...bpStepTemplate });
          hideProgress(progressDiv);
          let response: {
            objectID: string;
            bpTemplateSteps: BusinessProcessStepTemplateRow[];
          } = { objectID: bpStepResponse, bpTemplateSteps: allbpSteps };
          resolve(response);
        });
      });
    }
  }).catch((error) => error);
}

export function checkBPTemplateExist(
  businessProcessTemplateDto: IBusinessProcessTemplateDto,
  bpTemplateList: BusinessProcessTemplateRow[]
) {
  if (isNotNullAndUndefined(businessProcessTemplateDto.VersionUID)) {
    let item = bpTemplateList.find(
      (x) => x.VersionUID === businessProcessTemplateDto.VersionUID
    );
    if (isNotNullAndUndefined(item) && isNotNullAndUndefined(item.UID)) {
      return false;
    } else {
      return true;
    }
  } else {
    if (isNotNullAndUndefined(bpTemplateList) && bpTemplateList.length > 0) {
      return true;
    } else {
      return false;
    }
  }
}

export function SaveAllBPStepTemplate(
  bpTemplateUID?: string,
  updateProcessTemplateSteps?: any,
  bpTemplate?: BusinessProcessTemplateRow
) {
  let progressDiv = showProgress("body", true);
  return new Promise<boolean>((resolve, reject) => {
    const rafBpStepTemplateRepository: BpStepTemplateRepository =
      new BpStepTemplateRepository();
    rafBpStepTemplateRepository
      .getAllRAFBPTemplateSteps(bpTemplateUID)
      .then((allBPTemplateSteps) => {
        if (isNotNullAndUndefined(allBPTemplateSteps)) {
          let bpStepTemplate =
            isNotNullAndUndefined(allBPTemplateSteps) &&
            allBPTemplateSteps.length > 0
              ? sortItem(allBPTemplateSteps, "StepOrder")
              : [];
          let promiseArr = bpStepTemplate.map(function (item) {
            //Save BP Step Template
            return SaveBusinessProcessStepTemplateAPI(item).then(
              (dependentStepResponse) => {
                return item;
              }
            );
          });
          Promise.all(promiseArr)
            .then(function (resultsArray) {
              if (bpStepTemplate.length === resultsArray.length) {
                hideProgress(progressDiv);
                updateProcessTemplateSteps(bpStepTemplate);
                resolve(true);
              } else {
                resolve(false);
              }
            })
            .catch(function (err) {
              // do something when any of the promises in array are rejected
            });
        } else {
          GetBusinessProcessTemplateSteps(bpTemplate).then(
            (allBPTemplateStepsResponse) => {
              if (isNotNullAndUndefined(allBPTemplateStepsResponse)) {
                let bpStepTemplate =
                  isNotNullAndUndefined(allBPTemplateStepsResponse) &&
                  allBPTemplateStepsResponse.length > 0
                    ? sortItem(allBPTemplateSteps, "StepOrder")
                    : [];
                // let promiseArr = bpStepTemplate.map(function (item) {
                //   //Save BP Step Template
                //   return SaveBusinessProcessStepTemplateAPI(item).then(
                //     (dependentStepResponse) => {
                //       return item;
                //     }
                //   );
                // });
                // Promise.all(promiseArr)
                //   .then(function (resultsArray) {
                //     if (bpStepTemplate.length === resultsArray.length) {
                //       hideProgress(progressDiv);
                //       updateProcessTemplateSteps(bpStepTemplate);
                //       resolve(true);
                //     } else {
                //       resolve(false);
                //     }
                //   })
                //   .catch(function (err) {
                //     // do something when any of the promises in array are rejected
                //   });

                hideProgress(progressDiv);
                updateProcessTemplateSteps(bpStepTemplate);
                resolve(true);
              }
            }
          );
        }
      });
  });
}

export function FormContainsUserInput(formComponents: RAFDataModelRow[]) {
  let retVal = false;
  let flattenFormComponent: RAFDataModelRow[] =
    isNotNullAndUndefined(formComponents) && formComponents.length > 0
      ? flattenFormComponents(formComponents)
      : null;
  if (
    isNotNullAndUndefined(flattenFormComponent) &&
    flattenFormComponent.length > 0
  ) {
    flattenFormComponent.forEach((formComponent) => {
      if (
        formComponent.DataType === RAFDataType.Text ||
        formComponent.DataType === RAFDataType.Number ||
        formComponent.DataType === RAFDataType.Date ||
        formComponent.DataType === RAFDataType.DateTime ||
        formComponent.DataType === RAFDataType.Lookup ||
        formComponent.DataType === RAFDataType.Boolean ||
        formComponent.DataType === RAFDataType.Dropdown
      ) {
        retVal = true;
      }
    });
  }
  return retVal;
}
