import { MappingPair, MapperConfiguration } from '@dynamic-mapper/mapper';
import { BusinessProcessRow, IBusinessProcessDto } from "./BusinessProcessRow";

export const ConvertBusinessProcessRowToBusinessProcessDto = (BusinessProcessRow: BusinessProcessRow): IBusinessProcessDto => {

    const BusinessProcessRowToIBusinessProcessDto = new MappingPair<BusinessProcessRow, IBusinessProcessDto>();
    const BusinessProcessRowToBusinessProcessDtoConfiguration = new MapperConfiguration(cfg => {
        cfg.createAutoMap(BusinessProcessRowToIBusinessProcessDto, {})
            ;
    });

    const mapper = BusinessProcessRowToBusinessProcessDtoConfiguration.createMapper();
    return mapper.map(BusinessProcessRowToIBusinessProcessDto, BusinessProcessRow);
}

export const ConvertBusinessProcessDtoToBusinessProcessRow = (BusinessProcessDto: IBusinessProcessDto): BusinessProcessRow => {
    const IBusinessProcessDtoToBusinessProcessRow = new MappingPair<IBusinessProcessDto, BusinessProcessRow>();
    const IBusinessProcessDtoToBusinessProcessRowConfiguration = new MapperConfiguration(cfg => {
        cfg.createAutoMap(IBusinessProcessDtoToBusinessProcessRow, {}) ;
    });

    const mapper = IBusinessProcessDtoToBusinessProcessRowConfiguration.createMapper();
    return mapper.map(IBusinessProcessDtoToBusinessProcessRow, BusinessProcessDto);
}

