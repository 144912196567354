import { FormState } from "final-form";
import { Engine, RuleProperties } from "json-rules-engine";
import { FormRenderProps } from "react-final-form";
import { HideElementAllOccurence, isNotNullAndUndefined, IsNullOrWhiteSpace, ShowElementAllOccurence } from "../../RAFComponents/helpers/utils";
import { hideToast, showInformationToast } from "../../RAFComponents/Utility/RAFToastComponent";
import { BusinessAction } from "../../RAFMaster/RMModules/BusinessRules/BusinessAction";
//import React from 'react';

function addBasicOperatorsToEngine(engine: Engine): Engine {

    engine.removeOperator('startsWith');
    engine.addOperator('startsWith', (factValue, jsonValue) => {
        if (!factValue) return false;
        if (!jsonValue) return false;
        if (!factValue.toString().length) return false;
        //return factValue[0].toLowerCase() === jsonValue.toString().toLowerCase()
        return factValue.toString().toLowerCase().startsWith(jsonValue.toString().toLowerCase());
    });

    engine.removeOperator('endsWith');
    engine.addOperator('endsWith', (factValue, jsonValue) => {
        if (!factValue) return false;
        if (!jsonValue) return false;
        if (!factValue.toString().length) return false;
        //return factValue[0].toLowerCase() === jsonValue.toString().toLowerCase();
        return factValue.toString().toLowerCase().endsWith(jsonValue.toString().toLowerCase());
    });

    //engine.removeOperator('contains');
    //engine.addOperator('contains', (factValue, jsonValue) => {
    //    if (!factValue) return false;
    //    if (!jsonValue) return false;
    //    if (!factValue.toString().length) return false;
    //    //return factValue[0].toLowerCase() === jsonValue.toString().toLowerCase();
    //    return factValue.toString().toLowerCase().indexOf(jsonValue.toString().toLowerCase()) >= 0 ? true : false;
    //});



    return engine;
}

export function runRulesEngine(rules: RuleProperties[], facts, form: FormRenderProps | null, formState: FormState<Record<string, any>, Partial<Record<string, any>>>) {

    return new Promise<boolean>((resolve) => {
        let engine = new Engine(undefined, { allowUndefinedFacts: true });

        engine = addBasicOperatorsToEngine(engine);

        if (isNotNullAndUndefined(rules) && rules.length > 0) {
            rules.forEach(x => {
                engine.addRule(x);
            });
        }

        engine.on('success', (event, almanac, ruleResult) => {
            //almanac.factValue(event.params.field).then(username => {
            //    console.log('success', `${username} succeeded ${ruleResult.name}! ${event.params.field}`, ruleResult)
            //})
            if (isNotNullAndUndefined(event)) {
                if (event.type === "setValue") {
                    if (isNotNullAndUndefined(form) && isNotNullAndUndefined(form.form) && isNotNullAndUndefined(form.form.mutators)) {
                        form.form.mutators.setValue(event.params.Field, event.params.Value);
                        //form.form.mutators.setFieldData(event.params.field, { value: event.params.value })
                    }
                }
                if (event.type === "clearValue") {
                    if (isNotNullAndUndefined(form) && isNotNullAndUndefined(form.form) && isNotNullAndUndefined(form.form.mutators)) {
                        form.form.mutators.setValue(event.params.Field, undefined);
                        //form.form.mutators.setFieldData(event.params.field, { value: event.params.value })
                    }
                }
                if (event.type === "showField") {
                    ShowElementAllOccurence("#rafdiv" + event.params.Field);
                }
                if (event.type === "hideField") {
                    HideElementAllOccurence("#rafdiv" + event.params.Field);
                }
                if (event.type === "showMessage") {
                    showInformationToast(event.params.Value);
                }
                if (event.type === "setCurrentDateAs") {
                    if (isNotNullAndUndefined(form) && isNotNullAndUndefined(form.form) && isNotNullAndUndefined(form.form.mutators)) {
                        form.form.mutators.setValue(event.params.Field, new Date());
                        //form.form.mutators.setFieldData(event.params.field, { value: event.params.value })
                    }
                }
                if (event.type === "setCurrentUserAs") {
                    if (isNotNullAndUndefined(form) && isNotNullAndUndefined(form.form) && isNotNullAndUndefined(form.form.mutators)) {
                        form.form.mutators.setValue(event.params.Field, "currentuser");
                        //form.form.mutators.setFieldData(event.params.field, { value: event.params.value })
                    }
                }
                if (event.type === "copyValue") {
                    if (isNotNullAndUndefined(form) && isNotNullAndUndefined(form.form) && isNotNullAndUndefined(form.form.mutators)) {
                        form.form.mutators.setValue(event.params.Field, isNotNullAndUndefined(facts[event.params.Value]) ? facts[event.params.Value] : undefined);
                        //form.form.mutators.setFieldData(event.params.field, { value: event.params.value })
                    }
                }
            }
        });

        engine.on('failure', (event, almanac, ruleResult) => {
            //almanac.factValue(event.params.field).then(username => {
            //    console.log('failure', `${username} succeeded ${ruleResult.name}! ${event.params.field}`, ruleResult)
            //})
            if (isNotNullAndUndefined(event)) {
                if (event.type === "setValue") {
                    if (isNotNullAndUndefined(form) && isNotNullAndUndefined(form.form) && isNotNullAndUndefined(form.form.mutators)) {
                        form.form.mutators.setValue(event.params.Field, (isNotNullAndUndefined(formState.initialValues) ? formState.initialValues[event.params.Field] : undefined));
                        //form.form.mutators.setFieldData(event.params.field, { value: event.params.value })
                    }
                }
                if (event.type === "clearValue") {
                    if (isNotNullAndUndefined(form) && isNotNullAndUndefined(form.form) && isNotNullAndUndefined(form.form.mutators)) {
                        form.form.mutators.setValue(event.params.Field, (isNotNullAndUndefined(formState.initialValues) ? formState.initialValues[event.params.Field] : undefined));
                        //form.form.mutators.setFieldData(event.params.field, { value: event.params.value })
                    }
                }
                if (event.type === "showField") {
                    HideElementAllOccurence("#rafdiv" + event.params.Field);
                }
                if (event.type === "hideField") {
                    ShowElementAllOccurence("#rafdiv" + event.params.Field);
                }
                if (event.type === "showMessage") {
                    hideToast();
                }
                if (event.type === "setCurrentDateAs") {
                    if (isNotNullAndUndefined(form) && isNotNullAndUndefined(form.form) && isNotNullAndUndefined(form.form.mutators)) {
                        form.form.mutators.setValue(event.params.Field, (isNotNullAndUndefined(formState.initialValues) ? formState.initialValues[event.params.Field] : undefined));
                        //form.form.mutators.setFieldData(event.params.field, { value: event.params.value })
                    }
                }
                if (event.type === "setCurrentUserAs") {
                    if (isNotNullAndUndefined(form) && isNotNullAndUndefined(form.form) && isNotNullAndUndefined(form.form.mutators)) {
                        form.form.mutators.setValue(event.params.Field, (isNotNullAndUndefined(formState.initialValues) ? formState.initialValues[event.params.Field] : undefined));
                        //form.form.mutators.setFieldData(event.params.field, { value: event.params.value })
                    }
                }
                if (event.type === "copyValue") {
                    if (isNotNullAndUndefined(form) && isNotNullAndUndefined(form.form) && isNotNullAndUndefined(form.form.mutators)) {
                        form.form.mutators.setValue(event.params.Field, (isNotNullAndUndefined(formState.initialValues) ? formState.initialValues[event.params.Field] : undefined));
                        //form.form.mutators.setFieldData(event.params.field, { value: event.params.value })
                    }
                }
            }
        });

        engine
            .run(facts)
            .then(({ }) => {
                //    events.map(event => {
                //        console.log('event', event, formState);
                //        if (event.type === "setValue") {
                //            if (isNotNullAndUndefined(form) && isNotNullAndUndefined(form.form) && isNotNullAndUndefined(form.form.mutators)) {
                //                //form.form.mutators.setValue(event.params.field, event.params.value);
                //                form.form.mutators.setFieldData(event.params.field, { value: event.params.value })
                //            }
                //        }
                //        if (event.type === "setError") {
                //            if (isNotNullAndUndefined(form) && isNotNullAndUndefined(form.form) && isNotNullAndUndefined(form.form.mutators)) {
                //                //form.form.mutators.setError(event.params.field, event.params.error);
                //                form.form.mutators.setFieldData(event.params.field, { error: event.params.error, validating: false })
                //            }
                //        }
                //        if (event.type === "hideField") {
                //            if (isNotNullAndUndefined(form) && isNotNullAndUndefined(form.form) && isNotNullAndUndefined(form.form.mutators)) {
                //                HideElementAllOccurence("#rafdiv" + event.params.field);
                //            }
                //        }
                //    })
                resolve(true);
            });

    });
}


export function runRulesAndSetError(rules: RuleProperties[], facts) {

    //return new Promise<{ field: string, error: string }[]>((resolve, reject) => {
    return new Promise<{}>((resolve) => {
        let engine = new Engine(undefined, { allowUndefinedFacts: true });

        engine = addBasicOperatorsToEngine(engine);

        if (isNotNullAndUndefined(rules) && rules.length > 0) {
            rules.forEach(x => {
                engine.addRule(x);
            });
        }

        engine.on('success', (event) => {
            //console.log('success11', event, almanac, ruleResult)
            //almanac.factValue(event.params.field).then(username => {
            //    console.log('success', `${username} succeeded ${ruleResult.name}! ${event.params.field}`, ruleResult)
            //})
            if (isNotNullAndUndefined(event)) {
                if (event.type === "makeRequired") {
                    if (IsNullOrWhiteSpace(facts[event.params.Field])) {
                        const errorList2 = {};
                        errorList2[event.params.Field] = IsNullOrWhiteSpace(event.params.Value) ? "required" : event.params.Value;
                        //console.log('errorList2', errorList2)
                        resolve(errorList2);
                    }
                }
            }
        });

        engine.on('failure', (event) => {
            //console.log('failure11', event, almanac, ruleResult)
            //almanac.factValue(event.params.field).then(username => {
            //    console.log('failure', `${username} succeeded ${ruleResult.name}! ${event.params.field}`, ruleResult)
            //})
            if (isNotNullAndUndefined(event)) {
                if (event.type === "makeRequired") {
                    const errorList2 = {};
                    //errorList2[event.params.field] = null;
                    delete errorList2[event.params.Field];
                    //console.log('errorList2', errorList2)
                    resolve(errorList2);
                }
            }
        });

        engine
            .run(facts);
        //.then(({ events }) => {
        //    //const errorList: { field: string, error: string }[] = [];
        //    const errorList2 = {};
        //    events.map(event => {
        //        if (event.type === "setError") {
        //            //errorList.push({ field: event.params.field, error: event.params.error });
        //            errorList2[event.params.field] = event.params.error;
        //        }
        //    })
        //    //resolve(errorList);
        //    resolve(errorList2);

        //})


    });
}

export function runRulesEngine1(rules: RuleProperties[], facts) {

    return new Promise<BusinessAction[]>((resolve) => {
        let engine = new Engine(undefined, { allowUndefinedFacts: true });

        engine = addBasicOperatorsToEngine(engine);

        if (isNotNullAndUndefined(rules) && rules.length > 0) {
            rules.forEach(x => {
                engine.addRule(x);
            });
        }


        engine.on('success', (event, almanac, ruleResult) => {
            //console.log('success11', event, almanac, ruleResult)
        });

        engine.on('failure', (event, almanac, ruleResult) => {
            // console.log('failure11', event, almanac, ruleResult)
        });

        engine
            .run(facts)
            .then(({ events }) => {
                if (isNotNullAndUndefined(events) && events.length > 0) {
                    const retVal: BusinessAction[] = events[0].params as BusinessAction[];
                    resolve(retVal);
                }
                else {
                    resolve(null);
                }
            });

    });
}
